import React, { useState } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import Tree from './Treechart/TreeChart';
// import List from './List/List';
import RefferalChart from './Chart/RefferalChart';
import DataTable from './List';


const Tabs = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <UncontrolledTooltip placement="top" target="refTreeHelp">
        In this section, people are invited to view your subset structurally.
        You can also search for the FFCode subset in the tree structure.
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target="refChartHelp">
        In this section, you can see the level of your activity in the form of a chart
        based on the time and number of people invited.
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target="refListHelp">
        In this section, you can see your invited subsets in a tabular structure.
        You can also search the record based on all the fields in the table.
      </UncontrolledTooltip>

      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' }, 'tab_link')}
              onClick={() => toggle('1')}
            >
              Refferal Tree <i id="refTreeHelp" className="fas fa-info-circle" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' }, 'tab_link')}
              onClick={() => toggle('2')}
            >
              Refferal Chart <i id="refChartHelp" className="fas fa-info-circle" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' }, 'tab_link')}
              onClick={() => toggle('3')}
            >
              Refferal List <i id="refListHelp" className="fas fa-info-circle" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Tree />
          </TabPane>
          <TabPane tabId="2">
            <RefferalChart />
          </TabPane>
          <TabPane tabId="3">
            <DataTable />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Tabs;
