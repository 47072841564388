import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  UncontrolledTooltip, Button, Modal, ButtonToolbar, Row, Col,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReactSpeedometer from 'react-d3-speedometer';
import { setBalance, setHavePlan, getRefferal } from '../../../../../redux/actions/userActions';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { store } from '../../../../App/store';
import { serverURL } from '../../../../../utils/url';
import NoSecPass from '../../../../Layout/NoSecPass';



const BuyInvestNCC = ({
  balanceWallet,
  havePlans,
  stackingLoad,
  FFCode,
  isActive,
  isAllowPlan,
  haveSecPass,
  plans,
  plancrypto,
  currencies,
  tab,
  StackingSummary,
  BuyInvestSummary,
}) => {
  const [secPass, setSecPass] = useState();
  const [inRequest, setInRequest] = useState(false);
  const [disable, setDisable] = useState(false);
  const [, forceUpdate] = useState();

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [nccNeed, setNccNeed] = useState();
  const [nccMax, setNccMax] = useState();

  const [stakeNcc, setStakeNcc] = useState();
  const [feeNCC, setFeeNCC] = useState(4444.4);
  const [totalNCC, setTotalNCC] = useState(44444);
  const [payedNCC, setPayedNCC] = useState(26789);
  const [payedTime, setPayedTime] = useState(6);

  const [stackingTimeOk, setStackingTimeOk] = useState(true);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());

  const secInput = useRef(null);
  const dispatch = useDispatch();


  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const reset = () => {
    setStakeNcc('');
    validator.current.hideMessages();
    forceUpdate(13);
  };

  const handlecheckStacking = () => {
    // e.preventDefault();
    if (validator.current.fieldValid('amount')) {
      toggle();
    } else {
      validator.current.showMessageFor('amount');
      forceUpdate(18);
    }
  };

  const handleCheckAmount = async (e) => {
    await setStakeNcc(e.target.value);
    if (validator.current.fieldValid('amount') && !Number.isNaN(+e.target.value)) {
      setUnValid(false);
      setValid('valid');
    } else if (Number.isNaN(+e.target.value)) {
      setStakeNcc('');
      setUnValid(true);
      setValid('');
      forceUpdate(1);
    } else if (!Number.isNaN(+e.target.value)) {
      setUnValid(true);
      setValid('');
      forceUpdate(1);
    }
  };

  const handleStacking = () => {
    if (validator.current.fieldValid('Security Password')) {
      const stakingData = {
        FFCode,
        plan: 6,
        token: localStorage.getItem('token'),
        secPass,
        StakingValue: stakeNcc,
        Currency: 1,
      };
      console.log(stakingData);
      setInRequest(true);
      setDisable(true);
      reset();
      localStorage.setItem('lastReq', new Date().getTime());
      AuthRequest(`${serverURL}/staking/setStaking`, stakingData)
        .then(res => res.json())
        .then((data) => {
          setInRequest(false);
          setDisable(false);
          console.log(data);
          if (data.numReq === 1 && data.sttReq === 'ok') {
            toast.success('You successfully active the Staking plan !', {
              position: 'top-center',
              autoClose: 15000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
            // dispatch(setHavePlan(data.havePlans));
            // dispatch(setBalance(data.Balance));
            // dispatch(getRefferal());
          } else if (data.numReq === -2 && data.sttReq === 'you have this plan') {
            toast.error('You already have the Staking plan !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setHavePlan(data.havePlans));
          } else if (data.numReq === -2 && data.sttReq === 'user have problem') {
            toast.error('Your process cant be done, Try again later', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'not enough cash') {
            toast.error('There is not enough cash in your balance !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'plan not active') {
            toast.error('There Staking plan is not active right now !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'expire') {
            toast.error('The Staking plan is not available in this date !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'wrong secPass') {
            toast.error('The entered security is not true, try again !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq
            === 'Your account does not have enough TRX for this plan') {
            toast.error('Your account does not have enough TRX to activate this plan !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInRequest(false);
          setDisable(false);
        });
      togglePass();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    // setPayedTime(0);
    // setPayedTime(BuyInvestSummary?.monthCount || 0);
    setFeeNCC((BuyInvestSummary?.nccTotal / 10) || 0);
    setTotalNCC(Math.floor(BuyInvestSummary?.nccTotal) || 0);
    setPayedNCC(BuyInvestSummary?.nccPay || 0);
    setPayedTime(((Math.floor(BuyInvestSummary?.nccPay)) / (Math.floor(BuyInvestSummary?.nccTotal))).toFixed(1) * 10);

    const startDate = new Date(BuyInvestSummary?.created_at);
    const finishDate = new Date(startDate?.setMonth(startDate.getMonth() + 10));
    if ((new Date()) > finishDate) {
      setPayedTime(10);
    }
  }, [BuyInvestSummary]);


  useEffect(() => {
    const stackingTimeStart = Date.parse(plans.find(entry => entry.id === 3).fromDate.replace(/-/g, '/'));
    const stackingTimeNow = new Date().getTime();
    const stackingTimeEnd = Date.parse(plans.find(entry => entry.id === 3).toDate.replace(/-/g, '/'));

    // if (isActive
    //   && plans.find(i => i.id === 3)?.isActive === 1
    //   && balanceWallet?.NCC?.Deposit > 0
    //   && !stackingLoad
    //   && (stackingTimeNow < stackingTimeEnd && stackingTimeNow > stackingTimeStart)
    // ) {
    //   setDisable(false);
    // }

    // if (havePlans) {
    //   if (havePlans.Stacking) {
    //     if (havePlans.Stacking?.isActiveUserPlan === 1) {
    //       setHaveStacking(true);
    //     } else {
    //       setHaveStacking(false);
    //     }
    //   }
    // }

    if (stackingTimeNow > stackingTimeEnd || stackingTimeNow < stackingTimeStart) {
      setStackingTimeOk(false);
    }

    if (balanceWallet?.TRX?.Deposit < 20) {
      setDisable(true);
    }

    if (!isActive) {
      setDisable(true);
    }

    if (plans.find(i => i.id === 3)?.isActive !== 1) {
      setDisable(true);
    }

    if (plancrypto) {
      setNccNeed(plancrypto.find(entry => entry.id === 6)?.amount);
      setNccMax(Math.min(plancrypto.find(entry => entry.id === 6)?.maxAmount, 17778));
    }
  }, [plancrypto, havePlans, plans]);



  return (
    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleStacking();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleStacking}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >

        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          <p>Stacking <strong> {stakeNcc} </strong>  NCC for {12} months</p>
          {/* ? */}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>



      <div className="text-center bi_report_holder">

        <div className="text-left">
          <h6>
            NCC Invest ( {totalNCC} ) :
          </h6>
        </div>

        <div className="progress binvest_progress_bar mb-3 mt-3 m-auto">
          <div
            className={payedTime >= 1 ? 'progress-bar binvest_steps_bar_fill binvest_steps_bar_first'
              : 'progress-bar binvest_steps_bar binvest_steps_bar_first'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 2 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 3 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 4 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 5 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 6 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 7 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 8 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 9 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>
          <div
            className={payedTime >= 10 ? 'progress-bar binvest_steps_bar_fill binvest_steps_bar_last'
              : 'progress-bar binvest_steps_bar binvest_steps_bar_last'}
          >
            <span className="BI_bar_ncc">
              {Math.floor(feeNCC)}
            </span>
          </div>

        </div>

        <div className="mt-5">
          <Row>
            <Col md={3} xs={6}>
              <div className="bi_info_holder">
                <span>
                  Receive :
                </span>
                {' '}
                <span>
                  <strong>
                    {Math.floor(payedNCC)}
                  </strong>
                </span>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="bi_info_holder">
                <span>
                  Remain :
                </span>
                {' '}
                <span>
                  <strong>
                    {Math.floor(totalNCC - payedNCC)}
                  </strong>
                </span>
              </div>
            </Col>
            <Col>
              {/* <form> */}
              <div className="input-group flex-nowrap bi_stak_input">
                <input
                  className={unValid ? 'unValid form-control text-center ' : `${valid} form-control text-center`}
                  type="text"
                  placeholder="Amount"
                  id="biStakInput"
                  value={stakeNcc}
                  onBlur={() => {
                    setUnValid(false);
                    setValid('');
                    // setTimeout(() => {
                    //   validator.current.hideMessages();
                    //   forceUpdate(22);
                    // }, 5000);
                  }}
                  onChange={e => handleCheckAmount(e)}
                  onClick={() => setStakeNcc('')}
                  disabled={Boolean(true)}
                />
                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={() => handlecheckStacking()}
                  disabled={true
                    // || disable
                    || payedNCC === 0
                    || balanceWallet?.TRX?.['buy invest'] === 0
                    || !isAllowPlan
                    || stackingLoad
                    // || !stackingTimeOk
                    // || haveStacking 
                    ? 'true'
                    : ''}
                >
                  Stake Now !
                </button>
              </div>
              {/* </form> */}
              <div className="tefo_error tefo_error_strong">
                {validator.current.message('amount', stakeNcc,
                  `required|min:${nccNeed},num|max:${nccMax},num|numeric|integer`)}
              </div>
            </Col>
          </Row>
        </div>

        <hr className="hr_bi_invest" />

      </div>
    </>
  );
};

BuyInvestNCC.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader, constants } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    StackingSummary: userInfo.user?.StackingSummary,
    BuyInvestSummary: userInfo.user?.BuyInvestSummary?.active,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
  };
}


export default connect(mapStateToProps)(BuyInvestNCC);
