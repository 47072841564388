import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import { Card, CardBody } from 'reactstrap';

import getTooltipStyles from '@/shared/helpers';
import { getMainColor } from '../../../../shared/helpers/themHelper';


const RefferalBar = ({ theme, chartData }) => {
  const [themeColor, setThemeColor] = useState('');

  const dataMontly = [
    { name: '1', num: chartData?.days?.find(c => c.name === 1)?.num || 0 },
    { name: '2', num: chartData?.days?.find(c => c.name === 2)?.num || 0 },
    { name: '3', num: chartData?.days?.find(c => c.name === 3)?.num || 0 },
    { name: '4', num: chartData?.days?.find(c => c.name === 4)?.num || 0 },
    { name: '5', num: chartData?.days?.find(c => c.name === 5)?.num || 0 },
    { name: '6', num: chartData?.days?.find(c => c.name === 6)?.num || 0 },
    { name: '7', num: chartData?.days?.find(c => c.name === 7)?.num || 0 },
    { name: '8', num: chartData?.days?.find(c => c.name === 8)?.num || 0 },
    { name: '9', num: chartData?.days?.find(c => c.name === 9)?.num || 0 },
    { name: '10', num: chartData?.days?.find(c => c.name === 10)?.num || 0 },
    { name: '11', num: chartData?.days?.find(c => c.name === 11)?.num || 0 },
    { name: '12', num: chartData?.days?.find(c => c.name === 12)?.num || 0 },
    { name: '13', num: chartData?.days?.find(c => c.name === 13)?.num || 0 },
    { name: '14', num: chartData?.days?.find(c => c.name === 14)?.num || 0 },
    { name: '15', num: chartData?.days?.find(c => c.name === 15)?.num || 0 },
    { name: '16', num: chartData?.days?.find(c => c.name === 16)?.num || 0 },
    { name: '17', num: chartData?.days?.find(c => c.name === 17)?.num || 0 },
    { name: '18', num: chartData?.days?.find(c => c.name === 18)?.num || 0 },
    { name: '19', num: chartData?.days?.find(c => c.name === 19)?.num || 0 },
    { name: '20', num: chartData?.days?.find(c => c.name === 20)?.num || 0 },
    { name: '21', num: chartData?.days?.find(c => c.name === 21)?.num || 0 },
    { name: '22', num: chartData?.days?.find(c => c.name === 22)?.num || 0 },
    { name: '23', num: chartData?.days?.find(c => c.name === 23)?.num || 0 },
    { name: '24', num: chartData?.days?.find(c => c.name === 24)?.num || 0 },
    { name: '25', num: chartData?.days?.find(c => c.name === 25)?.num || 0 },
    { name: '26', num: chartData?.days?.find(c => c.name === 26)?.num || 0 },
    { name: '27', num: chartData?.days?.find(c => c.name === 27)?.num || 0 },
    { name: '28', num: chartData?.days?.find(c => c.name === 28)?.num || 0 },
    { name: '29', num: chartData?.days?.find(c => c.name === 29)?.num || 0 },
    { name: '30', num: chartData?.days?.find(c => c.name === 30)?.num || 0 },
    { name: '31', num: chartData?.days?.find(c => c.name === 31)?.num || 0 },
  ];

  const dataYearly = [
    { name: 'Jan', num: chartData?.moon?.find(c => c.name === 'Jan')?.num || 0 },
    { name: 'Feb', num: chartData?.moon?.find(c => c.name === 'Feb')?.num || 0 },
    { name: 'Mar', num: chartData?.moon?.find(c => c.name === 'Mar')?.num || 0 },
    { name: 'Apr', num: chartData?.moon?.find(c => c.name === 'Apr')?.num || 0 },
    { name: 'May', num: chartData?.moon?.find(c => c.name === 'May')?.num || 0 },
    { name: 'Jun', num: chartData?.moon?.find(c => c.name === 'Jun')?.num || 0 },
    { name: 'Jul', num: chartData?.moon?.find(c => c.name === 'Jul')?.num || 0 },
    { name: 'Aug', num: chartData?.moon?.find(c => c.name === 'Aug')?.num || 0 },
    { name: 'Sep', num: chartData?.moon?.find(c => c.name === 'Sep')?.num || 0 },
    { name: 'Oct', num: chartData?.moon?.find(c => c.name === 'Oct')?.num || 0 },
    { name: 'Nov', num: chartData?.moon?.find(c => c.name === 'Nov')?.num || 0 },
    { name: 'Dec', num: chartData?.moon?.find(c => c.name === 'Dec')?.num || 0 },
  ];

  const dataWeekly = [
    { name: 'Mon', num: chartData?.week?.find(c => c.name === 'Mon')?.num || 0 },
    { name: 'Sun', num: chartData?.week?.find(c => c.name === 'Sun')?.num || 0 },
    { name: 'Tue', num: chartData?.week?.find(c => c.name === 'Tue')?.num || 0 },
    { name: 'Wed', num: chartData?.week?.find(c => c.name === 'Wed')?.num || 0 },
    { name: 'Thu', num: chartData?.week?.find(c => c.name === 'Thu')?.num || 0 },
    { name: 'Fri', num: chartData?.week?.find(c => c.name === 'Fri')?.num || 0 },
    { name: 'Sat', num: chartData?.week?.find(c => c.name === 'Sat')?.num || 0 },
  ];

  const [dataKind, setDataKind] = useState(dataYearly);

  const handleChangeToYearly = () => {
    setDataKind(dataYearly);
  };
  const handleChangeMontly = () => {
    setDataKind(dataMontly);
  };
  const handleChangeToWeekly = () => {
    setDataKind(dataWeekly);
  };

  useEffect(() => {
    setDataKind(dataWeekly);
  }, [chartData]);

  useEffect(() => {
    // if (theme === 'theme-dark') {
    setThemeColor(getMainColor());
    // } else if (theme === 'theme-capital') {
    //   setThemeColor('#ff8f1f');
    // }
  }, [theme]);

  return (
    <>
      <Card>
        <CardBody>
          <div dir="ltr">
            <ResponsiveContainer height={260}>
              <BarChart data={dataKind} margin={{ top: 10, left: -20 }}>
                <XAxis dataKey="name" tickLine={false} />
                <YAxis tickLine={false} />
                {/* <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} /> */}
                <Tooltip
                  itemStyle={{ color: 'Black', backgroundColor: themeColor }}
                  labelStyle={{ color: 'Black', backgroundColor: themeColor }}
                  contentStyle={{ color: 'Black', backgroundColor: themeColor }}
                  {...getTooltipStyles('defaultItems')}
                />
                <CartesianGrid vertical={false} />
                <Bar dataKey="num" name="Member" className="desk_ref_chart_bar" barSize={8} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* <hr /> */}
          <div className="text-center">
            <button
              type="button"
              className={dataKind?.length === 12
                ? 'btn table-btn active_btn_chart'
                : 'btn table-btn btn-outline-theme'}
              onClick={e => handleChangeToYearly()}
            >
              Yearly
            </button>
            <button
              type="button"
              className={dataKind?.length === 31
                ? 'btn table-btn active_btn_chart'
                : 'btn table-btn btn-outline-theme'}
              onClick={e => handleChangeMontly()}
            >
              Monthly
            </button>
            <button
              type="button"
              className={dataKind?.length === 7
                ? 'btn table-btn active_btn_chart'
                : 'btn table-btn btn-outline-theme'}
              onClick={e => handleChangeToWeekly()}
            >
              Weekly
            </button>
          </div>
        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};

RefferalBar.propTypes = {
  theme: PropTypes.string.isRequired,
  chartData: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  const { theme } = state;
  return {
    chartData: userInfo.user?.Refferal?.chartData,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(RefferalBar);
