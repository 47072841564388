import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  PieChart, Pie,
} from 'recharts';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import TimeLine from './SubComponents/TimeLine';
import { getMainColor } from '../../../../shared/helpers/themHelper';


const Levels = ({ levels, theme }) => {
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);

  const [, forceUpdate] = useState();

  const [fillcolor, setFillcolor] = useState('#3cd2a5');

  useEffect(() => {
    if (levels?.level1) {
      setValue1(levels?.level1);
    } else {
      setValue1(0);
    }
    if (levels?.level2) {
      setValue2(levels?.level2);
    } else {
      setValue2(0);
    }
    if (levels?.level3) {
      setValue3(levels?.level3);
    } else {
      setValue3(0);
    }
    forceUpdate(13);
  }, [levels]);


  const level1 = [{ value: value1 * 20, fill: fillcolor },
  { value: 100 - value1 * 20, fill: '#f2f4f7' }];
  const level1More = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];


  const level2 = [{ value: value2 * 4, fill: fillcolor },
  { value: 100 - value2 * 4, fill: '#f2f4f7' }];
  const level2More = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];


  const level3 = [{ value: (value3 * 100) / 125, fill: fillcolor },
  { value: 100 - (value3 * 100) / 125, fill: '#f2f4f7' }];
  const level3More = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];


  // useEffect(() => {
  // setInterval(() => {
  //   setFillcolor('#36d5a57d');
  //   setTimeout(() => {
  //     setFillcolor('#3cd2a5');
  //   }, 2000);
  // }, 4000);

  // setInterval(async () => {
  // await setValue1(0);
  // await setValue2(0);
  // await setValue3(0);
  // setTimeout(async () => {
  // await setValue1(3);
  // await setValue2(16);
  // await setValue3(79);
  //   }, 3000);
  // }, 6000);
  // }, []);

  useEffect(() => {
    setFillcolor(getMainColor());
  }, [theme]);


  return (

    <Card>
      <CardBody className="cardBody_low_padding_desk cardBody_lvl_margin_desk">
        <Row className="desk_msg_no_margin_row">
          <Col md={4} xs={4}>
            <div className="dashboard__current-users">
              <div className="dashboard__current-users-chart">
                <PieChart className="desk_levels_charts" height={150} width={280}>
                  <Pie
                    data={value1 > 5 ? level1More : level1}
                    dataKey="value"
                    cx={135}
                    cy={140}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={121}
                    outerRadius={130}
                    paddingAngle={1.5}
                  />
                </PieChart>
                <p className="dashboard__current-users-label dashboard__current-users-label_5">
                  {value1 > 5 ? 5 : value1}<span className="levels_ofValue"> / 5</span>
                </p>
              </div>
              <div className="levels_level">
                Level 1
              </div>
            </div>
          </Col>
          <Col md={4} xs={4}>
            <div className="dashboard__current-users">
              <div className="dashboard__current-users-chart">
                <PieChart className="desk_levels_charts" height={150} width={280}>
                  <Pie
                    data={value2 > 25 ? level2More : level2}
                    dataKey="value"
                    cx={135}
                    cy={140}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={121}
                    outerRadius={130}
                    paddingAngle={1.5}
                  />
                </PieChart>
                <p className="dashboard__current-users-label dashboard__current-users-label_25">
                  {value2 > 25 ? 25 : value2}<span className="levels_ofValue"> / 25</span>
                </p>
              </div>
              <div className="levels_level">
                Level 2
              </div>
            </div>
          </Col>
          <Col md={4} xs={4}>
            <div className="dashboard__current-users">
              <div className="dashboard__current-users-chart">
                <PieChart className="desk_levels_charts" height={150} width={280}>
                  <Pie
                    data={value3 > 125 ? level3More : level3}
                    dataKey="value"
                    cx={135}
                    cy={140}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={121}
                    outerRadius={130}
                    paddingAngle={1.5}
                  />
                </PieChart>
                <p className="dashboard__current-users-label">
                  {value3 > 125 ? 125 : value3}<span className="levels_ofValue"> / 125</span>
                </p>
              </div>
              <div className="levels_level">
                Level 3
              </div>
            </div>
          </Col>
          <TimeLine />
        </Row>

      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};

Levels.propTypes = {
  levels: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    levels: userInfo.user?.levels,
    theme: theme?.className,
  };
}


export default connect(mapStateToProps)(Levels);
