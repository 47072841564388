import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  Row, Col,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { setCurrency } from '../../../../../redux/actions/currencyActions';
import { getMainColor } from '../../../../../shared/helpers/themHelper';



const AffiliateTable = ({
  ncc, Affiliate, plancrypto, planProfitSetting, theme,
}) => {
  const [lvl, setLvl] = useState(0);
  const [level, setLevel] = useState(0);
  const [fee, setFee] = useState(0);
  const [feeTether, setFeeTether] = useState(0);
  const [Ncc, setNcc] = useState(0);

  const [totalNcc3, setTotalNcc3] = useState(0);
  const [totalTether3, setTotalTether3] = useState(0);

  const [totalNcc6, setTotalNcc6] = useState(0);
  const [totalTether6, setTotalTether6] = useState(0);

  const [totalTether, setTotalTether] = useState(0);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState();

  const [themeColor, setThemeColor] = useState();

  useEffect(() => {
    setThemeColor(getMainColor());
  }, [theme]);

  const dispatch = useDispatch();


  useEffect(() => {
    setNcc(ncc);
    setFeeTether(plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount);
    // setFeeTether(100);

    setFee(Math.floor(((plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount)
      * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
        && p.forStonk === 1)?.amount / 100)) / ncc));
  }, [plancrypto]);


  const profitPure = (100
    - Math.floor(plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).profitStonk)) / 100;


  const commafy = (num) => {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const validatorConfigs = {
    element: message => <div className="validationErr">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  // if (planProfitSetting[0]) {
  //   let sum = 0;
  //   for (let i = 0; i < planProfitSetting?.length; i += 1) {
  //     if (planProfitSetting[i]?.planId === 2) {
  //       sum += (planProfitSetting[i]?.amount);
  //     }
  //   }
  //   console.log(sum);
  //   setTetherNeed(sum);
  // }



  const handleLevel = async (e) => {
    e.preventDefault();

    if (validator.current.allValid() && Number(Ncc) > 0) {
      await setLevel(lvl);

      await setFee(Math.floor(((plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount)
        * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
          && p.forStonk === 1)?.amount / 100)) / Ncc));

      const sumNcc3 = commafy(Math.floor(((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
        && p.cryptocurrencyId === 3).amount
        * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
          && p.forStonk === 1)?.amount / 100))
        / Ncc) * (Affiliate.Level1.profit / 100) * profitPure))
        + Math.floor(((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level2.profit / 100) * profitPure))
        + Math.floor(((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level3.profit / 100) * profitPure)));


      const sumTether3 = (Math.floor(((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
        && p.cryptocurrencyId === 3).amount
        * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
          && p.forStonk === 1)?.amount / 100))
        / Ncc) * (Affiliate.Level1.profit / 100) * profitPure * Ncc)
        - (Math.ceil(Math.floor((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc)
          * (Affiliate.Level1.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level2.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level2.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level3.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level3.profit / 100) * profitPure) / 3000) * 30)));

      const sumNcc6 = commafy(Math.floor(((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
        && p.cryptocurrencyId === 3).amount
        * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
          && p.forStonk === 1)?.amount / 100))
        / Ncc) * (Affiliate.Level1.profit / 100) * profitPure))
        + Math.floor(((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level2.profit / 100) * profitPure))
        + Math.floor(((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level3.profit / 100) * profitPure))
        + Math.floor(((lvl ** 4) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level4.profit / 100) * profitPure))
        + Math.floor(((lvl ** 5) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level5.profit / 100) * profitPure))
        + Math.floor(((lvl ** 6) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level6.profit / 100) * profitPure)));

      const sumTether6 = commafy((Math.floor(((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
        && p.cryptocurrencyId === 3).amount
        * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
          && p.forStonk === 1)?.amount / 100))
        / Ncc) * (Affiliate.Level1.profit / 100) * profitPure * Ncc)
        - (Math.ceil(Math.floor((lvl ** 1) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc)
          * (Affiliate.Level1.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level2.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 2) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level2.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level3.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 3) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level3.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 4) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level4.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 4) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level4.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 5) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level5.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 5) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level5.profit / 100) * profitPure) / 3000) * 30)))
        + (Math.floor(((lvl ** 6) * Math.floor((plancrypto.find(p => p.ffplanId === 2
          && p.cryptocurrencyId === 3).amount
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 1)?.amount / 100))
          / Ncc) * (Affiliate.Level6.profit / 100) * profitPure * Ncc)
          - (Math.ceil(Math.floor((lvl ** 6) * Math.floor((plancrypto.find(p => p.ffplanId === 2
            && p.cryptocurrencyId === 3).amount
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 1)?.amount / 100))
            / Ncc)
            * (Affiliate.Level6.profit / 100) * profitPure) / 3000) * 30))));

      const sumTether = commafy(Math.floor(((lvl ** 1)
        * plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount
        * (Math.ceil((Affiliate?.Level1T?.profit)
          * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
            && p.forStonk === 0)?.amount / 100)) / 100) * profitPure)
        + ((lvl ** 2) * plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount
          * (Math.ceil((Affiliate?.Level2T?.profit)
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 0)?.amount / 100)) / 100) * profitPure)
        + ((lvl ** 3) * plancrypto.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3).amount
          * (Math.ceil((Affiliate?.Level3T?.profit)
            * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
              && p.forStonk === 0)?.amount / 100)) / 100)
          * profitPure)));
      // const sumTether = commafy(Math.floor(((lvl ** 1)
      //   * 100 * (10 / 100) * profitPure)
      //   + ((lvl ** 2) * 100
      //     * (5 / 100) * profitPure)
      //   + ((lvl ** 3) * 100 * (10 / 100)
      //     * profitPure)));

      await setTotalNcc3(sumNcc3);
      forceUpdate(13);
      await setTotalTether3(sumTether3);
      forceUpdate(13);
      await setTotalNcc6(sumNcc6);
      forceUpdate(13);
      await setTotalTether6(sumTether6);
      forceUpdate(13);
      await setTotalNcc3(sumNcc3);
      forceUpdate(13);
      await setTotalTether(sumTether);
      forceUpdate(16);
    } else {
      // setUnValid(true);
      // setValid('');
      // setLvl('');
      // setNcc('');
      // validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <div className="mb-3 mt-3">
        In this table, by entering the number of people invited by you who have activated their affiliate plan,
        you can see the amount of your reward from the following levels.
      </div>
      <div className="mb-3 mt-3">
        <span className="table_title">
          NCC Profits calculations :
        </span>
        <table className="table table-sm affiliate text-center calcTable react-table resizable-table">
          <thead>
            <tr>
              <th scope="col">Level</th>
              <th scope="col">Member</th>
              <th scope="col">
                Fee({(plancrypto?.find(p => p.ffplanId === 2 && p.cryptocurrencyId === 3)?.amount)
                  * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                    && p.forStonk === 1)?.amount / 100)}$)
              </th>
              <th scope="col">Total</th>
              <th scope="col">%</th>
              <th scope="col">Receive</th>
              {profitPure === 1 ? (
                ''
              ) : (
                <th scope="col">Pure</th>
              )}
              <th scope="col">$</th>
              <th scope="col">Pure$</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>{commafy(level ** 1)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 1) * fee)}</td>
              <td>%{Affiliate?.Level1?.profit}</td>
              <td>{commafy(Math.floor((level ** 1) * fee * (Affiliate?.Level1?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 1) * fee * (Affiliate?.Level1?.profit / 100) * profitPure))}</td>
              )}
              <td>{Math.floor((level ** 1) * fee * (Affiliate?.Level1?.profit / 100) * profitPure * Ncc)}</td>
              <td>
                {Math.floor(((level ** 1) * fee * (Affiliate?.Level1?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 1) * fee
                    * (Affiliate?.Level1?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{commafy(level ** 2)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 2) * fee)}</td>
              <td>%{Affiliate?.Level2?.profit}</td>
              <td>{commafy(Math.floor((level ** 2) * fee * (Affiliate?.Level2?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 2) * fee * (Affiliate?.Level2?.profit / 100) * profitPure))}</td>
              )}
              <td>{commafy(Math.floor((level ** 2) * fee * (Affiliate?.Level2?.profit / 100) * profitPure * Ncc))}</td>
              <td>
                {Math.floor(((level ** 2) * fee * (Affiliate?.Level2?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 2) * fee
                    * (Affiliate?.Level2?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{commafy(level ** 3)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 3) * fee)}</td>
              <td>%{Affiliate?.Level3?.profit}</td>
              <td>{commafy(Math.floor((level ** 3) * fee * (Affiliate?.Level3?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 3) * fee * (Affiliate?.Level3?.profit / 100) * profitPure))}</td>
              )}
              <td>{commafy(Math.floor((level ** 3) * fee * (Affiliate?.Level3?.profit / 100) * profitPure * Ncc))}</td>
              <td>
                {Math.floor(((level ** 3) * fee * (Affiliate?.Level3?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 3) * fee
                    * (Affiliate?.Level3?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total NCC: </th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>{totalNcc3}</td>
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total USDT:</th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>{totalTether3}</td>
            </tr>
            <tr>
              <td style={{ border: 'none' }} />
            </tr>
            <tr>
              <td style={{ border: 'none' }} />
            </tr>
            <tr>
              <td style={{ border: '0px' }} />
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{commafy(level ** 4)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 4) * fee)}</td>
              <td>%{Affiliate?.Level4?.profit}</td>
              <td>{commafy(Math.floor((level ** 4) * fee * (Affiliate?.Level4?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 4) * fee * (Affiliate?.Level4?.profit / 100) * profitPure))}</td>
              )}
              <td>{commafy(Math.floor((level ** 4) * fee * (Affiliate?.Level4?.profit / 100) * profitPure * Ncc))}</td>
              <td>
                {Math.floor(((level ** 4) * fee * (Affiliate?.Level4?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 4) * fee
                    * (Affiliate?.Level4?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>{commafy(level ** 5)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 5) * fee)}</td>
              <td>%{Affiliate?.Level5?.profit}</td>
              <td>{commafy(Math.floor((level ** 5) * fee * (Affiliate?.Level5?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 5) * fee * (Affiliate?.Level5?.profit / 100) * profitPure))}</td>
              )}
              <td>{commafy(Math.floor((level ** 5) * fee * (Affiliate?.Level5?.profit / 100) * profitPure * Ncc))}</td>
              <td>
                {Math.floor(((level ** 5) * fee * (Affiliate?.Level5?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 5) * fee
                    * (Affiliate?.Level5?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>{commafy(level ** 6)}</td>
              <td>{fee}</td>
              <td>{commafy((level ** 6) * fee)}</td>
              <td>%{Affiliate?.Level6?.profit}</td>
              <td>{commafy(Math.floor((level ** 6) * fee * (Affiliate?.Level6?.profit / 100)))}</td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 6) * fee * (Affiliate?.Level6?.profit / 100) * profitPure))}</td>
              )}
              <td>{commafy(Math.floor((level ** 6) * fee * (Affiliate?.Level6?.profit / 100) * profitPure * Ncc))}</td>
              <td>
                {Math.floor(((level ** 6) * fee * (Affiliate?.Level6?.profit / 100) * profitPure * Ncc)
                  - (Math.ceil(Math.floor((level ** 6) * fee
                    * (Affiliate?.Level6?.profit / 100) * profitPure) / 3000) * 30))}
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total NCC :</th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>{totalNcc6}</td>
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total USDT :</th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>{totalTether6}</td>
            </tr>
          </tbody>
        </table>
        <span className="table_title">
          USDT Profits calculations :
        </span>
        <table className="table table-sm affiliate text-center calcTable react-table resizable-table">
          <thead>
            <tr>
              <th scope="col">Level</th>
              <th scope="col">Member</th>
              <th scope="col">Fee$</th>
              <th scope="col">Total</th>
              <th scope="col">%</th>
              <th scope="col">Receive</th>
              {profitPure === 1 ? (
                ''
              ) : (
                <th scope="col">Pure$</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>{commafy(level ** 1)}</td>
              <td>{feeTether}</td>
              <td>{commafy((level ** 1) * feeTether)}</td>
              <td>%{Math.ceil((Affiliate?.Level1T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100))}
              </td>
              <td>{commafy(Math.floor((level ** 1) * feeTether * (Math.ceil((Affiliate?.Level1T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100)) / 100)))}
              </td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 1) * feeTether * (Math.ceil((Affiliate?.Level1T?.profit)
                  * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                    && p.forStonk === 0)?.amount / 100)) / 100) * profitPure))}
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{commafy(level ** 2)}</td>
              <td>{feeTether}</td>
              <td>{commafy((level ** 2) * feeTether)}</td>
              <td>%{Math.ceil((Affiliate?.Level2T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100))}
              </td>
              <td>{commafy(Math.floor((level ** 2) * feeTether * (Math.ceil((Affiliate?.Level2T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100)) / 100)))}
              </td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 2) * feeTether * (Math.ceil((Affiliate?.Level2T?.profit)
                  * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                    && p.forStonk === 0)?.amount / 100)) / 100) * profitPure))}
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{commafy(level ** 3)}</td>
              <td>{feeTether}</td>
              <td>{commafy((level ** 3) * feeTether)}</td>
              <td>%{Math.ceil((Affiliate?.Level3T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100))}
              </td>
              <td>{commafy(Math.floor((level ** 3) * feeTether * (Math.ceil((Affiliate?.Level3T?.profit)
                * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                  && p.forStonk === 0)?.amount / 100)) / 100)))}
              </td>
              {profitPure === 1 ? (
                ''
              ) : (
                <td>{commafy(Math.floor((level ** 3) * feeTether * (Math.ceil((Affiliate?.Level3T?.profit)
                  * (planProfitSetting?.find(p => p.planId === 2 && p.planDetailId === 5
                    && p.forStonk === 0)?.amount / 100)) / 100) * profitPure))}
                </td>
              )}
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total USDT :</th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>{totalTether}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Row className="affiliate_under_table">
        <Col md={5}>
          <Row className="affiliate_under_table">
            <Col md={5} xs={5}>
              <label className="form-label quiceInfo_row" htmlFor="level">Members :</label>
            </Col>
            <Col md={6} xs={6}>
              <label className="form-label price_label_affiliate" htmlFor="level">Price $ :</label>
            </Col>
          </Row>
        </Col>
        {/* <Col md={7} /> */}
      </Row>
      <Row className="affiliate_table_values_row">
        <Col md={5}>
          <form onSubmit={e => handleLevel(e)}>
            <div className="input-group flex-nowrap">
              <input
                className={unValid ? 'unValid form-control text-center' : `form-control text-center ${valid}`}
                placeholder=""
                id="level"
                autoComplete="false"
                value={lvl}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                }}
                onChange={async (e) => {
                  await setLvl(e.target.value);
                  // if (validator.current.allValid()) {
                  //   setUnValid(false);
                  //   setValid('valid');
                  // }
                  if (!validator.current.allValid()) {
                    // setUnValid(true);
                    setValid('');
                    setLvl('');
                  }
                }}
                onClick={() => setLvl('')}
                onFocus={e => dispatch(setCurrency())}
              />
              <input
                className={unValid ? 'unValid form-control text-center' : `form-control text-center ${valid}`}
                placeholder="NCC Price"
                id="price"
                autoComplete="false"
                value={Ncc}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                }}
                onChange={async (e) => {
                  await setNcc(e.target.value);
                  // if (validator.current.allValid()
                  //   && !Number.isNaN(+e.target.value)
                  //   && (e.target.value >= 0)) {
                  //   setUnValid(false);
                  //   setValid('valid');
                  // }
                  if (!validator.current.fieldValid('price')
                    || Number.isNaN(+e.target.value)) {
                    //   || (e.target.value < 0)) {
                    //   setUnValid(true);
                    //   setValid('');
                    setNcc('');
                  }
                }}
              // disabled
              // onClick={() => setNcc('')}
              // onFocus={e => dispatch(setCurrency())}
              />
              <button
                type="submit"
                className="input-group-text promotion_table_btn"
                style={{ color: themeColor }}
              // onClick={e => handleLevel(e)}
              >
                Calculate
              </button>
            </div>
          </form>
          {validator.current.message('Members', lvl, 'numeric|max:9,num|min:2,num')}
          {validator.current.message('price', Ncc, 'min:0,num')}
        </Col>
        {/* <Col md={7} className="afiiliate_total_div">
          <Row className="quiceInfo_row">
            <Col md={8} />
            <Col md={4} className="text-center">
              <Row className="quiceInfo_row">
                <label className="form-label text-center" htmlFor="Total">TOTAL</label>
                <input
                  className="form-control text-center"
                  placeholder=" "
                  value={total}
                  readOnly
                  onChange={() => forceUpdate(1313)}
                />
              </Row>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};


AffiliateTable.propTypes = {
  ncc: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  Affiliate: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  planProfitSetting: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, currency, constants, theme,
  } = state;
  return {
    ncc: currency?.ncc,
    plancrypto: constants?.plancrypto,
    planProfitSetting: constants?.planProfitSetting,
    Affiliate: userInfo?.user?.voidPlans?.Affiliate,
    theme: theme?.className,
  };
}

export default connect(mapStateToProps)(AffiliateTable);
