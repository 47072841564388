
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { store } from '../../containers/App/store';
import AuthRequest from '../../services/AuthFetchRequest';
import { serverURL, serverbetaURL } from '../../utils/url';
import AuthService from '../../services/userService';
import AuthGetRequest from '../../services/AuthGetRequest';
import { customFFCode, showLogs } from '../../containers/App/Rules';
import { changeThemeToCapital, changeThemeToDark } from './themeActions';


const defaultBalance = {
  NCC: {
    Deposit: 0,
    Fund: 0,
    Affiliate: 0,
    BuyToken: 0,
    Stacking: 0,
  },
  TRX: {
    Deposit: 0,
    Fund: 0,
    Affiliate: 0,
    BuyToken: 0,
    Stacking: 0,
  },
  USDT: {
    Deposit: 0,
    Fund: 0,
    Affiliate: 0,
    BuyToken: 0,
    Stacking: 0,
  },
};



export const CodeRequest = () => dispatch => dispatch({ type: 'CODE_REQUEST' });
export const RefCodeVallid = () => dispatch => dispatch({ type: 'REFFERAL_CODE_VALID' });
export const setUser = user => dispatch => dispatch({ type: 'SET_USER', user });
export const setFFuser = FFuser => dispatch => dispatch({ type: 'SET_FFUSER', FFuser });
export const setKYC = KYC => dispatch => dispatch({ type: 'SET_KYC', KYC });
export const setHavePlan = havePlans => dispatch => dispatch({ type: 'SET_HAVE_PLAN', havePlans });

export const setAffiliateSammury = affiliateSummary => dispatch => dispatch({
  type: 'SET_AFFILIATE_SUMMARY', affiliateSummary,
});
export const setDevice = deviceInfo => dispatch => dispatch({ type: 'SET_DEVICE', deviceInfo });
export const setToken = token => dispatch => dispatch({ type: 'SET_TOKEN', token });
export const setDepositWallet = DepositWallet => dispatch => dispatch({ type: 'SET_DEPOSIT_WALLETS', DepositWallet });
export const setMesseges = announcements => dispatch => dispatch({ type: 'SET_MESSAGES', messages: announcements });
export const setSecPass = () => dispatch => dispatch({ type: 'SET_SEC_PASS' });
export const setAutoWithAff = autoWithAff => dispatch => dispatch({ type: 'SET_AUTO_WITH_AFF', autoWithAff });
export const setOrders = orders => dispatch => dispatch({ type: 'SET_ORDERS', orders });
export const setOrdersRecordBuy = buyRecord => dispatch => dispatch({ type: 'SET_ORDERS_RECORD_BUY', buyRecord });
export const userSelectRefferal = selectedUser => dispatch => dispatch({ type: 'SELECT_USER_REFFERAL', selectedUser });
export const setOrdersRecordWithdraw = withdrawRecord => dispatch => dispatch({
  type: 'SET_ORDERS_RECORD_WITHDRAW', withdrawRecord,
});

export const setBalance = Balance => dispatch => dispatch({ type: 'SET_BALANCE', balance: Balance });

export const getLevels = () => async (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    await AuthRequest(`${serverURL}/holder/deskLevel`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getLevels :', data);
        }
        if (data) {
          dispatch({ type: 'SET_LEVELS', levels: data });
        } else {
          dispatch({ type: 'SET_LEVELS', levels: {} });
        }
      }).catch(err => null);
  }
};

export const getOrders = () => async (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    await AuthRequest(`${serverURL}/holder/getTransactions`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getOrders :', data);
        }
        // if (data) {
        dispatch({ type: 'SET_ORDERS', orders: data.orders });
        // } else {
        //   dispatch({ type: 'SET_LEVELS', levels: {} });
        // }
      }).catch(err => null);
  }
};

export const getVoidPlans = () => async (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    await AuthRequest(`${serverURL}/holder/voidPlans`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getVoidPlans :', data);
        }
        if (data) {
          dispatch({ type: 'SET_VOID_PLANS', voidPlans: data });
        } else {
          dispatch({ type: 'SET_VOID_PLANS', voidPlans: {} });
        }
      }).catch(err => null);
  }
};

export const getWithdrawWallets = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/checkWithdrawWallet`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getWithdrawWallets :', data);
        }
        const { withdrawWallet } = data;
        if (withdrawWallet) {
          dispatch({ type: 'SET_WITHDRAW_WALLETS', withdrawWallet });
        } else {
          dispatch({ type: 'SET_WITHDRAW_WALLETS', withdrawWallet: [] });
        }
      })
      .catch(err => null);
  }
};

export const getMesseges = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/getAnnouncements`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getMesseges :', data);
        }
        const { announcements } = data;
        if (announcements[0]) {
          dispatch({ type: 'GET_MESSAGES', messages: announcements });
        } else {
          dispatch({ type: 'GET_MESSAGES', messages: [] });
        }
      })
      .catch(err => null);
  }
};

export const getBuyInvestSummary = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    axios.get(`${serverbetaURL}/userplans/Summary`, {
      params: {
        FFCode: customFFCode || FFCode,
        query: 'planCryptoCurrencyId=9',
        page: 1,
        paginate: 10,
      },
    })
      .then((data) => {
        if (showLogs) {
          console.log('Summary (BuyInvest) :', data);
        }
        if (data?.status === 200) {
          if (data?.data?.status === true) {
            dispatch({
              type: 'SET_BUYINVEST_SUMMARY',
              active: data?.data?.data[0],
              all: data?.data?.data,
            });
          }
        }
      })
      .catch(err => null);
  }
};

export const getStackingSummary = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverbetaURL}/stackings`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('Summary (Stacking) :', data);
        }
        if (data?.status === true) {
          dispatch({
            type: 'SET_STAKING_SUMMARY',
            stage: data?.data?.getStacking[0],
            active: data?.data?.getStacking,
            profits: data?.data?.getProfit,
            finishes: data?.data?.getFinished,
            cancels: data?.data?.getCancel,
          });
        }
      })
      .catch(err => null);
  }
};

export const getRefundSummary = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverbetaURL}/refunds`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('Summary (Refund) :', data);
        }
        if (data?.status === true) {
          dispatch({
            type: 'SET_REFUND_SUMMARY',
            active: data?.data?.getRefund[0],
            profits: data?.data?.getProfit,
            finishes: data?.data?.getFinished,
            cancels: data?.data?.getCancel,
          });
        }
      })
      .catch(err => null);
  }
};

export const getAwardSummary = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverbetaURL}/awards/Summary`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('Summary (Award) :', data);
        }
        dispatch({
          type: 'SET_AWARD_SUMMARY',
          awardSummary: data,
        });
      })
      .catch(err => null);
  }
};

export const getNftSummary = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverbetaURL}/nfts/Summary`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('Summary (NFT) :', data);
        }
        dispatch({
          type: 'SET_NFT_SUMMARY',
          nftSummary: data,
        });
      })
      .catch(err => null);
  }
};

export const getReportPlans = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    axios.post(`${serverbetaURL}/reports/allReportIncomingSummary`,
      {
        FFCode: customFFCode || FFCode,
        typeId: 'inCome',
        planCryptoCurrencyId: 9,
        token: localStorage.getItem('token'),
      })
      .then((data) => {
        if (showLogs) {
          console.log('allReportSummary :', data);
        }
        if (data?.data?.status === true) {
          dispatch({
            type: 'SET_REPORT_PLANS',
            reportPlans: data?.data?.data,
          });
        }
      })
      .catch(err => null);
  }
};

export const getHistoryBuyInvest = (month, year) => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    axios.post(`${serverbetaURL}/reports/getProfitHistory`,
      {
        FFCode: customFFCode || FFCode,
        month: (month || (new Date()).getMonth() + 1).toString(),
        year: (year || ((new Date()).getFullYear())).toString(),
        planCryptoCurrencyId: 9,
        token: localStorage.getItem('token'),
      })
      .then((data) => {
        if (showLogs) {
          console.log('getProfitHistory :', data);
        }
        if (data?.status === 200) {
          dispatch({
            type: 'SET_HISTORY_BUYINVEST',
            historyBuyInvest: data?.data,
          });
        }
      })
      .catch(err => null);
  }
};

export const getBinarySummaryProfit = (month, year) => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    axios.post(`${serverbetaURL}/reports/getBinarySummaryProfit`,
      {
        FFCode: customFFCode || FFCode,
        typeId: 'inCome',
        month: (month || (new Date()).getMonth() + 1).toString(),
        year: (year || ((new Date()).getFullYear())).toString(),
        planCryptoCurrencyId: 9,
        palnDetailCryptoCurrencyId: 9,
        Level: 4,
        token: localStorage.getItem('token'),
      })
      .then((data) => {
        if (showLogs) {
          console.log('getBinarySummaryProfit :', data);
        }
        if (data?.status === 200) {
          if (data?.data !== []) {
            dispatch({
              type: 'SET_BINARY_SUMMARY',
              members: data?.data?.getBinerySummary?.sort((a, b) => b.amount - a.amount),
              profit: data?.data?.profit,
            });
          } else {
            dispatch({
              type: 'SET_BINARY_SUMMARY',
              binarySummary: [],
              profit: 0,
            });
          }
        } else {
          dispatch({
            type: 'SET_BINARY_SUMMARY',
            binarySummary: [],
          });
        }
      })
      .catch(err => null);
  }
};

export const getBalance = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/getBalanceCurrency`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getBalanceCurrency :', data);
        }
        const Balance = data;
        if (Balance) {
          dispatch({ type: 'SET_BALANCE', balance: Balance });
        } else {
          dispatch({
            type: 'SET_BALANCE',
            balance: defaultBalance,
          });
        }
      })
      .catch(err => null);
  }
};

export const logOut = () => (dispatch) => {
  console.clear();
  if (store.getState().userInfo?.user?.FFuser) {
    const { FFCode } = store.getState().userInfo?.user?.FFuser;
    axios.post(`${serverbetaURL}/auth/logout`, JSON.stringify({
      FFCode: customFFCode || FFCode,
    }), {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Allow-Headers': '*',
      },
    }).then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('logout :', data);
        }
        localStorage.clear();
        // if (data) {
        // } else {
        //   dispatch({ type: 'LOGOUT' });
        // }
      }).catch(err => null);
  }
  // localStorage.clear();
  dispatch({ type: 'LOGOUT' });
  dispatch(changeThemeToDark());
};

export const getRefferal = () => async (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user?.FFuser;

    await AuthRequest(`${serverURL}/holder/refferalAll`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('refferalAll :', data);
        }
        if (data.chartTree?.original) {
          if (data.chartTree?.original?.getChild) {
            const treeData = JSON.parse(data.chartTree?.original?.getChild?.replaceAll("'", '"')
              .replaceAll('[]', 'null'));
            dispatch({ type: 'SET_REFFERAL_TREE', treeData });
          } else if (data.refferalList === []) {
            dispatch({ type: 'SET_REFFERAL_TREE', treeData: [] });
          } else {
            dispatch({ type: 'SET_REFFERAL_TREE', treeData: [] });
          }
        } else {
          dispatch({ type: 'SET_REFFERAL_TREE', listData: [] });
        }

        if (data.sttReq === 'ok' && data.numReq === 1) {
          if (data.refferalChart) {
            if (data.refferalChart?.days) {
              dispatch({ type: 'SET_REFFERAL_CHART', chartData: data.refferalChart });
            } else {
              dispatch({ type: 'SET_REFFERAL_CHART', chartData: [] });
            }
          } else {
            dispatch({ type: 'SET_REFFERAL_CHART', chartData: [] });
          }

          if (data.refferalList) {
            if (data.refferalList[0]) {
              dispatch({ type: 'SET_REFFERAL_LIST', listData: data.refferalList });
            } else if (data.refferalList === []) {
              dispatch({ type: 'SET_REFFERAL_LIST', listData: [] });
            } else {
              dispatch({ type: 'SET_REFFERAL_LIST', listData: [] });
            }
          } else {
            dispatch({ type: 'SET_REFFERAL_LIST', listData: [] });
          }
        }
      }).catch(err => null);
  }
};

export const profileReload = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/profileReload`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('profileReload :', data);
        }
        if (data.numReq === 1 && data.sttReq === 'ok') {
          if (data.withdrawWallet) {
            dispatch({ type: 'SET_WITHDRAW_WALLETS', withdrawWallet: data.withdrawWallet });
          } else {
            dispatch({ type: 'SET_WITHDRAW_WALLETS', withdrawWallet: [] });
          }
          dispatch({ type: 'SET_FFUSER', FFuser: data.FFuser });
        }
      })
      .catch(err => null);
  }
};

export const monetaryReload = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/monetaryReload`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('monetaryReload :', data);
        }
        if (data.numReq === 1 && data.sttReq === 'ok') {
          if (data.balanceWallet) {
            dispatch({ type: 'SET_BALANCE', balance: data.balanceWallet });
          } else {
            dispatch({
              type: 'SET_BALANCE',
              balance: defaultBalance,
            });
          }
          if (data.price) {
            const nccPrice = Number(data.price.NCC).toFixed(4);
            const tronPrice = Number(data.price.TRX).toFixed(4);
            const tetherPrice = Number(data.price.USDT).toFixed(4);
            dispatch({
              type: 'SET_CURRENCY',
              nccPrice,
              tronPrice,
              tetherPrice,
            });
          }
          if (data.plancryptocurrencydtls) {
            dispatch({ type: 'SET_PLAN_CRYPTO', plancrypto: data.plancryptocurrencydtls });
          }
          if (data.depositWallet) {
            dispatch({ type: 'SET_DEPOSIT_WALLETS', DepositWallet: data.depositWallet });
          }
          if (data.orders) {
            dispatch({ type: 'SET_ORDERS', orders: data.orders });
          }
        }
      })
      .catch(err => null);
  }
};

export const promotionReload = () => (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    AuthRequest(`${serverURL}/holder/promotoinReload`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('promotionReload :', data);
        }
        if (data.numReq === 1 && data.sttReq === 'ok') {
          if (data.havePlans) {
            dispatch({ type: 'SET_HAVE_PLAN', havePlans: data.havePlans });
          }
          if (data.plancryptocurrencydtls) {
            dispatch({ type: 'SET_PLAN_CRYPTO', plancrypto: data.plancryptocurrencydtls });
          }
          if (data.plans) {
            dispatch({ type: 'SET_PLAN_CONSTANTS', plans: data.plans });
          }
          if (data.planProfitSetting) {
            dispatch({ type: 'SET_PLAN_PROFIT_SETTING', planProfitSetting: data.planProfitSetting });
          }
          if (data.planWithdrawSetting) {
            dispatch({ type: 'SET_PLAN_WITHDRAW_SETTING', planWithdrawSetting: data.planWithdrawSetting });
          }
          if (data.StackingSummary) {
            dispatch({ type: 'SET_STAKING_SUMMARY', StackingSummary: data.StackingSummary });
          }
          if (data.AffilieteSummary) {
            dispatch({ type: 'SET_AFFILIATE_SUMMARY', AffiliateSummary: data.AffilieteSummary });
          }
          if (data.balanceWallet) {
            dispatch({ type: 'SET_BALANCE', balance: data.balanceWallet });
          } else {
            dispatch({
              type: 'SET_BALANCE',
              balance: defaultBalance,
            });
          }
          if (data.price) {
            const nccPrice = Number(data.price.NCC).toFixed(4);
            const tronPrice = Number(data.price.TRX).toFixed(4);
            const tetherPrice = Number(data.price.USDT).toFixed(4);
            dispatch({
              type: 'SET_CURRENCY',
              nccPrice,
              tronPrice,
              tetherPrice,
            });
          }
        }
      })
      .catch(err => null);
  }
};

export const getReload = () => async (dispatch) => {
  if (store.getState().userInfo.user) {
    const { FFCode } = store.getState().userInfo.user.FFuser;
    await AuthRequest(`${serverURL}/holder/getReload`,
      {
        FFCode: customFFCode || FFCode,
        token: localStorage.getItem('token'),
      })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('getReload :', data);
        }
        if (data.sttReq === 'ok' && data.numReq === 1) {
          dispatch({ type: 'SET_FFUSER', FFuser: data?.user?.FFuser });

          dispatch({ type: 'SET_IS_CAPITAL', isCapital: data?.user?.isCapital });
          if (data?.user?.isCapital) {
            dispatch(changeThemeToCapital());
          } else {
            dispatch(changeThemeToDark());
          }

          //  const history = useHistory();
          setTimeout(() => {
            const Active = store.getState().userInfo?.user?.FFuser?.isActive;
            if (Active === 0) {
              //   history.push({
              //     pathname: '/',
              //   });
              // dispatch(clearCurrency());
              // dispatch(clearConstants());
              dispatch({ type: 'LOGOUT' });
            }
          }, 5000);

          dispatch({
            type: 'SET_CONSTANTS',
            constants: data.constants,
            plans: data.plans,
            cryptocurrencies: data.cryptocurrencies,
            plancryptocurrencydtls: data.plancryptocurrencydtls,
            deskMap: data.deskMap,
            planProfitSetting: data.planProfitSetting,
            planWithdrawSetting: data.planWithdrawSetting,
          });
          dispatch({ type: 'SET_ORDERS', orders: data?.user?.orders });
          const nccPrice = Number(data.price.NCC).toFixed(4);
          const tronPrice = Number(data.price.TRX).toFixed(4);
          const tetherPrice = Number(data.price.USDT).toFixed(4);
          dispatch({
            type: 'SET_CURRENCY',
            nccPrice,
            tronPrice,
            tetherPrice,
          });
          if (data.user.balanceWallet) {
            dispatch({ type: 'SET_BALANCE', balance: data?.user?.balanceWallet });
            // } else if(data.numReq === 0 && data.sttReq === 'no balance') {
          } else {
            dispatch({
              type: 'SET_BALANCE',
              balance: defaultBalance,
            });
          }
          if (data.StackingSummary) {
            dispatch({ type: 'SET_STAKING_SUMMARY', StackingSummary: data.StackingSummary });
          }
          if (data.AffilieteSummary) {
            dispatch({ type: 'SET_AFFILIATE_SUMMARY', AffiliateSummary: data.AffilieteSummary });
          }
          dispatch({ type: 'SET_WITHDRAW_WALLETS', withdrawWallet: data?.user?.withdrawWallet });
          dispatch({ type: 'SET_DEPOSIT_WALLETS', DepositWallet: data?.user?.depositWallet });
          dispatch({ type: 'SET_HAVE_PLAN', havePlans: data?.user?.havePlans });
          dispatch({ type: 'SET_LEVELS', levels: data?.levels });
          dispatch({ type: 'SET_MESSAGES', messages: data?.announcements });
          dispatch({ type: 'SET_KYC', KYC: data?.user?.kyc });
          dispatch({ type: 'SET_VOID_PLANS', voidPlans: data?.voidPlan });
          // console.log('treeData');
          // const treeData = JSON.parse(data.refferal?.replaceAll("'", '"').replaceAll('[]', 'null'));
          // const treeData = JSON.parse(data.refferal?.replaceAll("'", '"').replaceAll('[]', 'null'));
          // dispatch({ type: 'SET_REFFERAL', treeData });
        }
      }).catch(err => null);
  }
};

