import React from 'react';
import {
  Row, Container, Col,
} from 'reactstrap';
import UserInformation from './components/userInformation/userInformation';
import UserList from './components/userList/userList';



const Users = () => (

  <Container className="dashboard">
    <Row>
      <Col md={4}>
        <UserInformation />
      </Col>
      <Col md={8}>
        <UserList />
      </Col>
    </Row>
  </Container>
);

export default Users;
