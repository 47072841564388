import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import {
  Button,
  ButtonToolbar,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getWithdrawWallets } from '../../../../redux/actions/userActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { store } from '../../../App/store';
import { serverURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';



const Wallet = () => {
  const [WalletName, setWalletName] = useState();
  const [WalletAddress, setWalletAddress] = useState();
  const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  const validatorConfigs = {
    element: message => <div className="validationErr_profile">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));



  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const reset = () => {
    validator.current.hideMessages();
    setWalletAddress('');
    setWalletName('');
  };

  const handleCheckAddWallet = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      toggle();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleAddWallet = (e) => {
    toggle();
    const { FFuser } = store.getState().userInfo.user;
    AuthRequest(`${serverURL}/holder/addWithdrawWallet`, {
      FFCode: FFuser.FFCode, walletTitle: WalletName, walletAddress: WalletAddress,
    }).then(res => res.json())
      .then(async (data) => {
        if (showLogs) {
          console.log('addWithdrawWallet :', data);
        }
        if (data.sttReq === 'ok' && data.numReq === 1) {
          await dispatch(getWithdrawWallets());
          forceUpdate(1);
          toast.success(`The ${WalletName} Wallet Address has been added to your Wallets !`, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.sttReq === 'more have withdrow wallet' && data.numReq === 0) {
          toast.error('You have used the maximum available number of your Wallets !', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.sttReq === 'wallet Address has' && data.numReq === 0) {
          toast.error(`You already have the "${WalletAddress}" wallet name in your Wallets !`, {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.sttReq === 'walletTitle has' && data.numReq === 0) {
          toast.error(`You already have the "${WalletName}" wallet name in your Wallets !`, {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.sttReq === 'The wallet address has already been taken.' && data.numReq === -1) {
          toast.error('This wallet address has already been taken !', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    reset();
    forceUpdate(1);
  };
  return (
    <>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Add New Wallet</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          Address: {WalletAddress}
          <br />
          <br />
          Name: {WalletName}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleAddWallet}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <Row>
        <Col md={5} />
        {/* <div className="kyc_notes">
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Quisque nec leo congue, lacinia nisl et, tristique dolor..</li>
              <li>Aliquam et velit accumsan, imperdiet neque id, tempus nisl..</li>
              <li>Duis ut lorem eu felis finibus rhoncus nec eu odio..</li>
              <li>Morbi eget metus et eros ultricies egestas..</li>
            </ul>
          </div>
        </Col> */}
        <Col md={4}>
          {/* <div className="add_wallet">
            <h4>
              Add New Wallet
            </h4>
          </div> */}
          <form onSubmit={e => handleCheckAddWallet(e)}>
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="walletAddress">Address</label>
              <input
                type="text"
                className="form-control text-center"
                id="walletAddress"
                placeholder="Your Wallet address"
                value={WalletAddress}
                onChange={e => setWalletAddress(e.target.value)}
              />
              {validator.current.message('Wallet Address', WalletAddress, 'required')}
            </div>
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="WalletName">Name</label>
              <input
                type="text"
                className="form-control text-center"
                id="WalletName"
                placeholder="Your Wallet name"
                value={WalletName}
                onChange={e => setWalletName(e.target.value)}
              />
              {validator.current.message('Wallet Name', WalletName, 'required')}
            </div>
            <div className="form-group mb-1 text-center">
              <button type="submit" className="btn btn-outline-theme tefo_buttons_md">Submit</button>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default connect()(Wallet);
