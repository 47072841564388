import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { getPlanIcon } from '../../../../shared/helpers/themHelper';





const PlansDesktop = ({ havePlans, BuyInvestSummary }) => {
  const [haveFund, setHaveFund] = useState(false);
  const [haveAffiliate, setHaveAffiliate] = useState(false);
  const [haveStacking, setHaveStacking] = useState(false);
  const [haveBuyInvest, setHaveBuyInvest] = useState(false);

  useEffect(() => {
    if (havePlans?.Affiliate?.isActiveUserPlan === 1) {
      setHaveAffiliate(true);
    } else {
      setHaveAffiliate(false);
    }
    if (havePlans?.Fund?.isActiveUserPlan === 1) {
      setHaveFund(true);
    } else {
      setHaveFund(false);
    }
    if (havePlans?.Stacking?.isActiveUserPlan === 1) {
      setHaveStacking(true);
    } else {
      setHaveStacking(false);
    }
    if (BuyInvestSummary?.nccTotal > 0) {
      setHaveBuyInvest(true);
    } else {
      setHaveBuyInvest(false);
    }
  }, [havePlans, BuyInvestSummary]);


  return (
    <Card>
      <CardBody className="desk_plans_body">
        <Row className="desk_plans_row">
          <Col className="plan_desk_col" xs={12} md={12}>
            <h5 className="text-left plan_desk_title">Active Plans</h5>
          </Col>

          <div className="desk_planicon_holder">


            <div className="planicon_holder">
              <img
                className={haveFund ? '' : 'planicon_deactive'}
                src={getPlanIcon().fund}
                alt=""
              />
              <div className={haveFund ? 'desk_plan_name desk_plan_name_active' : 'desk_plan_name'}>
                Fund
              </div>
            </div>

            <div className="planicon_holder">
              <img
                className={haveAffiliate ? '' : 'planicon_deactive'}
                src={getPlanIcon().affiliate}
                alt=""
              />
              <div className={haveAffiliate ? 'desk_plan_name desk_plan_name_active' : 'desk_plan_name'}>
                Affiliate
              </div>
            </div>

            <div className="planicon_holder">
              <img
                className={haveStacking ? '' : 'planicon_deactive'}
                src={getPlanIcon().stacking}
                alt=""
              />
              <div className={haveStacking ? 'desk_plan_name desk_plan_name_active' : 'desk_plan_name'}>
                Stacking
              </div>
            </div>

            <div className="planicon_holder">
              <img
                className={haveBuyInvest ? '' : 'planicon_deactive'}
                src={getPlanIcon().buyinvest}
                alt=""
              />
              <div className={haveBuyInvest ? 'desk_plan_name desk_plan_name_active' : 'desk_plan_name'}>
                Buy Invest
              </div>
            </div>

          </div>



          {/* <Col className="plan_desk_col" xs={12} md={12}>
            <div className="desk_plan_holder">
              <div className="access_items">
                <div className="form-check desk_check_plan">
                  <span className={haveFund ? 'desk_plans_checked' : 'desk_plans_unchecked'} />
                  <label
                    className={haveFund ? 'have_plan form-check-label'
                      : 'form-check-label'}
                    htmlFor="Fund"
                  >
                    Fund
                  </label>
                </div>
              </div>
              <div className="access_items">
                <div className="form-check desk_check_plan">
                  <span className={haveAffiliate ? 'desk_plans_checked' : 'desk_plans_unchecked'} />
                  <label
                    className={haveAffiliate ? 'have_plan form-check-label'
                      : 'form-check-label'}
                    htmlFor="Fund"
                  >
                    Affiliate
                  </label>
                </div>
              </div>
              <div className="access_items">
                <div className="form-check desk_check_plan">
                  <span className={haveStacking ? 'desk_plans_checked' : 'desk_plans_unchecked'} />
                  <label
                    className={haveStacking ? 'have_plan form-check-label'
                      : 'form-check-label'}
                    htmlFor="Fund"
                  >
                    Staking
                  </label>
                </div>
              </div>
              <div className="access_items">
                <div className="form-check desk_check_plan">
                  <span className={haveBuyInvest ? 'desk_plans_checked' : 'desk_plans_unchecked'} />
                  <label
                    className={haveBuyInvest ? 'have_plan form-check-label'
                      : 'form-check-label'}
                    htmlFor="BuyInvest"
                  >
                    Buy Invest
                  </label>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};



PlansDesktop.propTypes = {
  havePlans: PropTypes.objectOf.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { havePlans: userInfo.user?.havePlans, BuyInvestSummary: userInfo.user?.BuyInvestSummary };
}


export default connect(mapStateToProps)(PlansDesktop);

