import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { QRCode } from 'react-qrcode-logo';
import SimpleReactValidator from 'simple-react-validator';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, CardHeader, Modal, Popover, PopoverHeader, UncontrolledTooltip,
} from 'reactstrap';
import canvasToImage from 'canvas-to-image';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { serverURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';



const RefferalGenerate = ({ user }) => {
  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

  const [shareEmail, setshareEmail] = useState();

  const [refDeactive, setRefDeactive] = useState();


  const [copyLink, setCopylink] = useState('fas fa-copy');
  const [refCode, setRefCode] = useState(user.FFuser.FFCodeRefrence);
  const [refLink, setReflink] = useState(`https://financialfactory.capital/refferallink/${refCode}`);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState();

  const [IsOpenedCopied, setIsOpenedCopied] = useState(false);
  const handleOpenCopied = () => {
    setIsOpenedCopied(!IsOpenedCopied);
  };




  useEffect(() => {
    if (!user.FFuser.isActive
      || !user.FFuser.isAllowShowRefferalCode) {
      setRefDeactive(true);
    }
    // dispatch(getRefferal());
  }, [user]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  // const copyCodeToClipboard = () => {
  //   const element = document.querySelector('#refCode');
  //   element.select();
  //   element.setSelectionRange(0, 99999);
  //   document.execCommand('copy');
  //   setCopycode('fas fa-check');
  //   setTimeout(() => setCopycode('fas fa-copy'), 1500);
  // };
  const copyLinkToClipboard = () => {
    const element = document.querySelector('#refLink');
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopylink('fas fa-check');
    setTimeout(() => {
      setCopylink('fas fa-copy');
      setIsOpenedCopied(false);
    }, 2000);
  };

  const validator = useRef(new SimpleReactValidator());

  const reset = () => {
    validator.current.hideMessages();
    setshareEmail('');
  };


  const onShareRefLink = () => {
    const shareInfos = { FFCode: user.FFuser.FFCode, shareEmail, token: localStorage.getItem('token') };
    // console.log(shareInfos);
    AuthRequest(`${serverURL}/shareEmail`, shareInfos)
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('shareEmail :', data);
        }
        if (data.sttReq === 'ok' && data.numReq === 1) {
          toast.success(`Your refferal link has been sent to ${shareEmail}
              Email Address !`, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success('Your request failed. Please try again later !', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    toggle();
    reset();
  };


  const downloadRefQR = () => {
    const refCodeQR = document.getElementById('refQrCode');
    canvasToImage(refCodeQR, {
      name: `${user.FFuser.FFCodeRefrence}`,
      type: 'jpg',
      quality: 1,
    });
  };


  return (
    <>

      <UncontrolledTooltip placement="top" target="refGenHelp">
        To take advantage of the benefits and plans of Financial Factor, you need to invite people to this team,
        which is possible by sharing the written code, QR Code or specified login link.
      </UncontrolledTooltip>

      {refDeactive ? '' : (
        <Popover
          placement="top"
          isOpen={IsOpenedCopied}
          target="copied"
          toggle={handleOpenCopied}
          className="copied_popover"
        >
          <PopoverHeader>Copied !</PopoverHeader>
        </Popover>
      )}

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Share Refferal</h4>
        </div>
        <div className="modal__body">
          <h6>Are you want to share your refferal link with  </h6>
          <br />
          <h4>{shareEmail}</h4>
          <br />
          <h6>?</h6>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={onShareRefLink}>Yes, Share !</Button>
        </ButtonToolbar>
      </Modal>

      <Card>
        <CardHeader className="text-center">
          <h5 className="bold-text">
            Refferal <i id="refGenHelp" className="fas fa-info-circle monetary_title_info" />
          </h5>
        </CardHeader>
        <CardBody className="ref_gen_cardBody">
          {/* <h5 className="subhead text-center mb-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            It has survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged
          </h5> */}
          {/* <div className="mb-2">
            <label className="form-label" htmlFor="refCode">Refferal Code</label>
            <div className="input-group flex-nowrap">
              <input
                className="form-control"
                type="text"
                placeholder="Your Refferal Code"
                id="refCode"
                value={refCode}
              />
              <button type="button" className="input-group-text" onClick={copyCodeToClipboard}>
                <i className={copyCode} />
              </button>
            </div>
          </div> */}
          <div className="mb-4 mt-2">
            <label className="form-label" htmlFor="refLink">Refferal Link</label>
            <div className="input-group flex-nowrap">
              <input
                className="form-control"
                type="text"
                placeholder="Your Refferal Link"
                id="refLink"
                value={refDeactive ? '' : refLink}
                disabled={refDeactive ? 'true' : ''}
              />
              <button
                type="button"
                id="copied"
                className="input-group-text"
                onClick={copyLinkToClipboard}
                disabled={refDeactive ? 'true' : ''}
              >
                <i className={copyLink} />
              </button>
            </div>
          </div>
          <div className="text-center mt-5 mb-5">
            {/* <QRCode className="deposit_code" value={refLink} size="180" /> */}
            <QRCode
              value={refDeactive ? '' : refLink}
              logoWidth="220"
              eyeRadius={[
                [0, 80, 0, 80], // top/left eye
                [80, 0, 80, 0], // top/right eye
                [80, 0, 80, 0], // bottom/left
              ]}
              logoOpacity="0.4"
              qrStyle="dots"
              bgColor="#fff"
              fgColor="#000"
              ecLevel="Q"
              size="180"
              logoImage={logo}
              className="myqrcode"
              id="refQrCode"
            // style={{ borderRadius: '10%' }}
            // enableCORS="true"
            />

            {refDeactive ? '' : (
              <div className="text-center">
                <button type="button" className="btn btn-outline-theme table-btn" onClick={e => downloadRefQR()}>
                  <i className="fas fa-arrow-down" />
                </button>
              </div>
            )}



          </div>
          <div className="mt-2">

            <form onSubmit={(e) => {
              e.preventDefault();
              if (validator.current.allValid()) {
                toggle();
              } else {
                validator.current.showMessages();
                setUnValid(true);
                forceUpdate(1);
              }
            }}
            >
              <label className="form-label" htmlFor="shareRefferal">SHARE</label>
              <div className="input-group flex-nowrap">
                <input
                  className={unValid ? 'unValid form-control' : `form-control ${valid}`}
                  type="text"
                  placeholder="Enter the Email Address"
                  id="shareRefferal"
                  value={shareEmail}
                  onBlur={() => {
                    setUnValid(false);
                    setValid('');
                  }}
                  onChange={async (e) => {
                    await setshareEmail(e.target.value);
                    if (validator.current.allValid()) {
                      setUnValid(false);
                      setValid('valid');
                    }
                    if (!validator.current.allValid()) {
                      setValid('');
                      setUnValid(true);
                    }
                  }}
                  disabled={refDeactive ? 'true' : ''}
                />
                <button
                  type="submit"
                  className="input-group-text"
                  disabled={refDeactive ? 'true' : ''}
                >
                  Send Refferal
                </button>
              </div>
              <div className="tefo_error">
                {validator.current.message('email', shareEmail, 'required|email')}
              </div>
            </form>

          </div>
        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};


RefferalGenerate.propTypes = {
  user: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { user: userInfo.user };
}

export default connect(mapStateToProps)(RefferalGenerate);
