import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { connect, useDispatch } from 'react-redux';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { getRefferal } from '../../../../../../../redux/actions/userActions';
import { store } from '../../../../../../App/store';
import useCenteredTree from './treeHelper';







const TreeChart = () => {
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [datatree, setDatatree] = useState({});
  const [zoom, setZoom] = useState(0.75);

  const [selectedUser, setSelectedUser] = useState({
    name: '- - - - - - - -',
  });

  const [force, forceUpdate] = useState();

  const [depth, setDepth] = useState(1);

  const containerStyles = {
    width: '100%',
    height: '60vh',
  };


  const dispatch = useDispatch();

  // const handleNodeClick = (nodeDatum) => {
  //   console.log(nodeDatum.name);
  //   dispatch(adminSelect([nodeDatum.name, nodeDatum.attributes?.department]));
  // };

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <>
      <g>
        <circle
          r="9"
          onClick={() => {
            setSelectedUser(nodeDatum);
            toggleNode();
            setZoom(1);
          }}
        />
        {/* <button type="button" ref={myNode} onClick={toggleNode}>s</button> */}
        <line x1="-39" y1="-47" x2="32" y2="-47" strokeWidth="0.9" />
        <text
          strokeWidth="0.1"
          x="-40"
          y="-33"
          className="node_id"
          id={`node${nodeDatum.name}`}
          onClick={() => {
            setSelectedUser(nodeDatum);
            // toggleNode();
            // setZoom(1);
          }}
        >
          {nodeDatum.name}
        </text>
        <line x1="-39" y1="-28" x2="32" y2="-28" strokeWidth="0.9" />

        {nodeDatum.attributes?.BuyToken ? (
          <rect width="12" className="node_plan" height="12" x="18" y="-22" fill="green" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="18" y="-22" fill="transparent" />
        )}

        {nodeDatum.attributes?.Stacking ? (
          <rect width="12" className="node_plan" height="12" x="-35" y="-22" fill="yellow" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="-35" y="-22" fill="transparent" />
        )}

        {nodeDatum.attributes?.Affiliate ? (
          <rect width="12" className="node_plan" height="12" x="-17" y="-22" fill="#9370DB" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="-17" y="-22" fill="transparent" />
        )}

        {nodeDatum.attributes?.Fund ? (
          <rect width="12" className="node_plan" height="12" x="0" y="-22" fill="blue" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="0" y="-22" fill="transparent" />
        )}


        <UncontrolledTooltip placement="top" target={`node${nodeDatum.name}`}>
          FF Code : {nodeDatum.name}
          <br />
          Buy Token: {nodeDatum.attributes?.BuyToken ? ' ✔️' : '-'}
          <br />
          Fund : {nodeDatum.attributes?.Fund ? ' ✔️' : ' -'}
          <br />
          Affiliate : {nodeDatum.attributes?.Affiliate ? ' ✔️' : ' -'}
          <br />
          Stacking : {nodeDatum.attributes?.Stacking ? ' ✔️' : ' -'}
        </UncontrolledTooltip>

      </g>
    </>
  );

  // const getdata = () => {
  // AuthRequest(`${serverURL}/holder/chartTree`,
  //     { FFCode, token: localStorage.getItem('token') })
  //     .then(res => res.json())
  //     .then(async (data) => {
  //       const firstLevel = JSON.parse(data.getChild.replaceAll("'", '"')).children.length;
  //       if (firstLevel >= 12) {
  //         setZoom(0.5);
  //       } else if (firstLevel < 12 && firstLevel >= 8) {
  //         setZoom(0.7);
  //       } else if (firstLevel < 8 && firstLevel > 4) {
  //         setZoom(0.85);
  //       } else if (firstLevel <= 4) {
  //         setZoom(1);
  //       }
  //       setDatatree(JSON.parse(data.getChild.replaceAll("'", '"').replaceAll('[]', 'null')));
  //     });
  // };


  // useEffect(() => {
  //   getdata();
  // });

  useEffect(() => {
    dispatch(getRefferal());
    const { Refferal } = store.getState().userInfo?.user;
    if (Refferal) {
      setDatatree(Refferal.treeData);
      const firstLevel = Refferal.treeData.children?.length;
      if (firstLevel >= 12) {
        setZoom(0.5);
      } else if (firstLevel < 12 && firstLevel >= 8) {
        setZoom(0.7);
      } else if (firstLevel < 8 && firstLevel > 4) {
        setZoom(0.85);
      } else if (firstLevel <= 4) {
        setZoom(1);
      }
    }
  }, []);

  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      return 'link__to-leaf';
    }
    return 'link__to-branch';
  };

  return (
    <>
      <Col xl={4} lg={5} md={6} className="admin_treeInfo_col">
        <div className="mb-1 mt-3">
          <div className="admin_treeInfo">
            <Row>
              <span>FF Code : <strong> {selectedUser.name ? selectedUser.name : '- - - - - - - -'} </strong>  </span>
              <span>Fund : {selectedUser.attributes?.Fund ? ' ✅' : ' ❌'} </span>
              <span>Affiliate : {selectedUser.attributes?.Affiliate ? ' ✅' : ' ❌'} </span>
              <span>Stacking : {selectedUser.attributes?.Stacking ? ' ✅' : ' ❌'} </span>
              <span>Buy Token: {selectedUser.attributes?.BuyToken ? ' ✅' : '❌'} </span>
            </Row>
            <br />

          </div>
        </div>
      </Col>
      <Col md={12}>

        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(100);
              setZoom(0.3);
              forceUpdate(!force);
            }}
          >
            Expand {' '}
            <i className="fas fa-angle-double-down" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(0);
              setZoom(1);
              forceUpdate(!force);
            }}
          >
            Collapse {' '}
            <i className="fas fa-angle-double-up" />
          </button>
        </div>
      </Col>



      <div style={containerStyles} ref={containerRef}>
        <Tree
          data={datatree}
          translate={translate}
          renderCustomNodeElement={renderRectSvgNode}
          orientation="vertical"
          enableLegacyTransitions="true"
          transitionDuration={500}
          zoom={zoom}
          siblings="1"
          nonSiblings="1"
          initialDepth={depth}
          nodeSet="1"
          centeringTransitionDuration={1000}
          id="treeNode"
          dimensions={dimensions}
          allowForeignObjects
          pathClassFunc={getDynamicPathClass}
          rootNodeClassName="node_root"
          branchNodeClassName="node_branch"
          leafNodeClassName="node_leaf"
          key={force}
        />
      </div>
      <div className="text-center pt-2">
        <span className="tree_plan_fund tree_plan" />  Fund |{'  '}
        <span className="tree_plan_affiliate tree_plan" />  Affiliate |{'  '}
        <span className="tree_plan_stacking tree_plan" />  Stacking |{'  '}
        <span className="tree_plan_buy tree_plan" />  Buy
      </div>
    </>
  );
};
export default connect()(TreeChart);
