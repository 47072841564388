import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CodeDropdown = ({ items, title }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="dropdown-wrapper">
      <button
        type="button"
        onClick={() => setShowDropdown(pre => !pre)}
        className="btn magic_history_btn magic_codes_btn"
      >
        {title}
        {' '}
        <span
          className="magic_codes_icon"
        >
          <i
            className={showDropdown ? 'fa-solid fa-chevron-down codes_open' : 'fa-solid fa-chevron-down'}
          />
        </span>
      </button>
      <br />
      <ul
        className={showDropdown ? 'active code_drop_ul' : 'code_drop_ul'}
        style={{ height: `${(items.length && showDropdown) ? (items.length * 20.4) : 0}px` }}
      >
        {items.map(item => <li className="code_drop_li"><span>{item}</span></li>)}
      </ul>
    </div>
  );
};
CodeDropdown.propTypes = {
  items: PropTypes.objectOf.isRequired,
  title: PropTypes.string.isRequired,
};


export default CodeDropdown;
