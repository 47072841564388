import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { setBalance, setHavePlan, getRefferal } from '../../../../../redux/actions/userActions';
import { serverURL } from '../../../../../utils/url';
import { clearLoadingFund, setLoadingFund } from '../../../../../redux/actions/loaderAction';
import NoSecPass from '../../../../Layout/NoSecPass';
import { showLogs } from '../../../../App/Rules';
import { getCryptoImgUrl } from '../../../../../shared/helpers/themHelper';



const FundJoin = ({
  havePlans,
  theme,
  balanceWallet,
  isAllowPlan,
  isActive,
  FFCode,
  haveSecPass,
  Fund,
  plans,
  currencies,
  plancrypto,
  fundLoad,
}) => {
  const [nccImgURL, setNccImgURL] = useState();
  const [tronImgURL, setTronImgURL] = useState();
  const [tetherImgURL, setTetherImgURL] = useState();

  useEffect(() => {
    setNccImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().ncc}`);
    setTronImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tron}`);
    setTetherImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tether}`);
  }, [theme]);

  const [, forceUpdate] = useState();

  const [haveFund, setHaveFund] = useState();

  const [fundTimeOk, setFundTimeOk] = useState(true);

  const [inRequest, setInRequest] = useState(false);

  const secInput = useRef(null);

  const [nccAmount, setNccAmount] = useState(0);
  const [teronAmount, setTeronAmount] = useState(0);
  const [tetherAmount, setTetherAmount] = useState(0);

  const [secPass, setSecPass] = useState();

  const [nccNeed, setNccNeed] = useState(110);
  const [teronNeed, setTeronNeed] = useState(110);
  const [tetherNeed, setTetherNeed] = useState(110);

  const [TronStock, setTronStock] = useState(50);

  const [ProfitStonkNcc, setProfitStonkNcc] = useState(10);
  const [ProfitStonkTron, setProfitStonkTron] = useState(10);
  const [ProfitStonkTether, setProfitStonkTether] = useState(10);


  const dispatch = useDispatch();

  useEffect(() => {
    const fundTimeStart = Date.parse(plans.find(entry => entry.id === 1).fromDate.replace(/-/g, '/'));
    const fundTimeNow = new Date().getTime();
    const fundTimeEnd = Date.parse(plans.find(entry => entry.id === 1).toDate.replace(/-/g, '/'));

    if (fundTimeNow > fundTimeEnd || fundTimeNow < fundTimeStart) {
      setFundTimeOk(false);
    }

    if (!isActive) {
      setFundTimeOk(false);
    }

    if (plans?.find(entry => entry.id === 1).isActive !== 1) {
      setFundTimeOk(false);
    }

    if (balanceWallet) {
      setNccAmount(balanceWallet?.NCC?.Deposit || 0);
      setTeronAmount(balanceWallet?.TRX?.Deposit || 0);
      setTetherAmount(balanceWallet?.USDT?.Deposit || 0);
    }

    if (havePlans) {
      if (havePlans.Fund) {
        //  if (havePlans.Fund.isActiveUserPlan === 1 && havePlans.Fund.isActive === 1) {
        setHaveFund(true);
        // }
      }
    }

    if (plancrypto) {
      setNccNeed(plancrypto.find(entry => entry.id === 1)?.amount);
      setTeronNeed(plancrypto.find(entry => entry.id === 2)?.amount);
      setTetherNeed(plancrypto.find(entry => entry.id === 3)?.amount);

      setProfitStonkNcc(plancrypto.find(entry => entry.id === 1)?.profitStonk);
      setProfitStonkTron(plancrypto.find(entry => entry.id === 2)?.profitStonk);
      setProfitStonkTether(plancrypto.find(entry => entry.id === 3)?.profitStonk);
    }

    if (currencies[1]) {
      setTronStock(currencies.find(i => i.id === 2)?.minStock);
    }
  }, [havePlans, plancrypto]);

  // console.log(TronStock + ProfitStonkNcc + ProfitStonkTron + ProfitStonkTether);



  const validator = useRef(new SimpleReactValidator());

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };


  const handleFund = () => {
    if (validator.current.fieldValid('Security Password')) {
      setInRequest(true);
      dispatch(setLoadingFund());
      localStorage.setItem('lastReq', new Date().getTime());
      AuthRequest(`${serverURL}/holder/setPlans`,
        {
          FFCode, planId: '1', token: localStorage.getItem('token'), secPass,
        })
        .then(res => res.json())
        .then((data) => {
          setInRequest(false);
          dispatch(clearLoadingFund());
          if (showLogs) {
            console.log('setPlans (Fund) :', data);
          }
          if (data.numReq === 1 && data.sttReq === 'ok') {
            toast.success('You successfully active the Fund plan !', {
              position: 'top-center',
              autoClose: 15000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setHavePlan(data.havePlans));
            dispatch(setBalance(data.Balance));
            dispatch(getRefferal());
          } else if (data.numReq === -2 && data.sttReq === 'you have this plan') {
            toast.error('You already have the Fund plan !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setHavePlan(data.havePlans));
          } else if (data.numReq === -2 && data.sttReq === 'user have problem') {
            toast.error('Your process cant be done, Try again later', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'not enough cash') {
            toast.error('There is not enough cash in your balance !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'plan not active') {
            toast.error('There Fund plan is not active right now !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'expire') {
            toast.error('The Fund plan is not available in this date !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'not enough TRX') {
            toast.error('There is not enough TRX in your balance !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'have problem txid') {
            toast.error('Server response is not available right now (taxid) !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'wrong secPass') {
            toast.error('The entered security is not true, try again !', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInRequest(false);
          dispatch(clearLoadingFund());
        });
      togglePass();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleCheckFund = () => {
    if ((nccAmount < nccNeed) || (teronAmount < teronNeed) || (tetherAmount < tetherNeed)) {
      if (nccAmount < nccNeed) {
        toast.error(`Your NCC Amount is not Enough (${nccAmount}),
       Should be ${nccNeed} !`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (teronAmount < teronNeed) {
        toast.error(`Your Tron Amount is not Enough (${teronAmount}),
       Should be ${teronNeed} !`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (tetherAmount < tetherNeed) {
        toast.error(`Your Tether Amount is not Enough (${tetherAmount}),
       Should be ${tetherNeed} !`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else if ((nccAmount >= nccNeed) && (teronAmount >= teronNeed) && (tetherAmount >= tetherNeed)) {
      toggle();
    }
  };

  return (
    <>
      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleFund();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleFund}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Fund</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <div className="d-flex justify-content-around">
            <span className="font-weight-bold">
              <img src={nccImgURL} className="crypto_moda_img" alt="" />
              {nccNeed}
            </span>
            <span className="font-weight-bold">
              <img src={tronImgURL} className="crypto_moda_img" alt="" />
              {teronNeed}
            </span>
            <span className="font-weight-bold">
              <img src={tetherImgURL} className="crypto_moda_img" alt="" />
              {tetherNeed}
            </span>
          </div>
          <br />
          <br />
          <p>
            You are going to transfer the following percentages to the recipients listed below.
            Do you confirm?
          </p>
          <br />
          <br />
          <table
            className="table affiliate text-center"
            style={{ height: 'auto' }}
          >
            <thead>
              <tr>
                <th scope="col">Percent</th>
                <th scope="col">Pay To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{Fund?.site?.profit} %</td>
                <td>Financial Factory</td>
              </tr>
              <tr>
                <td>{Fund?.Level1?.profit} %</td>
                <td>{Fund?.Level1?.FFCode || '-'}</td>
              </tr>
              <tr>
                <td>{Fund?.Level2?.profit} %</td>
                <td>{Fund?.Level2?.FFCode || '-'}</td>
              </tr>
              <tr>
                <td>{Fund?.Level3?.profit} %</td>
                <td>{Fund?.Level3?.FFCode || '-'}</td>
              </tr>
              <tr>
                <td>{Fund?.Level6?.profit} %</td>
                <td>{Fund?.Level6?.FFCode || '-'}</td>
              </tr>
            </tbody>
          </table>
          <br />

        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <div>
        <ul className="plan_conditions">
          <li>You need to enable it in this section to enter and use the benefits of the plan fund.</li>
          <h6>
            Conditions for activating the plan:
          </h6>
          <li>
            You will need {tetherNeed} Tether, {teronNeed} Tron and {nccNeed} NCC,
            which can only be entered once by each person.
          </li>
          <li>You can calculate the method of generating income from this plan in the table below.</li>
          <li>You can increase your income by inviting people through this plan.</li>
          <li>
            A green icon means sufficient balance, the gray icon is the lack of currency in your wallet,
            and the amount of deficit of each currency is written below.
          </li>
        </ul>
      </div>
      <div className="text-center">
        <div className="plan_img">
          <div className={(nccAmount < nccNeed) && !haveFund ? 'plan_img_red' : 'plan_img_have'}>
            <img
              className={(nccAmount < nccNeed) && !haveFund ? 'plan_image_gray' : 'plan_image'}
              alt=""
              src={nccImgURL}
            />
          </div>
          <div className="tefo_error_plan text-center">
            {(nccAmount < nccNeed) && !haveFund
              && `${nccNeed - parseInt(nccAmount, 10)} NCC`}
            <br />
            {(nccAmount < nccNeed) && !haveFund
              && 'required'}
          </div>
        </div>
        <div className="plan_img">
          <div className={(teronAmount < teronNeed) && !haveFund ? 'plan_img_red' : 'plan_img_have'}>
            <img
              className={(teronAmount < teronNeed) && !haveFund ? 'plan_image_gray' : 'plan_image'}
              alt=""
              src={tronImgURL}
            />
          </div>
          <div className="tefo_error_plan text-center">
            {(teronAmount < teronNeed) && !haveFund
              && `${teronNeed - parseInt(teronAmount, 10)} Tron `}
            <br />
            {(teronAmount < teronNeed) && !haveFund
              && 'required'}
          </div>
        </div>
        <div className="plan_img">
          <div className={(tetherAmount < tetherNeed) && !haveFund ? 'plan_img_red' : 'plan_img_have'}>
            <img
              className={(tetherAmount < tetherNeed) && !haveFund ? 'plan_image_gray' : 'plan_image'}
              alt=""
              src={tetherImgURL}
            />
          </div>
          <div className="tefo_error_plan text-center">
            {(tetherAmount < tetherNeed) && !haveFund
              && `${tetherNeed - parseInt(tetherAmount, 10)} Tether`}
            <br />
            {(tetherAmount < tetherNeed) && !haveFund
              && 'required'}
          </div>
        </div>
      </div>
      {haveFund
        && (
          <div className="tefo_error_plan text-center">
            <span className="havePlan_msg">You already have the Fund plan !</span>
          </div>
        )}
      <div className="mb-3 text-center">
        {haveFund ? ''
          : (
            <>
              {
                (teronAmount < teronNeed + ProfitStonkNcc + ProfitStonkTron + ProfitStonkTether)
                  && (nccAmount >= nccNeed) && (teronAmount >= teronNeed) && (tetherAmount >= tetherNeed)
                  ? (
                    <div className="tefo_error tefo_error_strong text-center mb-5">
                      *Your TRX deposit balance must be at least {teronNeed
                        + ProfitStonkNcc + ProfitStonkTron
                        + ProfitStonkTether} to Activating this plan.
                    </div>
                  ) : ''
              }
              <button
                type="submit"
                className="btn btn-outline-theme tefo_buttons_md w-50"
                onClick={handleCheckFund}
                disabled={haveFund
                  || !fundTimeOk
                  || true
                  || fundLoad
                  || inRequest
                  || !isAllowPlan
                  || (teronAmount < teronNeed + ProfitStonkNcc + ProfitStonkTron + ProfitStonkTether)
                  || (nccAmount < nccNeed)
                  || (teronAmount < teronNeed)
                  || (tetherAmount < tetherNeed) ? true : ''}
              >
                {inRequest || fundLoad ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" /> Processing ...
                  </>
                ) : 'Join Now !'}
              </button>
            </>
          )}
      </div>
    </>
  );
};

FundJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  Fund: PropTypes.objectOf.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  fundLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, constants, loader, theme,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    Fund: userInfo.user?.voidPlans?.Fund,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    fundLoad: loader.fundLoad,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(FundJoin);
