import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import Carousel from '@/shared/components/carousel/CarouselSingle';
import { getBannersUrl } from '../../../../redux/actions/constantActions';


const DeskTopMessage = ({ banners }) => {
  const [deskBanners, setDeskBanners] = useState([
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-1.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-2.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-3.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-4.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-5.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-6.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-desk-7.png`,
  ]);
  const [mobBanners, setMobBanners] = useState([
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-1.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-2.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-3.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-4.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-5.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-6.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-7.png`,
    `${process.env.PUBLIC_URL}/img/banner/banner-mob-8.png`,
  ]);

  const dispatch = useDispatch();


  // useEffect(() => {
  //   dispatch(getBannersUrl());
  // }, []);

  // useEffect(async () => {
  //   setDeskBanners(banners?.desktop);
  //   setMobBanners(banners?.mobile);
  // }, [banners]);


  return (

    <Card>
      <CardBody className="desk_msg_cardBody text-center">

        {/* <div>
          Welcome to Financial Factory
        </div> */}

        <div className="banner_desk_pc">
          <Carousel>
            {deskBanners?.map((item, i) => (
              <div key={item.i}>
                <img
                  src={item}
                  alt=""
                  onError={Math.floor(deskBanners[deskBanners?.length * Math.random() * 10])}
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="banner_desk_mob">
          <Carousel>
            {mobBanners?.map((item, i) => (
              <div key={item.i}>
                <img
                  src={item}
                  alt=""
                  onError={Math.floor(mobBanners[mobBanners?.length * Math.random() * 10])}
                />
              </div>
            ))}
          </Carousel>
        </div>

      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>

  );
};


DeskTopMessage.propTypes = {
  banners: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const {
    constants,
  } = state;
  return {
    banners: constants?.banners,
  };
}


export default connect(mapStateToProps)(DeskTopMessage);
