import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import axios from 'axios';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import SidebarLink from './SidebarLink';
import { logOut, setFFuser } from '../../../redux/actions/userActions';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants } from '../../../redux/actions/constantActions';
import {
  clearLoadingAffiliate,
  clearLoadingBuy,
  clearLoadingBuyInvest,
  clearLoadingFund,
  clearLoadingKyc,
  clearLoadingRefund,
  clearLoadingStacking,
  clearLoadingWithdraw,
} from '../../../redux/actions/loaderAction';
import { showLogs } from '../../App/Rules';
import { serverbetaURL, profileImgURL } from '../../../utils/url';
import { getLogoImgUrl } from '../../../shared/helpers/themHelper';
import axiosHeaders from '../../../utils/axiosHelper';
// import SidebarCategory from './SidebarCategory';


const SidebarContent = ({
  onClick,
  messages,
  theme,
  sidebarCollapse,
  FFuser,
}) => {
  const [logo, setLogo] = useState();


  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [profileFile, setProfileFile] = useState(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
  const [profileBlob, setProfileBlob] = useState();
  const [readerImg, setReaderImg] = useState(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);

  const [errSize, setErrSize] = useState(false);
  const [errFormat, setErrFormat] = useState(false);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
    setErrSize(false);
    setErrFormat(false);
    setReaderImg(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
  };
  const closeModal = () => {
    setModal(false);
  };
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const editor = useRef(null);
  const fileInput = useRef(null);


  useEffect(() => {
    setLogo(getLogoImgUrl());
  }, [theme]);

  useEffect(() => {
    setProfileFile(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
    setReaderImg(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
  }, [FFuser]);


  const handleSelectImg = async (e) => {
    console.log(e.target.files[0]);
    setErrSize(false);
    setErrFormat(false);
    if (e.target.files[0]?.size > 1000000) {
      setErrSize(true);
      setProfileFile(null);
      setReaderImg(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
      fileInput.current.value = null;
    }
    if (e.target.files[0]?.type !== 'image/jpg' && e.target.files[0]?.type !== 'image/jpeg') {
      setErrFormat(true);
      setProfileFile(null);
      setReaderImg(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
      fileInput.current.value = null;
    }
    if ((e.target.files[0]?.type === 'image/jpg' || e.target.files[0]?.type === 'image/jpeg')
      && (e.target.files[0]?.size < 1000000)) {
      setErrSize(false);
      setErrFormat(false);
      setProfileFile(e.target.files[0]);

      const files = Array.from(e.target.files);
      if (files[0]) {
        const oFReader = new FileReader();
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = function (oFREvent) {
          setReaderImg(oFREvent.target);
        };
      }
    }
  };

  const handleLogout = () => {
    dispatch(logOut());
    dispatch(clearCurrency());
    dispatch(clearConstants());
    dispatch(clearLoadingAffiliate());
    dispatch(clearLoadingBuy());
    dispatch(clearLoadingFund());
    dispatch(clearLoadingWithdraw());
    dispatch(clearLoadingKyc());
    dispatch(clearLoadingStacking());
    dispatch(clearLoadingBuyInvest());
    dispatch(clearLoadingRefund());
  };


  const changeprofilepicture = async () => {
    // const img = editor.current.getImageScaledToCanvas();

    // img.toBlob((blob) => {
    //   if (blob) {
    //     // create object url to show the edited image
    //     setProfileBlob(URL.createObjectURL(blob));
    //     console.log(URL.createObjectURL(blob));
    //     setLogo(URL.createObjectURL(blob));
    //   }
    //   //  this.props.value(blob)  //return value as file
    // });

    // console.log(img.toDataURL('image/jpeg'));
    if (profileFile !== logo
      && !errFormat
      && !errSize
      && profileFile !== `${profileImgURL}/${FFuser?.userImage}`) {
      const sendData = new FormData();
      sendData.append('profilePic', profileFile);
      sendData.append('FFCode', FFuser.FFCode);
      if (showLogs) {
        console.log(sendData);
      }

      closeModal();

      await axios.post(`${serverbetaURL}/users/changeprofilepicture`,
        sendData, {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('changeprofilepicture :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            dispatch(setFFuser(data?.data?.data[0]));
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch(() => {
          toast.error('No response from server , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      closeModal();
    }
  };

  const deleteprofilepicture = async () => {
    closeModal();
    setProfileFile(logo);
    setScale(1.3);
    setRotate(0);

    await axios.delete(`${serverbetaURL}/users/deleteProfilePic/${FFuser.FFCode}`,
      {
        headers: axiosHeaders(),
      })
      .then((data) => {
        if (showLogs) {
          console.log('deleteprofilepicture :', data);
        }
        if (data?.status === 200) {
          if (data?.data?.status === false) {
            toast.error(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          dispatch(setFFuser(data?.data?.data[0]));
          if (data?.data?.status === true) {
            toast.success(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      })
      .catch(() => {
        toast.error('No response from server , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


  return (
    <>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support profilePicModal"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Profile Picture</h4>
        </div>
        <div className="modal__body">
          {/* <AvatarEditor
            ref={editor}
            image={profileFile}
            width={250}
            height={250}
            border={5}
            color={[255, 255, 255, 0.6]}
            scale={scale}
            rotate={rotate}
            borderRadius={125}
          /> */}
          <img
            src={readerImg?.result || readerImg}
            alt=""
            className="profile_image_sidebar"
          />
          <input
            type="file"
            ref={fileInput}
            accept="image/jpeg, image/jpg"
            name="profileFile"
            id="profileFile"
            className="form-control form-control-sm w-50 m-auto"
            onChange={e => handleSelectImg(e)}
          />
          <div className="tefo_error tefo_error_strong text-center">
            {errSize && 'The selected image size must be less than 1Mb'}
          </div>
          <div className="tefo_error tefo_error_strong text-center">
            {errFormat && 'The selected image must be JPEG or JPG'}
          </div>
          {/* <input
            type="range"
            className="form-range w-50"
            id="scale"
            value={scale}
            min={1}
            max={10}
            step={0.1}
            onChange={e => setScale(e.target.value)}
          />
          <div>
            <button
              type="button"
              className="btn btn-outline-theme table-btn"
              onClick={() => {
                setRotate(pre => pre - 90);
              }}
            >
              <i className="fas fa-rotate-left" />
            </button>
            <button
              type="button"
              className="btn btn-outline-theme table-btn"
              onClick={() => {
                setRotate(pre => pre + 90);
              }}
            >
              <i className="fas fa-rotate-right" />
            </button>
          </div> */}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          {FFuser?.userImage && (
            <>
              <Button
                className="modal_cancel"
                onClick={() => deleteprofilepicture()}
              >
                <span className="table_icon lnr lnr-trash" />
                {' '}
                Delete
              </Button>
              {' '}
            </>
          )}
          <Button
            className="modal_cancel"
            // color="primary"
            onClick={() => changeprofilepicture()}
            disabled={errFormat || errSize}
          >
            ✔️ Confirm
          </Button>
          <Button className="modal_cancel" onClick={() => closeModal()}>Cancel</Button>
        </ButtonToolbar>
      </Modal>

      <div className="sidebar__content">

        <div className="logo_sidebar_holder">
          <button
            type="button"
            className="logo_sidebar_btn"
          // onClick={() => {
          //   openModal();
          //   setProfileFile(FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo);
          // }}
          >
            <img
              className={!sidebarCollapse ? 'logo_sidebar' : 'logo_sidebar_collapse'}
              // src={FFuser?.userImage ? `${profileImgURL}/${FFuser?.userImage}` : logo}
              src={logo}
              alt=""
            />
            {/* <span className="edit_profile_icon lnr lnr-pencil" /> */}
          </button>
        </div>
        <div className="sidebar_nickname">
          {FFuser?.nickname}
        </div>
        <ul className="sidebar__block desktop_sidebar">
          <SidebarLink title="Desktop" icon="home" route="/Desktop" onClick={onClick} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Award" icon="gift" route="/award" onClick={onClick} isBold />
          <SidebarLink title="Monetary" icon="database" route="/Monetary" onClick={onClick} />
          <SidebarLink title="Refferal" icon="select" route="/Refferal" onClick={onClick} />
          <SidebarLink title="Promotion" icon="checkmark-circle" route="/Promotion" onClick={onClick} />
          <SidebarLink
            title="Message"
            icon="envelope"
            route="/Messeges"
            onClick={onClick}
            notifCount={messages?.filter(m => m.pivot?.isRead === 0)?.length}
          />
          <SidebarLink title="Profile" icon="user" route="/Profile" onClick={onClick} />
          <SidebarLink title="Reports" icon="chart-bars" route="/Reports" onClick={onClick} />
        </ul>


        <SidebarLink title="Log Out" icon="power-switch" route="/ " onClick={handleLogout} />
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  messages: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    messages: userInfo.user?.messages,
    theme: theme.className,
    FFuser: userInfo?.user?.FFuser,
  };
}


export default connect(mapStateToProps)(SidebarContent);
