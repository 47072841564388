import React from 'react';
import {
  ButtonToolbar, ButtonGroup, Button,
} from 'reactstrap';




const Monetary = () => (

  <>
    <div className="access_admin">
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-2">
          NCC
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="BuyNcc">Buy</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="DepositNCC">Deposit</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="FundNCC">Fund</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="AffiliateNCC">Affiliate</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="StackingNCC">Stacking</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-2">
          TRX
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="BuyTRX">Buy</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="DepositTRX">Deposit</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-2">
          Tether
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="BuyTether">Buy</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <label className="form-check-label" htmlFor="DepositTether">Deposit</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <ButtonToolbar>
          <ButtonGroup className="btn-group--justified" dir="ltr">
            <Button color="secondary">Edit</Button>
            <Button color="danger">Cancel</Button>
            <Button color="primary">Save</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>

  </>
);

export default Monetary;
