import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import LayoutAdmin from '../LayoutAdmin';

import Landing from '../LogIn/Landing';
import Desktop from '../Menus/Desktop/Desktop';
import AwardMenu from '../Menus/award-menu/AwardMenu';
import Messeges from '../Menus/Messeges/Messeges';
import Refferal from '../Menus/Refferal/Refferal';
import Monetary from '../Menus/Monetary/Monetary';
import Profile from '../Menus/Profile/Profile';
import Promotion from '../Menus/Promotion/Promotion';
import Reports from '../Menus/Reports/Reports';
import Users from '../Menus/Users/Users';
import RegisterLink from '../LogIn/RegisterLink';
import SubAdmin from '../Menus/SubAdmin/SubAdmin';
import AdminMesseges from '../Menus/AdminMesseges/AdminMesseges';

import { store } from './store';



const wrappedRoutes = () => (
  <div className="bgMain">
    <Layout />
    <div className="container__wrap">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        className="toast_in_dashboard"
        draggablePercent={40}
      />
      <Switch>
        <Route path="/Desktop" component={Desktop} />
        <Route path="/award" component={AwardMenu} />
        <Route path="/Monetary" component={Monetary} />
        <Route path="/Refferal" component={Refferal} />
        <Route path="/Promotion" component={Promotion} />
        <Route path="/Messeges" component={Messeges} />
        <Route path="/Profile" component={Profile} />
        <Route path="/Reports" component={Reports} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
    <div className="text-center mb-3 copyright">
      <span className="copyright_span">
        ©2022 Financial Factory, All rights reserved. (Version 1.0)
      </span>
    </div>
  </div>
);
const adminRoutes = () => (
  <div className="bgMain">
    <LayoutAdmin />
    <div className="container__wrap">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        className="toast_in_dashboard"
        draggablePercent={40}
      />
      <Switch>
        <Route path="/Users" component={Users} />
        <Route path="/SubAdmin" component={SubAdmin} />
        <Route path="/AdminMessages" component={AdminMesseges} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>

    <main>
      <Switch>
        <Route exact path={['/']} component={Landing} />
        <Route path="/refferallink/:code" component={RegisterLink} />
        <Route path="/emailactivation/:ffCode" component={Landing} />
        <Route
          path="/"
          render={() => {
            const currentUser = store.getState().userInfo.user;
            if (!currentUser) {
              return <Redirect to="/" />;
            }
            if (currentUser.FFuser.isHolder === 1) {
              return wrappedRoutes();
            }
            if (currentUser.FFuser.isHolder === 0) {
              return adminRoutes();
            }
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
