import React, { useMemo } from 'react';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        // disableSortBy: true,
      },
      {
        Header: 'Cancel Time',
        accessor: 'Cancel',
      },
    ],
    [],
  );
  const { refundSummary } = store.getState().userInfo.user;


  const data = [];

  function compare(a, b) {
    if (a.updated_at < b.updated_at) {
      return 1;
    }
    if (a.updated_at > b.updated_at) {
      return -1;
    }
    return 0;
  }

  const rows = () => {
    const allRefunds = [];
    if (refundSummary) {
      if (refundSummary?.active) {
        allRefunds?.push(refundSummary?.active);
      }
      if (refundSummary?.cancels) {
        for (let i = 0; i < refundSummary?.cancels?.length; i += 1) {
          allRefunds?.push(refundSummary?.cancels[i]);
        }
      }
      if (refundSummary?.finishes) {
        for (let j = 0; j < refundSummary?.finishes?.length; j += 1) {
          allRefunds?.push(refundSummary?.finishes[j]);
        }
      }

      for (let pl = 0; pl < allRefunds?.length; pl += 1) {
        data.push({
          date: allRefunds[pl]?.created_at?.split(' ')[0],
          time: allRefunds[pl]?.created_at?.split(' ')[1],
          Status: `refundStatus${allRefunds[pl]?.typeId}`,
          Cancel: allRefunds[pl]?.typeId === 327 ? allRefunds[pl]?.updated_at?.split(' ')[0] : '-',
        });
      }
    }
    return data;
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
