import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import { Card, CardBody } from 'reactstrap';
import { transparentize } from 'polished';
import maps from './world';
import data from './world-population-density.json';
import { store } from '../../../../../App/store';
import { changeThemeToCapital, changeThemeToDark } from '../../../../../../redux/actions/themeActions';

// you can find demos here: https://www.amcharts.com/demos


const Map = ({ theme, deskMap, mapMainColor }) => {
  const dispatch = useDispatch();
  // const [mapMainColor, setMapMainColor] = useState('#fff');
  // const [, forceUpdate] = useState(1);


  // useEffect(() => {
  //   if (theme === 'theme-dark') {
  //     setMapMainColor('#3cd2a5');
  //     dispatch(changeThemeToCapital());
  //     forceUpdate(2);
  //     dispatch(changeThemeToDark());
  //   } else if (theme === 'theme-capital') {
  //     setMapMainColor('#ff8f1f');
  //     dispatch(changeThemeToDark());
  //     forceUpdate(3);
  //     dispatch(changeThemeToCapital());
  //   }
  // }, [theme]);


  useEffect(() => {
    const map = am4core.create('chartdiv', am4maps.MapChart);

    // if (theme === 'theme-dark') {
    //   setMapMainColor('#3cd2a5');
    //   dispatch(changeThemeToCapital());
    //   forceUpdate(2);
    //   dispatch(changeThemeToDark());
    // } else if (theme === 'theme-capital') {
    //   setMapMainColor('#ff8f1f');
    //   dispatch(changeThemeToDark());
    //   forceUpdate(3);
    //   dispatch(changeThemeToCapital());
    // }

    const strokeColor = '#38b994';
    // const mapMainColor = '#38b994';
    const lightGrayColor = '#F2F2F2';
    const lightTextColor = '#93A2AF';
    const whiteColor = '#FFFFFF';

    const heatRule = {
      property: 'fill',
      min: am4core.color('#ffffff'),
    };


    map.geodata = maps;
    map.series.draggable = false;
    map.centerMapOnZoomOut = true;
    // Set projection
    map.projection = new am4maps.projections.Miller();

    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

    heatRule.max = am4core.color(mapMainColor);
    heatRule.target = polygonSeries.mapPolygons.template;

    polygonSeries.heatRules.push(heatRule);

    polygonSeries.useGeodata = true;
    const label = map.createChild(am4core.Label);
    // label.text = 'Your Members Activity Density In World';
    label.fontSize = 12;
    label.align = 'center';
    label.valign = 'bottom';
    label.fill = am4core.color(lightTextColor);
    label.padding(400, 0, 60, 0);
    const noDataLabel = map.createChild(am4core.Label);
    noDataLabel.text = 'no available data';
    noDataLabel.fontSize = 12;
    noDataLabel.align = 'center';
    noDataLabel.valign = 'middle';
    noDataLabel.padding(10, 20, 10, 20);
    noDataLabel.background.fill = am4core.color(lightGrayColor);
    noDataLabel.background.stroke = am4core.color('#000000');
    noDataLabel.fillOpacity = data.length === 0 ? 1 : 0;
    noDataLabel.background.strokeOpacity = data.length === 0 ? 1 : 0;
    const heatLegend = map.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.padding(10, 20, 12, 20);
    heatLegend.width = 300;
    heatLegend.valueAxis.renderer.minGridDistance = 60;
    heatLegend.valueAxis.numberFormatter.numberFormat = '#.a';
    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', text => (data.length > 0 ? text : ''));
    heatLegend.align = 'center';
    heatLegend.valign = 'bottom';
    heatLegend.minColor = '#FFF';
    heatLegend.maxColor = mapMainColor;
    heatLegend.valueAxis.tooltip.background.fill = am4core.color(whiteColor);
    heatLegend.valueAxis.tooltip.background.stroke = am4core.color(mapMainColor);
    heatLegend.valueAxis.tooltip.label.fill = am4core.color('#000');
    heatLegend.stroke = am4core.color(lightTextColor);
    heatLegend.valueAxis.strokeWidth = 0;

    const polygonTemplate = polygonSeries.mapPolygons.template;

    let minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.label.paddingTop = -15;

    let maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.label.paddingTop = -15;

    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => (''));

    polygonSeries.events.on('datavalidated', () => {
      const min = heatLegend.series.dataItem.values.value.low;
      minRange = heatLegend.valueAxis.axisRanges.getIndex(0);
      minRange.value = min;
      minRange.label.text = `${min}`;

      const max = heatLegend.series.dataItem.values.value.high;
      maxRange = heatLegend.valueAxis.axisRanges.getIndex(1);
      maxRange.value = max;
      maxRange.label.text = `${heatLegend.numberFormatter.format(max)}`;

      minRange.label.fill = am4core.color(lightTextColor);
      maxRange.label.fill = am4core.color(lightTextColor);

      if (min === max) {
        minRange.label.paddingRight = 300;
        maxRange.label.paddingLeft = 300;
      } else {
        minRange.label.paddingRight = 45;
        maxRange.label.paddingLeft = 45;
      }
    });

    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    polygonTemplate.fill = am4core.color('#f7f7f7');
    polygonTemplate.stroke = am4core.color('#999999');

    const tooltipFormatter = (target) => {
      const { value } = target.dataItem.dataContext;
      const { name } = target.dataItem.dataContext;
      const textValue = value >= 0 ? `: ${value}` : '';

      // return `${`vector_map.countries.${target.dataItem.dataContext['hc-key']}`}${textValue}`;
      return `${name} ${textValue}`;
    };

    polygonSeries.data = [...deskMap];
    polygonTemplate.adapter.add('tooltipText', (text, target) => tooltipFormatter(target));
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color(`${transparentize(0.2, '#FFF')}`);
    polygonSeries.tooltip.label.fill = am4core.color('#000');

    const handleHover = (mapPolygon) => {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(mapPolygon.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(mapPolygon.dataItem.value);
      } else {
        heatLegend.valueAxis.hideTooltip();
      }
    };

    polygonSeries.mapPolygons.template.events.on('over', (event) => {
      handleHover(event.target);
    });

    polygonSeries.mapPolygons.template.events.on('hit', (event) => {
      handleHover(event.target);
    });

    polygonSeries.mapPolygons.template.events.on('out', () => {
      heatLegend.valueAxis.hideTooltip();
    });

    const hs = polygonTemplate.states.create('hover');
    hs.properties.stroke = am4core.color(strokeColor);
    hs.properties.strokeWidth = 2;
    const zoomControl = new am4maps.ZoomControl();
    zoomControl.align = 'left';
    zoomControl.valign = 'middle';
    map.zoomControl = zoomControl;

    // if (theme === 'theme-dark') {
    //   setMapMainColor('#3cd2a5');
    //   dispatch(changeThemeToCapital());
    //   forceUpdate(2);
    //   dispatch(changeThemeToDark());
    // } else if (theme === 'theme-capital') {
    //   setMapMainColor('#ff8f1f');
    //   dispatch(changeThemeToDark());
    //   forceUpdate(3);
    //   dispatch(changeThemeToCapital());
    // }
  }, [theme]);

  return (
    <Card className="map_card">
      <CardBody>
        <h5 className="text-left map_title">All Members Activity</h5>
        <div
          id="chartdiv"
          className="map_desk_chart"
        />
      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};

Map.propTypes = {
  theme: PropTypes.string.isRequired,
  mapMainColor: PropTypes.string.isRequired,
  deskMap: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const {
    // theme,
    constants,
  } = state;
  return {
    // theme: theme.className,
    deskMap: constants.deskMap,
  };
}

export default connect(mapStateToProps)(Map);
