import React, { useMemo } from 'react';
import { getAvatarPlaceHolder } from '../../../../../../shared/helpers/themHelper';
import { profileImgURL } from '../../../../../../utils/url';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   width: '9x',
      // },
      {
        Header: 'FF Code',
        accessor: 'FFCode',
      },
      {
        Header: 'Nickname',
        accessor: 'Nickname',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Join Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Sell',
        accessor: 'Sell',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Direct Count',
        accessor: 'DirectCount',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Avatar',
        accessor: 'Avatar',
        customRender: true,
        disableGlobalFilter: true,
      },
      {
        Header: 'Score',
        accessor: 'Score',
        customRender: true,
        // disableGlobalFilter: true,
        // Footer: (info) => {
        //   const { rows, flatRows } = info;
        //   const totalAge = useMemo(
        //     () => rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
        //     [rows],
        //   );
        //   const age = Math.round(totalAge / flatRows.length);
        //   return <span>{age}</span>;
        // },
      },
    ],
    [],
  );

  const binaries = store.getState().userInfo?.user?.binarySummary?.members;



  const data = [];
  const rows = () => {
    binaries?.map((bin, i) => {
      data.push({
        id: i + 1,
        FFCode: bin?.FFCode,
        Nickname: bin?.nickname || '-',
        date: bin?.created_at.split('T')[0],
        Sell: Math.floor(bin.amount),
        DirectCount: bin?.countUser,
        Avatar: `avatar&&${!bin?.userImage ? getAvatarPlaceHolder() : `${profileImgURL}/${bin?.userImage}`}`,
        Score: `starScore&&${bin?.amount}`,
      });
      return data;
    });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
