import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button, Col, Popover, PopoverBody, PopoverHeader, Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { store } from '../../App/store';
import { setCurrency } from '../../../redux/actions/currencyActions';


const convertor = `${process.env.PUBLIC_URL}/img/convert.svg`;


const TopbarConvertor = ({ dir }) => {
  const [cr1, setCr1] = useState('NCC');
  const [cr2, setCr2] = useState('Tether');
  const [amount, setAmount] = useState('0');
  const [result, setResult] = useState();
  const [unValid, setUnValid] = useState(false);
  const [, forceUpdate] = useState();

  const { ncc, tether, tron } = store.getState().currency;

  const dispatch = useDispatch();

  const [IsOpenedConvertor, setIsOpenedConvertor] = useState(false);
  const handleOpenConvertor = () => {
    setIsOpenedConvertor(!IsOpenedConvertor);
    dispatch(setCurrency());
  };

  const closeConvertor = () => {
    setIsOpenedConvertor(!IsOpenedConvertor);
  };

  const validatorConfigs = {
    element: message => <div className="validationErr mt-1">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  const convert = (e) => {
    setAmount(e.target.value);
    if (validator.current.allValid() && !Number.isNaN(+e.target.value)) {
      setUnValid(false);

      if (cr1 === 'NCC') {
        if (cr2 === 'NCC') {
          setResult(e.target.value);
        }
        if (cr2 === 'Tether') {
          setResult(e.target.value * ncc);
        }
        if (cr2 === 'TRX') {
          setResult((e.target.value * tron) / ncc);
        }
      }

      if (cr1 === 'Tether') {
        if (cr2 === 'NCC') {
          setResult(e.target.value / ncc);
        }
        if (cr2 === 'Tether') {
          setResult(e.target.value);
        }
        if (cr2 === 'TRX') {
          setResult((e.target.value * tron));
        }
      }

      if (cr1 === 'TRX') {
        if (cr2 === 'NCC') {
          setResult((e.target.value * ncc) / tron);
        }
        if (cr2 === 'Tether') {
          setResult(e.target.value * tron);
        }
        if (cr2 === 'TRX') {
          setResult(e.target.value);
        }
      }
    } else if (Number.isNaN(+e.target.value)) {
      setResult('---');
      setAmount('');
      setUnValid(true);
    }
  };

  const convertOnChangeCr = () => {
    if (validator.current.allValid() && !Number.isNaN(+amount)) {
      setUnValid(false);

      if (cr1 === 'NCC') {
        if (cr2 === 'NCC') {
          setResult(amount);
        }
        if (cr2 === 'Tether') {
          setResult(amount * ncc);
        }
        if (cr2 === 'TRX') {
          setResult((amount * tron) / ncc);
        }
      }

      if (cr1 === 'Tether') {
        if (cr2 === 'NCC') {
          setResult(amount / ncc);
        }
        if (cr2 === 'Tether') {
          setResult(amount);
        }
        if (cr2 === 'TRX') {
          setResult((amount * tron));
        }
      }

      if (cr1 === 'TRX') {
        if (cr2 === 'NCC') {
          setResult((amount * ncc) / tron);
        }
        if (cr2 === 'Tether') {
          setResult(amount * tron);
        }
        if (cr2 === 'TRX') {
          setResult(amount);
        }
      }
    } else if (Number.isNaN(+amount)) {
      setResult('---');
      setAmount('');
      setUnValid(true);
    }
  };

  return (
    <div className="quice_info quice_convertor">
      <div className="quice_info_img">
        <Button
          id="convertorTop"
          onClick={handleOpenConvertor}
          outline
          className="button-tooltip quiceInfo_btn"
        >
          <img className="quice_info_image" id="convertorTop" alt="" src={convertor} />
        </Button>
        <Popover
          placement="bottom"
          isOpen={IsOpenedConvertor}
          target="convertorTop"
          toggle={handleOpenConvertor}
          dir={dir}
        >
          <button type="button" className="btn_convertor" onClick={closeConvertor}>
            <span className="lnr lnr-cross" />
          </button>
          <div className="convertor_holder">
            <PopoverHeader>
              <h5>Crypto Convertor</h5>
            </PopoverHeader>
            <hr className="convertor_hr" />
            <PopoverBody>
              <Row className="quiceInfo_row">
                <Col>
                  <select
                    className="form-select mb-2 text-center"
                    id="cr1"
                    value={cr1}
                    onClick={(e) => {
                      convertOnChangeCr();
                      setCr1(e.target.value);
                    }}
                    onChange={(e) => {
                      convertOnChangeCr();
                      setCr1(e.target.value);
                    }}
                  >
                    <option>NCC</option>
                    <option>Tether</option>
                    <option>TRX</option>
                  </select>
                </Col>
                <Col>
                  <input
                    className={unValid ? 'unValid form-control text-center ' : 'form-control text-center'}
                    type="text"
                    placeholder=""
                    value={amount}
                    onChange={e => convert(e)}
                    onClick={() => setAmount('')}
                  />
                  {validator.current.message('amount', amount, 'numeric')}
                </Col>
              </Row>
              <Row className="quiceInfo_row">
                <Col>
                  <select
                    className="form-select mb-2 text-center"
                    id="cr2"
                    value={cr2}
                    onClick={(e) => {
                      convertOnChangeCr();
                      setCr2(e.target.value);
                    }}
                    onChange={(e) => {
                      convertOnChangeCr();
                      setCr2(e.target.value);
                    }}
                  >
                    <option>NCC</option>
                    <option>Tether</option>
                    <option>TRX</option>
                  </select>
                </Col>
                <Col>
                  <input
                    className="form-control text-center"
                    type="text"
                    placeholder=""
                    readOnly
                    value={result}
                  />
                </Col>
              </Row>
            </PopoverBody>
          </div>
        </Popover>
      </div>
    </div>
  );
};

TopbarConvertor.propTypes = {
  dir: PropTypes.string.isRequired,
};




export default connect()(TopbarConvertor);

