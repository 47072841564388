import React from 'react';

import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import DataTable from './DataTable';



const Transactions = () => {
  const Ava = 1;

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <DataTable />
        </Row>
      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};

export default Transactions;
