import React, { useMemo } from 'react';
import { store } from '../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   width: 1000,
      // },
      {
        Header: 'Plan',
        accessor: 'Plan',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        forBadge: true,
        // disableSortBy: true,
      },
      {
        Header: 'Cancel Time',
        accessor: 'Cancel',
        // disableGlobalFilter: true,
        customRender: true,
        forBadge: true,
        disableSortBy: true,
      },
    ],
    [],
  );
  const { havePlans } = store.getState().userInfo.user;

  const data = [];

  const rows = () => {
    if (havePlans) {
      for (let pl = 0; pl < Object.values(havePlans).length; pl += 1) {
        data.push({
          id: pl + 1,
          Plan: Object.values(havePlans)[pl]?.namePlan,
          date: Object.values(havePlans)[pl]?.dataActive?.toString()?.split(' ')[0],
          time: Object.values(havePlans)[pl]?.dataActive?.toString()?.split(' ')[1],
          Status: `activePlan${Object.values(havePlans)[pl]?.isActiveUserPlan}`,
          Cancel: '',
        });
      }
    }
    return data;
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
