import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SidebarProps } from '@/shared/prop-types/ReducerProps';

const NoSecPass = ({ togglePass }) => (
  <>
    <h5>You need Security Password to do this</h5>
    <br />
    <br />
    <h6>
      For setting up your security password
      <br />
      <br />
      go to Profile {'>'} password
    </h6>
    <ButtonToolbar className="modal__footer tefo_modal__footer">
      <Button className="modal_cancel" onClick={togglePass}>Dismiss</Button>{' '}
    </ButtonToolbar>
  </>
);



NoSecPass.propTypes = {
  togglePass: PropTypes.func.isRequired,
};

export default NoSecPass;
