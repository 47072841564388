import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SidebarLink from './SidebarLink';
import { logOut } from '../../../redux/actions/userActions';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants } from '../../../redux/actions/constantActions';
// import SidebarCategory from './SidebarCategory';


const SidebarContent = ({
  onClick,
  // sidebarCollapse,
}) => {
  const logo = `${process.env.PUBLIC_URL}/img/logo-w.png`;

  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logOut());
    dispatch(clearCurrency());
    dispatch(clearConstants());
  };
  return (
    <div className="sidebar__content">
      <div className="logo_sidebar_holder">
        <img className="logo_sidebar" src={logo} alt="" />
      </div>
      <ul className="sidebar__block desktop_sidebar">
        <SidebarLink title="Desktop" icon="home" route="/desktop" onClick={onClick} />
      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Users" icon="users" route="/Users" onClick={onClick} />
        <SidebarLink title="Sub Admin" icon="laptop" route="/SubAdmin" onClick={onClick} />
        <SidebarLink title="Message" icon="envelope" route="/AdminMessages" onClick={onClick} />
        {/* <SidebarLink title="Messeges" icon="envelope" route="/Messeges" onClick={onClick} />
        <SidebarLink title="Profile" icon="user" route="/Profile" onClick={onClick} /> */}
      </ul>

      {/* <SidebarCategory title="Invitation" icon="share" sidebarCollapse={sidebarCollapse}>
      <button type="button" className="sidebar__link" onClick={changeToLight}>
        <p className="sidebar__link-title">Light Theme</p>
      </button>
      <button type="button" className="sidebar__link" onClick={changeToDark}>
        <p className="sidebar__link-title">Dark Theme</p>
      </button>
    </SidebarCategory> */}

      <SidebarLink title="Log Out" icon="power-switch" route="/ " onClick={handleLogout} />
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  // sidebarCollapse: PropTypes.bool,
};

// SidebarContent.defaultProps = {
//   sidebarCollapse: false,
// };

export default SidebarContent;
