import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';


const BuyInvestConditions = ({
  plancrypto,
}) => {
  const [tetherNeed, setTetherNeed] = useState();
  useEffect(() => {
    if (plancrypto[0]) {
      setTetherNeed(plancrypto.find(entry => entry.id === 5)?.amount);
      // setTetherNeed(100);
    }
  }, [plancrypto]);


  return (
    <>
      <div className="plan_conditions kyc_notes">
        <ul>
          <li>The minimum balance to participate in this project is 500 Tether.</li>
          <li>
            Your participation interest includes 25% Tether interest, which is
            paid to you within ten months and can be withdrawn at any time by deducting 30% of the interest amount.
          </li>
          <li>
            Also, the amount of NCC purchased within ten months will be
            returned to you at the price of the plan activation day.
          </li>
          <li>
            You can staking or withdraw the received amount every month.
          </li>
          <li>
            Use its monetization plan by activating this plan !
          </li>
        </ul>
      </div>
    </>
  );
};

BuyInvestConditions.propTypes = {
  plancrypto: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { constants } = state;
  return {
    plancrypto: constants?.plancrypto,
  };
}

export default connect(mapStateToProps)(BuyInvestConditions);

