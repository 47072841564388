import React, { useState } from 'react';
import {
  ButtonToolbar, ButtonGroup, Button,
} from 'reactstrap';




const Access = () => (

  <>
    <div className="access_admin">
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-3">
          Monetary
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Buy" />
            <label className="form-check-label" htmlFor="Buy">Buy</label>
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Withdrawel" />
            <label className="form-check-label" htmlFor="Withdrawel">Withdrawel</label>
          </div>
        </div>
      </div>
      <hr />
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-3">
          Refferal
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Refferal" />
            <label className="form-check-label" htmlFor="Refferal">Refferal</label>
          </div>
        </div>
      </div>
      <hr />
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-3">
          Promotion
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="All" />
            <label className="form-check-label" htmlFor="All">All Promotion Plans</label>
          </div>
        </div>
      </div>
      <hr />
      <div className="access_admin_divItem mb-4">
        <div className="access_title mb-3">
          Profile
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Active" />
            <label className="form-check-label" htmlFor="Active">Active</label>
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="EditProfile" />
            <label className="form-check-label" htmlFor="EditProfile">Edit Profile</label>
          </div>
        </div>
        <div className="access_items">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="ValidKYC" />
            <label className="form-check-label" htmlFor="ValidKYC">Valid KYC</label>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <ButtonToolbar>
          <ButtonGroup className="btn-group--justified" dir="ltr">
            <Button color="secondary">Edit</Button>
            <Button color="danger">Cancel</Button>
            <Button color="primary">Save</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>

  </>
);


export default Access;
