import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  UncontrolledTooltip, Button, Modal, ButtonToolbar, Row, Col,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReactSpeedometer from 'react-d3-speedometer';
import { getBuyInvestSummary } from '../../../../../redux/actions/userActions';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { store } from '../../../../App/store';
import { serverbetaURL } from '../../../../../utils/url';
import { clearLoadingStacking, setLoadingStacking } from '../../../../../redux/actions/loaderAction';
import NoSecPass from '../../../../Layout/NoSecPass';
import CodeDropdown from '../../../../../shared/components/CodeDropdown';
import Format2 from '../../../../../utils/Format2';
import { showLogs } from '../../../../App/Rules';
import axiosHeaders from '../../../../../utils/axiosHelper';



const BuyInvestReport = ({
  balanceWallet,
  havePlans,
  stackingLoad,
  FFCode,
  isActive,
  isAllowPlan,
  haveSecPass,
  plans,
  plancrypto,
  currencies,
  tab,
  BuyInvestSummary,
  withdrawWallet,
  depositWallet,
  FFuser,
}) => {
  const [feeUSDT, setFeeUsdt] = useState(0);
  const [usdtTotal, setTotalUsdt] = useState(0);
  const [nccTotal, setTotalNcc] = useState(0);
  const [usdtPay, setPayedUsdt] = useState(0);
  const [payedTime, setPayedTime] = useState(0);

  const [finished, setFinished] = useState();

  const [agree, setAgree] = useState(false);

  const dispatch = useDispatch();

  const [, forceUpdate] = useState();

  const [secPass, setSecPass] = useState('');

  const secInput = useRef(null);

  const [wallet, setWallet] = useState();
  const [walletModal, setWalletModal] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };



  useEffect(() => {
    // if (BuyInvestSummary?.endProfit) {
    //   setPayedTime(10);
    // } else {
    //   setPayedTime(BuyInvestSummary?.monthCount || 0);
    // }


    if ((Number(BuyInvestSummary?.usdtTotal * 0.7).toFixed(2)
      === Number(BuyInvestSummary?.usdtPay).toFixed(2)) && BuyInvestSummary?.nccTotal > 0) {
      setPayedTime(10);
    } else {
      setPayedTime(BuyInvestSummary?.monthCount || 0);
    }
    if (BuyInvestSummary?.usdtTotal > 0) {
      setFeeUsdt(Number((BuyInvestSummary?.usdtTotal || 0) / 10).toFixed(1) || 0);
    } else {
      setFeeUsdt(Number((BuyInvestSummary?.usdtTotal || 0) / 10).toFixed(0) || 0);
    }
    setTotalUsdt(BuyInvestSummary?.usdtTotal || 0);
    setPayedUsdt(BuyInvestSummary?.usdtPay || 0);
    setTotalNcc(BuyInvestSummary?.nccTotal || 0);

    const startDate = new Date(BuyInvestSummary?.created_at);
    const finishDate = new Date(startDate?.setMonth(startDate.getMonth() + 10));
    if ((new Date()) < finishDate) {
      setFinished(0);
    } else {
      setFinished(1);
      if (BuyInvestSummary?.id) {
        setPayedTime(10);
      }
    }
    // || BuyInvestSummary?.id
  }, [BuyInvestSummary]);


  const selectWallet = async (e) => {
    await setWallet(e.target.value);
    if (e.target.value === depositWallet.address) {
      setWalletModal('Deposit Wallet');
    } else {
      const selectedWallet = withdrawWallet?.find(entry => entry.address === e.target.value);
      setWalletModal(selectedWallet?.name);
    }
  };

  const handleWithdrawBuyInvest = () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastBuyReq', new Date().getTime());
      const reqData = {
        userPlanId: BuyInvestSummary?.id,
        FFCode,
        token: localStorage.getItem('token'),
        secPass,
        cryptoCurrencyId: 3,
        walletAddress: wallet,
      };
      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);
      if (showLogs) {
        console.log('request Data for withdraw buy invest tether :', reqData);
      }
      axios.post(`${serverbetaURL}/userplans/fastClose`, JSON.stringify(reqData), {
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Access-Control-Allow-Origin': 'http://localhost:3000',
        //   'Access-Control-Allow-Credentials': true,
        // },
        headers: axiosHeaders(),
      })
        .then((data) => {
          dispatch(getBuyInvestSummary());
          if (showLogs) {
            console.log('fastClose :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getBuyInvestSummary());
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleGoToPass = () => {
    if (validator.current.fieldValid('agreement') && validator.current.fieldValid('wallet')) {
      toggle();
      togglePass();
      setSecPass('');
      setTimeout(() => {
        secInput.current?.focus();
      }, 1000);
      validator.current.hideMessageFor('Security Password');
      forceUpdate(14);
    } else if (!validator.current.fieldValid('agreement')) {
      validator.current.showMessageFor('agreement');
      forceUpdate(15);
    } else if (!validator.current.fieldValid('wallet')) {
      validator.current.showMessageFor('wallet');
      forceUpdate(15);
    }
  };



  return (
    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleWithdrawBuyInvest();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>

            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleWithdrawBuyInvest}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Withdraw</h4>
        </div>
        <div className="modal__body">

          <>
            <h5>Are you sure ? </h5>
            <br />
            <br />
          </>

          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={agree}
                id="agree"
                defaultChecked={agree}
                onChange={e => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="agree">
                I agree to recieve the rest of my USDT
                profits ({Format2((usdtTotal - usdtPay) || 0)} $) with a 30% deduction
              </label>
            </div>
            <div className="pt-3">
              <Row>
                <Col md={2}>
                  <label className="form-label text-left d-block" htmlFor="wallet">Wallet :</label>
                </Col>
                <Col md={10}>
                  <select
                    className="form-select text-center"
                    id="wallet"
                    value={wallet}
                    onClick={e => selectWallet(e)}
                    onChange={e => selectWallet(e)}
                  >
                    {depositWallet && (
                      <option key={depositWallet?.address} value={depositWallet?.address}>
                        {'Deposit Wallet'}
                        {' '}- {depositWallet?.address?.slice(0, 3)}
                        ***************
                        {depositWallet?.address?.slice(depositWallet?.address?.length - 4)}
                      </option>
                    )}

                    {withdrawWallet && withdrawWallet.map((item, i) => (
                      <option key={item.address} value={item.address}>
                        {item.name}
                        {' '}- {item.address?.slice(0, 3)}
                        ***************{item.address?.slice(item.address.length - 4)}
                      </option>
                    ))}
                  </select>
                  <div className="tefo_error tefo_error_strong">
                    {validator.current.message('wallet', wallet, 'required')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="tefo_error">
              {validator.current.message('agreement', agree, 'accepted')}
            </div>
          </div>

        </div>

        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          {agree ? (
            <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
          ) : ''}
        </ButtonToolbar>
      </Modal>


      <div className="text-center bi_report_holder">

        <div className="text-left">
          <h6>
            USDT Profits
            (
            {' '}
            <span>
              <strong>
                {usdtTotal ? Format2(usdtTotal) : 0}
                {' '}
                $
                {' '}
              </strong>
            </span>)
            :
          </h6>
        </div>

        <div className="progress binvest_progress_bar mb-3 mt-3 m-auto">
          <div
            className={payedTime >= 1 ? 'progress-bar binvest_steps_bar_fill binvest_steps_bar_first'
              : 'progress-bar binvest_steps_bar binvest_steps_bar_first'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 2 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 3 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 4 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 5 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 6 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 7 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 8 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 9 ? 'progress-bar binvest_steps_bar_fill'
              : 'progress-bar binvest_steps_bar'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>
          <div
            className={payedTime >= 10 ? 'progress-bar binvest_steps_bar_fill binvest_steps_bar_last'
              : 'progress-bar binvest_steps_bar binvest_steps_bar_last'}
          >
            <span className="BI_bar_usdt">
              {feeUSDT}$
            </span>
          </div>

        </div>

        <div className="mt-5">
          <Row>
            <Col md={3} xs={6}>
              <div className="bi_info_holder">
                <span>
                  Receive :
                </span>
                {' '}
                <span>
                  <strong>
                    {usdtTotal ? Number(usdtPay).toFixed(2) : 0} $
                  </strong>
                </span>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="bi_info_holder">
                <span>
                  Remain  :
                </span>
                {' '}
                <span>
                  <strong>
                    {
                      usdtTotal
                        && (!((Number(BuyInvestSummary?.usdtTotal * 0.7).toFixed(2)
                          === Number(BuyInvestSummary?.usdtPay).toFixed(2)) && BuyInvestSummary?.nccTotal > 0))
                        ? Number(usdtTotal - usdtPay).toFixed(2) : 0
                    } $
                  </strong>
                </span>
              </div>
            </Col>
            <Col>
              <button
                type="submit"
                className="btn btn-outline-theme w-100 bi_withdraw_all"
                onClick={() => {
                  toggle();
                  setAgree(false);
                  setWallet(depositWallet?.address);
                }}
                disabled={
                  ((Number(BuyInvestSummary?.usdtTotal * 0.7).toFixed(2)
                    === Number(BuyInvestSummary?.usdtPay).toFixed(2)) && BuyInvestSummary?.nccTotal > 0)
                  || finished
                  || !BuyInvestSummary?.id
                  || FFuser?.isValidInfo === 3
                  || FFuser?.isAllowWithdraw !== 1
                }
              >
                {/* {(BuyInvestSummary?.endProfit)
                  ? 'It has been settled'
                  : 'Ready to Withdraw All !'} */}
                {/* Move To Wallet ! */}
                Withdraw All !
              </button>
            </Col>
          </Row>
        </div>


      </div>
    </>
  );
};

BuyInvestReport.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  withdrawWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader, constants } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    depositWallet: userInfo.user?.depositWallet,
    withdrawWallet: userInfo.user?.withdrawWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    FFuser: userInfo.user?.FFuser,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    BuyInvestSummary: userInfo.user?.BuyInvestSummary?.active,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
  };
}


export default connect(mapStateToProps)(BuyInvestReport);
