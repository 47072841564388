import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateData';

const TablebuyInvest = ({ BuyInvestSummary }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [BuyInvestSummary]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

TablebuyInvest.propTypes = {
  BuyInvestSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { BuyInvestSummary: userInfo.user?.BuyInvestSummary };
}


export default connect(mapStateToProps)(TablebuyInvest);
