import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  Row, Col,
} from 'reactstrap';
import SelectSearch, { fuzzySearch } from 'react-select-search';



const SearchFF = ({ selectedUser }) => {
  const [value, setValue] = useState(['30303030', 'tofigh']);


  useEffect(() => {
    if (selectedUser) {
      setValue(selectedUser);
    }
  }, [selectedUser]);


  const handleCodeSelect = (v) => {
    setValue(v);
  };

  const options = [
    { name: '30303030', value: ['30303030', 'tofigh'] },
    { name: '30000000', value: ['30000000', 'kozmos'] },
    { name: '30405060', value: ['30405060', 'mohammad'] },
    { name: '30405760', value: ['30405760', 'ali'] },
  ];

  return (
    <div>
      <Col md={5}>
        <div className="mb-1">
          <SelectSearch
            options={options}
            value={value}
            onChange={v => handleCodeSelect(v)}
            search
            filterOptions={fuzzySearch}
            placeholder="Search the FF codes..."
          />
        </div>
      </Col>
      <Col md={5}>
        <div className="mb-1">
          <div className="admin_treeInfo">
            <Row>
              <span>FF Code : {value[0]} </span>
              <span> name: {value[1]}</span>
              <span>level :<span> 2</span></span>
              <span>Status :<span> active</span></span>
            </Row>
          </div>
        </div>
      </Col>
    </div>

  );
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { selectedUser: userInfo.selectedUser };
}

SearchFF.propTypes = {
  selectedUser: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SearchFF);

