import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateData';

const TableRefund = ({ refundSummary }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [refundSummary]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

TableRefund.propTypes = {
  refundSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { refundSummary: userInfo.user?.refundSummary };
}


export default connect(mapStateToProps)(TableRefund);
