import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Row, Col, Modal, ButtonToolbar, Button,
} from 'reactstrap';
import { setFFuser, setKYC } from '../../../../redux/actions/userActions';
import { store } from '../../../App/store';
import { serverURL } from '../../../../utils/url';
import { clearLoadingKyc, setLoadingKyc } from '../../../../redux/actions/loaderAction';
import { showLogs } from '../../../App/Rules';



const KYC = ({ FFuser, kycLoad }) => {
  const charachter = `${process.env.PUBLIC_URL}/img/charachter.svg`;

  const [identity, setIdentity] = useState('19');
  const [frontImg, setFrontimg] = useState('');
  const [backImg, setBackimg] = useState('');
  const [selfieImg, setSelfieimg] = useState('');

  const [frontImgFile, setFrontimgFile] = useState();
  const [backImgFile, setBackimgFile] = useState();
  const [selfieImgFile, setSelfieimgFile] = useState();

  const [frontImgInput, setFrontimgInput] = useState();
  const [backImgInput, setBackimgInput] = useState();
  const [selfieImgInput, setSelfieimgInput] = useState();

  const [frontImgError, setFrontimgError] = useState(false);
  const [backImgError, setBackimgError] = useState(false);
  const [selfieImgError, setSelfieimgError] = useState(false);

  const [frontImgErrorSize, setFrontimgErrorSize] = useState(false);
  const [backImgErrorSize, setBackimgErrorSize] = useState(false);
  const [selfieImgErrorSize, setSelfieimgErrorSize] = useState(false);

  const [inRequest, setInRequest] = useState(false);

  const identities = store.getState().constants.constants[10].constants;

  const { kyc } = store.getState().userInfo?.user;

  const [, forceUpdate] = useState();

  const [kycStatus, setKYCStatus] = useState(0);

  const dispatch = useDispatch();


  useEffect(() => {
    if ((FFuser?.isValidKYC === 0) || (FFuser?.isValidKYC === null)) {
      setKYCStatus(0);
      forceUpdate(13);
    } else if (FFuser?.isValidKYC === 2) {
      if (kyc[0]) {
        const { kindId } = kyc.find(k => k.docId === 1);
        setIdentity(identities.find(i => i.id === kindId)?.name);
      } else {
        setIdentity('No Data');
      }
      setKYCStatus('pending');
      forceUpdate(13);
    } else if (FFuser?.isValidKYC === 1) {
      if (kyc[0]) {
        const { kindId } = kyc.find(k => k.docId === 1);
        setIdentity(identities.find(i => i.id === kindId)?.name);
      } else {
        setIdentity('No Data');
      }
      setKYCStatus('confirm');
      forceUpdate(13);
    }
  }, [FFuser]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const removePhotoFront = () => {
    setFrontimg('');
    setFrontimgFile('');
    setFrontimgInput('');
    forceUpdate(13);
  };
  const removePhotoBack = () => {
    setBackimg('');
    setBackimgFile('');
    setBackimgInput('');
    forceUpdate(13);
  };
  const removePhotoSelfie = () => {
    setSelfieimg('');
    setSelfieimgFile('');
    setSelfieimgInput('');
    forceUpdate(13);
  };


  const handleFileSelectedFront = (e) => {
    setFrontimgInput(e.target.value);

    if ((e.target.files[0]?.type === 'image/jpg' || e.target.files[0]?.type === 'image/jpeg')
      && (e.target.files[0]?.size < 2000000)) {
      setFrontimgFile(e.target.files[0]);
      setFrontimgErrorSize(false);
      setFrontimgError(false);
      const files = Array.from(e.target.files);
      // console.log('files:', files[0]);
      if (files[0]) {
        const oFReader = new FileReader();
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = function (oFREvent) {
          setFrontimg(oFREvent.target);
        };
        forceUpdate(13);
      } else {
        setFrontimg('');
        setFrontimgFile('');
        setFrontimgErrorSize(false);
        setFrontimgError(false);
      }
    } else if (e.target.files[0]?.size >= 2000000) {
      setFrontimgErrorSize(true);
      setFrontimgError(false);
    } else if (e.target.files[0]?.type !== 'image/jpg' || e.target.files[0]?.type !== 'image/jpeg') {
      setFrontimgErrorSize(false);
      setFrontimgError(true);
    } else {
      setFrontimgErrorSize(false);
      setFrontimgError(false);
      forceUpdate(13);
    }
  };

  const handleFileSelectedBack = (e) => {
    setBackimgInput(e.target.value);
    if ((e.target.files[0]?.type === 'image/jpg' || e.target.files[0]?.type === 'image/jpeg')
      && (e.target.files[0]?.size < 2000000)) {
      setBackimgFile(e.target.files[0]);
      setBackimgError(false);
      setBackimgErrorSize(false);
      forceUpdate(13);
      const files = Array.from(e.target.files);
      if (files[0]) {
        const oFReader = new FileReader();
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = function (oFREvent) {
          setBackimg(oFREvent.target);
        };
      } else {
        setBackimg('');
        setBackimgFile('');
      }
    } else if (e.target.files[0]?.size >= 2000000) {
      setBackimgError(false);
      setBackimgErrorSize(true);
    } else if (e.target.files[0]?.type !== 'image/jpg' || e.target.files[0]?.type !== 'image/jpeg') {
      setBackimgError(true);
      setBackimgErrorSize(false);
    } else {
      setBackimgErrorSize(false);
      setBackimgError(false);
      forceUpdate(13);
    }
  };

  const handleFileSelectedSelfie = (e) => {
    setSelfieimgInput(e.target.value);
    if ((e.target.files[0]?.type === 'image/jpg' || e.target.files[0]?.type === 'image/jpeg')
      && (e.target.files[0]?.size < 2000000)) {
      setSelfieimgFile(e.target.files[0]);
      setSelfieimgError(false);
      setSelfieimgErrorSize(false);
      forceUpdate(13);
      const files = Array.from(e.target.files);
      if (files[0]) {
        const oFReader = new FileReader();
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = function (oFREvent) {
          setSelfieimg(oFREvent.target);
        };
      } else {
        setSelfieimg('');
        setSelfieimgFile('');
        forceUpdate(13);
      }
    } else if (e.target.files[0]?.size >= 2000000) {
      setSelfieimgErrorSize(true);
      setSelfieimgError(false);
    } else if (e.target.files[0]?.type !== 'image/jpg' || e.target.files[0]?.type !== 'image/jpeg') {
      setSelfieimgError(true);
      setSelfieimgErrorSize(false);
    } else {
      setSelfieimgErrorSize(false);
      setSelfieimgError(false);
      forceUpdate(13);
    }
  };


  const handleKYC = async (e) => {
    e.preventDefault();

    setInRequest(true);

    localStorage.setItem('lastReq', Date.parse(new Date()));
    dispatch(setLoadingKyc());

    const sendData = new FormData();
    sendData.append('Frontside', frontImgFile);
    sendData.append('Backside', backImgFile);
    sendData.append('Selfie', selfieImgFile);
    sendData.append('FFCode', FFuser.FFCode);
    sendData.append('identity', identity);
    // sendData.append('token', localStorage.getItem('token'));
    // console.clear();
    /* eslint-disable-next-line */
    // for (const pair of sendData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    if (showLogs) {
      console.log(sendData);
    }
    toggle();
    await axios.post(`${serverURL}/holder/personalPicInfo`, sendData)
      // .then(res => res.json())
      .then((data) => {
        setInRequest(false);
        dispatch(clearLoadingKyc());
        if (showLogs) {
          console.log('personalPicInfo (send kyc) :', data);
        }
        if (data.data.sttReq === 'ok' && data.data.numReq === 2) {
          toast.info('Your Document photos has been uploaded and waiting for admin confirmation !', {
            position: 'top-center',
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setKYCStatus('pending');
          dispatch(setKYC(data.data.KYC));
          dispatch(setFFuser(data.data.FFuser));
          setFrontimg('');
          setBackimg('');
          setSelfieimg('');
          forceUpdate(13);
          if (data.data.FFuser.isValidKYC === 2) {
            setKYCStatus('pending');
            forceUpdate(13);
          }
        } else {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((err) => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(clearLoadingKyc());
        setInRequest(false);
        // console.log(err);
      });
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Confirm KYC</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure about uploading these photos ? </h5>
          <br />
          <h5>This action is non-returnable </h5>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={e => handleKYC(e)}>Confirm</Button>
        </ButtonToolbar>
      </Modal>
      <div className={kycStatus === 'confirm' ? 'profile_info_done' : 'profile_info'}>
        <h4>
          KYC
        </h4>
      </div>
      <div>
        <form onSubmit={(e) => {
          e.preventDefault();
          toggle();
        }}
        >
          <Row>
            <Col md={6}>
              <div className="form-group mb-1">
                <label className="form-label" htmlFor="identity">
                  {kycStatus === 0 ? 'Choose ' : ''}
                  Your Identity
                </label>
                {kycStatus === 0 ? (
                  <select
                    className="form-select"
                    value={identity}
                    id="identity"
                    onChange={(e) => {
                      setIdentity(e.target.value);
                      if (e.target.value === '19') {
                        setBackimg('');
                        setBackimgFile('');
                        setBackimgInput('');
                      }
                    }}
                    onClick={(e) => {
                      setIdentity(e.target.value);
                      if (e.target.value === '19') {
                        setBackimg('');
                        setBackimgFile('');
                        setBackimgInput('');
                      }
                    }}
                  >
                    {identities.map((item, i) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control text-center"
                    value={identity}
                    // onChange={e => setIdentity(e.target.value)}
                    disabled
                  />
                )}
              </div>
              {/* <input type="file" name="ax" id="ax" onChange={onChange} encType="multipart/form-data" /> */}
            </Col>
          </Row>
          <Row>

            <Col md={6} xs={6}>
              <div className="mb3">

                {frontImg ? (
                  <div
                    className={kycStatus === 0 ? 'identity_img text-center' : 'identity_image'}
                  >
                    <div>

                      <img
                        width="100%"
                        alt=""
                        height="100%"
                        src={kycStatus === 0 ? frontImg.result : frontImg}
                      />
                    </div>
                    {kycStatus === 0 ? (
                      <button
                        type="button"
                        className="btn btn btn-outline-danger btn-sm remove_identity"
                        onClick={removePhotoFront}
                      >
                        remove
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="identity_no_img" htmlFor="Frontside">
                    <label className="form-label identity_label" htmlFor="Frontside">
                      <p className="identity_label_p">
                        {kycStatus === 'pending' ? (
                          'Verifying...'
                        ) : (
                          ''
                        )}
                        {kycStatus === 'confirm' ? (
                          'Confirmed ✔️'
                        ) : (
                          ''
                        )}
                        {kycStatus === 0 ? 'Upload Frontside' : ''}
                        <br />
                        {frontImgError && kycStatus === 0 ? (
                          <div style={{ color: 'indianred' }}>Your image should be JPEG or JPG</div>
                        ) : ''}
                        {frontImgErrorSize && kycStatus === 0 ? (
                          <div style={{ color: 'indianred' }}>Your image size should be less than 2 mb </div>
                        ) : ''}
                      </p>
                    </label>
                  </div>
                )}
                {kycStatus === 0 ? (
                  <input
                    type="file"
                    accept="image/*"
                    name="Frontside"
                    id="Frontside"
                    onChange={e => handleFileSelectedFront(e)}
                    value={frontImgInput}
                    style={{ display: 'none' }}
                  />
                ) : (
                  ''
                )}
              </div>
            </Col>

            <Col md={6} xs={6}>
              {identity !== '19' && identity !== 'Passport' ? (
                <div className="mb3 text-center">
                  {backImg ? (
                    <div
                      className={kycStatus === 0 ? 'identity_img text-center' : 'identity_image'}
                    >
                      <div>
                        <img
                          width="100%"
                          alt=""
                          height="100%"
                          src={kycStatus === 0 ? backImg.result : backImg}
                        />
                      </div>
                      {kycStatus === 0 ? (
                        <button
                          type="button"
                          className="btn btn btn-outline-danger btn-sm remove_identity"
                          onClick={removePhotoBack}
                        >
                          remove
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div className="identity_no_img" htmlFor="Frontside">
                      <label className="form-label identity_label" htmlFor="Backside">
                        <p className="identity_label_p">
                          {kycStatus === 'pending' ? (
                            'Verifying...'
                          ) : (
                            ''
                          )}
                          {kycStatus === 'confirm' ? (
                            'Confirmed ✔️'
                          ) : (
                            ''
                          )}
                          {kycStatus === 0 ? 'Upload Backside' : ''}
                          <br />
                          {backImgError && kycStatus === 0 ? (
                            <div style={{ color: 'indianred' }}>Your image should be JPEG or JPG</div>
                          ) : ''}
                          {backImgErrorSize && kycStatus === 0 ? (
                            <div style={{ color: 'indianred' }}>Your image size should be less than 2 mb </div>
                          ) : ''}
                        </p>
                      </label>
                    </div>
                  )}
                  {kycStatus === 0 ? (
                    <input
                      type="file"
                      accept="image/*"
                      name="Backside"
                      id="Backside"
                      onChange={e => handleFileSelectedBack(e)}
                      value={backImgInput}
                      style={{ display: 'none' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
            </Col>

          </Row>
          <Row className="mb-2">
            <Col md={6} xs={6}>
              <div className="mb3">
                {selfieImg ? (
                  <div
                    className={kycStatus === 0 ? 'identity_img text-center' : 'identity_image'}
                  >
                    <div>
                      <img
                        width="100%"
                        alt=""
                        height="100%"
                        src={kycStatus === 0 ? selfieImg.result : selfieImg}
                      />
                    </div>
                    {kycStatus === 0 ? (
                      <button
                        type="button"
                        className="btn btn btn-outline-danger btn-sm remove_identity"
                        onClick={removePhotoSelfie}
                      >
                        remove
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="identity_no_img" htmlFor="Frontside">
                    <label className="form-label identity_label" htmlFor="Selfie">
                      <p className="identity_label_p">
                        {kycStatus === 'pending' ? (
                          'Verifying...'
                        ) : (
                          ''
                        )}
                        {kycStatus === 'confirm' ? (
                          'Confirmed ✔️'
                        ) : (
                          ''
                        )}
                        {kycStatus === 0 ? 'Upload a Selfie Photo of Yourself' : ''}
                        <br />
                        {kycStatus === 0 ? (
                          <img src={charachter} className="charachter_kyc" alt="" />
                        ) : ''}
                        <br />
                        {selfieImgError && kycStatus === 0 ? (
                          <div style={{ color: 'indianred' }}>Your image should be JPEG or JPG</div>
                        ) : ''}
                        {selfieImgErrorSize && kycStatus === 0 ? (
                          <div style={{ color: 'indianred' }}>Your image size should be less than 2 mb </div>
                        ) : ''}
                      </p>
                    </label>
                  </div>
                )}
                {kycStatus === 0 ? (
                  <input
                    type="file"
                    accept="image/*"
                    name="Selfie"
                    id="Selfie"
                    onChange={e => handleFileSelectedSelfie(e)}
                    value={selfieImgInput}
                    style={{ display: 'none' }}
                  />
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="kyc_notes">
                <ul>
                  {/* <li>Conditions for sending documents :</li> */}
                  {/* <li>Black and white images are not allowed.</li> */}
                  {/* <li>Using a folded image is not allowed.</li> */}
                  <li>Only the original image of the documents is required.</li>
                  <li>
                    The text of the letter of commitment must be in handwritten
                    form with the signature and the date of the day.
                  </li>
                  {/* <li>Images should not be mirrored or inverted.</li> */}
                  <li>
                    The Text: While reading and approving the rules of Financial Factory services,
                    documents have been sent for authentication.
                  </li>
                </ul>
                {/* <div className="text-center">
                  <img src={charachter} className="charachter_kyc" alt="" />
                </div> */}
              </div>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col md={6}>
              <div className="form-group mb-1 text-center">
                {identity !== '19' ? (
                  <button
                    type="submit"
                    style={{ overflowY: 'hidden' }}
                    className="btn btn-outline-theme tefo_buttons_md w-100 m-0"
                    disabled={
                      (((frontImgFile && selfieImgFile && backImgFile) && kycStatus === 0) && !inRequest)
                        && !kycLoad
                        ? '' : 'true'
                    }
                  >
                    {kycStatus === 0 && !inRequest ? 'Submit' : ''}
                    {kycStatus === 'pending' ? 'Verifying...' : ''}
                    {kycStatus === 'confirm' ? 'Verified ✔️' : ''}
                    {inRequest || kycLoad ? (
                      <>
                        <div className="spinner-border text-success spinner-border-sm" />  Sending photos ...
                      </>
                    ) : ''}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-outline-theme tefo_buttons_md w-100 m-0"
                    disabled={(((frontImgFile && selfieImgFile) && kycStatus === 0) && !inRequest) && !kycLoad
                      ? '' : 'true'}
                  >
                    {kycStatus === 0 && !inRequest && !kycLoad ? 'Submit' : ''}
                    {kycStatus === 'pending' ? 'Verifying...' : ''}
                    {kycStatus === 'confirm' ? 'Verified ✔️' : ''}
                    {inRequest || kycLoad ? (
                      <>
                        <div className="spinner-border text-success spinner-border-sm" />  Sending photos ...
                      </>
                    ) : ''}
                  </button>
                )}
                {kycStatus === 'confirm' ? (
                  <div className="personal_info_message">Your Documents Confirmed By Admin</div>
                ) : ''}
                {kycStatus === 'pending' ? (
                  <div className="personal_info_message">Your Documents is being verified by the admin...</div>
                ) : ''}
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </>

  );
};

KYC.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  kycLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader } = state;
  return {
    FFuser: userInfo.user?.FFuser,
    kyc: userInfo.user?.kyc,
    kycLoad: loader.kycLoad,
  };
}


export default connect(mapStateToProps)(KYC);
