import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Popover, PopoverHeader, Row,
} from 'reactstrap';
import { RWebShare } from 'react-web-share';

import { QRCode } from 'react-qrcode-logo';
import canvasToImage from 'canvas-to-image';
import { serverURL } from '../../../../utils/url';





const WalletDesktop = ({
  FFCode, isActive, isAllowDeposit, depositWallet,
}) => {
  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

  const [, forceUpdate] = useState();

  const [disable, setDisable] = useState(false);

  const [IsOpenedWalletCopied, setIsOpenedWalletCopied] = useState(false);
  const handleOpenWalletCopied = () => {
    setIsOpenedWalletCopied(true);
  };



  const [copyCode, setCopycode] = useState('fas fa-copy');

  const [walletAddrs, setWalletAdrs] = useState(depositWallet ? depositWallet.address : '');


  useEffect(() => {
    setWalletAdrs(depositWallet ? depositWallet.address : '');
  }, [depositWallet]);


  const copyCodeToClipboard = () => {
    const element = document.querySelector('#refCode');
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopycode('fas fa-check-circle');
    setTimeout(() => {
      setCopycode('fas fa-copy');
      setIsOpenedWalletCopied(false);
    }, 1000);
  };

  useEffect(() => {
    if (!isActive) {
      setDisable(true);
    }
  }, [isActive]);

  const downloadRefQR = () => {
    const refCodeQR = document.getElementById('walletQrCode');
    canvasToImage(refCodeQR, {
      name: `${FFCode}-Deposit`,
      type: 'jpg',
      quality: 1,
    });
  };

  return (
    <>
      {depositWallet ? (
        <Popover
          placement="top"
          isOpen={IsOpenedWalletCopied}
          target="copiedWallet"
          toggle={handleOpenWalletCopied}
          className="copied_popover"
        >
          <PopoverHeader>Copied !</PopoverHeader>
        </Popover>
      ) : ''}

      <Card>
        <CardBody>
          <h5 className="text-left wallet_desk_title">Wallet</h5>
          <Row className="desk_wallet_row">
            {depositWallet ? (
              <>
                <Col xs={8} md={12} className="desk_wallet_button_holder text-center">
                  <input
                    className="form-control text-center"
                    type="text"
                    placeholder={!isAllowDeposit ? '-   -   -   -   -' : 'Your Deposit Wallet'}
                    id="refCode"
                    value={disable || !isAllowDeposit ? '' : walletAddrs}
                    disabled={disable || !isAllowDeposit ? 'true' : ''}
                  />
                  <div className="text-center desk_wallet_button mb-2 mt-1">
                    <button
                      type="button"
                      className="btn btn-outline-theme table-btn"
                      onClick={e => downloadRefQR()}
                      disabled={disable || !isAllowDeposit ? 'true' : ''}
                    >
                      <i className="fas fa-arrow-down" />
                    </button>
                  </div>
                  <div className="text-center desk_wallet_button mb-2">
                    <RWebShare
                      data={{
                        text: walletAddrs,
                        url: serverURL,
                        title: `${FFCode}-Deposit`,
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-theme table-btn"
                        disabled={disable || !isAllowDeposit ? 'true' : ''}
                      >
                        <i className="fas fa-share-alt" />
                      </button>
                    </RWebShare>
                  </div>
                  <div className="text-center desk_wallet_button">
                    <button
                      type="button"
                      className="btn btn-outline-theme table-btn"
                      id="copiedWallet"
                      onClick={e => copyCodeToClipboard()}
                      disabled={disable || !isAllowDeposit ? 'true' : ''}
                    >
                      <i className={copyCode} />
                    </button>
                  </div>

                </Col>
                <Col className="text-center" xs={4} md={12}>
                  <QRCode
                    value={disable || !isAllowDeposit ? '' : walletAddrs}
                    logoWidth="140"
                    eyeRadius={[
                      [0, 80, 0, 80], // top/left eye
                      [80, 0, 80, 0], // top/right eye
                      [80, 0, 80, 0], // bottom/left
                    ]}
                    logoOpacity="0.4"
                    qrStyle="dots"
                    bgColor="#fff"
                    fgColor="#000"
                    ecLevel="Q"
                    size="90"
                    id="walletQrCode"
                  />
                </Col>
              </>
            ) : (
              <>
                <div className="no_wallet_desk_div">
                  <h6>
                    To display your Deposit address wallet, you must first generate it from Monetary, Deposit
                  </h6>
                </div>
              </>
            )}

          </Row>
        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};


WalletDesktop.propTypes = {
  isAllowDeposit: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  FFCode: PropTypes.string.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    isAllowDeposit: userInfo.user?.FFuser?.isAllowDeposit,
    isActive: userInfo.user?.FFuser?.isActive,
    FFCode: userInfo.user?.FFuser?.FFCode,
    depositWallet: userInfo.user?.depositWallet,
  };
}

export default connect(mapStateToProps)(WalletDesktop);

