import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { setSecPass } from '../../../../redux/actions/userActions';
import { store } from '../../../App/store';
import { serverbetaURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';
import axiosHeaders from '../../../../utils/axiosHelper';



const TradingPassword = () => {
  const [oldpass, setOldpass] = useState('');
  const [newpass, setNewpass] = useState('');
  const [confirmpass, setConfirmpass] = useState('');

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [showErrorEqual, setShowErrorEqual] = useState(false);
  const [showErrorOld, setShowErrorOld] = useState(false);

  const [unValidOld, setUnValidOld] = useState(false);
  const [unValidnew, setUnValidnew] = useState(false);
  const [unValidconfirm, setUnValidconfirm] = useState(false);

  const [disable, setDisable] = useState(false);


  const { FFCode, email, isActive } = store.getState().userInfo.user.FFuser;
  const { haveSecPass } = store.getState().userInfo.user;
  const [, forceUpdate] = useState();

  useEffect(() => {
    if (!isActive) {
      setDisable(true);
    }
  }, []);

  const validatorConfigs = {
    messages: {
      // required: 'That feild is required.',
      default: 'This field must be a six-digit number!',
    },
    element: message => <div className="tefo_error">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const reset = () => {
    setOldpass('');
    setNewpass('');
    setConfirmpass('');
  };

  const dispatch = useDispatch();

  const checkEqualPass = (e) => {
    if (newpass !== confirmpass) {
      setShowErrorEqual(true);
      setUnValidconfirm(true);
    } else {
      setShowErrorEqual(false);
      setUnValidconfirm(false);
    }
  };

  const checkChangePass = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if ((newpass === confirmpass)
        && newpass
        && confirmpass
        // && (oldpass && haveSecPass)
      ) {
        toggle();
      }
      if ((newpass !== confirmpass) && confirmpass) {
        setShowErrorEqual(true);
        setUnValidconfirm(true);
        forceUpdate(13);
      }
      if (oldpass === '') {
        setShowErrorOld(true);
        setUnValidOld(true);
        forceUpdate(13);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(13);
    }
  };

  const changePasssword = () => {
    const passData = {
      FFCode,
      type: haveSecPass ? 'update' : 'new',
      newSecPass: newpass,
      oldSecPass: oldpass,
      confirmSecPass: confirmpass,
      email,
    };
    if (showLogs) {
      console.log(passData);
    }
    axios.post(`${serverbetaURL}/auth/changeSecurityPassword`, JSON.stringify(passData), {
      headers: axiosHeaders(),
    })
      .then((data) => {
        if (showLogs) {
          console.log('changeSecurityPassword :', data);
        }
        if (data?.status === 200) {
          if (data?.data?.status === false) {
            toast.error(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (data?.data?.status === true) {
            dispatch(setSecPass());
            setUnValidOld(false);
            toast.success(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    validator.current.hideMessages();
    forceUpdate(13);
    toggle();
    reset();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Security Password</h4>
        </div>
        <div className="modal__body">
          <h6>
            {haveSecPass ? 'Are you sure about changing your security password ?'
              : 'Are you sure about the entered security password ?'}
          </h6>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={changePasssword}>Yes, I am sure</Button>
        </ButtonToolbar>
      </Modal>

      <div className="add_wallet">
        <h4>
          Security Password
        </h4>
      </div>
      <form onSubmit={checkChangePass}>
        {haveSecPass ? (
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="oldpass">Old Password</label>
            <div className="input-group flex-nowrap">
              <input
                type={showPasswordOld ? 'text' : 'password'}
                className={unValidOld
                  ? `form-control form-control-password-login form-control-lg 
                bg-white bg-opacity-5 unValid letter_space_input`
                  : `form-control form-control-password-login 
                form-control-lg bg-white bg-opacity-5 letter_space_input`}
                id="oldpass"
                placeholder=""
                maxLength="6"
                value={oldpass}
                onChange={async (e) => {
                  if (!Number.isNaN(+e.target.value.replaceAll(' ', ''))) {
                    await setOldpass(e.target.value.replaceAll(' ', ''));
                    validator.current.hideMessageFor('old password');
                    forceUpdate(14);
                  }
                }}
                onBlur={() => {
                  if (!validator.current.fieldValid('old password')) {
                    validator.current.showMessageFor('old password');
                    forceUpdate(16);
                  }
                }}
              />
              <button
                type="button"
                className="input-group-text input-group-text-login"
                id="eye"
                onClick={e => setShowPasswordOld(!showPasswordOld)}
              >
                {showPasswordOld ? <EyeOutlineIcon className="eye_icon" />
                  : <EyeOffOutlineIcon className="eye_icon" />}
              </button>
            </div>
            <div className="tefo_error">
              {validator.current.message('old password', oldpass, 'numeric|size:6|required')}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group mb-0">
          <label className="form-label" htmlFor="newpass">New Password</label>
          <div className="input-group flex-nowrap">
            <input
              type={showPassword ? 'text' : 'password'}
              className={unValidnew
                ? `form-control form-control-password-login form-control-lg 
                bg-white bg-opacity-5 unValid letter_space_input`
                : `form-control form-control-password-login 
                form-control-lg bg-white bg-opacity-5 letter_space_input`}
              id="newpass"
              placeholder=""
              maxLength="6"
              value={newpass}
              onChange={async (e) => {
                if (!Number.isNaN(+e.target.value.replaceAll(' ', ''))) {
                  await setNewpass(e.target.value.replaceAll(' ', ''));
                  validator.current.hideMessageFor('new password');
                  forceUpdate(15);
                }
              }}
              onBlur={() => {
                if (!validator.current.fieldValid('new password')) {
                  validator.current.showMessageFor('new password');
                  forceUpdate(16);
                }
              }}
            />
            <button
              type="button"
              className="input-group-text input-group-text-login"
              id="eye"
              onClick={e => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOutlineIcon className="eye_icon" />
                : <EyeOffOutlineIcon className="eye_icon" />}
            </button>
          </div>
          <div className="tefo_error tefo_error_strong">
            {validator.current.message('new password', newpass, 'required|size:6|numeric')}
          </div>
        </div>
        <div className="form-group mb-2 mt-4">
          <label className="form-label" htmlFor="confirmpass">Confirm Password</label>
          <div className="input-group flex-nowrap">
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              className={unValidconfirm
                ? `form-control form-control-password-login form-control-lg 
                bg-white bg-opacity-5 unValid letter_space_input`
                : `form-control form-control-password-login 
                form-control-lg bg-white bg-opacity-5 letter_space_input`}
              id="confirmpass"
              placeholder=""
              maxLength="6"
              value={confirmpass}
              onChange={async (e) => {
                if (!Number.isNaN(+e.target.value.replaceAll(' ', ''))) {
                  await setConfirmpass(e.target.value.replaceAll(' ', ''));
                  validator.current.hideMessageFor('confirm password');
                  forceUpdate(16);
                }
              }}
              onBlur={(e) => {
                if (!validator.current.fieldValid('confirm password')) {
                  validator.current.showMessageFor('confirm password');
                  forceUpdate(16);
                }
                checkEqualPass(e);
              }}
              onFocus={e => setShowErrorEqual(false)}
            />
            <button
              type="button"
              className="input-group-text input-group-text-login"
              id="eye"
              onClick={e => setShowPasswordConfirm(!showPasswordConfirm)}
            >
              {showPasswordConfirm ? <EyeOutlineIcon className="eye_icon" />
                : <EyeOffOutlineIcon className="eye_icon" />}
            </button>
          </div>
          {/* <div className="tefo_error">
            {validator.current.message('confirm password', confirmpass, 'required|size:6|numeric')}
          </div> */}
          <div className="tefo_error">
            {showErrorEqual && 'Password and Confirm Password should be Equal !'}
          </div>
        </div>
        {/* <div className="landing_sublink_div">
          <CardLink className="landing_sublink">Forgot Security  password?</CardLink>
        </div> */}
        <div className="form-group mb-1 mt-3 text-center">
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_md"
            disabled={disable}
          >
            {haveSecPass ? 'Change' : 'Submit'}
          </button>
        </div>
      </form>
    </>
  );
};


export default withRouter(connect()(TradingPassword));
