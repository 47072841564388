import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import {
  Card, Row, Container, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import { useParams, withRouter, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import {
  getBinarySummaryProfit, getRefferal, getReportPlans,
} from '../../../redux/actions/userActions';
import ReportsBuyInvest from './components/plans/ReportsBuyInvest';
import ReportsAffiliate from './components/plans/ReportsAffiliate';
import ReportsFund from './components/plans/ReportsFund';
import ReportTree from './components/ReportTree/ReportTree';
import BinaryInvest from './components/binaryInvest/BinaryInvest';



const Reports = ({ location, user }) => {
  const [activeTab, setActiveTab] = useState('1');

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (location.haveAct) {
      if (location.act === 'showBinary') {
        setActiveTab('3');
      }
    }
    dispatch(getReportPlans());
    dispatch(getRefferal());
  }, []);


  return (
    <>

      <UncontrolledTooltip placement="right" target="reportPlans">
        You can see all the reports about your plans by the curruncies.
      </UncontrolledTooltip>

      <UncontrolledTooltip placement="right" target="reportTree">
        You can see tree chart of your members by the plan.
      </UncontrolledTooltip>

      {/* {user.isCapital && ( */}
      <UncontrolledTooltip placement="right" target="reportBinary">
        You can see the detail of Binary Invest sales of your members.
      </UncontrolledTooltip>
      {/* )} */}


      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="">
                <Row>
                  <div className="tabs tabs--bordered-top">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem className="refferal_nav">
                          <NavLink
                            className={classnames({ active: activeTab === '1' }, 'tab_link')}
                            onClick={() => toggle('1')}
                          >
                            Reports <i id="reportPlans" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' }, 'tab_link')}
                            onClick={() => toggle('2')}
                          >
                            Sales Tree <i id="reportTree" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        {/* {user.isCapital && ( */}
                        <NavItem className="refferal_nav">
                          <NavLink
                            className={classnames({ active: activeTab === '3' }, 'tab_link')}
                            onClick={() => {
                              toggle('3');
                            }}
                          >
                            Binary Plus <i id="reportBinary" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        {/* )} */}
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col md={4}>
                              <ReportsBuyInvest />
                            </Col>
                            <Col md={4}>
                              <ReportsAffiliate />
                            </Col>
                            <Col md={4}>
                              <ReportsFund />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          {activeTab === '2' && (
                            <ReportTree />
                          )}
                        </TabPane>
                        {/* {user.isCapital && ( */}
                        <TabPane tabId="3">
                          <BinaryInvest tab={activeTab} />
                        </TabPane>
                        {/* )} */}
                      </TabContent>
                    </div>
                  </div>
                </Row>
              </CardBody>
              <div className="card-arrow">
                <div className="card-arrow-top-left" />
                <div className="card-arrow-top-right" />
                <div className="card-arrow-bottom-left" />
                <div className="card-arrow-bottom-right" />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};


Reports.propTypes = {
  location: PropTypes.string.isRequired,
  user: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    user: userInfo?.user,
  };
}

export default withRouter(connect(mapStateToProps)(Reports));
