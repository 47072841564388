import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Tree from 'react-d3-tree';
import {
  Col, Modal, Row, UncontrolledTooltip,
} from 'reactstrap';
import useCenteredTree from './treeHelper';
import { userSelectRefferal } from '../../../../../redux/actions/userActions';
import { profileImgURL } from '../../../../../utils/url';
import { getLogoImgUrl, getPlanColor, getPlanIcon } from '../../../../../shared/helpers/themHelper';



const ReportTree = ({
  Refferal, selectUser, listData, FFuser, theme,
}) => {
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [datatree, setDatatree] = useState({});
  const [zoom, setZoom] = useState(0.1);

  const [plan, setPlan] = useState();


  // const [selectedUser, setSelectedUser] = useState({
  //   name: '- - - - - - - -',
  // });

  const [logo, setLogo] = useState();
  useEffect(() => {
    setLogo(getLogoImgUrl());
    // setLogo(`${process.env.PUBLIC_URL}/img/logo-dark.png`);
  }, [theme]);



  const [userFromSelect, setUserFromSelect] = useState(0);

  const dispatch = useDispatch();

  const [force, forceUpdate] = useState();

  const [depth, setDepth] = useState(4);

  // const [showFullModal, setShowFullModal] = useState();
  // const toggleFull = () => {
  //   setShowFullModal(pre => !pre);
  // };

  const containerStyles = {
    width: '100%',
    height: '63vh',
  };

  // const containerStylesModal = {
  //   width: '100%',
  //   height: '92vh',
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     // setDepth(3);
  //     forceUpdate(1);
  //   }, 2000);
  // }, []);


  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <>
      <g>

        {nodeDatum?.attributes?.[plan] === '1' ? (
          <>
            <image
              alt=""
              xlinkHref={logo}
              // xlinkHref={nodeDatum?.attributes?.userImage
              //   ? `${profileImgURL}/${nodeDatum?.attributes?.userImage}` : logo}
              x="-38"
              y="-28"
              rx="50"
              ry="50"
              height="75px"
              width="75px"
              preserveAspectRatio="none"
              onClick={() => {
                // setSelectedUser(nodeDatum);
                // dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
                toggleNode();
                setZoom(1);
              }}
            />
            <rect
              strokeWidth="5"
              width="75px"
              height="75px"
              x="-38"
              y="-28"
              rx="5"
              fill="transparent"
              className="node_active"
              // stroke={getPlanColor()?.[plan]}
              onClick={() => {
                toggleNode();
                setZoom(1);
              }}
            />

            {/* <line x1="-38" y1="-47" x2="32" y2="-47" strokeWidth="0.9" /> */}
            <text
              id={`node${nodeDatum.name}`}
              // strokeWidth="1"
              // stroke={getPlanColor()?.[plan]}
              x="-34"
              y="-33"
              className="node_active_code"
              onClick={() => {
                console.log(nodeDatum);
                //   setSelectedUser(nodeDatum);
                //   if (nodeDatum.name !== FFuser.FFCode) {
                //     dispatch(userSelectRefferal(listData?.find(u => u.FFCode === nodeDatum.name)));
                //   }
              }}
            >
              {nodeDatum.name}
            </text>
          </>
        ) : (
          <>
            <image
              alt=""
              xlinkHref={logo}
              // xlinkHref={nodeDatum?.attributes?.userImage
              //   ? `${profileImgURL}/${nodeDatum?.attributes?.userImage}`
              //   : `${process.env.PUBLIC_URL}/img/logo-dark.png`}
              x="-38"
              y="-28"
              rx="50"
              ry="50"
              height="75px"
              width="75px"
              preserveAspectRatio="none"
              onClick={() => {
                // setSelectedUser(nodeDatum);
                // dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
                toggleNode();
                setZoom(1);
              }}
            />
            <rect
              width="75px"
              height="75px"
              x="-38"
              y="-28"
              rx="5"
              className="not_active_node"
              onClick={() => {
                toggleNode();
                setZoom(1);
              }}
            />
            <text
              id={`node${nodeDatum.name}`}
              strokeWidth="0.1"
              x="-34"
              y="-33"
              className="not_active_code"
              // id={`node${nodeDatum.name}`}
              onClick={() => {
                console.log(nodeDatum);
                //   setSelectedUser(nodeDatum);
                //   if (nodeDatum.name !== FFuser.FFCode) {
                //     dispatch(userSelectRefferal(listData?.find(u => u.FFCode === nodeDatum.name)));
                //   }
              }}
            >
              {nodeDatum.name}
            </text>
            {/* <line x1="-38" y1="-28" x2="32" y2="-28" strokeWidth="0.9" /> */}
          </>
        )}
        <text
          strokeWidth="1"
          // stroke={getPlanColor()?.[plan]}
          x="-15"
          y="65"
          className="node_active_code"
        // onClick={() => {
        //   setSelectedUser(nodeDatum);
        //   if (nodeDatum.name !== FFuser.FFCode) {
        //     dispatch(userSelectRefferal(listData?.find(u => u.FFCode === nodeDatum.name)));
        //   }
        // }}
        >
          {plan === 'Fund' && (Math.floor(nodeDatum?.attributes?.fundAmount) || ' ')}
          {plan === 'Affiliate' && (Math.floor(nodeDatum?.attributes?.affiliateAmount) || ' ')}
          {plan === 'Stacking' && (Math.floor(nodeDatum?.attributes?.stackingAmount) || ' ')}
          {plan === 'buyInvest' && (Math.floor(nodeDatum?.attributes?.buyInvestAmount) || ' ')}
        </text>

        <UncontrolledTooltip placement="top" target={`node${nodeDatum.name}`}>
          FF Code : {nodeDatum.name}
          <br />
          Nickname : {nodeDatum?.attributes?.nickname}
          <br />
          Buy Invest: {nodeDatum.attributes?.buyInvest === '1' ? ' ✔️' : '-'}
          <br />
          Fund : {nodeDatum.attributes?.Fund === '1' ? ' ✔️' : ' -'}
          <br />
          Affiliate : {nodeDatum.attributes?.Affiliate === '1' ? ' ✔️' : ' -'}
          <br />
          Staking : {nodeDatum.attributes?.Stacking === '1' ? ' ✔️' : ' -'}
          <br />
          Award : {nodeDatum.attributes?.Award === '1' ? ' ✔️' : ' -'}
        </UncontrolledTooltip>

      </g>
    </>
  );

  useEffect(() => {
    if (Refferal) {
      setDatatree(Refferal.treeData);
      const firstLevel = Refferal.treeData.children?.length;
      // if (firstLevel >= 12) {
      //   setZoom(0.5);
      // } else if (firstLevel < 12 && firstLevel >= 8) {
      //   setZoom(0.7);
      // } else if (firstLevel < 8 && firstLevel > 4) {
      //   setZoom(0.85);
      // } else if (firstLevel <= 4) {
      //   setZoom(1);
      // }
    }
  }, []);

  useEffect(() => {
    setDatatree(Refferal.treeData);
  }, []);


  useEffect(() => {
    // if (selectUser?.FFCode !== FFuser.FFCode && selectUser) {
    //   setDepth(Number(selectUser?.level) + 1);
    //   if (Number(selectUser?.level) + 1 < 4) {
    //     setZoom(0.8);
    //   } else {
    //     setZoom(0.3);
    //   }
    //   forceUpdate(!force);
    // }
  }, [userFromSelect]);


  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      return 'link__to-leaf';
    }
    return 'link__to-branch';
  };


  return (
    <>
      {/* <Row> */}

      {/*
      <Modal
        isOpen={showFullModal}
        toggle={toggleFull}
        modalClassName="ltr-support"
        className="modal-tree"
      >
        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              setDepth(100);
              setZoom(0.3);
              forceUpdate(!force);
            }}
          >
            <i className="fas fa-angle-double-down" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              setDepth(0);
              setZoom(1);
              forceUpdate(!force);
            }}
          >
            <i className="fas fa-angle-double-up" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              toggleFull();
            }}
          >
            <i className="fas fa-compress" />
          </button>
        </div>
        <div className="full_tree" style={containerStylesModal} ref={containerRef}>
          <Tree
            data={datatree}
            translate={translate}
            renderCustomNodeElement={renderRectSvgNode}
            orientation="vertical"
            enableLegacyTransitions="true"
            transitionDuration={500}
            zoom={zoom}
            siblings="1"
            nonSiblings="1"
            initialDepth={depth}
            nodeSet="1"
            centeringTransitionDuration={1000}
            id="treeNode"
            dimensions={dimensions}
            allowForeignObjects
            pathClassFunc={getDynamicPathClass}
            rootNodeClassName="node_root"
            branchNodeClassName="node_branch"
            leafNodeClassName="node_leaf"
            key={force}
          />
        </div>
      </Modal> */}

      <div className="report_plans_holder">
        <div className="report_planicon_holder">

          <div className="planicon_holder">
            <button
              type="button"
              className="btn_tree_report"
              disabled
              onClick={() => {
                if (plan !== 'Fund') {
                  setPlan('Fund');
                } else {
                  setPlan('');
                }
              }}
            >
              <img
                className={plan === 'Fund' ? '' : 'planicon_deactive'}
                src={getPlanIcon().fund}
                alt=""
              />
              <div className={plan === 'Fund'
                ? 'report_plan_name report_plan_name_active' : 'report_plan_name'}
              >
                Fund
              </div>
            </button>
          </div>

          <div className="planicon_holder">
            <button
              type="button"
              className="btn_tree_report"
              disabled
              onClick={() => {
                if (plan !== 'Affiliate') {
                  setPlan('Affiliate');
                } else {
                  setPlan('');
                }
              }}
            >
              <img
                className={plan === 'Affiliate' ? '' : 'planicon_deactive'}
                src={getPlanIcon().affiliate}
                alt=""
              />
              <div className={plan === 'Affiliate'
                ? 'report_plan_name report_plan_name_active' : 'report_plan_name'}
              >
                Affiliate
              </div>
            </button>
          </div>

          <div className="planicon_holder">
            <button
              type="button"
              className="btn_tree_report"
              disabled
              onClick={() => {
                if (plan !== 'Stacking') {
                  setPlan('Stacking');
                } else {
                  setPlan('');
                }
              }}
            >
              <img
                className={plan === 'Stacking' ? '' : 'planicon_deactive'}
                src={getPlanIcon().stacking}
                alt=""
              />
              <div className={plan === 'Stacking'
                ? 'report_plan_name report_plan_name_active' : 'report_plan_name'}
              >
                Stacking
              </div>
            </button>
          </div>

          <div className="planicon_holder">
            <button
              type="button"
              className="btn_tree_report"
              onClick={() => {
                if (plan !== 'buyInvest') {
                  setPlan('buyInvest');
                } else {
                  setPlan('');
                }
              }}
            >
              <img
                className={plan === 'buyInvest' ? '' : 'planicon_deactive'}
                src={getPlanIcon().buyinvest}
                alt=""
              />
              <div className={plan === 'buyInvest'
                ? 'report_plan_name report_plan_name_active' : 'report_plan_name'}
              >
                Buy Invest
              </div>
            </button>
          </div>

        </div>
      </div>
      <hr />

      <Col md={12}>

        <div className="text-center mt-3">
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(100);
              setZoom(0.3);
              forceUpdate(!force);
            }}
          >
            Expand {' '}
            <i className="fas fa-angle-double-down" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(0);
              setZoom(1);
              forceUpdate(!force);
            }}
          >
            Collapse {' '}
            <i className="fas fa-angle-double-up" />
          </button>
          {/* <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(4);
              setZoom(0.5);
              forceUpdate(!force);
            }}
          >
            Level 4 {' '}
          </button> */}
          {/* <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              toggleFull();
            }}
          >
            Fullscreen {' '}
            <i className="fas fa-expand" />
          </button> */}
        </div>
      </Col>

      <div>
        <div style={containerStyles} ref={containerRef}>
          <Tree
            data={datatree}
            translate={translate}
            renderCustomNodeElement={renderRectSvgNode}
            orientation="vertical"
            enableLegacyTransitions="true"
            transitionDuration={500}
            zoom={zoom}
            siblings="1"
            nonSiblings="1"
            initialDepth={depth}
            nodeSet="1"
            centeringTransitionDuration={1000}
            id="treeNode"
            dimensions={dimensions}
            allowForeignObjects
            pathClassFunc={getDynamicPathClass}
            rootNodeClassName="node_root"
            branchNodeClassName="node_branch"
            leafNodeClassName="node_leaf"
            key={force}
          />
        </div>
      </div>
      {/* <div className="text-center pt-2 plan_tree_info">
        <span className="tree_plan_buyInvest tree_plan" /> Buy Invest |{'  '}
        <span className="tree_plan_fund tree_plan" /> Fund |{'  '}
        <span className="tree_plan_affiliate tree_plan" /> Affiliate |{'  '}
        <span className="tree_plan_stacking tree_plan" /> Staking |{'  '}
        <span className="tree_plan_buy tree_plan" /> Buy
      </div> */}
    </>
  );
};

ReportTree.propTypes = {
  Refferal: PropTypes.objectOf.isRequired,
  selectUser: PropTypes.objectOf.isRequired,
  listData: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};


function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    Refferal: userInfo.user?.Refferal,
    listData: userInfo?.user?.Refferal?.listData,
    selectUser: userInfo.user?.selectedUser,
    FFuser: userInfo.user?.FFuser,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(ReportTree);
