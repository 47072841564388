// 281221 import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import React, { useEffect, useRef, useState } from 'react';

// import LogInForm from './components/LogInForm';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import axios from 'axios';
import { Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useTimer from '../../../utils/useTimer';
import AuthRequest from '../../../services/AuthFetchRequest';
import {
  getMesseges,
  getBalance, setDevice, getLevels, setToken, setUser, getBuyInvestSummary,
} from '../../../redux/actions/userActions';
import getDeviceInfo from '../../../services/getDeviceInfo';
import { setCurrency } from '../../../redux/actions/currencyActions';
import { getBannersUrl, setConstants } from '../../../redux/actions/constantActions';
import request from '../../../services/fetchRequest';
import { serverbetaURL } from '../../../utils/url';
import { showLogs } from '../../App/Rules';
import { changeThemeToCapital, changeThemeToDark } from '../../../redux/actions/themeActions';



const VerificationCode = ({ toLogin, history }) => {
  const inputRef = useRef(null);
  const [verCode, setVerCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [, forceUpdate] = useState();

  const hashedEmail = localStorage.getItem('hashedEmail');

  const [resendTime, setResendTime] = useTimer({
    startTime: 60,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });

  const dispatch = useDispatch();

  const sendRequest = () => {
    setLoading(true);
    setDisable(true);
    forceUpdate(1);
  };

  const handleResend = () => {
    const resendData = {
      FFCode: localStorage.getItem('FFCode'),
      tempToken: localStorage.getItem('tempToken'),
    };
    axios.post(`${serverbetaURL}/auth/resendVerCode`, JSON.stringify(resendData), {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
    })
      .then((data) => {
        if (showLogs) {
          console.log('resendVerCode :', data);
        }
        if (data?.status === 200) {
          if (data?.data?.status === true) {
            toast.success(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
    setLoading(false);
    setDisable(false);
    setResendTime(60);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    sendRequest();
    const deviceInfo = await getDeviceInfo();

    axios.post(`${serverbetaURL}/auth/verificationcheck`, JSON.stringify({
      deviceInfo,
      emailToken: verCode,
      tempToken: localStorage.getItem('tempToken'),
      FFCode: localStorage.getItem('FFCode'),
    }), {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Allow-Headers': '*',
      },
    })
      // AuthRequest(`${serverURL}/verificationcheck`,
      //   {
      //     deviceInfo, verCode, tempToken: localStorage.getItem('tempToken'), FFCode: localStorage.getItem('FFCode'),
      //   })
      //   .then(response => response.json())
      .then(async (data) => {
        if (showLogs) {
          console.log('verificationcheck :', data);
        }
        dispatch(getBannersUrl());
        if (data?.status === 200) {
          if (data?.data?.status === false) {
            toast.error(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (data?.data?.sttReq === 'ok') {
            toast.success(data?.data?.message, {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if ((localStorage.getItem('tempToken') === data?.data?.tempToken && data?.data?.token)) {
              await dispatch(setUser(data?.data?.user));
              if (data?.data?.user?.isCapital) {
                dispatch(changeThemeToCapital());
              } else {
                dispatch(changeThemeToDark());
              }
              dispatch(setCurrency());
              await dispatch(getBalance());
              dispatch(setDevice(deviceInfo));
              localStorage.removeItem('tempToken');
              localStorage.setItem('token', data?.data?.token);
              dispatch(setToken(data?.data?.token));
              dispatch(getLevels());
              dispatch(setConstants(
                data?.data?.constants,
                data?.data?.plans,
                data?.data?.cryptocurrencies,
                data?.data?.plancryptocurrencydtls,
                data?.data?.deskMap,
              ));
              dispatch(getMesseges());
              await dispatch(getBuyInvestSummary());
              if (data?.data?.user.FFuser.isHolder === 0) {
                history.push({
                  pathname: '/Users',
                });
              } else {
                history.push({
                  pathname: '/desktop',
                });
              }
            } else {
              localStorage.clear();
              history.push({
                pathname: '/',
              });
            }
          }
        }
        setLoading(false);
        setInterval(() => {
          setDisable(false);
        }, 6000);
        forceUpdate(1);
      });
  };

  useEffect(() => {
    inputRef.current.focus();
  });


  return (
    <>
      <div className="account__card">
        <Card className="loginCard">
          <CardBody>
            <form onSubmit={onSubmit} method="POST" name="login_form">
              <h4 className="text-center mb-3">
                Enter The Code <br />
                {hashedEmail}
              </h4>
              <div className="mb-1">
                <div className="d-flex">
                  <label className="form-label" htmlFor="password">Varification Code :</label>
                </div>
                <input
                  className="form-control form-control-lg bg-white bg-opacity-5 verification"
                  maxLength="5"
                  type="text"
                  ref={inputRef}
                  placeholder="_____"
                  aria-describedby="code"
                  value={verCode}
                  onChange={e => setVerCode(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 mt-3 submit_button"
                disabled={disable ? 'true' : ''}
              >
                {loading ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" /> Validating ...
                  </>
                ) : 'Verify and Login'}
              </button>
              <button
                type="button"
                className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 submit_button"
                disabled={resendTime !== 0 ? 'true' : ''}
                onClick={e => handleResend()}
              >
                Resend Code
                {resendTime !== 0 ? ' in ' : ''}
                {resendTime !== 0 ? resendTime : ''}
                {resendTime !== 0 ? ' second' : ''}
              </button>
            </form>


          </CardBody>
          <div className="card-arrow">
            <div className="card-arrow-top-left" />
            <div className="card-arrow-top-right" />
            <div className="card-arrow-bottom-left" />
            <div className="card-arrow-bottom-right" />
          </div>
        </Card>
      </div>
    </>

  );
};

// VerificationCode.propTypes = {
//   dispatch: PropTypes.func.isRequired,
// };

VerificationCode.propTypes = {
  toLogin: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
};


export default withRouter(connect(null)(VerificationCode));
