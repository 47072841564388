import React from 'react';
import {
  Card, Row, Container, CardBody, Col,
} from 'reactstrap';



const AdminMesseges = () => (

  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">AdminMesseges</h3>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">AdminMesseges</h5>
              <h5 className="subhead">Example subhead</h5>
            </div>
            <p>Your AdminMesseges Link here</p>
          </CardBody>
          <div className="card-arrow">
            <div className="card-arrow-top-left" />
            <div className="card-arrow-top-right" />
            <div className="card-arrow-bottom-left" />
            <div className="card-arrow-bottom-right" />
          </div>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AdminMesseges;
