import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, CardFooter, CardHeader, Modal, UncontrolledTooltip,
} from 'reactstrap';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import {
  setBalance, getBalance, setOrders, getRefferal, setOrdersRecordBuy, getOrders, setHavePlan,
} from '../../../../redux/actions/userActions';
import { setCurrency } from '../../../../redux/actions/currencyActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import useTimer from '../../../../utils/useTimer';
import { serverURL } from '../../../../utils/url';
import { clearLoadingBuy, setLoadingBuy } from '../../../../redux/actions/loaderAction';
import NoSecPass from '../../../Layout/NoSecPass';
import { showLogs } from '../../../App/Rules';


const Buy = ({
  FFuser,
  haveSecPass,
  havePlans,
  balanceWallet,
  voidPlans,
  currencies,
  plans,
  plancrypto,
  depositWallet,
  ncc,
  tron,
  buyLoad,
}) => {
  const [currency, setBuyCurrency] = useState();
  const [Amount, setAmount] = useState();
  const [secPass, setSecPass] = useState('');
  const [AmountTether, setAmountTether] = useState();
  const [, forceUpdate] = useState();

  const secInput = useRef(null);

  const [currencyList, setCurrencyList] = useState([]);

  const [MinRemove, setMinRemove] = useState(10);
  const [MaxRemove, setMaxRemove] = useState(100000);
  const [Profit, setProfit] = useState(5);

  const [disable, setDisable] = useState(false);

  const [DeactiveUser, setDeactiveUser] = useState(false);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [unValidTether, setUnValidTether] = useState(false);


  const [TronNeed, setTronNeed] = useState(50);
  const [ProfitStonk, setProfitStonk] = useState(10);


  const validator = useRef(new SimpleReactValidator());

  const dispatch = useDispatch();

  const [resendTime, setResendTime] = useTimer({
    startTime: 0,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };

  useEffect(() => {
    // localStorage.setItem('lastBuyReq', Date.parse(new Date()));
    if (localStorage.getItem('lastBuyReq')) {
      setResendTime(180 - ((Date.parse(new Date()) - localStorage.getItem('lastBuyReq')) / 1000));
      //   dispatch(setLoadingBuy());
      // } else {
      //   dispatch(clearLoadingBuy());
    }
  }, []);


  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const reset = () => {
    setAmount('');
    setAmountTether('');
    setSecPass('');
    validator.current.hideMessages();
    forceUpdate(13);
  };

  useEffect(async () => {
    const buyTimeNow = new Date().getTime();
    const buyTimeStart = Date.parse(plans.find(i => i.id === 4).fromDate.replace(/-/g, '/'));
    const buyTimeEnd = Date.parse(plans.find(i => i.id === 4).toDate.replace(/-/g, '/'));


    if (plans.find(i => i.id === 4).isActive === 0) {
      setDisable(true);
      setDeactiveUser(true);
    }
    if (!FFuser.isActive) {
      setDeactiveUser(true);
      setDisable(true);
    }
    if (FFuser.isAllowBuy !== 1) {
      setDeactiveUser(true);
      setDisable(true);
    }

    if (buyTimeNow > buyTimeEnd || buyTimeNow < buyTimeStart) {
      setDeactiveUser(true);
      setDisable(true);
    }

    if (plans.find(i => i.id === 4).isActive === 1
      && FFuser.isActive === 1
      && FFuser.isAllowBuy === 1
      && buyTimeNow > buyTimeEnd
      && buyTimeNow < buyTimeStart) {
      setDeactiveUser(false);
      setDisable(false);
    }

    if (plancrypto[0]) {
      const currencyArr = [];
      for (let cr = 0; cr < plancrypto.length; cr += 1) {
        if (plancrypto[cr].ffplanId === 4 && plancrypto[cr].isActive === 1) {
          const cryptoTimeNow = new Date().getTime();
          const cryptoTimeStart = Date.parse(plancrypto[cr].fromDate.replace(/-/g, '/'));
          const cryptoTimeEnd = Date.parse(plancrypto[cr].toDate.replace(/-/g, '/'));
          if ((cryptoTimeNow < cryptoTimeEnd) && (cryptoTimeNow > cryptoTimeStart)) {
            if (currencies.find(i => i.id === plancrypto[cr].cryptocurrencyId)?.isBuy
              && currencies.find(i => i.id === plancrypto[cr].cryptocurrencyId)?.isActive) {
              if (plancrypto[cr]?.id === 8) {
                currencyArr.push(plancrypto[cr]);
              }
            }
          }
        }
      }

      setBuyCurrency(currencyArr[0]?.cryptocurrencyId?.toString());
      setMaxRemove(currencyArr[0]?.maxAmount);

      if (currencyArr[0]?.cryptocurrencyId?.toString() === '2') {
        // setProfitStonk((currencyArr[0]?.profitStonk) * 2);
        setProfitStonk(0);
      } else {
        setProfitStonk(currencyArr[0]?.profitStonk * 2);
      }

      setTronNeed(0); // yani teron
      // setTronNeed(currencies.find(i => i.id === 2)?.minStock); // yani teron

      if (currencyArr[0]?.isUserPay) {
        if (currencyArr[0]?.cryptocurrencyId?.toString() === '2') {
          setProfit(0);
        } else {
          setProfit(voidPlans?.BuyToken?.Level1?.profit || 0
            + voidPlans?.BuyToken?.Level2?.profit || 0
            + voidPlans?.BuyToken?.Level3?.profit || 0
            + voidPlans?.BuyToken?.Level4?.profit || 0
            + voidPlans?.BuyToken?.Level5?.profit || 0
            + voidPlans?.BuyToken?.Level6?.profit || 0
            + voidPlans?.BuyToken?.Level7?.profit || 0
            + voidPlans?.BuyToken?.Level8?.profit || 0
            + voidPlans?.BuyToken?.Level9?.profit || 0
            + voidPlans?.BuyToken?.Level10?.profit || 0);
        }
      } else {
        setProfit(0);
      }



      // setMinRemove(Math.max(
      //   currencyArr[0]?.amount,

      //   (currencyArr[0]?.cryptocurrencyId?.toString() === '2'
      //     ? 20 : (currencyArr[0]?.profitStonk) * 2),

      //   currencyArr[0]?.cryptocurrencyId?.toString() === '2'
      //     ? 20 : (currencyArr[0]?.profitStonk) * 2,
      // ).toFixed(2));

      setCurrencyList(currencyArr);
      forceUpdate(13);

      if (!currencyArr[0]) {
        setDisable(true);
        setDeactiveUser(true);
      }
    }
  }, [FFuser, plancrypto]);


  const selectCurrency = async (e) => {
    await setBuyCurrency(e.target.value);

    if (currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.isUserPay) {
      if (currencyList.find(
        c => c.cryptocurrencyId.toString() === e.target.value,
      )?.cryptocurrencyId.toString() === '2') {
        await setProfit(0);
      } else {
        await setProfit(voidPlans?.BuyToken?.Level1?.profit || 0
          + voidPlans?.BuyToken?.Level2?.profit || 0
          + voidPlans?.BuyToken?.Level3?.profit || 0
          + voidPlans?.BuyToken?.Level4?.profit || 0
          + voidPlans?.BuyToken?.Level5?.profit || 0
          + voidPlans?.BuyToken?.Level6?.profit || 0
          + voidPlans?.BuyToken?.Level7?.profit || 0
          + voidPlans?.BuyToken?.Level8?.profit || 0
          + voidPlans?.BuyToken?.Level9?.profit || 0
          + voidPlans?.BuyToken?.Level10?.profit || 0);
      }
    } else {
      setProfit(0);
    }

    await setMaxRemove(currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)
      ?.maxAmount);

    if (currencyList.find(
      c => c.cryptocurrencyId.toString() === e.target.value,
    )?.cryptocurrencyId.toString() === '2') {
      // setProfitStonk((currencyList.find(
      //   c => c.cryptocurrencyId.toString() === e.target.value,
      // )?.profitStonk) * 2);
      setProfitStonk(0);
    } else {
      setProfitStonk(currencyList.find(
        c => c.cryptocurrencyId.toString() === e.target.value,
      )?.profitStonk * 2);
    }

    await setTronNeed(0); // yani teron
    // await setTronNeed(currencies.find(f => f.id === 2)?.minStock); // yani teron

    // await setMinRemove(Math.max(
    //   currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.amount,
    //   currencies.find(i => i.id === 2)?.minStock - (balanceWallet?.TRX?.Deposit)
    //   + (currencyList.find(c => c.cryptocurrencyId.toString()
    //     === e.target.value)?.cryptocurrencyId?.toString() === '2'
    //     ? (currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk) * 2
    //     : currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk),
    //   currencyList.find(c => c.cryptocurrencyId.toString()
    //     === e.target.value)?.cryptocurrencyId?.toString() === '2'
    //     ? (currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk) * 2
    //     : currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk,
    // ).toFixed(2));

    // await setMinRemove(Math.max(
    //   currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.amount,

    //   (currencyList.find(c => c.cryptocurrencyId.toString()
    //     === e.target.value)?.cryptocurrencyId?.toString() === '2'
    //     ? 20 : currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk * 2),

    //   currencyList.find(c => c.cryptocurrencyId.toString()
    //     === e.target.value)?.cryptocurrencyId?.toString() === '2'
    //     ? 20
    //     : (currencyList.find(c => c.cryptocurrencyId.toString() === e.target.value)?.profitStonk * 2),
    // ).toFixed(2));


    setAmount('');
    setAmountTether('');
    setUnValid(false);
    setUnValidTether(false);
    validator.current.hideMessages();
    forceUpdate(13);
  };

  const handleConvert = async (e) => {
    await setAmount(e.target.value);
    if (currency === '1' && ncc !== 0) {
      if (validator.current.fieldValid('Amount') && !Number.isNaN(+e.target.value)) {
        setUnValid(false);
        setValid('valid');
        setAmountTether((e.target.value * ncc).toFixed(4));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmountTether((e.target.value * ncc).toFixed(4));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else if (currency === '2' && tron !== 0) {
      if (validator.current.fieldValid('Amount') && !Number.isNaN(+e.target.value)) {
        setUnValid(false);
        setValid('valid');
        setAmountTether((e.target.value * tron).toFixed(4));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmountTether((e.target.value * tron).toFixed(4));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else {
      setAmount('');
      setAmountTether('');
    }
  };

  const handleConvertOnTether = async (e) => {
    await setAmountTether(e.target.value);
    if (currency === '1' && ncc !== 0) {
      if (validator.current.fieldValid('Amount Tether') && !Number.isNaN(+e.target.value)) {
        setUnValidTether(false);
        setUnValid(false);
        setValid('valid');
        setAmount((e.target.value / ncc).toFixed(0));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount((e.target.value / ncc).toFixed(0));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmount('');
      }
    } else if (currency === '2' && tron !== 0) {
      if (validator.current.fieldValid('Amount Tether') && !Number.isNaN(+e.target.value)) {
        setUnValidTether(false);
        setUnValid(false);
        setValid('valid');
        setAmount((e.target.value / tron).toFixed(0));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount((e.target.value / tron).toFixed(0));
        setUnValid(true);
        setValid('');
        forceUpdate(13);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else {
      setAmount('');
      setAmountTether('');
    }
  };

  const handlecheckBuy = (e) => {
    e.preventDefault();
    if (validator.current.fieldValid('Amount') && validator.current.fieldValid('Amount Tether')) {
      toggle();
    } else if (!validator.current.fieldValid('Amount')) {
      setUnValid(true);
      validator.current.showMessageFor('Amount');
      forceUpdate(1);
    } else if (!validator.current.fieldValid('Amount Tether')) {
      setUnValidTether(true);
      validator.current.showMessageFor('Amount Tether');
      forceUpdate(1);
    }
  };

  const handleBuy = async () => {
    if (validator.current.fieldValid('Security Password')) {
      dispatch(setLoadingBuy());
      localStorage.setItem('lastBuyReq', new Date().getTime());
      const buyData = {
        currencyId: Number(currency),
        planId: plans.find(i => i.planTitle === 'Buy Token').id,
        amountTether: Number(AmountTether),
        amount: Number(Amount),
        FFCode: FFuser.FFCode,
        token: localStorage.getItem('token'),
        secPass,
      };
      // console.log(buyData);
      setDisable(true);
      const current = new Date();
      const pendDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}T
            ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
      dispatch(setOrdersRecordBuy({
        kindId: 9,
        created_at: pendDate,
        cryptocurrencyId: Number(currency),
        amount: Number(Amount),
        // ffplanId: plans.find(i => i.planTitle === 'Buy Token').id,
        // walletId: depositWallet?.id,
        stateId: 299,
      }));
      setResendTime(180);
      togglePass();
      reset();
      validator.current.hideMessages();
      forceUpdate(13);
      window.scrollTo(0, document.body.scrollHeight);
      await AuthRequest(`${serverURL}/holder/buyToken`, buyData)
        .then(res => res.json())
        .then((data) => {
          if (showLogs) {
            console.log('buyToken :', data);
          }
          // localStorage.removeItem('lastBuyReq');
          dispatch(clearLoadingBuy());
          if (data.numReq === 1 && data.sttReq === 'ok') {
            toast.success('Your Buy Token was successful !', {
              position: 'top-center',
              autoClose: 15000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setOrders(data.orders));
            dispatch(setBalance(data.Balance));
            dispatch(setHavePlan(data.havePlans));
          } else if (data.numReq === 0 && data.sttReq === 'expire') {
            toast.error('Buy Token is not available now !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'unBalance') {
            toast.error('The Price of seleted token in different at this moment, try again !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'noCash') {
            toast.error('You dont have enough Tether to do this deal !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'lessThanMin') {
            toast.error('Your entered amount is less than allowed !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'noWage') {
            toast.error('You dont have enough Tron as wage to do this deal !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'no minTRX') {
            toast.error('You dont have enough Tron as wage to do this deal !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'no maxAmount') {
            toast.error('Your entered amount is more than allowed !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'no have stank') {
            toast.error('Server response is not available right now !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'have problem sendToken') {
            toast.error('Server response is not available right now (txid) !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'Not enough TRX') {
            toast.error('You dont have enough TRX to do this deal !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'noActive') {
            toast.error('Buy Token is not active now !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'have pending order') {
            toast.error('Another Buy Token is under proccess, Try again later !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'have problem') {
            toast.error('No response from server, Try again later !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'noBuy Crypto') {
            toast.error('This currency is not available for Buying !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'noActive Crypto') {
            toast.error('This currency is not active right now !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'no have stank TRX') {
            toast.error('No response , Try again later !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'user have problem') {
            toast.error('Your account is not active right now !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          } else if (data.numReq === 0 && data.sttReq === 'wrong secPass') {
            toast.error('The entered security password is not true, try again !', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(getOrders());
          }
        }).catch((err) => {
          toast.error('Something went wrong , Try again later or check your Balance !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // localStorage.removeItem('lastBuyReq');
          dispatch(clearLoadingBuy());
          setDisable(false);
          setResendTime(0);
          dispatch(getBalance());
          dispatch(getOrders());
        });
      setResendTime(0);
      setDisable(false);
      if (havePlans) {
        if (!Object.prototype.hasOwnProperty.call(havePlans, 'Buy Token')) {
          dispatch(getRefferal());
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (

    <>

      <UncontrolledTooltip placement="top" target="buyHelp">
        You can use Tether to buy and add other currencies to your wallet.
        After performing the operation and pressing the BUY button,
        the purchased currency balance will be visible in your wallet.
        You can buy NCC and Tron using Tether. Your FF wallet balance will increase after the Buy operation.
        The minimum currency purchase is {MinRemove} units.
      </UncontrolledTooltip>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              ref={secInput}
              value={secPass}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleBuy();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleBuy}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">
            Buy
          </h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          Pay {AmountTether} Tether for receiving
          {' '}
          {!Number.isNaN(parseFloat(Amount)) && Number(currency) === 1
            ? (Amount * ((100 - Profit) / 100)).toFixed(2) : ''}
          {!Number.isNaN(parseFloat(Amount)) && Number(currency) === 2
            ? (Amount - 20).toFixed(2) : ''}
          {' '}
          {currencyList.find(c => c.cryptocurrencyId === Number(currency))?.planCryptoCurrencyDtlTitle?.split('-')[1]}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <Card>
        <CardHeader className="text-center">
          <h4 className="monetary_title">Buy <i id="buyHelp" className="fas fa-info-circle monetary_title_info" /></h4>
        </CardHeader>
        <form>
          <CardBody className="monetary_CardBody">
            <div>
              <label className="form-label" htmlFor="currency">Currency</label>
              <select
                className="form-select text-center"
                id="currency"
                value={currency}
                onClick={async e => selectCurrency(e)}
                onChange={e => selectCurrency(e)}
              >
                {currencyList && currencyList?.map((item, i) => (
                  <option key={item.cryptocurrencyId} value={item.cryptocurrencyId}>
                    {item.planCryptoCurrencyDtlTitle?.split('-')[1]}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <label className="form-label" htmlFor="AmountTether">Amount Tether :</label>
              <input
                className={unValidTether ? 'unValid form-control text-center amount_input_space'
                  : 'form-control text-center amount_input_space'}
                type="text"
                placeholder="Amount Tether"
                value={AmountTether}
                disabled={disable
                  || ((balanceWallet?.TRX?.Deposit < ProfitStonk)
                    && (currency !== '2'))
                  || (balanceWallet?.TRX?.Deposit < TronNeed)
                  || !balanceWallet?.USDT?.Deposit}
                onChange={async (e) => {
                  await handleConvertOnTether(e);
                  if (!validator.current.fieldValid('Amount')) {
                    setUnValid(true);
                    forceUpdate(13);
                  } else {
                    setUnValid(false);
                    forceUpdate(13);
                  }
                }}
                onFocus={(e) => {
                  dispatch(getBalance());
                  dispatch(setCurrency());
                  setAmount('');
                  setAmountTether('');
                  setUnValidTether(false);
                  validator.current.hideMessages();
                  forceUpdate(13);
                }}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                  setTimeout(() => {
                    validator.current.hideMessages();
                    forceUpdate(13);
                  }, 10000);
                }}
              />
              <div className="tefo_error tefo_error_strong">
                {validator.current.message(
                  'Amount Tether', AmountTether, `max:${balanceWallet?.USDT?.Deposit},num`,
                )}
              </div>
            </div>
            <div>
              <label className="form-label" htmlFor="Amount">
                Amount {currencies.find(i => i.id === Number(currency))?.name} :
              </label>
              <input
                id="Amount"
                className={unValid ? 'unValid form-control text-center amount_input_space'
                  : `${valid} form-control text-center amount_input_space`}
                type="text"
                autoComplete="off"
                placeholder={`Amount ${currencies.find(i => i.id === Number(currency))?.name}`}
                value={Amount}
                disabled={disable
                  || ((balanceWallet?.TRX?.Deposit < ProfitStonk)
                    && (currency !== '2'))
                  // || (balanceWallet?.TRX?.Deposit < TronNeed)
                  || !balanceWallet?.USDT?.Deposit}
                onChange={async (e) => {
                  await handleConvert(e);
                  setAmount(e.target.value);
                  if (!validator.current.fieldValid('Amount Tether')) {
                    setUnValidTether(true);
                    forceUpdate(13);
                  } else {
                    setUnValidTether(false);
                    forceUpdate(13);
                  }
                }}
                onFocus={(e) => {
                  dispatch(getBalance());
                  dispatch(setCurrency());
                  setAmount('');
                  setAmountTether('');
                  setUnValidTether(false);
                  validator.current.hideMessages();
                  forceUpdate(13);
                }}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                  setTimeout(() => {
                    validator.current.hideMessages();
                    forceUpdate(13);
                  }, 10000);
                }}
              />
              <div className="tefo_error tefo_error_strong">
                {validator.current.message('Amount', Amount, `required|min:${MinRemove},num|max:${MaxRemove},num`)}
              </div>
              {currency === '2' ? (
                <div className="buy_fee">
                  <span className="buy_fee_info">Network Fee :{' '}
                    {/* {ProfitStonk} TRX */}
                    20 TRX
                  </span>
                  {/* <br /> */}
                  <span className="buy_fee_info">Pure receive :{' '}
                    {/* {!Number.isNaN(parseFloat(Amount))
                      && (Amount - ProfitStonk >= 0)
                      && (Amount - ProfitStonk).toFixed(2)} */}
                    {!Number.isNaN(parseFloat(Amount))
                      && (Amount - 20 >= 0)
                      && (Amount - 20).toFixed(2)}
                  </span>
                </div>
              ) : (
                <div className="buy_fee">
                  <span className="buy_fee_info">Network Fee :{' '}
                    {!Number.isNaN(parseFloat(Amount)) && (Amount * (Profit / 100)).toFixed(2)}
                  </span>
                  {/* <br /> */}
                  <span className="buy_fee_info">Pure receive :{' '}
                    {!Number.isNaN(parseFloat(Amount)) && (Amount * ((100 - Profit) / 100)).toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          </CardBody>
        </form>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
        <CardFooter className="monetary_footer">
          <div className="form-group row">
            <div className="text-center mt-2">
              <button
                type="submit"
                className="btn btn-outline-theme tefo_buttons_lg w-100"
                onClick={e => handlecheckBuy(e)}
                disabled={
                  disable
                  || ((balanceWallet?.TRX?.Deposit <= ProfitStonk)
                    && (currency !== '2'))
                  // || (balanceWallet?.TRX?.Deposit >= 0)
                  // || (balanceWallet?.TRX?.Deposit < TronNeed)
                  || (Number(AmountTether) === 0.0000)
                  || !balanceWallet?.USDT?.Deposit
                  || buyLoad
                }
              >
                {/* {resendTime === 0 && !disable && currencyList[0]
                  && !buyLoad
                  ? (
                    <> */}
                Buy
                {/* </>
                  ) : ''} */}
              </button>

              {/* {(resendTime >= 0 && disable && currencyList[0] && !DeactiveUser)
                || buyLoad
                ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                  </>
                ) : ''} */}
              <div className="mb-2 mt-2">
                <div className="tefo_error tefo_error_strong">
                  {FFuser?.isActive
                    && !FFuser?.isAllowBuy
                    ? '*You are NOT Allowed to make a Buy Token right now !' : ''}
                </div>
                <div className="tefo_error tefo_error_strong">
                  {resendTime > 0 && buyLoad && currencyList[0]
                    ? (
                      <>
                        <div className="waiting_msg text-center">
                          {/* <i className="fas fa-exclamation-triangle" /> */}
                          {/* Your Buy Token is doing, */}
                          Please Wait {Math.floor(resendTime)} Seconds ...
                        </div>
                      </>
                    )
                    : ''}
                  {FFuser.isActive
                    && FFuser?.isAllowBuy
                    // && TronNeed > 0
                    // && Amount < (ProfitStonk * 2)
                    // && (balanceWallet?.TRX?.Deposit >= TronNeed)
                    && currency !== '2'
                    && (balanceWallet?.TRX?.Deposit < ProfitStonk)
                    && balanceWallet?.TRX?.Deposit >= 0
                    ? `*Your TRX deposit balance must be at least ${ProfitStonk}
                     to be able to Buy NCC Token ` : ''}
                  {/* after that Your TRX deposit balance will be less than ${TronNeed} ` : ''} */}
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

Buy.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  voidPlans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  plans: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tron: PropTypes.string.isRequired,
  ncc: PropTypes.string.isRequired,
  buyLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo,
    constants,
    currency,
    loader,
  } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    havePlans: userInfo?.user?.havePlans,
    balanceWallet: userInfo?.user?.balanceWallet,
    depositWallet: userInfo?.user?.depositWallet,
    haveSecPass: userInfo?.user?.haveSecPass,
    voidPlans: userInfo?.user?.voidPlans,
    plancrypto: constants?.plancrypto,
    plans: constants?.plans,
    currencies: constants?.currencies,
    tron: currency.tron,
    ncc: currency.ncc,
    buyLoad: loader.buyLoad,
  };
}


export default connect(mapStateToProps)(Buy);
