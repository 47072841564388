import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button, Col, Popover, PopoverBody, PopoverHeader, Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import Format2 from '../../../utils/Format2';
import { store } from '../../App/store';



const convertor = `${process.env.PUBLIC_URL}/img/convert.svg`;


const TopbarConvertor = ({
  dir, IsOpenedConvertor, closeConvertor, handleOpenConvertor,
}) => {
  const [cr1, setCr1] = useState('NCC');
  const [cr2, setCr2] = useState('Tether');
  const [amount, setAmount] = useState('0');
  const [result, setResult] = useState();

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState(0);


  const commafy = (num) => {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };


  const validatorConfigs = {
    element: message => <div className="validationErr mt-1">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const { ncc, tron } = store.getState().currency;

  const convert = async (e) => {
    await setAmount(e.target.value);
    if (validator.current.allValid() && !Number.isNaN(+e.target.value)) {
      setUnValid(false);
      setValid('valid');

      if (cr1 === 'NCC') {
        if (cr2 === 'NCC') {
          setResult(commafy(Format2(e.target.value)));
          forceUpdate(1);
        }
        if (cr2 === 'Tether') {
          setResult(commafy(Format2(e.target.value * ncc)));
          forceUpdate(2);
        }
        if (cr2 === 'TRX') {
          setResult(commafy(Format2((e.target.value * ncc) / tron)));
          forceUpdate(3);
        }
      }

      if (cr1 === 'Tether') {
        if (cr2 === 'NCC') {
          setResult(commafy(Format2(e.target.value / ncc)));
          forceUpdate(4);
        }
        if (cr2 === 'Tether') {
          setResult(commafy(Format2(e.target.value)));
          forceUpdate(5);
        }
        if (cr2 === 'TRX') {
          setResult(commafy(Format2((e.target.value / tron))));
          forceUpdate(6);
        }
      }

      if (cr1 === 'TRX') {
        if (cr2 === 'NCC') {
          setResult(commafy(Format2((e.target.value * tron) / ncc)));
          forceUpdate(7);
        }
        if (cr2 === 'Tether') {
          setResult(commafy(Format2(e.target.value * tron)));
          forceUpdate(8);
        }
        if (cr2 === 'TRX') {
          setResult(commafy(Format2(e.target.value)));
          forceUpdate(9);
        }
      }
    } else if (Number.isNaN(+e.target.value)) {
      setResult('---');
      setAmount('');
      setUnValid(true);
      setValid('');
    }
  };

  const convertOnChangeCr = async (curr1, curr2) => {
    if (validator.current.allValid() && !Number.isNaN(+amount)) {
      setUnValid(false);

      if (curr1 === 'NCC') {
        if (curr2 === 'NCC') {
          setTimeout(() => {
            setResult(commafy(Format2(amount)));
            forceUpdate(10);
          }, 300);
        }
        if (curr2 === 'Tether') {
          setTimeout(() => {
            setResult(commafy(Format2(amount * ncc)));
            forceUpdate(11);
          }, 300);
        }
        if (curr2 === 'TRX') {
          setTimeout(() => {
            setResult(commafy(Format2((amount * ncc) / tron)));
            forceUpdate(12);
          }, 300);
        }
      }

      if (curr1 === 'Tether') {
        if (curr2 === 'NCC') {
          setTimeout(() => {
            setResult(commafy(Format2(amount / ncc)));
            forceUpdate(13);
          }, 300);
        }
        if (curr2 === 'Tether') {
          setTimeout(() => {
            setResult(commafy(Format2(amount)));
            forceUpdate(14);
          }, 300);
        }
        if (curr2 === 'TRX') {
          setTimeout(() => {
            setResult(commafy(Format2((amount / tron))));
            forceUpdate(15);
          }, 300);
        }
      }

      if (curr1 === 'TRX') {
        if (curr2 === 'NCC') {
          setTimeout(() => {
            setResult(commafy(Format2((amount * tron) / ncc)));
            forceUpdate(16);
          }, 300);
        }
        if (curr2 === 'Tether') {
          setTimeout(() => {
            setResult(commafy(Format2(amount * tron)));
            forceUpdate(17);
          }, 300);
        }
        if (curr2 === 'TRX') {
          setTimeout(() => {
            setResult(commafy(Format2(amount)));
            forceUpdate(18);
          }, 300);
        }
      }
    } else if (Number.isNaN(+amount)) {
      setResult('---');
      setAmount('');
      setUnValid(true);
    }
  };

  return (
    <div className="quice_info quice_convertor">
      {IsOpenedConvertor && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={closeConvertor}
        />
      )}
      <div className="quice_info_img">
        <Button
          id="convertorTop"
          onClick={handleOpenConvertor}
          outline
          className="button-tooltip quiceInfo_btn"
        >
          <img className="quice_info_image quice_info_image_right" id="convertorTop" alt="" src={convertor} />
        </Button>
        <Popover
          placement="bottom"
          isOpen={IsOpenedConvertor}
          target="convertorTop"
          toggle={handleOpenConvertor}
          dir={dir}
          className="topmenu_popovers convertor_popover"
        >
          <button type="button" className="btn_convertor" onClick={closeConvertor}>
            <span className="lnr lnr-cross" />
          </button>
          <div className="convertor_holder">
            <PopoverHeader className="text-left">
              <p className="quice_info_title">
                Crypto Convertor
              </p>
            </PopoverHeader>
            <hr className="convertor_hr" />
            <PopoverBody>
              <Row className="quiceInfo_row">
                <Col>
                  <select
                    className="form-select mb-2 text-center"
                    id="cr1"
                    value={cr1}
                    onClick={async (e) => {
                      await setCr1(e.target.value);
                      forceUpdate(19);
                      convertOnChangeCr(e.target.value, cr2);
                    }}
                    onChange={async (e) => {
                      await setCr1(e.target.value);
                      forceUpdate(20);
                      convertOnChangeCr(e.target.value, cr2);
                    }}
                  >
                    <option value="NCC">NCC</option>
                    <option value="TRX">TRX</option>
                    <option value="Tether">Tether</option>
                  </select>
                </Col>
                <Col>
                  <input
                    className={unValid ? 'unValid form-control text-center ' : `form-control text-center ${valid}`}
                    type="text"
                    placeholder=""
                    value={amount}
                    onChange={e => convert(e)}
                    onClick={() => setAmount('')}
                    onBlur={() => {
                      setUnValid(false);
                      setValid('');
                    }}
                  />
                  {validator.current.message('amount', amount, 'numeric')}
                </Col>
              </Row>
              <Row className="quiceInfo_row">
                <Col>
                  <select
                    className="form-select mb-2 text-center"
                    id="cr2"
                    value={cr2}
                    onClick={async (e) => {
                      await setCr2(e.target.value);
                      forceUpdate(21);
                      convertOnChangeCr(cr1, e.target.value);
                    }}
                    onChange={async (e) => {
                      await setCr2(e.target.value);
                      forceUpdate(22);
                      convertOnChangeCr(cr1, e.target.value);
                    }}
                  >
                    <option value="NCC">NCC</option>
                    <option value="TRX">TRX</option>
                    <option value="Tether">Tether</option>
                  </select>
                </Col>
                <Col>
                  <input
                    className="form-control text-center"
                    type="text"
                    placeholder=""
                    readOnly
                    value={result}
                  />
                </Col>
              </Row>
            </PopoverBody>
          </div>
        </Popover>
      </div>
    </div>
  );
};

TopbarConvertor.propTypes = {
  dir: PropTypes.string.isRequired,
  IsOpenedConvertor: PropTypes.bool.isRequired,
  handleOpenConvertor: PropTypes.func.isRequired,
  closeConvertor: PropTypes.func.isRequired,
};




export default connect()(TopbarConvertor);

