const AuthRequest = (url, data) => fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': '*',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
  credentials: 'same-origin',
  mode: 'cors',
  body: JSON.stringify(data),
});

export default AuthRequest;
