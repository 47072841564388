import React from 'react';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateData';

const DataTableTransactions = () => {
  const reactTableData = CreateTableData();

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

export default DataTableTransactions;
