import React, { useState } from 'react';
import {
  ButtonGroup, ButtonToolbar, Button,
} from 'reactstrap';




const KYCAdmin = () => {
  const [identity] = useState();
  const [frontImg, setFrontimg] = useState('');
  const [backImg, setBackimg] = useState('');
  const [selfieImg, setSelfieimg] = useState('');

  const removePhotoFront = () => setFrontimg('');
  const removePhotoBack = () => setBackimg('');
  const removePhotoSelfie = () => setSelfieimg('');

  const handleFileSelectedFront = (e) => {
    const files = Array.from(e.target.files);
    // console.log('files:', files[0]);
    if (files[0]) {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(files[0]);

      oFReader.onload = function (oFREvent) {
        setFrontimg(oFREvent.target);
      };
    } else {
      setFrontimg('');
    }
  };

  const handleFileSelectedBack = (e) => {
    const files = Array.from(e.target.files);
    // console.log('files:', files[0]);
    if (files[0]) {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(files[0]);

      oFReader.onload = function (oFREvent) {
        setBackimg(oFREvent.target);
      };
    } else {
      setBackimg('');
    }
  };

  const handleFileSelectedSelfie = (e) => {
    const files = Array.from(e.target.files);
    // console.log('files:', files[0]);
    if (files[0]) {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(files[0]);

      oFReader.onload = function (oFREvent) {
        setSelfieimg(oFREvent.target);
      };
    } else {
      setSelfieimg('');
    }
  };


  return (

    <>
      <div className="Kyc_admin_holder">
        <div>
          <div className="mb-3">
            {frontImg ? (
              <div className="identity_img text-center">
                <img width="100%" alt="identity" height="100%" src={frontImg.result} />
                <button
                  type="button"
                  className="btn btn btn-outline-danger btn-sm remove_identity"
                  onClick={removePhotoFront}
                >
                  remove
                </button>
              </div>
            ) : (
              <div className="identity_no_img" htmlFor="Frontside">
                <label className="form-label identity_label_admin" htmlFor="Frontside">
                  <p className="identity_label_p">
                    Upload Frontside
                  </p>
                </label>
              </div>
            )}
            <input
              type="file"
              name="Frontside"
              id="Frontside"
              onChange={handleFileSelectedFront}
              style={{ display: 'none' }}
            />
          </div>

          {identity !== 'Passport' ? (
            <div className="mb-3">
              {backImg ? (
                <div className="identity_img text-center">
                  <img width="100%" alt="identity" height="100%" src={backImg.result} />
                  <button
                    type="button"
                    className="btn btn btn-outline-danger btn-sm remove_identity"
                    onClick={removePhotoBack}
                  >
                    remove
                  </button>
                </div>
              ) : (
                <div className="identity_no_img" htmlFor="Frontside">
                  <label className="form-label identity_label_admin" htmlFor="Backside">
                    <p className="identity_label_p">
                      Upload Backside
                    </p>
                  </label>
                </div>
              )}
              <input
                type="file"
                name="Backside"
                id="Backside"
                onChange={handleFileSelectedBack}
                style={{ display: 'none' }}
              />
            </div>
          ) : null}

          <div className="mb-3">
            {selfieImg ? (
              <div className="identity_img text-center">
                <img width="100%" alt="identity" height="100%" src={selfieImg.result} />
                <button
                  type="button"
                  className="btn btn btn-outline-danger btn-sm remove_identity"
                  onClick={removePhotoSelfie}
                >
                  remove
                </button>
              </div>
            ) : (
              <div className="identity_no_img" htmlFor="Frontside">
                <label className="form-label identity_label_admin" htmlFor="Selfie">
                  <p className="identity_label_p">
                    Upload a Selfie Photo of Yourself
                  </p>
                </label>
              </div>
            )}
            <input
              type="file"
              name="Selfie"
              id="Selfie"
              onChange={handleFileSelectedSelfie}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <div className="mb-3">
          <ButtonToolbar>
            <ButtonGroup className="btn-group--justified" dir="ltr">
              <Button color="secondary">Edit</Button>
              <Button color="danger">Cancel</Button>
              <Button color="primary">Save</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
    </>
  );
};
export default KYCAdmin;
