import React, { useEffect, useMemo, useState } from 'react';
import { store } from '../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   // width: 1000,
      // },
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Wallet name',
        accessor: 'WalletName',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Wallet Addrress',
        accessor: 'WalletAddrress',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Delete / Edit',
        accessor: 'Activity',
        disableGlobalFilter: true,
        customRender: true,
        disableSortBy: true,

      },
    ],
    [],
  );



  const { withdrawWallet } = store.getState().userInfo.user;

  const data = [];

  const rows = () => {
    withdrawWallet.map((wallet, i) => {
      data.push({
        id: i + 1,
        date: wallet.created_at.toString().split('T')[0],
        time: wallet.created_at.toString().split('T')[1].split('.')[0],
        WalletName: wallet.name,
        WalletAddrress: wallet.address,
        Activity: `WalletActivity&&${wallet.id}&&${wallet.name}&&${wallet.address}`,
      });
      return data;
    });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
