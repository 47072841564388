import React, { useMemo } from 'react';
import { store } from '../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   // width: 1000,
      // },
      {
        Header: 'Attach',
        accessor: 'Attach',
        disableGlobalFilter: true,
        customRender: true,
        width: 10,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        customRender: true,
        disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        disableGlobalFilter: true,
      },
      {
        Header: 'Kind',
        accessor: 'Kind',
        customRender: true,
        // disableGlobalFilter: true,
      },
      {
        Header: 'Subject',
        accessor: 'Subject',
        // disableGlobalFilter: true,
      },
      // {
      //   Header: 'Description',
      //   accessor: 'Description',
      //   disableGlobalFilter: true,
      //   customRender: true,
      //   modalMsg: true,
      //   disableSortBy: true,
      // },
    ],
    [],
  );

  const { messages } = store.getState().userInfo.user;

  const data = [];

  const rows = () => {
    messages?.map((message, i) => {
      // console.log(`read${message.pivot.isRead}`);
      data.push({
        id: i + 1,
        Status: `read${message?.pivot?.isRead}`,
        date: message?.pivot?.created_at?.toString()?.split('T')[0],
        time: message?.pivot?.created_at?.toString()?.split('T')[1]?.split('.')[0],
        Kind: `isAdminCreate${message?.isAdminCreate}`,
        Subject: message?.announcementTitle,
        Attach: `attach&&${message?.attachFileAddressLink}`,
        Description: `message&&${message?.pivot?.id}&&${message?.announcementTitle}
        &&${message?.announcementDesc}&&${message?.created_at}`,
        msgdtls: `message&&${message?.pivot?.id}&&${message?.announcementTitle}
        &&${message?.announcementDesc}&&${message?.created_at}`,
      });
      return data;
    });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};


export default CreateTableData;
