import axios from 'axios';
import { showLogs } from '../../containers/App/Rules';
import { serverbetaURL } from '../../utils/url';

export const SET_CONSTANTS = 'SET_CONSTANTS';
export const SET_STRUCTURES = 'SET_STRUCTURES';
export const CLEAR_CONSTANTS = 'CLEAR_CONSTANTS';


export const setConstants = (
  constants, plans, cryptocurrencies, plancryptocurrencydtls, deskMap, planProfitSetting, planWithdrawSetting,
) => (dispatch) => {
  dispatch({
    type: 'SET_CONSTANTS',
    constants,
    plans,
    cryptocurrencies,
    plancryptocurrencydtls,
    deskMap,
    planProfitSetting,
    planWithdrawSetting,
  });
};

export const clearConstants = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CONSTANTS',
  });
};

export const getBannersUrl = () => (dispatch) => {
  const deskBanners = [];
  for (let i = 1; i < 3; i += 1) {
    fetch(`${process.env.PUBLIC_URL}/img/banner/banner-desk-${i}.png`, { method: 'HEAD' })
      .then((res) => {
        if (res?.status === 200) {
          deskBanners.push(`${process.env.PUBLIC_URL}/img/banner/banner-desk-${i}.png`);
        }
      }).catch(err => null);
  }
  const mobBanners = [];
  for (let i = 1; i < 4; i += 1) {
    fetch(`${process.env.PUBLIC_URL}/img/banner/banner-mob-${i}.png`, { method: 'HEAD' })
      .then((res) => {
        if (res?.status === 200) {
          mobBanners.push(`${process.env.PUBLIC_URL}/img/banner/banner-mob-${i}.png`);
        }
      }).catch(err => null);
  }
  dispatch({
    type: 'SET_BANNERS',
    deskBanners,
    mobBanners,
  });
};

export const getStructures = () => (dispatch) => {
  axios.get(`${serverbetaURL}/services/structures`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Credentials': true,
    },
  })
    .then((data) => {
      if (showLogs) {
        console.log('/services/structures :', data);
      }
      if (data?.status === 200) {
        if (data?.data?.status === true) {
          dispatch({
            type: 'SET_STRUCTURES',
            structures: data?.data?.data,
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      }
    });
};




