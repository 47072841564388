import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col, Modal, Popover, PopoverHeader, Row,
} from 'reactstrap';
import canvasToImage from 'canvas-to-image';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { RWebShare } from 'react-web-share';
import { QRCode } from 'react-qrcode-logo';
import { store } from '../../../App/store';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { serverURL } from '../../../../utils/url';



const ReffDesktop = ({ user }) => {
  const [shareEmail, setshareEmail] = useState();

  const [copyCode, setCopycode] = useState('fas fa-copy');
  const [copyLink, setCopylink] = useState('fas fa-copy');

  const [refDeactive, setRefDeactive] = useState();

  useEffect(() => {
    // if ((!user.FFuser.isActive && !user.havePlans)
    if (!user?.FFuser?.isActive
      || !user?.FFuser?.isAllowShowRefferalCode) {
      setRefDeactive(true);
    }
  }, [user]);

  const [refCode, setRefCode] = useState(user?.FFuser?.FFCodeRefrence);
  const [refLink, setReflink] = useState(`https://financialfactory.capital/refferallink/${refCode}`);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState();

  const [IsOpenedCopied, setIsOpenedCopied] = useState(false);
  const handleOpenCopied = () => {
    setIsOpenedCopied(true);
  };

  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const copyLinkToClipboard = () => {
    const element = document.querySelector('#refLink');
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopylink('fas fa-check-circle');
    setTimeout(() => {
      setCopylink('fas fa-copy');
      setIsOpenedCopied(false);
    }, 1000);
  };

  const validatorConfigs = {
    messages: {
      required: 'That feild is required.',
      // default: 'Validation has failed!',
    },
    element: message => <div className="validationErr">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const reset = () => {
    validator.current.hideMessages();
    setshareEmail('');
  };


  const onShareRefLink = () => {
    const shareInfos = { FFCode: user.FFuser.FFCode, shareEmail, token: localStorage.getItem('token') };
    // console.log(shareInfos);
    AuthRequest(`${serverURL}/shareEmail`, shareInfos)
      .then(res => res.json())
      .then((data) => {
        if (data.sttReq === 'ok' && data.numReq === 1) {
          toast.success(`Your refferal link has been sent to ${shareEmail}
              Email Address !`, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success('Your request failed. Please try again later !', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    toggle();
    reset();
  };


  const downloadRefQR = () => {
    const refCodeQR = document.getElementById('refQrCode');
    canvasToImage(refCodeQR, {
      name: `${user?.FFuser?.FFCodeRefrence}`,
      type: 'jpg',
      quality: 1,
    });
  };


  return (
    <>
      {refDeactive ? '' : (
        <Popover
          placement="top"
          isOpen={IsOpenedCopied}
          target="copied"
          toggle={handleOpenCopied}
          className="copied_popover"
        >
          <PopoverHeader>Copied !</PopoverHeader>
        </Popover>
      )}

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Share Refferal</h4>
        </div>
        <div className="modal__body">
          <h6>Are you want to share your refferal link with  </h6>
          <br />
          <h4>{shareEmail}</h4>
          <br />
          <h6>?</h6>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={onShareRefLink}>Yes, Share !</Button>
        </ButtonToolbar>
      </Modal>

      <Card>
        <CardBody className="cardBody_low_padding_desk">
          <Row>
            <Col xs={8} md={12}>
              <h5 className="text-left ref_desk_title">Refferal</h5>
              <p className="ref_desk_link_title">Refferal Link</p>
              <div className="ref_desk_link">
                <div>
                  {/* <label className="form-label" htmlFor="refLink">Refferal Link</label> */}
                  <div className="input-group flex-nowrap">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Your Refferal Link"
                      id="refLink"
                      value={refDeactive ? '' : refLink}
                      disabled={refDeactive ? 'true' : ''}
                    />
                    {/* <button
                  type="button"
                  className="input-group-text"
                  id="copied"
                  onClick={copyLinkToClipboard}
                  disabled={refDeactive ? 'true' : ''}
                >
                  <i className={copyLink} />
                </button> */}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <p className="ref_desk_link_title">Send to Email</p>
                <form>
                  {/* <label className="form-label" htmlFor="shareRefferal">SHARE</label> */}
                  <div className="input-group flex-nowrap">
                    <input
                      className={unValid ? 'unValid form-control' : `form-control ${valid}`}
                      type="text"
                      placeholder="Enter the Email Address"
                      id="shareRefferal"
                      value={shareEmail}
                      onBlur={() => {
                        setUnValid(false);
                        setValid('');
                      }}
                      onChange={async (e) => {
                        await setshareEmail(e.target.value);
                        if (validator.current.allValid()) {
                          setUnValid(false);
                          setValid('valid');
                        }
                        if (!validator.current.allValid()) {
                          setValid('');
                          setUnValid(true);
                        }
                      }}
                      disabled={refDeactive ? 'true' : ''}
                    />
                    {/* <button
                      type="submit"
                      className="input-group-text"
                      disabled={refDeactive ? 'true' : ''}
                    >
                      <i className="fas fa-share-alt" />
                    </button> */}
                  </div>
                  {validator.current.message('email', shareEmail, 'required|email')}
                </form>
              </div>
              <div className="text-center share_ref_desk_btn">
                <button
                  type="button"
                  className="btn btn-outline-theme table-btn"
                  disabled={refDeactive ? 'true' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    if (validator.current.allValid()) {
                      toggle();
                    } else {
                      // validator.current.showMessages();
                      setUnValid(true);
                      forceUpdate(1);
                    }
                  }}
                >
                  Share
                </button>
              </div>
            </Col>
            <Col xs={4} md={12}>
              <div className="text-center ref_desk_qrcode">
                {refDeactive ? (
                  <img className="logo_ref_desk" src={logo} alt="QR" />
                ) : (
                  <QRCode
                    value={refDeactive ? '' : refLink}
                    logoWidth="150"
                    eyeRadius={[
                      [0, 80, 0, 80], // top/left eye
                      [80, 0, 80, 0], // top/right eye
                      [80, 0, 80, 0], // bottom/left
                    ]}
                    logoOpacity="0.4"
                    qrStyle="dots"
                    bgColor="#fff"
                    fgColor="#000"
                    ecLevel="Q"
                    size="90"
                    // logoImage={logo}
                    className="myqrcode"
                    id="refQrCode"
                    style={{ borderRadius: '10%' }}
                    enableCORS="true"
                  />
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-outline-theme table-btn ref_desk_btn"
                    onClick={e => downloadRefQR()}
                    disabled={refDeactive ? 'true' : ''}
                  >
                    <i className="fas fa-arrow-down" />
                  </button>
                  <RWebShare
                    data={{
                      text: refLink,
                      url: serverURL,
                      title: `${user?.FFuser?.FFCodeRefrence}`,
                    }}
                  // onClick={() => console.log('shared successfully!')}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-theme table-btn ref_desk_btn"
                      disabled={refDeactive ? 'true' : ''}
                    >
                      <i className="fas fa-share-alt" />
                    </button>
                  </RWebShare>
                  <button
                    type="button"
                    id="copied"
                    className="btn btn-outline-theme table-btn ref_desk_btn"
                    onClick={copyLinkToClipboard}
                    disabled={refDeactive ? 'true' : ''}
                  >
                    <i className={copyLink} />
                  </button>
                </div>
              </div>
            </Col>
          </Row>




        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};


ReffDesktop.propTypes = {
  user: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { user: userInfo.user };
}

export default connect(mapStateToProps)(ReffDesktop);

