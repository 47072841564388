import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';
import TopbarQuiceInfo from './TopbarQuiceInfo';
import TopbarConvertor from './TopbarConvertor';
import { clearCurrency, setCurrency } from '../../../redux/actions/currencyActions';
import {
  getMesseges,
  getBalance,
  getReload,
  getRefferal,
  logOut,
  getOrders,
  getBuyInvestSummary,
  getReportPlans,
  getBinarySummaryProfit,
  getRefundSummary,
  getAwardSummary,
  getStackingSummary,
} from '../../../redux/actions/userActions';
import { closeMobileSidebarVisibility } from '../../../redux/actions/sidebarActions';
import { clearConstants, getStructures } from '../../../redux/actions/constantActions';
import { store } from '../../App/store';
import {
  clearLoadingAffiliate,
  clearLoadingAward,
  clearLoadingBuy,
  clearLoadingBuyInvest,
  clearLoadingFund,
  clearLoadingKyc,
  clearLoadingRefund,
  clearLoadingStacking,
  clearLoadingWithdraw,
} from '../../../redux/actions/loaderAction';
import { changeThemeToCapital, changeThemeToDark } from '../../../redux/actions/themeActions';
import { customFFCode } from '../../App/Rules';


const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility, show }) => {
  const [isCollapsedNotification, setIsCollapsedNotification] = useState(false);
  const [IsOpenedConvertor, setIsOpenedConvertor] = useState(false);
  const [isCollapsedProfile, setIsCollapsedProfile] = useState(false);

  const [IsOpenedNcc, setIsOpenedNcc] = useState(false);
  const [IsOpenedTether, setIsOpenedTether] = useState(false);
  const [IsOpenedTron, setIsOpenedTron] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getReload());
    dispatch(getBuyInvestSummary());
    dispatch(getRefundSummary());
    dispatch(getAwardSummary());
    dispatch(getStackingSummary());
    dispatch(getRefferal());
    dispatch(getReportPlans());
    dispatch(getBinarySummaryProfit());
    dispatch(getStructures());



    setInterval(() => {
      if (store.getState().userInfo.user
        && (!customFFCode)
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30303030'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30535129'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30000001'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30000034'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30412509'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30123456'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30334455'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '66666666') {
        if (((new Date().getTime() - Date.parse(new Date(store.getState().userInfo.loginDate))) / 60000) > 60) {
          history.push({
            pathname: '/',
            haveMsg: true,
            msg: 'expire',
          });
          dispatch(logOut());
          dispatch(clearCurrency());
          dispatch(clearConstants());
          dispatch(clearLoadingAffiliate());
          dispatch(clearLoadingBuy());
          dispatch(clearLoadingFund());
          dispatch(clearLoadingWithdraw());
          dispatch(clearLoadingKyc());
          dispatch(clearLoadingStacking());
          dispatch(clearLoadingBuyInvest());
          dispatch(clearLoadingRefund());
          dispatch(clearLoadingAward());
        }
      }

      if (store.getState().userInfo.user && localStorage.getItem('lastBuyReq')) {
        if (((new Date().getTime() - (localStorage.getItem('lastBuyReq') || 0)) / 1000) > 180) {
          localStorage.removeItem('lastBuyReq');
          dispatch(clearLoadingBuy());
          dispatch(getOrders());
        }
      }

      if (store.getState().userInfo.user && localStorage.getItem('lastWithdrawReq')) {
        if (((new Date().getTime() - (localStorage.getItem('lastWithdrawReq') || 0)) / 1000) > 180) {
          localStorage.removeItem('lastWithdrawReq');
          dispatch(clearLoadingWithdraw());
          dispatch(getOrders());
        }
      }

      if (store.getState().userInfo.user && localStorage.getItem('lastReq')) {
        if (((new Date().getTime() - (localStorage.getItem('lastReq') || 0)) / 1000) > 180) {
          localStorage.removeItem('lastReq');
          dispatch(clearLoadingAffiliate());
          dispatch(clearLoadingFund());
          dispatch(clearLoadingKyc());
          dispatch(clearLoadingStacking());
          dispatch(clearLoadingBuyInvest());
          dispatch(clearLoadingRefund());
          dispatch(clearLoadingAward());
        }
      }
    }, 5000);



    let timeout;
    document.onmousemove = () => {
      clearTimeout(timeout);
      if (store.getState().userInfo.user
        && (!customFFCode)
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30303030'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30535129'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30000001'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30000034'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30412509'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30123456'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '30334455'
        && store.getState().userInfo.user?.FFuser?.FFCode !== '66666666') {
        timeout = setTimeout(() => {
          history.push({
            pathname: '/',
            haveMsg: true,
            msg: 'mouseMove',
          });
          dispatch(logOut());
          dispatch(clearCurrency());
          dispatch(clearConstants());
          dispatch(clearLoadingAffiliate());
          dispatch(clearLoadingBuy());
          dispatch(clearLoadingFund());
          dispatch(clearLoadingWithdraw());
          dispatch(clearLoadingKyc());
          dispatch(clearLoadingStacking());
          dispatch(clearLoadingBuyInvest());
          dispatch(clearLoadingRefund());
        }, 300000);
      }
    };
  }, []);


  useEffect(() => {
    if (show) {
      setIsOpenedNcc(false);
      setIsOpenedTether(false);
      setIsOpenedTron(false);
      setIsOpenedConvertor(false);
      setIsCollapsedNotification(false);
      setIsCollapsedProfile(false);
    }
  }, [show]);


  const handleOpenNcc = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsOpenedNcc(!IsOpenedNcc);
    setIsOpenedTether(false);
    setIsOpenedTron(false);
    setIsOpenedConvertor(false);
    setIsCollapsedProfile(false);
    setIsCollapsedNotification(false);
    // dispatch(setCurrency());
    dispatch(getBalance());
    // dispatch(getBuyInvestSummary());
  };

  const handleOpenTether = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsOpenedTether(!IsOpenedTether);
    setIsOpenedNcc(false);
    setIsOpenedTron(false);
    setIsOpenedConvertor(false);
    setIsCollapsedProfile(false);
    setIsCollapsedNotification(false);
    // dispatch(setCurrency());
    dispatch(getBalance());
    // dispatch(getBuyInvestSummary());
  };

  const handleOpenTron = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsOpenedTron(!IsOpenedTron);
    setIsOpenedNcc(false);
    setIsOpenedTether(false);
    setIsOpenedConvertor(false);
    setIsCollapsedProfile(false);
    setIsCollapsedNotification(false);
    // dispatch(setCurrency());
    dispatch(getBalance());
    // dispatch(getBuyInvestSummary());
  };

  const handleOpenConvertor = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsOpenedConvertor(!IsOpenedConvertor);
    setIsCollapsedProfile(false);
    setIsCollapsedNotification(false);
    setIsOpenedNcc(false);
    setIsOpenedTron(false);
    setIsOpenedTether(false);
    dispatch(setCurrency());
  };

  const closeConvertor = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsOpenedConvertor(!IsOpenedConvertor);
  };

  const collapseNotification = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    if (!isCollapsedNotification) {
      dispatch(getMesseges());
    }
    setIsCollapsedNotification(!isCollapsedNotification);
    setIsOpenedConvertor(false);
    setIsCollapsedProfile(false);
    setIsOpenedNcc(false);
    setIsOpenedTron(false);
    setIsOpenedTether(false);
  };

  const toggleProfile = () => {
    if (show) {
      dispatch(closeMobileSidebarVisibility());
    }
    setIsCollapsedProfile(!isCollapsedProfile);
    setIsCollapsedNotification(false);
    setIsOpenedConvertor(false);
    setIsOpenedNcc(false);
    setIsOpenedTron(false);
    setIsOpenedTether(false);
  };


  return (
    <div className="topbar topbar_custom">
      <div className="topbar__wrapper">
        <div className="topbar__left topbar__left_custome">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          {/* <Link className="topbar__logo" to="/Desktop" /> */}
        </div>
        <div className="topbar__right topbar__right_custome">
          <div className="topbar__right-quice">
            <TopbarQuiceInfo
              handleOpenNcc={handleOpenNcc}
              handleOpenTether={handleOpenTether}
              handleOpenTron={handleOpenTron}
              IsOpenedNcc={IsOpenedNcc}
              IsOpenedTether={IsOpenedTether}
              IsOpenedTron={IsOpenedTron}
              setIsOpenedNcc={setIsOpenedNcc}
              setIsOpenedTether={setIsOpenedTether}
              setIsOpenedTron={setIsOpenedTron}
            />
          </div>
          {/* <button type="button" onClick={() => dispatch(changeThemeToCapital())}>capital</button>
          <button type="button" onClick={() => dispatch(changeThemeToDark())}>main</button> */}
          <div className="topbar__right-over">
            <TopbarConvertor
              handleOpenConvertor={handleOpenConvertor}
              closeConvertor={closeConvertor}
              IsOpenedConvertor={IsOpenedConvertor}
            />
            <TopbarNotification collapseNotification={collapseNotification} isCollapsed={isCollapsedNotification} />
            <TopbarProfile isCollapsed={isCollapsedProfile} toggleProfile={toggleProfile} />
          </div>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { show } = state.sidebar;
  return { show };
}


export default withRouter(connect(mapStateToProps)(Topbar));
