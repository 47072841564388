import { store } from '../../containers/App/store';


export const getAvatarPlaceHolder = () => (`${process.env.PUBLIC_URL}/img/logo-dark.png`);

export const getStarImgUrl = () => (`${process.env.PUBLIC_URL}/img/star.png`);

export const getNftImgUrl = () => (`${process.env.PUBLIC_URL}/img/nft.jpg`);

export const getNccFinanceImgUrl = () => (`${process.env.PUBLIC_URL}/img/finance.png`);


export const getPlanColor = () => ({
  Fund: '#005fff',
  Affiliate: '#9370DB',
  Stacking: 'yellow',
  BuyToken: 'green',
  BuyInvest: '#91180f',
});


export const getMainColor = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return '#3cd2a5';
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return '#ff8f1f';
  }
  return '#3cd2a5';
};



export const getCryptoImgUrl = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return {
      ncc: `${process.env.PUBLIC_URL}/img/currency-icon/NCC.svg`,
      tron: `${process.env.PUBLIC_URL}/img/currency-icon/TRON.svg`,
      tether: `${process.env.PUBLIC_URL}/img/currency-icon/TETHER.svg`,
    };
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return {
      ncc: `${process.env.PUBLIC_URL}/img/currency-icon/ncc-capital.png`,
      tron: `${process.env.PUBLIC_URL}/img/currency-icon/tron-capital.png`,
      tether: `${process.env.PUBLIC_URL}/img/currency-icon/tether-capital.png`,
    };
  }
  return {
    ncc: `${process.env.PUBLIC_URL}/img/currency-icon/NCC.svg`,
    tron: `${process.env.PUBLIC_URL}/img/currency-icon/TRON.svg`,
    tether: `${process.env.PUBLIC_URL}/img/currency-icon/TETHER.svg`,
  };
};



export const getLogoImgUrl = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return `${process.env.PUBLIC_URL}/img/logo-dark.png`;
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return `${process.env.PUBLIC_URL}/img/logo-capital.png`;
  }
  return `${process.env.PUBLIC_URL}/img/logo-dark.png`;
};


export const getLegendImgUrl = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return `${process.env.PUBLIC_URL}/img/legend-dark.png`;
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return `${process.env.PUBLIC_URL}/img/legend-capital.png`;
  }
  return `${process.env.PUBLIC_URL}/img/legend-dark.png`;
};

export const getAwardBoardImgUrl = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return `${process.env.PUBLIC_URL}/img/award-board-dark.png`;
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return `${process.env.PUBLIC_URL}/img/award-board-capital.png`;
  }
  return `${process.env.PUBLIC_URL}/img/award-board-dark.png`;
};



export const getAwardResultImg = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return `${process.env.PUBLIC_URL}/img/award-result-dark.png`;
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return `${process.env.PUBLIC_URL}/img/award-result-capital.png`;
  }
  return `${process.env.PUBLIC_URL}/img/award-result-dark.png`;
};



export const getPlanIcon = () => {
  if (store.getState().theme?.className === 'theme-dark') {
    return {
      fund: `${process.env.PUBLIC_URL}/img/plan-icon/fund_dark.png`,
      affiliate: `${process.env.PUBLIC_URL}/img/plan-icon/affiliate_dark.png`,
      stacking: `${process.env.PUBLIC_URL}/img/plan-icon/stacking_dark.png`,
      refund: `${process.env.PUBLIC_URL}/img/plan-icon/refund_dark.png`,
      buyinvest: `${process.env.PUBLIC_URL}/img/plan-icon/buyinvestdark.png`,
      buymagic: `${process.env.PUBLIC_URL}/img/plan-icon/buymagic_dark.png`,
    };
  }
  if (store.getState().theme?.className === 'theme-capital') {
    return {
      fund: `${process.env.PUBLIC_URL}/img/plan-icon/fund_capital.png`,
      affiliate: `${process.env.PUBLIC_URL}/img/plan-icon/affiliate_capital.png`,
      stacking: `${process.env.PUBLIC_URL}/img/plan-icon/stacking_capital.png`,
      refund: `${process.env.PUBLIC_URL}/img/plan-icon/refund_capital.png`,
      buyinvest: `${process.env.PUBLIC_URL}/img/plan-icon/buyinvest_capital.png`,
      buymagic: `${process.env.PUBLIC_URL}/img/plan-icon/buymagic_capital.png`,
    };
  }
  return {
    fund: `${process.env.PUBLIC_URL}/img/plan-icon/fund_dark.png`,
    affiliate: `${process.env.PUBLIC_URL}/img/plan-icon/affiliate_dark.png`,
    stacking: `${process.env.PUBLIC_URL}/img/plan-icon/stacking_dark.png`,
    refund: `${process.env.PUBLIC_URL}/img/plan-icon/refund_dark.png`,
    buyinvest: `${process.env.PUBLIC_URL}/img/plan-icon/buyinvestdark.png`,
    buymagic: `${process.env.PUBLIC_URL}/img/plan-icon/buymagic_dark.png`,
  };
};



