import React from 'react';
import {
  Card, CardBody,
} from 'reactstrap';
import Tabs from './Tabs/Tabs';




const RefferalReport = () => {
  const Ava = 1;

  return (
    <Card>
      <CardBody>
        <div className="tabs tabs--justify tabs--bordered-top">
          <Tabs />
        </div>
      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};

export default RefferalReport;
