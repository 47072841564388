import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col, Modal, Popover, PopoverHeader, Row,
} from 'reactstrap';
import canvasToImage from 'canvas-to-image';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { RWebShare } from 'react-web-share';
import { QRCode } from 'react-qrcode-logo';
import { store } from '../../../App/store';
import AuthRequest from '../../../../services/AuthFetchRequest';
import serverURL from '../../../../utils/url';



const MagicDesk = ({ user }) => {
  const a = `${process.env.PUBLIC_URL}/img/magic-a.png`;
  const b = `${process.env.PUBLIC_URL}/img/magic-b.png`;
  const aa = `${process.env.PUBLIC_URL}/img/magic-aa.png`;
  const bb = `${process.env.PUBLIC_URL}/img/magic-bb.png`;

  const [day, setDay] = useState(1);
  const [hour, setHour] = useState(2);
  const [minute, setMinute] = useState(3);
  const [second, setSecond] = useState(4);

  const secondsToTime = async (secs) => {
    const days = Math.floor(secs / (60 * 60 * 24));
    await setDay(days);

    const divisorForHours = secs % (60 * 60 * 24);
    const hours = Math.floor(divisorForHours / (60 * 60));
    await setHour(hours < 10 ? `0${hours}` : hours);

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    await setMinute(minutes < 10 ? `0${minutes}` : minutes);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds === 0 ? 59 : divisorForSeconds);
    await setSecond(seconds < 10 ? `0${seconds - 1}` : seconds - 1);
  };


  useEffect(() => {
    setInterval(() => {
      // secondsToTime(1000000);
      secondsToTime(1657983211 - ((new Date().getTime()) / 1000));
      // console.log(new Date().getTime());
    }, 1000);
  }, []);

  return (
    <>
      <Card>
        <CardBody className="cardBody_low_padding_desk">

          {/* <img src={a} className="magic_img_a" alt="" /> */}
          <img src={aa} className="magic_img_a" alt="" />
          {/* <div className="magic_label_holder">
            <div className="magic_win_label">
              WIN
            </div>
            <div className="magic_under_win text-center">
              <span className="magic_under_win_price">
                100 $
              </span>
              {' '}
              <span className="magic_under_win_day">
                EVERY 10 DAY
              </span>
            </div>
          </div> */}
          {/* 
          <div>
            <p className="magic_draw_time_desk text-center">
              Draw Time:
              {' '}
              <span>
                30 june 2022
              </span>
            </p>
            <div className="magic_timer magic_timer_desk  text-center">
              <span className="magic_timer_value magic_timer_value_desk">
                {day}
                {' '}
                :
                {' '}
              </span>
              <span className="magic_timer_value magic_timer_value_desk">
                {hour}
                {' '}
                :
                {' '}
              </span>
              <span className="magic_timer_value magic_timer_value_desk">
                {minute}
                {' '}
                :
                {' '}
              </span>
              <span className="magic_timer_value magic_timer_value_desk">
                {second}
              </span>
            </div>
            <div className="magic_label magic_label_desk text-center">
              <span className="magic_timer_label magic_timer_label_desk">
                Day
              </span>
              {' '}

              {' '}
              <span className="magic_timer_label magic_timer_label_desk">
                Hour
              </span>
              {' '}

              {' '}
              <span className="magic_timer_label magic_timer_label_desk">
                Minute
              </span>
              {' '}

              {' '}
              <span className="magic_timer_label magic_timer_label_desk">
                Second
              </span>
            </div>
          </div> */}

          <Row className="m-0">
            <Col xs={6} sm={12} md={12} lg={12} xl={5} className="p-0">
              <div className="magic_desk_text">
                In this system, according to the number of NCC you buy,
                you will receive a lottery code and you can win a 100 $ prize.
                click for more info...
              </div>
            </Col>
            <Col xs={6} sm={12} md={12} lg={12} xl={7} className="p-0">
              {/* <img src={b} className="magic_img_b" alt="" /> */}
              <img src={bb} className="magic_img_b" alt="" />
              {/* 
              <div className="magic_desk_soon">
                <span>
                  Coming Soon !
                </span>
              </div> */}

              {/* <div className="magic_desk_btns_holder">
                <button
                  type="submit"
                  // btn-magic
                  className="btn btn-magic btn-magic-desk"
                // onClick={e => handlecheckBuy(e)}
                // disabled={
                //   disable
                //   || ((balanceWallet?.TRX?.Deposit <= ProfitStonk)
                //     && (currency !== '2'))
                //   // || (balanceWallet?.TRX?.Deposit >= 0)
                //   // || (balanceWallet?.TRX?.Deposit < TronNeed)
                //   || (Number(AmountTether) === 0.0000)
                //   || !balanceWallet?.USDT?.Deposit
                //   || buyLoad
                // }
                >
                  <strong>
                    BUY and get Code
                  </strong>
                </button>
                <div className="text-center">
                  More Details
                </div>
              </div> */}
            </Col>
          </Row>


        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};


MagicDesk.propTypes = {
  user: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { user: userInfo.user };
}

export default connect(mapStateToProps)(MagicDesk);

