import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Card, CardBody } from 'reactstrap';
import { getAvatarPlaceHolder, getMainColor, getStarImgUrl } from '../../../../shared/helpers/themHelper';
import { profileImgURL } from '../../../../utils/url';
import { getBinarySummaryProfit } from '../../../../redux/actions/userActions';


const TopSells = ({
  levels, theme, FFuser, binarySummary,
}) => {
  const [fillcolor, setFillcolor] = useState('#3cd2a5');

  const [emptyItems, setEmptyItems] = useState();

  const dispatch = useDispatch();

  const [, forceUpdate] = useState();
  const history = useHistory();


  useEffect(() => {
    setEmptyItems(5 - (binarySummary?.members?.length || 0));
  }, [binarySummary]);

  useEffect(() => {
    setFillcolor(getMainColor());
  }, [theme]);

  useEffect(() => {
    dispatch(getBinarySummaryProfit());
  }, []);

  const goToReportTree = () => {
    history.push({
      pathname: '/Reports',
      haveAct: true,
      act: 'showBinary',
    });
  };

  return (

    <Card>
      <CardBody className="">

        <div className="sellers">


          {binarySummary?.members?.slice(0, 5)?.map((item, i) => (
            <div className="seller_container seller_not_more">
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><i /></div>
              <div className="topsell_loader"><i /></div>
              <div className="seller_image_div">
                <img
                  src={item?.userImage ? `${profileImgURL}/${item?.userImage}`
                    : getAvatarPlaceHolder()}
                  alt="seller"
                />
              </div>
              <div className="seller_value">
                {Math.floor(item?.amount)} $
              </div>
              <div className="seller_star">
                <div className="binary_item_score">
                  {[...Array(Math.floor(item?.amount / 1000))]?.slice(0, 5)?.map((itemStar, ii) => (
                    <>
                      <img src={getStarImgUrl()} alt="" />
                    </>
                  ))}
                </div>
              </div>
              <div className="seller_code">
                {item?.nickname || item?.FFCode}
              </div>
            </div>
          ))}


          {[...Array(emptyItems)]?.map((item, i) => (
            <div className="seller_container deactive_seller seller_not_more">
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><i /></div>
              <div className="topsell_loader"><i /></div>
              <div className="seller_image_div">
                <img src={getAvatarPlaceHolder()} alt="seller" />
              </div>
              <div className="seller_value">
                - - -
              </div>
              <div className="seller_code">
                - - -
              </div>
            </div>
          ))}

          <div className="seller_container d-none d-md-flex">
            <div className="topsell_loader"><span /></div>
            <div className="topsell_loader"><span /></div>
            <div className="topsell_loader"><i /></div>
            <div className="topsell_loader"><i /></div>
            <div className="show_more_desk_sale">
              <button
                type="button"
                className="show_more_desk_sale_btn"
                onClick={() => goToReportTree()}
              >
                More ...
              </button>
            </div>
          </div>


        </div>

      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};

TopSells.propTypes = {
  levels: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  binarySummary: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    levels: userInfo.user?.levels,
    FFuser: userInfo.user?.FFuser,
    binarySummary: userInfo.user?.binarySummary,
    theme: theme?.className,
  };
}


export default connect(mapStateToProps)(TopSells);
