import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar, UncontrolledTooltip, Toast,
} from 'reactstrap';
import { Pie, PieChart } from 'recharts';
import ReactSpeedometer from 'react-d3-speedometer';

import SimpleReactValidator from 'simple-react-validator';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import {
  setBalance, setHavePlan, getRefferal, setAutoWithAff,
} from '../../../../../redux/actions/userActions';
import { serverURL } from '../../../../../utils/url';
import NoSecPass from '../../../../Layout/NoSecPass';



const AffiliateAutomatic = ({
  havePlans,
  haveSecPass,
  autoWithAff,
  plans,
  plancrypto,
  planWithdrawSetting,
  FFCode,
  isActive,
}) => {
  const [, forceUpdate] = useState();

  const [auto, setAuto] = useState(false);

  const [pay, setPay] = useState(30);
  const [recieve, setRecieve] = useState(3000);


  const secInput = useRef(null);

  const [secPass, setSecPass] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
    // setAuto(autoWithAff);
  };

  const validator = useRef(new SimpleReactValidator());

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
    // setAuto(autoWithAff);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setAuto(autoWithAff);
  }, [havePlans, plancrypto, haveSecPass]);

  // planWithdrawSetting

  useEffect(() => {
    setPay(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 3 && f.planId === 2 && f.isPay === 1)?.amount || 30);
    setRecieve(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 1 && f.planId === 2 && f.isPay === 0)?.amount || 3000);
  }, [planWithdrawSetting]);


  const AutomaticWithdraw = () => {
    togglePass();
    const autoData = {
      FFCode,
      secPass,
      isAllow: auto ? 1 : 0,
      planId: 2,
      cryptocurrencyId: 3,
      token: localStorage.getItem('token'),
    };
    // console.log(autoData);
    AuthRequest(`${serverURL}/holder/settings/WithDraw`, autoData)
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        if (data.numReq === 1 && data.sttReq === 'ok') {
          dispatch(setAutoWithAff(data.autoWithAff));
          console.log('Done');
          toast.success(`Automatic Withdraw for your affiliate plan ${auto ? 'Actived' : 'Deactived'}`, {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button
                className="modal_cancel"
                onClick={() => {
                  togglePass();
                  setAuto(autoWithAff);
                }}
              >
                Cancel
              </Button>
              {' '}
              <Button className="modal_ok" color="primary" onClick={() => AutomaticWithdraw()}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Automatic Withdraw</h4>
        </div>
        <div className="modal__body">
          <h6>
            Are you sure that you want to {auto ? 'ACTIVE' : 'DEACTIVE'}
            <br />
            <br />
            Automatic Withdraw {pay}$ for every {recieve} NCC in the Affiliate plan ?
            <br />
            <br />
          </h6>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button
            className="modal_cancel"
            onClick={() => {
              toggle();
              setAuto(autoWithAff);
            }}
          >
            Cancel
          </Button>
          {' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <div className="form-check form-switch">
        <input
          type="checkbox"
          className="form-check-input"
          id="autoWithdraw"
          checked={auto}
          defaultChecked={auto}
          disabled={!havePlans?.Affiliate ? 'true' : ''}
          onClick={async () => {
            await setAuto(pre => !pre);
            toggle();
          }}
        />
        <label
          className="form-check-label"
          htmlFor="autoWithdraw"
        >
          Automatic Withdraw {pay}$ for every {recieve} NCC
        </label>
      </div>
    </>
  );
};

AffiliateAutomatic.propTypes = {
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  autoWithAff: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  plans: PropTypes.string.isRequired,
  plancrypto: PropTypes.string.isRequired,
  planWithdrawSetting: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, constants, loader } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    haveSecPass: userInfo.user?.haveSecPass,
    autoWithAff: userInfo.user?.autoWithAff,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    planWithdrawSetting: constants?.planWithdrawSetting,
  };
}

export default connect(mapStateToProps)(AffiliateAutomatic);

