import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/app.scss';
import '@/scss/app-hud.scss';
import '@/scss/select-search.scss';
import '@/scss/ReactToastify.css';
import '@/scss/vendor.css';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import history from '../../services/history';

const App = () => {
  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              <div>
                <Router history={history} />
              </div>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
