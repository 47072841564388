import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import ReactSpeedometer from 'react-d3-speedometer';
import {
  Modal, Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { getMainColor } from '../../../../../shared/helpers/themHelper';
import NoSecPass from '../../../../Layout/NoSecPass';
import { clearLoadingStacking, setLoadingStacking } from '../../../../../redux/actions/loaderAction';
import { getBalance, getStackingSummary } from '../../../../../redux/actions/userActions';
import { showLogs } from '../../../../App/Rules';
import { serverbetaURL } from '../../../../../utils/url';
import axiosHeaders from '../../../../../utils/axiosHelper';



const StakingReport = ({
  stackingLoad,
  FFCode,
  isActive,
  isAllowPlan,
  depositWallet,
  withdrawWallet,
  haveSecPass,
  plans,
  plancrypto,
  currencies,
  tab,
  theme,
  StackingSummary,
}) => {
  const [duration, setDuration] = useState(0);
  const [nccProfit, setNccProfit] = useState(0.00);
  const [nccStaked, setNccStaked] = useState(0.00);

  const [themeColor, setThemeColor] = useState('');

  const [agree, setAgree] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [secPass, setSecPass] = useState();
  const [wallet, setWallet] = useState(depositWallet?.address);
  const [walletModal, setWalletModal] = useState();
  const secInput = useRef(null);

  const [modalCancel, setModalCancel] = useState(false);
  const toggleCancel = () => {
    setModalCancel(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    toggleCancel();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };


  const dispatch = useDispatch();

  useEffect(() => {
    if (StackingSummary) {
      setDuration((StackingSummary?.stage?.daysPass / 30) || 0);
      setNccProfit(Number(StackingSummary?.stage?.sumProfit || 0)?.toFixed(2));
      setNccStaked(Math.floor(StackingSummary?.stage?.Price || 0));
    }
  }, [StackingSummary]);

  useEffect(() => {
    if (tab === '3') {
      setDuration(pre => pre + 1);
      setTimeout(() => {
        setDuration(pre => pre - 1);
      }, 1);
    }
  }, [tab]);

  useEffect(() => {
    setThemeColor(getMainColor());
  }, [theme]);


  const handleCancelStacking = () => {
    if (validator.current.fieldValid('Security Password')) {
      localStorage.setItem('lastReq', new Date().getTime());
      const reqData = {
        cryptoCurrencyId: 1,
        userPlanId: StackingSummary?.stage?.userPlanId,
        FFCode,
        token: localStorage.getItem('token'),
        secPass,
        walletAddress: wallet,
      };
      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);
      if (showLogs) {
        console.log('request Data for cancel Stacking :', reqData);
      }
      dispatch(setLoadingStacking());
      axios.post(`${serverbetaURL}/stackings/planCancel`, JSON.stringify(reqData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('/stackings/planCancel :', data);
          }
          dispatch(getStackingSummary());
          dispatch(getBalance());
          dispatch(clearLoadingStacking());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getStackingSummary());
          dispatch(getBalance());
          dispatch(clearLoadingStacking());
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (
    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (
          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCancelStacking();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button
                className="modal_ok"
                color="primary"
                onClick={handleCancelStacking}
              >
                Confirm
              </Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modalCancel}
        toggle={toggleCancel}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Cancel Stacking</h4>
        </div>
        <div className="modal__body">
          <>
            <h5>Are you sure ? </h5>
            <br />
            <br />
          </>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={agree}
                id="agree"
                defaultChecked={agree}
                onChange={e => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="agree">
                I agree to recieve my {' '}
                {Math.floor(StackingSummary?.stage?.amount - (StackingSummary?.stage?.sumProfit || 0))} NCC
              </label>
            </div>
            <div className="pt-3">
              <Row>
                <Col md={2}>
                  <label className="form-label text-left d-block" htmlFor="wallet">Wallet :</label>
                </Col>
                <Col md={10}>
                  <select
                    className="form-select text-center"
                    id="wallet"
                    value={wallet}
                    onClick={e => setWallet(e.target.value)}
                    onChange={e => setWallet(e.target.value)}
                  >
                    {depositWallet && (
                      <option key={depositWallet?.address} value={depositWallet?.address}>
                        {'Deposit Wallet'}
                        {' '}- {depositWallet?.address?.slice(0, 3)}
                        ***************
                        {depositWallet?.address?.slice(depositWallet?.address?.length - 4)}
                      </option>
                    )}

                    {withdrawWallet && withdrawWallet.map((item, i) => (
                      <option key={item.address} value={item.address}>
                        {item.name}
                        {' '}- {item.address?.slice(0, 3)}
                        ***************{item.address?.slice(item.address.length - 4)}
                      </option>
                    ))}
                  </select>
                  <div className="tefo_error tefo_error_strong">
                    {validator.current.message('wallet', wallet, 'required')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="tefo_error">
              {validator.current.message('agreement', agree, 'accepted')}
            </div>
          </div>

        </div>

        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleCancel}>Cancel</Button>{' '}
          {agree ? (
            <Button
              className="modal_ok"
              color="primary"
              onClick={() => {
                toggleCancel();
                togglePass();
              }}
            >
              Confirm
            </Button>
          ) : ''}
        </ButtonToolbar>
      </Modal>


      <div className="text-center mt-5">
        <div className="afiiliate_speed_holder staking_speed_holder">
          <ReactSpeedometer
            className="speedometer"
            needleTransition="easeSinInOut"
            needleTransitionDuration={2000}
            needleHeightRatio={0.75}
            ringWidth={20}
            currentValueText={' '}
            minValue={0}
            value={duration}
            maxValue={12}
            valueTextFontSize="10px"
            labelFontSize="30px"
            segments={12}
            textColor="#9da3a6"
            startColor="#ffffff10"
            endColor={themeColor}
            // startColor="#f1f1f1"
            // endColor="#f1f1f1"
            needleColor={themeColor}
            width={350}
            height={220}
            forceRender
          />
        </div>
        <div className="text-center">
          <div className="staking_cover_div">
            <p className="profit_label">Your Profit</p>
            <p className="profit_value">+{nccProfit}</p>
            <p className="staked_value_holder">
              <strong>
                {nccStaked}
              </strong>
              <span className="staked_label">
                {' '}
                <span>
                  Staked
                </span>
                {' '}
                <span>
                  NCC
                </span>
              </span>
            </p>
            <p className="Staking_Counter_label">
              Staking Plan Counter
            </p>
          </div>
        </div>
        <div className="under_report" style={{ height: '50px' }}>
          {StackingSummary?.stage?.typeId === 338
            && (
              <button
                type="button"
                className="btn btn-outline-theme tefo_buttons_lg"
                disabled={!StackingSummary?.stage?.allowCancel
                  || stackingLoad
                  ? 'true' : ''}
                onClick={toggleCancel}
              >
                {stackingLoad ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" /> Processing ...
                  </>
                ) : ''}
                {!stackingLoad ? 'Cancel Staking' : ''}
              </button>
            )}
          {StackingSummary?.stage?.typeId === 327
            && (
              <p>This plan has been
                <span style={{ color: themeColor }}>
                  <strong>
                    {' '}Cancelled {' '}
                  </strong>
                </span>
                at <br />{StackingSummary?.stage?.updated_at}
              </p>
            )}
          {StackingSummary?.stage?.typeId === 326
            && (
              <p>This plan has been
                <span style={{ color: themeColor }}>
                  <strong>
                    {' '}Finished {' '}
                  </strong>
                </span>  at <br /> {StackingSummary?.stage?.updated_at}
              </p>
            )}
        </div>
      </div>

    </>
  );
};



StakingReport.propTypes = {
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  withdrawWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, loader, constants, theme,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    withdrawWallet: userInfo.user?.withdrawWallet,
    depositWallet: userInfo.user?.depositWallet,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    StackingSummary: userInfo.user?.StackingSummary,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(StakingReport);
