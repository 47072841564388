import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import TopbarMenuLink from './TopbarMenuLink';
import { store } from '../../App/store';
import { logOut } from '../../../redux/actions/userActions';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants } from '../../../redux/actions/constantActions';
import {
  clearLoadingAffiliate,
  clearLoadingBuy,
  clearLoadingBuyInvest,
  clearLoadingFund,
  clearLoadingKyc,
  clearLoadingRefund,
  clearLoadingStacking,
  clearLoadingWithdraw,
} from '../../../redux/actions/loaderAction';



const TopbarProfile = ({ toggleProfile, isCollapsed, FFuser }) => {
  const dispatch = useDispatch();


  const handleLogout = () => {
    // localStorage.clear();
    dispatch(logOut());
    dispatch(clearCurrency());
    dispatch(clearConstants());
    dispatch(clearLoadingAffiliate());
    dispatch(clearLoadingBuy());
    dispatch(clearLoadingFund());
    dispatch(clearLoadingWithdraw());
    dispatch(clearLoadingKyc());
    dispatch(clearLoadingStacking());
    dispatch(clearLoadingBuyInvest());
    dispatch(clearLoadingRefund());
  };


  return (
    <>
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={toggleProfile}>
          <div className="topbar__avatar-img_fake" />
          <p className="topbar__avatar-name">
            <p className="topbar__id">
              {FFuser?.FFCode}
            </p>
            <span className="topbar_email">
              {FFuser?.email}
            </span>
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {
          isCollapsed && (
            <button
              type="button"
              aria-label="button collapse"
              className="topbar__back"
              onClick={toggleProfile}
            />
          )
        }
        <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
          <div className="topbar__menu profileTop">
            <div className="topbar_user_in_collapse">
              <p className="text-center">
                <p className="topbar__id topbar__id_inside">
                  {FFuser?.FFCode}
                </p>
                {FFuser?.email}
              </p>
            </div>
            <TopbarMenuLink
              title="Profile"
              icon="inbox"
              path="/Profile"
              onClick={toggleProfile}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Log Out" icon="exit" path="/" onClick={handleLogout} />
          </div>
        </Collapse>
      </div>
    </>
  );
};



TopbarProfile.propTypes = {
  toggleProfile: PropTypes.func.isRequired,
  isCollapsed: PropTypes.string.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
  };
}

export default withRouter(connect(mapStateToProps)(TopbarProfile));

