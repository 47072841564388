import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import ReactSpeedometer from 'react-d3-speedometer';
import Slider from '../../../../../shared/components/slider';
import {
  getAwardBoardImgUrl, getAwardResultImg, getLegendImgUrl, getMainColor,
} from '../../../../../shared/helpers/themHelper';



const AwardReport = ({
  theme,
  awardSummary,
}) => {
  const [activeAward, setActiveAward] = useState();
  const [start, setStart] = useState([]);
  const [finish, setFinish] = useState([]);
  const [isLastDays, setIsLastDays] = useState(false);

  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [second, setSecond] = useState('00');

  const [resultImgURL, setResultImgURL] = useState();
  useEffect(() => {
    setResultImgURL(`${process.env.PUBLIC_URL}${getAwardResultImg()}`);
  }, [theme]);


  const secondsToTime = async (secs) => {
    if (secs > 0) {
      const hours = Math.floor(secs / (60 * 60));
      await setHour(hours < 10 ? `0${hours}` : hours);

      const divisorForMinutes = secs % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);
      await setMinute(minutes < 10 ? `0${minutes}` : minutes);

      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.ceil(divisorForSeconds === 0 ? 59 : divisorForSeconds);
      await setSecond(seconds < 10 ? `0${seconds - 1}` : seconds - 1);
    } else {
      await setHour(0);
      await setMinute(0);
      await setSecond(0);
    }
  };

  useEffect(async () => {
    clearInterval();
    if (awardSummary[0]) {
      const activePlan = awardSummary.find(a => a.HaveTime > 0);
      setActiveAward(activePlan);
      const formatedStart = (new Date(activePlan.startPlan).toUTCString()).split(' ');
      const formatedEnd = (new Date(activePlan.EndTime).toUTCString()).split(' ');
      setStart([formatedStart[1], formatedStart[2], formatedStart[3]]);
      setFinish([formatedEnd[1], formatedEnd[2], formatedEnd[3]]);
      const endTimeStamp = new Date(activePlan.EndTime).getTime();
      setInterval(() => {
        secondsToTime((endTimeStamp - new Date().getTime()) / 1000);
      }, 1000);
    } else {
      setStart([]);
      setFinish([]);
      setHour(0);
      setMinute(0);
      setSecond(0);
    }
    if (awardSummary.find(a => a.HaveTime > 0)?.HaveTime <= 10) {
      setIsLastDays(true);
    } else {
      setIsLastDays(false);
    }
  }, [awardSummary]);


  return (
    <>

      <div className="text-center">
        <div className="award_result_holder text-center">
          <div className="ar_time_holder">
            <div className="ar_time_start">
              <span>
                {start[0]}  {start[1]}
              </span>
              <span>
                {start[2]}
              </span>
            </div>
            <div className="ar_time_end">
              <span>
                {finish[0]}  {finish[1]}
              </span>
              <span>
                {finish[2]}
              </span>
            </div>
          </div>
          <div className="ar_score">
            <span className="scrore_value_close">
              {activeAward?.sumscore || 0}
            </span>
            <span>
              / 1000
            </span>
          </div>

          <div className="ar_time_remain">
            <div className="ar_timer">
              <span className={isLastDays ? 'ar_timer_value ar_timer_red' : 'ar_timer_value'}>
                {hour}
              </span>
              {' '}
              :
              {' '}
              <span className={isLastDays ? 'ar_timer_value ar_timer_red' : 'ar_timer_value'}>
                {minute}
              </span>
              {' '}
              :
              {' '}
              <span className={isLastDays ? 'ar_timer_value ar_timer_red' : 'ar_timer_value'}>
                {second}
              </span>
            </div>
            <div className="ar_label">
              <span className={isLastDays ? 'ar_timer_label ar_timer_red' : 'ar_timer_label'}>
                Hour
              </span>
              {' '}

              {' '}
              <span className={isLastDays ? 'ar_timer_label ar_timer_red' : 'ar_timer_label'}>
                Minute
              </span>
              {' '}

              {' '}
              <span className={isLastDays ? 'ar_timer_label ar_timer_red' : 'ar_timer_label'}>
                Second
              </span>
            </div>
          </div>
          <img src={resultImgURL} alt="" />



        </div>

      </div>

    </>
  );
};

AwardReport.propTypes = {
  awardSummary: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, loader, constants, theme,
  } = state;
  return {
    awardSummary: userInfo.user?.awardSummary,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(AwardReport);
