import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card, Row, Container, CardBody, Col,
} from 'reactstrap';
import {
  getAwardSummary,
  getBuyInvestSummary, getRefundSummary, getStackingSummary, getVoidPlans, promotionReload,
} from '../../../redux/actions/userActions';
import TableAward from './components/award/TableAward';
import TablebuyInvest from './components/buyInvest/TablebuyInvest';
import DataTable from './components/DataTable';
import TableRefund from './components/refund/TableRefund';
import TableStacking from './components/staking/TableStacking';
import Tabs from './components/Tabs';



const Promotion = () => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(promotionReload());
    dispatch(getBuyInvestSummary());
    dispatch(getRefundSummary());
    dispatch(getAwardSummary());
    dispatch(getStackingSummary());
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 572) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (

    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody className="plans_tabs">
              <Row className="mb-0">
                <div
                  // className={isMobile ? 'tabs mobile_tabs tabs--bordered-top' 
                  // : 'tabs tabs--justify tabs--bordered-top'}
                  className="tabs tabs--justify tabs--bordered-top"
                >
                  <Tabs activeTab={activeTab} toggle={toggle} />
                </div>
              </Row>
            </CardBody>
            <div className="card-arrow">
              <div className="card-arrow-top-left" />
              <div className="card-arrow-top-right" />
              <div className="card-arrow-bottom-left" />
              <div className="card-arrow-bottom-right" />
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="mt-3">
                {(activeTab !== '6' && activeTab !== '5' && activeTab !== '4' && activeTab !== '3') && <DataTable />}
                {activeTab === '6' && <TableRefund />}
                {activeTab === '5' && <TableAward />}
                {activeTab === '4' && <TablebuyInvest />}
                {activeTab === '3' && <TableStacking />}
              </Row>
            </CardBody>
            <div className="card-arrow">
              <div className="card-arrow-top-left" />
              <div className="card-arrow-top-right" />
              <div className="card-arrow-bottom-left" />
              <div className="card-arrow-bottom-right" />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Promotion;
