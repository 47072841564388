export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_INITIAL_CURRENCY = 'SET_INITIAL_CURRENCY';
export const CLEAR_CURRENCY = 'CLEAR_CURRENCY';


const initialState = {
  ncc: 0,
  tron: 0,
  tether: 0,
};

const currencyReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_CURRENCY:
      return {
        ...state,
        ncc: action.nccPrice,
        tron: action.tronPrice,
        tether: action.tetherPrice,
      };
    case SET_INITIAL_CURRENCY:
      return {
        ...state,
        ncc: Number(action.price.NCC).toFixed(4),
        tron: Number(action.price.TRX).toFixed(4),
        tether: Number(action.price.USDT).toFixed(4),
      };
    case CLEAR_CURRENCY:
      return {
        ncc: 0,
        tron: 0,
        tether: 0,
      };
    default:
      return state;
  }
};

export default currencyReducer;
