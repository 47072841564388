const request = (url, data) => fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': '*',
      },
      credentials: 'same-origin',
      mode: 'cors',
      body: JSON.stringify(data),
    });

export default request;
