import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';

import { useHistory, withRouter, useParams } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import {
  Card, CardBody, CardHeader, CardLink,
} from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import getDeviceInfo from '../../services/getDeviceInfo';

import request from '../../services/fetchRequest';
import { logOut, RefCodeVallid } from '../../redux/actions/userActions';
import { serverbetaURL } from '../../utils/url';
import { showLogs } from '../App/Rules';



const RegisterLink = () => {
  const backImg = `${process.env.PUBLIC_URL}/img/back.png`;
  const logoImg = `${process.env.PUBLIC_URL}/img/logo.png`;
  const styles = {
    backgroundImage: `url(${backImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderColor: '#333',
    backgroundPosition: 'center',
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const [passwordconfirm, setPasswordconfirm] = useState();
  const [agree, setAgree] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [, forceUpdate] = useState();
  const [quality, setQuality] = useState();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [showErrorEmail, setShowErrorEmail] = useState(false);
  const [showErrorEqual, setShowErrorEqual] = useState(false);
  const [showErrorStrong, setShowErrorStrong] = useState(false);

  const [unValidEmail, setUnValidEmail] = useState(false);
  const [unValidPass, setUnValidPass] = useState(false);
  const [unValidConfirmPass, setUnValidConfirmPass] = useState(false);

  const [validEmail, setValidEmail] = useState(false);

  const validatorConfigs = {
    messages: {
      email: 'Must be a valid Gmail address!',
    },
    element: message => <div className="validationErr">{message}</div>,
  };

  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const reset = () => {
    setEmail('');
    setPassword('');
    setPasswordconfirm('');
    validator.current.hideMessages();
    forceUpdate(13);
  };

  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    localStorage.removeItem('user');
    dispatch(logOut());
  }, []);

  const { code } = useParams();


  const sendRequest = () => {
    setLoading(true);
    setDisable(true);
    forceUpdate(1);
    setInterval(() => {
      setDisable(false);
    }, 10000);
  };


  const toLogin = (message) => {
    const tologin = true;
    history.push({
      pathname: '/',
      tologin,
      haveMsg: true,
      msg: message,
    });
  };


  const checkCode = async () => {
    await request(`${serverbetaURL}/auth/refferalcode`, { refCode: code })
      .then(response => response.json())
      .then((data) => {
        if (data.numReq === 0 && data.sttReq === 'noRefCode') {
          toast.error('Your Refferal Link is Not Valid !', {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push({
            pathname: '/',
            haveMsg: true,
            msg: 'unValRefLink',
          });
        } else {
          dispatch(RefCodeVallid());
        }
      })
      // .catch(err => console.log(err));
      .catch(err => null);
  };

  useEffect(() => {
    checkCode();
  }, []);





  const onSubmit = async (e) => {
    e.preventDefault();
    sendRequest();
    const deviceInfo = await getDeviceInfo();

    if (validator.current.allValid() && (email.replaceAll(' ', '').split('@')[1].toLowerCase() === 'gmail.com')) {
      if (quality >= 2 && password === passwordconfirm) {
        setShowErrorEmail(false);
        setShowErrorEqual(false);
        setShowErrorStrong(false);

        axios.post(`${serverbetaURL}/auth/register`, JSON.stringify({
          deviceInfo, email: email.toLowerCase(), password, refCode: code, agree,
        }), {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
          },
        })
          .then((data) => {
            if (showLogs) {
              console.log(data);
            }
            setLoading(false);
            if (data?.status === 200) {
              if (data?.data?.status === false) {
                toast.error(data?.data?.message, {
                  position: 'top-center',
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (data?.data?.status === true) {
                toast.success(data?.data?.message, {
                  position: 'top-center',
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                reset();
              }
            }
          }).catch(() => {
            toast.error('Something went wrong , Try again later !', {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
        validator.current.hideMessages();
      } else if (password !== passwordconfirm) {
        setShowErrorEqual(true);
        setUnValidConfirmPass(true);
        setUnValidPass(true);
        setLoading(false);
      } else if (quality < 2) {
        setShowErrorStrong(true);
        setUnValidConfirmPass(true);
        setUnValidPass(true);
        setLoading(false);
      }
    } else {
      validator.current.showMessages();
      if (validator.current.fieldValid('password')) {
        setUnValidPass(false);
      } else {
        setUnValidPass(true);
      }
      if (validator.current.fieldValid('email')
        && (email.replaceAll(' ', '').split('@')[1].toLowerCase() === 'gmail.com')) {
        setUnValidEmail(false);
        setValidEmail('valid');
      } else {
        setUnValidEmail(true);
        setValidEmail('');
        forceUpdate(13);
      }
      if (validator.current.fieldValid('password confirm')) {
        setUnValidConfirmPass(false);
      } else {
        setUnValidConfirmPass(true);
      }
      forceUpdate(1);
      setLoading(false);
    }
  };


  return (
    <div className="account backLanding" style={styles}>
      <ToastContainer
        position="top-center"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        draggablePercent={30}
      />
      <Card className="landingHolder">
        <CardHeader className="landigHolderHeader text-center">
          <div className="landigHolderHeader_div">
            <img className="logoImage" alt="Financial Factory" src={logoImg} />
            <h2 className="text-center financial_brand">Financial Factory</h2>
          </div>
        </CardHeader>
        <CardBody className="landingCardBody">
          <div className="landingFormHolder text-center">
            <div className="account__card">
              <Card className="loginCard">
                <CardBody>
                  <form onSubmit={onSubmit} method="POST" name="login_form">
                    <h2 className="text-center mb-3">Register</h2>
                    {/* <h4 className="text-center mb-3">{code}</h4> */}
                    <div className="mb-2">
                      <div className="d-flex">
                        <label htmlFor="email" className="form-label">Gmail Address</label>
                      </div>
                      <input
                        type="text"
                        className={unValidEmail
                          ? 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5 unValid'
                          : `form-control form-control-password-login 
                          form-control-lg bg-white bg-opacity-5 ${validEmail}`}
                        name="email"
                        placeholder="Enter your email address"
                        aria-describedby="email"
                        value={email}
                        onBlur={(e) => {
                          setUnValidEmail(false);
                          setValidEmail('');
                          setEmail(e.target.value.replaceAll(' ', ''));
                        }}
                        onChange={async (e) => {
                          await setEmail(e.target.value.replaceAll(' ', ''));
                          if (validator.current.fieldValid('email')
                            && (e.target.value.replaceAll(' ', '').split('@')[1].toLowerCase() === 'gmail.com')) {
                            setUnValidEmail(false);
                            setValidEmail('valid');
                          } else {
                            setUnValidEmail(true);
                            setValidEmail('');
                          }
                          setShowErrorEmail(false);
                        }}
                      />
                      <div className="tefo_error">
                        {validator.current.message('email', email, 'required|email')}
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <label className="form-label" htmlFor="password">Password</label>
                      </div>
                      <div className="input-group flex-nowrap">
                        <input
                          className={unValidPass
                            ? 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5 unValid'
                            : 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5'}
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Enter your password"
                          aria-describedby="password"
                          value={password}
                          onChange={async (e) => {
                            await setPassword(e.target.value);
                            if (validator.current.fieldValid('password')) {
                              setUnValidPass(false);
                            } else {
                              setUnValidPass(true);
                            }
                            setShowErrorEqual(false);
                            setShowErrorStrong(false);
                          }}
                        />
                        <button
                          type="button"
                          className="input-group-text input-group-text-login"
                          id="eye"
                          onClick={e => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <EyeOutlineIcon className="eye_icon" />
                            : <EyeOffOutlineIcon className="eye_icon" />}
                        </button>
                      </div>
                      <div className="tefo_error tefo_error_strong">
                        {validator.current.message('password', password, 'required|min:6')}
                      </div>
                      <PasswordStrengthBar
                        password={password}
                        scoreWords={['too short', 'weak', 'ok', 'good', 'strong']}
                        onChangeScore={(score) => {
                          setQuality(score);
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <div className="d-flex">
                        <label className="form-label" htmlFor="password">Confirm Password</label>
                      </div>
                      <div className="input-group flex-nowrap">
                        <input
                          className={unValidConfirmPass
                            ? 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5 unValid'
                            : 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5'}
                          type={showPasswordConfirm ? 'text' : 'password'}
                          placeholder="Enter your password"
                          aria-describedby="password"
                          value={passwordconfirm}
                          onChange={async (e) => {
                            await setPasswordconfirm(e.target.value);
                            if (validator.current.fieldValid('password confirm')) {
                              setUnValidConfirmPass(false);
                            } else {
                              setUnValidConfirmPass(true);
                            }
                            setShowErrorEqual(false);
                          }}
                        />
                        <button
                          type="button"
                          className="input-group-text input-group-text-login"
                          id="eye"
                          onClick={e => setShowPasswordConfirm(!showPasswordConfirm)}
                        >
                          {showPasswordConfirm ? <EyeOutlineIcon className="eye_icon" />
                            : <EyeOffOutlineIcon className="eye_icon" />}
                        </button>
                      </div>
                      {/* <PasswordStrengthBar
                        password={password}
                        scoreWords={['too short', 'weak', 'ok', 'good', 'strong']}
                        onChangeScore={(score) => {
                          setQuality(score);
                        }}
                      /> */}
                      <div className="tefo_error">
                        {validator.current.message('password confirm', passwordconfirm, 'required|min:6')}
                      </div>
                    </div>
                    {/* <div className="form-check text-left">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={agree}
                        id="agree"
                        onChange={e => setAgree(!agree)}
                      />
                      <label className="form-check-label" htmlFor="defaultCheck1">
                        I Agree to Terms and Conditions
                      </label>
                    </div>
                    <div className="tefo_error">
                      {validator.current.message('agreement', agree, 'accepted')}
                    </div> */}
                    <button
                      type="submit"
                      className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 mt-3 submit_button"
                      disabled={disable ? 'true' : ''}
                    >
                      {loading ? (
                        <div className="spinner-border text-success spinner-border-sm" />
                      ) : 'Register'}
                    </button>
                    Have an account? <CardLink onClick={toLogin} className="landing_sublink">Log In</CardLink>
                  </form>
                  <div className="tefo_error tefo_error_strong mb-2 text-center">
                    {showErrorEmail && 'This email has already been used !'}
                    {/* <br /> */}
                    {showErrorEqual && 'Password and Confirm Password should be equal !'}
                    {/* <br /> */}
                    {showErrorStrong && 'Your password is not strong enough !'}
                  </div>

                </CardBody>
                <div className="card-arrow">
                  <div className="card-arrow-top-left" />
                  <div className="card-arrow-top-right" />
                  <div className="card-arrow-bottom-left" />
                  <div className="card-arrow-bottom-right" />
                </div>
              </Card>
            </div>
          </div>
        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left landingHolder-topleft" />
          <div className="card-arrow-top-right landingHolder-topright" />
          <div className="card-arrow-bottom-left landingHolder-bottomleft" />
          <div className="card-arrow-bottom-right landingHolder-bottomright" />
        </div>
      </Card>
    </div>
  );
};

// RegisterLink.propTypes = {
//   history: PropTypes.string.isRequired,
// };

export default withRouter(connect()(RegisterLink));
