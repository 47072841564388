import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, Pie, PieChart,
} from 'recharts';
import { showLogs } from '../../../../App/Rules';
import getTooltipStyles from '@/shared/helpers';
import { getMainColor } from '../../../../../shared/helpers/themHelper';
import HistoryBuyInvest from './buyinvest-report/HistoyBuyInvest';
import { getHistoryBuyInvest } from '../../../../../redux/actions/userActions';


const ReportsBuyInvest = ({
  FFuser,
  haveSecPass,
  havePlans,
  balanceWallet,
  voidPlans,
  currencies,
  plans,
  plancrypto,
  depositWallet,
  ncc,
  tron,
  dir,
  theme,
  buyInvestReports,
}) => {
  const [fillcolor, setFillcolor] = useState('#3cd2a5');
  const [percent, setPercent] = useState(0);

  const [showFullModal, setShowFullModal] = useState();
  const toggleFull = () => {
    setShowFullModal(pre => !pre);
  };

  const dispatch = useDispatch();



  // const data = [
  //   { sell: 'Jan', amount: 0 },
  //   { sell: 'Feb', amount: 0 },
  //   { sell: 'Mar', amount: 0 },
  //   { sell: 'Apr', amount: 0 },
  //   { sell: 'May', amount: 0 },
  //   { sell: 'Jun', amount: 0 },
  //   { sell: 'Jul', amount: 0 },
  //   { sell: 'Aug', amount: 0 },
  //   { sell: 'Sep', amount: 0 },
  //   { sell: 'Oct', amount: 0 },
  //   { sell: 'Nov', amount: 0 },
  //   { sell: 'Dec', amount: 0 },
  // ];


  const data = [
    {
      sell: 'Jan',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary

        ?.find(m => m.month === 'January')?.sumAmount)) || 0,
    },
    {
      sell: 'Feb',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'February')?.sumAmount)) || 0,
    },
    {
      sell: 'Mar',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'March')?.sumAmount)) || 0,
    },
    {
      sell: 'Apr',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'April')?.sumAmount)) || 0,
    },
    {
      sell: 'May',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'May')?.sumAmount)) || 0,
    },
    {
      sell: 'Jun',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'June')?.sumAmount)) || 0,
    },
    {
      sell: 'Jul',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'July')?.sumAmount)) || 0,
    },
    {
      sell: 'Aug',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'August')?.sumAmount)) || 0,
    },
    {
      sell: 'Sep',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'September')?.sumAmount)) || 0,
    },
    {
      sell: 'Oct',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'October')?.sumAmount)) || 0,
    },
    {
      sell: 'Nov',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'November')?.sumAmount)) || 0,
    },
    {
      sell: 'Dec',
      amount: Math.floor(Number(buyInvestReports?.yearlySummary
        ?.find(m => m.month === 'December')?.sumAmount)) || 0,
    },
  ];


  const pieData = [
    { value: percent, fill: fillcolor },
    { value: 100 - percent, fill: '#f2f4f7' },
  ];


  useEffect(() => {
    setFillcolor(getMainColor());
  }, [theme]);


  useEffect(() => {
    setPercent(buyInvestReports?.MemberPercent?.percent);
    // setPercent(0);
  }, [buyInvestReports]);


  return (

    <>

      <Modal
        isOpen={showFullModal}
        toggle={toggleFull}
        modalClassName="ltr-support"
        className="modal-tree"
      >
        <div className="text-right">
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              toggleFull();
            }}
          >
            <i className="fas fa-close" />
          </button>
        </div>
        <HistoryBuyInvest />
      </Modal>


      <Card>
        <CardHeader className="text-center">
          <h4>Buy Invest</h4>
        </CardHeader>
        <form>
          <CardBody>
            <h6 className="text-center">
              Summary {(new Date()).getFullYear()} :
            </h6>
            <ResponsiveContainer className="text-center m-auto report_chart" height={170} width="100%">
              <BarChart data={data} margin={{ top: 10, left: -20 }}>
                <Tooltip
                  itemStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  labelStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  contentStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  {...getTooltipStyles('defaultItems')}
                />
                <XAxis dataKey="sell" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="amount" name="sell" className="desk_ref_chart_bar" barSize={8} />
              </BarChart>
            </ResponsiveContainer>

            <h6 className="mt-3 mb-0"> {(new Date()).toLocaleString('default', { month: 'long' })} : </h6>

            <div className="report_title">Sales volume</div>
            <div className="report_data_holder">
              <div className="report_data_item">
                <div className="report_data_label">
                  USDT
                </div>
                <div className="report_data_value">
                  {/* - */}
                  {buyInvestReports?.salesVolume ? Number(buyInvestReports?.salesVolume)?.toFixed(2) : 0}
                </div>
              </div>
            </div>

            <div className="report_title">Total Profits</div>
            <div className="report_data_holder">
              <div className="report_data_item">
                <div className="report_data_label">
                  USDT
                </div>
                <div className="report_data_value">
                  {/* - */}
                  {buyInvestReports?.salesProfit ? Number(buyInvestReports?.salesProfit)?.toFixed(2) : 0}
                </div>
              </div>
            </div>

            <div className="report_chart_holder">
              <PieChart className="m-auto report_piechart" height={250} width={300}>
                <Pie
                  data={pieData}
                  dataKey="value"
                  cx={130}
                  cy={140}
                  startAngle={270}
                  endAngle={-90}
                  innerRadius={72}
                  outerRadius={80}
                  paddingAngle={1.5}
                />
              </PieChart>
              <p className="report_chart_label">
                {Math.floor(buyInvestReports?.MemberPercent?.percent)} %
                {/* 0 % */}
                <br />
                {/* <hr className="report_seperator" /> */}
                <span className="report_chart_member">
                  {buyInvestReports?.MemberPercent?.member} Member
                  {/* 0 Member */}
                </span>
              </p>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn btn-outline-theme table-btn calc_table_refund_btn"
                onClick={() => {
                  toggleFull();
                  dispatch(getHistoryBuyInvest());
                }}
                // disabled
              >
                History
              </button>
            </div>


          </CardBody>
        </form>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};

ReportsBuyInvest.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  voidPlans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  plans: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tron: PropTypes.string.isRequired,
  ncc: PropTypes.string.isRequired,
  buyInvestReports: PropTypes.objectOf.isRequired,
  dir: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo,
    constants,
    currency,
    theme,
  } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    havePlans: userInfo?.user?.havePlans,
    balanceWallet: userInfo?.user?.balanceWallet,
    depositWallet: userInfo?.user?.depositWallet,
    haveSecPass: userInfo?.user?.haveSecPass,
    voidPlans: userInfo?.user?.voidPlans,
    plancrypto: constants?.plancrypto,
    plans: constants?.plans,
    currencies: constants?.currencies,
    tron: currency.tron,
    ncc: currency.ncc,
    buyInvestReports: userInfo.user?.reportPlans?.buyInvest,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(ReportsBuyInvest);
