import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const CarouselSingle = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // swipeToSlide: true,
    // rtl: rtl.direction === 'rtl',
  };

  return (
    <Slider
      pauseOnHover={false}
      pauseOnDotsHover={false}
      pauseOnFocus={false}
      vertical
      verticalSwiping
      // fade
      easing
      className="slick-slider--single"
      {...settings}
    >
      {children}
    </Slider>
  );
};

CarouselSingle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(state => ({
  rtl: state.rtl,
}))(CarouselSingle);
