import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
// import PropTypes from 'prop-types';
import TopbarMenuLink from './TopbarMenuLink';
import { store } from '../../App/store';
import { logOut } from '../../../redux/actions/userActions';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants } from '../../../redux/actions/constantActions';


const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logOut());
    dispatch(clearCurrency());
    dispatch(clearConstants());
  };


  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggleProfile}>
        <p className="topbar__avatar-name">
          <p className="topbar__id">
            {store.getState().userInfo.user.FFuser.FFCode}
          </p>
          {store.getState().userInfo.user.FFuser.email}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {
        isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__back"
            onClick={toggleProfile}
          />
        )
      }
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu profileTop">
          <div className="topbar_user_in_collapse">
            <p className="text-center">
              <p className="topbar__id">
                {store.getState().userInfo.user.FFuser.FFCode}
              </p>
              {store.getState().userInfo.user.FFuser.email}
            </p>
          </div>
          <TopbarMenuLink
            title="Profile"
            icon="inbox"
            path="/Profile"
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink title="Log Out" icon="exit" path="/" onClick={handleLogout} />
        </div>
      </Collapse>
    </div>
  );
};


export default connect()(TopbarProfile);

