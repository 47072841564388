import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Row, Container, Col,
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import VectorMap from './components/VectorMap';


import RefferalBar from './components/RefferalBar';
import { store } from '../../App/store';
import DeskTopMessage from './components/DeskTopMessage';
import ReffDesktop from './components/ReffDesktop';
import PlansDesktop from './components/PlansDesktop';
import WalletDesktop from './components/WalletDesktop';
import Levels from './components/Levels';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants, getBannersUrl } from '../../../redux/actions/constantActions';
import {
  getMesseges, getLevels, getRefferal, getVoidPlans, getReload, logOut, getReportPlans,
} from '../../../redux/actions/userActions';
import MagicDesk from './components/MagicDesk';
import TopSells from './components/TopSells';



const Desktop = ({
  voidPlans, levels, FFuser, Refferal, user,
}) => {
  const dispatch = useDispatch();


  useEffect(() => {
    if (!voidPlans) {
      dispatch(getVoidPlans());
    }
    if (!levels) {
      dispatch(getLevels());
    }
    if (!user?.reportPlans) {
      dispatch(getReportPlans());
    }
    if (!Refferal) {
      dispatch(getRefferal());
    }
    dispatch(getReload());
    dispatch(getBannersUrl());
  }, []);


  return (
    <Container className="dashboard">
      <Row className="desk_msg_no_margin_row">
        <Col md={12}>
          <DeskTopMessage />
        </Col>
      </Row>
      <Row className="">
        <Col md={6}>
          <Row className="desk_lvl_no_margin_row">
            <Col md={12}>
              <Levels />
            </Col>
          </Row>
          {/* {user.isCapital && ( */}
          <Row>
            <Col>
              <TopSells />
            </Col>
          </Row>
          {/* )} */}
          <Col className="desktop_chart_col" md={12}>
            <VectorMap />
          </Col>
        </Col>
        <Col md={6}>
          <Col className="desktop_chart_col ref_chart_desk" md={12}>
            <RefferalBar />
          </Col>
          <Row className="row_ref_desk">
            <Col md={6} className="desk_ref_no_margin_col">
              <Row>
                <Col md={12}>
                  <ReffDesktop />
                  {/* <MagicDesk /> */}
                </Col>
              </Row>
            </Col>
            <Col md={6} className="desk_ref_no_margin_col">
              <Row className="desk_plan_no_margin_row">
                <Col md={12}>
                  <PlansDesktop />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <WalletDesktop />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};


Desktop.propTypes = {
  voidPlans: PropTypes.objectOf.isRequired,
  levels: PropTypes.objectOf.isRequired,
  Refferal: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    user: userInfo?.user,
    voidPlans: userInfo?.user?.voidPlans,
    levels: userInfo?.user?.levels,
    Refferal: userInfo?.user?.Refferal,
  };
}

export default withRouter(connect(mapStateToProps)(Desktop));

