import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { profileImgURL } from '../../../../../utils/url';
import { getAvatarPlaceHolder, getMainColor, getStarImgUrl } from '../../../../../shared/helpers/themHelper';

const NftReport = ({
  levels,
  FFuser,
  binarySummary,
  theme,
  awardSummary,
}) => {
  const [fillcolor, setFillcolor] = useState('#3cd2a5');

  const [emptyItems, setEmptyItems] = useState();

  const dispatch = useDispatch();

  const history = useHistory();


  useEffect(() => {
    setEmptyItems(5 - (binarySummary?.members?.length || 0));
  }, [binarySummary]);

  useEffect(() => {
    setFillcolor(getMainColor());
  }, [theme]);

  useEffect(() => {
    // dispatch(getBinarySummaryProfit());
  }, []);

  const goToReportTree = () => {
    history.push({
      pathname: '/Reports',
      haveAct: true,
      act: 'showBinary',
    });
  };

  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [second, setSecond] = useState('00');


  const secondsToTime = async (secs) => {
    if (secs > 0) {
      const hours = Math.floor(secs / (60 * 60));
      await setHour(hours < 10 ? `0${hours}` : hours);

      const divisorForMinutes = secs % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);
      await setMinute(minutes < 10 ? `0${minutes}` : minutes);

      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.ceil(divisorForSeconds === 0 ? 59 : divisorForSeconds);
      await setSecond(seconds < 10 ? `0${seconds - 1}` : seconds - 1);
    } else {
      await setHour(0);
      await setMinute(0);
      await setSecond(0);
    }
  };

  useEffect(async () => {
    clearInterval();
    if (awardSummary[0]) {
      setInterval(() => {
        secondsToTime((1699999999999 - new Date().getTime()) / 1000);
      }, 1000);
    } else {
      setHour(0);
      setMinute(0);
      setSecond(0);
    }
  }, [awardSummary]);
  return (
    <>

      <div className="text-center">
        <div className="ar_time_remain mb-2">
          <div className="nft_timer">
            <span className="nft_timer_value">
              {/* {hour} */}
              0
            </span>
            {' '}
            :
            {' '}
            <span className="nft_timer_value">
              {/* {minute} */}
              0
            </span>
            {' '}
            :
            {' '}
            <span className="nft_timer_value">
              {/* {second} */}
              0
            </span>
          </div>
          <div className="nft_label">
            <span className="nft_timer_label">
              Hour
            </span>
            {' '}

            {' '}
            <span className="nft_timer_label">
              Minute
            </span>
            {' '}

            {' '}
            <span className="nft_timer_label">
              Second
            </span>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <div className="sellers">
          {binarySummary?.members?.slice(0, 5)?.map((item, i) => (
            <div className="seller_container seller_not_more">
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><i /></div>
              <div className="topsell_loader"><i /></div>
              <div className="seller_image_div">
                <img
                  src={item?.userImage ? `${profileImgURL}/${item?.userImage}`
                    : getAvatarPlaceHolder()}
                  alt="seller"
                />
              </div>
              <div className="seller_value">
                {Math.floor(item?.amount)} $
              </div>
              <div className="seller_star">
                <div className="binary_item_score">
                  {[...Array(Math.floor(item?.amount / 1000))]?.slice(0, 5)?.map((itemStar, ii) => (
                    <>
                      <img src={getStarImgUrl()} alt="" />
                    </>
                  ))}
                </div>
              </div>
              <div className="seller_code">
                {item?.nickname || item?.FFCode}
              </div>
            </div>
          ))}

          {[...Array(emptyItems)]?.map((item, i) => (
            <div className="seller_container deactive_seller seller_not_more">
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><span /></div>
              <div className="topsell_loader"><i /></div>
              <div className="topsell_loader"><i /></div>
              <div className="seller_image_div">
                <img src={getAvatarPlaceHolder()} alt="seller" />
              </div>
              <div className="seller_value">
                - - -
              </div>
              <div className="seller_code">
                - - -
              </div>
            </div>
          ))}

          {/* <div className="seller_container">
            <div className="topsell_loader"><span /></div>
            <div className="topsell_loader"><span /></div>
            <div className="topsell_loader"><i /></div>
            <div className="topsell_loader"><i /></div>
            <div className="show_more_desk_sale">
              <button
                type="button"
                className="show_more_desk_sale_btn"
                onClick={() => goToReportTree()}
              >
                More ...
              </button>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row m-0 nft_profits">
        <div className="col-12 col-md-6">
          Direct Profit : {' '}
          <span>0 $</span>
        </div>
        <div className="col-12 col-md-6">
          Network Profit : {' '}
          <span>0 $</span>
        </div>
      </div>

    </>
  );
};

NftReport.propTypes = {
  levels: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  binarySummary: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
  awardSummary: PropTypes.objectOf.isRequired,

};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    levels: userInfo.user?.levels,
    FFuser: userInfo.user?.FFuser,
    binarySummary: userInfo.user?.binarySummary,
    theme: theme?.className,
    awardSummary: userInfo.user?.awardSummary,
  };
}


export default connect(mapStateToProps)(NftReport);

