import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import iran from './iran';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { store } from '../../../App/store';
import { setFFuser } from '../../../../redux/actions/userActions';
import { serverbetaURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';
import axiosHeaders from '../../../../utils/axiosHelper';


const PersonalInfo = ({ FFuser }) => {
  const GendersList = store.getState().constants.constants[2].constants;
  const CountryList = store.getState().constants.constants[5].constants;
  const EduList = store.getState().constants.constants[3].constants;
  const JobsList = store.getState().constants.constants[4].constants;
  const CityList = store.getState().constants.constants[6].constants;


  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [NickName, setNickName] = useState();
  const [Birthday, setBirthday] = useState();
  const [Gender, setGender] = useState(GendersList[0].id);
  const [Country, setCountry] = useState();
  const [city, setCity] = useState();
  const [Mobile, setMobile] = useState();
  const [Education, setEducation] = useState(EduList[0].id);
  const [Job, setJob] = useState(JobsList[0].id);


  const [inRequest, setInRequest] = useState(false);

  const [nickErr, setNickErr] = useState(false);

  const [code, setCode] = useState();
  const [isIran, setIsIran] = useState(false);
  const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  const validatorConfigs = {
    element: message => <div className="tefo_error">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const reset = () => {
    setFirstName('');
    setLastName('');
    setNickName('');
    setBirthday('');
    setGender('');
    setCountry('');
    setCity('');
    setMobile('');
    setEducation('');
    setJob('');
  };
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [infoStatus, setInfoStatus] = useState(0);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `( ${phoneNumber.slice(0, 3)} )  ${phoneNumber.slice(3)}`;
    }
    return `( ${phoneNumber.slice(0, 3)} )  ${phoneNumber.slice(
      3, 6,
    )}-${phoneNumber.slice(6, 8)}${phoneNumber.slice(8, 10)}`;
  };


  useEffect(() => {
    if ((FFuser?.isValidInfo === 0) || (FFuser?.isValidInfo === null)) {
      setInfoStatus(0);
    } else if (FFuser?.isValidInfo === 2) {
      if (FFuser.countryId === 120) {
        setIsIran(true);
      }
      setInfoStatus('2');
      setFirstName(FFuser.firstName);
      setLastName(FFuser.lastName);
      setNickName(FFuser.nickname);
      setBirthday(FFuser.birthday);
      setGender(GendersList.find(entry => entry.id === FFuser.genderId)?.name);
      setCountry(CountryList.find(entry => entry.id === FFuser.countryId)?.name);
      setCity(CityList.find(entry => entry.id === FFuser.cityId)?.name);
      setMobile(FFuser.phone);
      setEducation(EduList.find(entry => entry.id === FFuser.educationId)?.name);
      setJob(JobsList.find(entry => entry.id === FFuser.jobId)?.name);
      forceUpdate(13);
    } else if (FFuser?.isValidInfo === 1) {
      if (FFuser.countryId === 120) {
        setIsIran(true);
      }
      setInfoStatus('1');
      setFirstName(FFuser.firstName);
      setLastName(FFuser.lastName);
      setNickName(FFuser.nickname);
      setBirthday(FFuser.birthday);
      setGender(GendersList.find(entry => entry.id === FFuser.genderId)?.name);
      setCountry(CountryList.find(entry => entry.id === FFuser.countryId)?.name);
      setCity(CityList.find(entry => entry.id === FFuser.cityId)?.name);
      setMobile(FFuser.phone);
      setEducation(EduList.find(entry => entry.id === FFuser.educationId)?.name);
      setJob(JobsList.find(entry => entry.id === FFuser.jobId)?.name);
      forceUpdate(13);
    } else if (FFuser?.isValidInfo === 3) {
      if (FFuser.countryId === 120) {
        setIsIran(true);
      }
      setInfoStatus('3');
      setFirstName(FFuser.firstName);
      setLastName(FFuser.lastName);
      setNickName(FFuser.nickname);
      setBirthday(FFuser.birthday);
      setGender(GendersList.find(entry => entry.id === FFuser.genderId)?.id);
      setCountry(CountryList.find(entry => entry.id === FFuser.countryId)?.id);
      setCity(CityList.find(entry => entry.id === FFuser.cityId)?.id);
      const oldCode = CountryList.find(entry => entry.id === FFuser.countryId)?.description?.split(/-(.*)/s)[1].replaceAll('-', '');
      setCode(oldCode);
      setMobile(formatPhoneNumber(FFuser?.phone?.slice(oldCode.length + 2)));
      setEducation(EduList.find(entry => entry.id === FFuser.educationId)?.id);
      setJob(JobsList.find(entry => entry.id === FFuser.jobId)?.id);
      forceUpdate(13);
    }
  }, [FFuser]);



  const handleChangePhone = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setMobile(formattedPhoneNumber);
  };

  const handleCountryCode = (e) => {
    setCountry(e.target.value);
    const speceficCountry = CountryList.find(i => (i.id.toString() === e.target.value));
    setCode(speceficCountry.description.split('-', 3).slice(1));
    if (speceficCountry.id === 120) {
      setCity(CityList[0].id);
      setIsIran(true);
    } else {
      setIsIran(false);
      setCity('');
    }
  };


  const checkNickName = () => {
    if (NickName && NickName !== FFuser?.nickname) {
      axios.post(`${serverbetaURL}/users/checkRequiredNickname`,
        JSON.stringify({ NickName, FFCode: FFuser?.FFCode }), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('checkRequiredNickname :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              setNickErr(true);
            }
            if (data?.data?.status === true) {
              setNickErr(false);
            }
          }
        })
        .catch(() => {
          toast.error('No response from server , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      checkNickName();
    }, 1000);

    return () => clearTimeout(timer);
  }, [NickName]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (FirstName === FFuser.firstName
      && LastName === FFuser.lastName
      && NickName === FFuser.nickname
      && Birthday === FFuser.birthday
      && Number(Gender) === FFuser.genderId
      && Number(Country) === FFuser.countryId
      && `00${code?.toString()?.replace(',', '') || ''}${Mobile.replace(/\s/g, '')
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')}` === FFuser.phone
      && Number(Education) === FFuser.educationId
      && Number(Job) === FFuser.jobId
      && FFuser?.isValidInfo === 3
    ) {
      toast.warn(`You requested us to change your information,
       but you did not change anything ! please check your information again !`, {
        position: 'top-center',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (validator.current.allValid() && !nickErr) {
      toggle();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handlePersonalInfo = async (e) => {
    e.preventDefault();
    if (validator.current.allValid() && !nickErr) {
      const personalInfo = {
        FirstName,
        LastName,
        NickName,
        // Birthday: formatedDate,
        Birthday,
        Gender: Number(Gender),
        Country: Number(Country),
        city: city || '',
        Mobile: `00${code?.toString()?.replace(',', '') || ''}${Mobile.replace(/\s/g, '')
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')}`,
        Education: Number(Education),
        Job: Number(Job),
      };
      if (showLogs) {
        console.log('personal send data :',
          { personalInfo, FFCode: FFuser?.FFCode, token: localStorage.getItem('token') });
      }
      setInRequest(true);
      axios.post(`${serverbetaURL}/users/personalInfo`,
        JSON.stringify({ personalInfo, FFCode: FFuser?.FFCode, token: localStorage.getItem('token') }), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          setInRequest(false);
          if (showLogs) {
            console.log('personalInfo :', data);
          }

          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setInfoStatus('2');
              dispatch(setFFuser(data?.data?.data[0]));
            }
          }
        }).catch((err) => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInRequest(false);
        });
      toggle();
    } else {
      toggle();
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Confirm information</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure about information that you entered ? </h5>
          <br />
          <h5>This action is non-returnable </h5>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={e => handlePersonalInfo(e)}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <div className={infoStatus === '1' ? 'profile_info_done' : 'profile_info'}>
        <h4>
          PERSONAL INFO
        </h4>
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control text-center"
              id="firstName"
              placeholder="Your Firstname"
              value={FirstName?.toLocaleUpperCase()}
              onChange={e => setFirstName(e.target.value.toLocaleUpperCase())}
              disabled={infoStatus === 0 || infoStatus === '3' ? '' : true}
            />
            {validator.current.message('FirstName', FirstName, 'required|alpha_space')}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control text-center"
              id="lastName"
              placeholder="Your Lastname"
              value={LastName?.toLocaleUpperCase()}
              onChange={e => setLastName(e.target.value.toLocaleUpperCase())}
              disabled={infoStatus === 0 || infoStatus === '3' ? '' : true}
            />
            {validator.current.message('LastName', LastName, 'required|alpha_space')}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="nickName">Nick Name</label>
            <input
              type="text"
              className="form-control text-center"
              id="nickName"
              placeholder="Your Nickname"
              value={NickName}
              onChange={e => setNickName(e.target.value.replaceAll(' ', ''))}
              onBlur={() => checkNickName()}
              disabled={infoStatus === 0 || infoStatus === '3' ? '' : true}
            />
            {validator.current.message('nickName', NickName, 'required|min:4,string|max:20,string')}
            {
              nickErr && validator.current.fieldValid('nickName')
                ? (
                  <div className="tefo_error">
                    Nickname already exists. Please try another one
                  </div>
                ) : ''
            }
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="birthday">Birthday</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              // <DatePicker
              //   placeholderText="Select your birthday"
              //   className="form-control text-center date_input"
              //   dateFormat="yyyy-MM-dd"
              //   renderCustomHeader={({
              //     date,
              //     changeYear,
              //     changeMonth,
              //     decreaseMonth,
              //     increaseMonth,
              //     prevMonthButtonDisabled,
              //     nextMonthButtonDisabled,
              //   }) => (
              //     <div
              //       style={{
              //         margin: 10,
              //         display: 'flex',
              //         justifyContent: 'center',
              //       }}
              //     >
              //       <button
              //         type="button"
              //         onClick={decreaseMonth}
              //         disabled={prevMonthButtonDisabled}
              //         className="btn btn-outline-theme mr-0"
              //       >
              //         <i className="fas fa-angle-left" />
              //       </button>
              //       <select
              //         className="form-select"
              //         value={date.getFullYear()}
              //         onChange={({ target: { value } }) => changeYear(value)}
              //       >
              //         {years.map(option => (
              //           <option key={option} value={option}>
              //             {option}
              //           </option>
              //         ))}
              //       </select>
              //       <select
              //         className="form-select"
              //         value={months[date.getMonth()]}
              //         onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              //       >
              //         {months.map(option => (
              //           <option key={option} value={option}>
              //             {option}
              //           </option>
              //         ))}
              //       </select>

              //       <button
              //         type="button"
              //         onClick={increaseMonth}
              //         disabled={nextMonthButtonDisabled}
              //         className="btn btn-outline-theme"
              //       >
              //         <i className="fas fa-angle-right" />
              //       </button>
              //     </div>
              //   )}
              //   selected={Birthday}
              //   onChange={date => setBirthday(date)}
              // />
              <input
                type="date"
                className="form-control text-center"
                id="birthday"
                placeholder="Your birthday"
                value={Birthday}
                onChange={e => setBirthday(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className="form-control text-center"
                id="birthday"
                placeholder="Your birthday"
                value={Birthday}
                onChange={e => setBirthday(e.target.value)}
                disabled
              />
            )}
            {validator.current.message('Birthday', Birthday, 'required')}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="gender">Gender</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              <select
                id="gender"
                className="form-select"
                placeholder="Your Gender"
                value={Gender}
                onClick={e => setGender(e.target.value)}
                onChange={e => setGender(e.target.value)}
              >
                {GendersList.map((item, i) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className="form-control text-center"
                id="gender"
                value={Gender}
                onChange={e => setGender(e.target.value)}
                disabled
              />
            )}
            {validator.current.message('Gender', Gender, 'required')}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="country">Country</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              <select
                id="country"
                className="form-select"
                value={Country}
                onClick={handleCountryCode}
                onChange={handleCountryCode}
              >
                {CountryList.map((item, i) => (
                  <option key={item.description} value={item.id}>{item.name}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className="form-control text-center"
                id="country"
                value={Country}
                onChange={handleCountryCode}
                disabled
              />
            )}
            {validator.current.message('Country', Country, 'required')}
          </div>

          <div className="form-group mb-1">
            {isIran && (infoStatus !== 0 && infoStatus !== '3') ? (
              <>
                <label className="form-label" htmlFor="city">State</label>
                <input
                  type="text"
                  className="form-control text-center"
                  id="city"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  disabled
                />
              </>
            ) : (
              ''
            )}
            {isIran && (infoStatus === 0 || infoStatus === '3') ? (
              <>
                <label className="form-label" htmlFor="city">State</label>
                <select
                  id="city"
                  className="form-select"
                  value={city}
                  onClick={e => setCity(e.target.value)}
                  onChange={e => setCity(e.target.value)}
                >
                  {CityList.map((item, i) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
                {validator.current.message('city', city, 'required')}
              </>
            ) : (
              // <label className="form-label" htmlFor="city">City</label>
              // <input
              //   type="text"
              //   className="form-control text-center"
              //   id="city"
              //   placeholder="Select your city you live now"
              //   value={city}
              //   onChange={e => setCity(e.target.value)}
              //   disabled
              // />
              ''
            )}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="mobileNo">Mobile</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              <div className="input-group flex-nowrap">
                <span className="input-group-text" id="addon-wrapping">{code ? '+' : '-'}{code}</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="- - -  - - -  - -  - -"
                  value={Mobile}
                  onChange={e => handleChangePhone(e)}
                />
              </div>
            ) : (
              <input
                type="text"
                className="form-control text-center"
                value={Mobile}
                onChange={e => handleChangePhone(e)}
                disabled
              />
            )}
            {validator.current.message('mobile number', Mobile, 'required')}
          </div>
          <div className="form-group mb-1">
            <label className="form-label" htmlFor="education">Education</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              <select
                id="education"
                className="form-select"
                value={Education}
                onClick={e => setEducation(e.target.value)}
                onChange={e => setEducation(e.target.value)}
              >
                {EduList.map((item, i) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className="form-control text-center"
                value={Education}
                onChange={e => setEducation(e.target.value)}
                disabled
              />
            )}
            {validator.current.message('Education', Education, 'required')}
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="job">Job</label>
            {infoStatus === 0 || infoStatus === '3' ? (
              <select
                id="job"
                className="form-select"
                value={Job}
                onClick={e => setJob(e.target.value)}
                onChange={e => setJob(e.target.value)}
              >
                {JobsList.map((item, i) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className="form-control text-center"
                value={Job}
                onChange={e => setJob(e.target.value)}
                disabled
              />
            )}

            {validator.current.message('Job', Job, 'required')}
          </div>
          <div className="form-group mb-1 text-center">
            <button
              type={infoStatus === 0 || infoStatus === '3' ? 'submit' : 'button'}
              className="btn btn-outline-theme tefo_buttons_md w-100 m-0"
              disabled={(infoStatus === 0 || infoStatus === '3') && !inRequest ? '' : true}
            >
              {(infoStatus === 0 || infoStatus === '3') && !inRequest ? 'Submit' : ''}
              {infoStatus === '2' ? 'Verifying...' : ''}
              {infoStatus === '1' ? 'Verified ✔️' : ''}
              {inRequest ? 'Sending Your Information ...' : ''}
            </button>
            {infoStatus === '1' ? (
              <div className="personal_info_message">Your Information Confirmed By Admin</div>
            ) : ''}
            {infoStatus === '2' ? (
              <div className="personal_info_message">Your information is being verified by the admin...</div>
            ) : ''}
          </div>
        </form>
      </div>
    </>

  );
};

PersonalInfo.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo } = state;
  return { FFuser: userInfo.user?.FFuser };
}

export default connect(mapStateToProps)(PersonalInfo);
