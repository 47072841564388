import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateData';

const TableStacking = ({ StackingSummary }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [StackingSummary]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

TableStacking.propTypes = {
  StackingSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { StackingSummary: userInfo.user?.StackingSummary };
}


export default connect(mapStateToProps)(TableStacking);
