import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { Card, CardBody, CardLink } from 'reactstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import getDeviceInfo from '../../../services/getDeviceInfo';


import { CodeRequest, logOut } from '../../../redux/actions/userActions';
import request from '../../../services/fetchRequest';
import { serverbetaURL } from '../../../utils/url';
import { showLogs } from '../../App/Rules';
import { getBannersUrl } from '../../../redux/actions/constantActions';

const Login = ({
  forgetPass, afterSubmit, ffcode, toRegister,
}) => {
  const [FFCode, setFFCode] = useState(ffcode);
  const [password, setPassword] = useState();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [showErrorFF, setShowErrorFF] = useState(false);
  const [showSuspendFF, setShowSuspendFF] = useState(false);

  const [unValidFFCode, setUnValidFFCode] = useState(false);
  const [unValidPass, setUnValidPass] = useState(false);

  const [validFF, setValidFF] = useState(false);
  const [validPass, setValidPass] = useState(false);



  const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.clear();
    dispatch(logOut());
  }, []);



  const validator = useRef(new SimpleReactValidator());

  const reset = () => {
    setFFCode('');
    setPassword('');
  };

  const sendRequest = () => {
    setLoading(true);
    setDisable(true);
    forceUpdate(1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    sendRequest();
    const deviceInfo = await getDeviceInfo();

    if (validator.current.allValid()) {
      setShowErrorFF(false);
      setShowSuspendFF(false);
      const loginData = { deviceInfo, FFCode, password };
      setDisable(true);
      setLoading(true);
      axios.post(`${serverbetaURL}/auth/login`, JSON.stringify(loginData), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('login :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setDisable(false);
            setLoading(false);
            if (data?.data?.status === true) {
              dispatch(getBannersUrl());
              dispatch(CodeRequest());
              localStorage.setItem('tempToken', data?.data?.tempToken);
              localStorage.setItem('FFCode', data?.data?.data[0]?.FFCode);
              afterSubmit();
              setLoading(false);
              setDisable(false);
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch(() => {
          toast.error('No response from server , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          setDisable(false);
          reset();
        });
    } else {
      validator.current.showMessages();
      if (validator.current.fieldValid('ff Code')) {
        setUnValidFFCode(false);
      } else {
        setUnValidFFCode(true);
      }
      if (validator.current.fieldValid('password')) {
        setUnValidPass(false);
      } else {
        setUnValidPass(true);
      }
      forceUpdate(1);
      setLoading(false);
      setDisable(false);
    }
  };

  return (
    <div className="account__card">
      <Card className="loginCard">
        <CardBody>

          <form onSubmit={onSubmit} method="POST" name="login_form">
            <h2 className="text-center mb-3">Log In</h2>
            {/* <div className="text-white text-opacity-50 text-center mb-4">
              1
            </div> */}
            <div className="mb-3">
              <div className="d-flex">
                <label htmlFor="FFCode" className="form-label">FF Code</label>
              </div>
              <input
                type="text"
                className={unValidFFCode
                  ? 'form-control form-control-lg bg-white bg-opacity-5 unValid'
                  : `form-control form-control-lg bg-white bg-opacity-5 ${validFF}`}
                name="FFCode"
                placeholder="Enter your FF Code"
                aria-describedby="FFCode"
                value={FFCode}
                onBlur={() => {
                  setUnValidFFCode(false);
                  setValidFF('');
                }}
                onChange={async (e) => {
                  await setFFCode(e.target.value);
                  if (validator.current.fieldValid('ff Code')) {
                    setUnValidFFCode(false);
                    setValidFF('valid');
                  } else {
                    setUnValidFFCode(true);
                    setValidFF('');
                  }
                }}
              />
              <div className="tefo_error">
                {validator.current.message('ff Code', FFCode, 'required|numeric|size:8')}
              </div>
            </div>
            <div className="mb-1">
              <div className="d-flex">
                <label className="form-label" htmlFor="password">Password</label>
              </div>
              <div className="input-group flex-nowrap">
                <input
                  className={unValidPass
                    ? 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5 unValid'
                    : 'form-control form-control-password-login form-control-lg bg-white bg-opacity-5'}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Enter your password"
                  aria-describedby="password"
                  value={password}
                  onBlur={() => {
                    setUnValidPass(false);
                    setValidPass('');
                  }}
                  onChange={async (e) => {
                    await setPassword(e.target.value);
                    // if (validator.current.fieldValid('password')) {
                    setUnValidPass(false);
                    setValidPass('valid');
                    // } else {
                    //   setUnValidPass(true);
                    //   setValidPass('');
                    // }
                  }}
                />
                <button
                  type="button"
                  className="input-group-text input-group-text-login"
                  id="eye"
                  onClick={e => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOutlineIcon className="eye_icon" /> : <EyeOffOutlineIcon className="eye_icon" />}
                </button>
              </div>
              <div className="tefo_error">
                {/* {validator.current.message('password', password, 'required|min:6')} */}
              </div>
            </div>
            <div className="mb-3 mt-3">
              <div className="landing_sublink_div">
                <CardLink onClick={forgetPass} className="landing_sublink">Forgot your password?</CardLink>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 submit_button"
              disabled={disable ? 'true' : ''}
            >
              {loading ? (
                <div className="spinner-border text-success spinner-border-sm" />
                // <img className="load_request" alt="" />
              ) : 'Log In'}
            </button>
            <div className="tefo_error text-center">
              {showErrorFF && 'This FF Code or Password is WRONG !'}
              {showSuspendFF && 'Your account has been Suspended !'}
            </div>
          </form>
          {/* <div className="landing_sublink_div">
            <CardLink onClick={toRegister} className="landing_sublink">Register</CardLink>
          </div> */}



        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </div>
  );
};

Login.propTypes = {
  forgetPass: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  toRegister: PropTypes.func.isRequired,
  ffcode: PropTypes.string.isRequired,
};


export default connect()(Login);
