import { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        // Footer: 'Middle age:',
        disableGlobalFilter: true,
        width: 1000,
      },
      {
        Header: 'Kind',
        accessor: 'kind',
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
      },
      {
        Header: 'Currency',
        accessor: 'Currency',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Amount',
        accessor: 'Amount',
        // disableGlobalFilter: true,
      },
      {
        Header: 'FFCode',
        accessor: 'FFCode',
        disableGlobalFilter: true,
      },
      {
        Header: 'FFWage',
        accessor: 'FFWage',
        disableGlobalFilter: true,
        // Footer: (info) => {
        //   const { rows, flatRows } = info;
        //   const totalAge = useMemo(
        //     () => rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
        //     [rows],
        //   );
        //   const age = Math.round(totalAge / flatRows.length);
        //   return <span>{age}</span>;
        // },
      },
      {
        Header: 'Fee',
        accessor: 'Fee',
        disableGlobalFilter: true,
        // forBadge: true,
        // disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        forBadge: true,
        // disableSortBy: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        kind: ['Deposit', 'Withdraw', 'Buy'][Math.floor((Math.random() * 3))],
        date: getRandomDate(new Date(2022, 3, 1), new Date(2015, 3, 1)),
        Currency: ['NNC', 'Tether  ', 'Tron'][Math.floor((Math.random() * 3))],
        Amount: Math.min(100, Math.round(Math.random() * 30) + 20),
        FFCode: Math.round(Math.random() * 3365659516) + 2024541918,
        // FFWage: ['Default Wallet', 'Main Wallet'][Math.floor((Math.random() * 2))],
        // Fee: ['Success', 'Pending', 'Wait for Confirm', 'Unsuccessfull'][Math.floor((Math.random() * 4))],
        Status: ['Success', 'Pending', 'Failed'][Math.floor((Math.random() * 3))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
