import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'reactstrap';
import { Pie, PieChart } from 'recharts';
import RefundTable from './RefundTable';
import { getMainColor } from '../../../../../shared/helpers/themHelper';



const RefundReport = ({
  balanceWallet,
  refundSummary,
  havePlans,
  FFCode,
  isActive,
  plans,
  plancrypto,
  currencies,
  tab,
  currency,
  theme,
}) => {
  const [, forceUpdate] = useState();
  const containerStylesModal = {
    width: '100%',
    height: '92vh',
  };
  const [fillcolor, setFillcolor] = useState('#3cd2a5');
  const commafy = (num) => {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const [totalNcc, setTotalNcc] = useState(Math.round(refundSummary?.active?.Price));
  const [priceNcc, setPriceNcc] = useState(currency?.ncc);
  const [totalUsdt, setTotalUsdt] = useState(Math.round(refundSummary?.active?.sumProfit));

  const [showFullModal, setShowFullModal] = useState();
  const toggleFull = () => {
    setShowFullModal(pre => !pre);
  };


  const level = [
    { value: totalUsdt || 0, fill: fillcolor },
    {
      value: (totalNcc * (priceNcc) * 5) - totalUsdt === 0
        ? 100 : (totalNcc * (priceNcc) * 5) - totalUsdt,
      fill: '#f2f4f7',
    },
  ];

  const levelMore = [{ value: 100, fill: refundSummary?.active?.id ? fillcolor : '#f2f4f7' },
  { value: 0, fill: '#f2f4f7' }];


  useEffect(() => {
    setPriceNcc(Number(refundSummary?.active?.currentPrice || currency?.ncc).toFixed(4));
    if (refundSummary?.active?.id) {
      setTotalNcc(Math.round(refundSummary?.active?.Price));
      setTotalUsdt(Math.round(refundSummary?.active?.sumProfit));
    } else {
      setTotalNcc(0);
      setTotalUsdt(0);
    }
  }, [refundSummary]);


  useEffect(() => {
    setFillcolor(getMainColor());
  }, [theme]);


  return (
    <>
      <Modal
        isOpen={showFullModal}
        toggle={toggleFull}
        modalClassName="ltr-support"
        className="modal-tree"
      >
        <div className="text-right">
          <button
            type="button"
            className="btn btn-outline-theme table-btn mb-1"
            onClick={() => {
              toggleFull();
            }}
          >
            {/* Fullscreen {' '} */}
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="full_tree" style={containerStylesModal}>
          <RefundTable />
        </div>
      </Modal>

      <div className="text-center mt-2">
        <Row className="m-0 refund_report_row">
          <Col md={4}>
            <Row className="m-0">
              <Col md={12} xs={12} className="refund_item_col_info">
                <div className="refund_item">
                  <span className="refund_item_label">
                    Total NCC :
                  </span>
                  <span className="refund_item_value">
                    {commafy(totalNcc || 0)}
                  </span>
                </div>
              </Col>
              <Col md={12} xs={12} className="refund_item_col_info">
                <div className="refund_item">
                  <span className="refund_item_label">
                    Contract Price :
                  </span>
                  <span className="refund_item_value">
                    {priceNcc}
                  </span>
                </div>
              </Col>
              <Col md={12} xs={12} className="refund_item_col_info">
                <div className="refund_item">
                  <span className="refund_item_label">
                    Total Price :
                  </span>
                  <span className="refund_item_value">
                    {commafy(Math.round(totalNcc * priceNcc || 0))} $
                  </span>
                </div>
              </Col>
              <Col md={12} xs={12} className="refund_item_col_btn">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-outline-theme table-btn calc_table_refund_btn"
                    onClick={() => {
                      toggleFull();
                    }}
                  >
                    Calculator
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={8} className="refund_chart_col">
            <div className="refund_chart_holder">
              <PieChart className="m-auto refund_piechart" height={250} width={280}>
                <Pie
                  data={(totalUsdt > totalNcc * (priceNcc) * 5)
                    && totalNcc > 0 ? levelMore : level}
                  dataKey="value"
                  cx={130}
                  cy={140}
                  startAngle={270}
                  endAngle={-90}
                  innerRadius={90}
                  outerRadius={100}
                  paddingAngle={1.5}
                />
                {/* <Pie
                  data={(totalUsdt > totalNcc * (currency?.ncc) * 5)
                    && totalNcc > 0 ? preLevelMore : preLevel}
                  dataKey="value"
                  cx={130}
                  cy={140}
                  startAngle={270}
                  endAngle={-90}
                  innerRadius={70}
                  outerRadius={80}
                  paddingAngle={1.5}
                /> */}
              </PieChart>
              <p className="refund_chart_label">
                {Math.floor(totalUsdt || 0)} $
                <hr className="refund_seperator" />
                {Math.floor(totalNcc * (priceNcc) * 5 || 0)} $
              </p>
            </div>
          </Col>
        </Row>
      </div>

    </>
  );
};

RefundReport.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  refundSummary: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  currency: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, constants, currency, theme,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    refundSummary: userInfo.user?.refundSummary,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    theme: theme.className,
    currency,
  };
}


export default connect(mapStateToProps)(RefundReport);
