// ============================================================================================
// ============================================================================================
// ***************************************
// ***************************************
// *************** URL *******************
// ***************************************
// ***************************************

// Main :
export const serverURL = 'https://api.financialfactory.capital/ff/v1';

// Test :
// export const serverURL = 'https://api.capitalleaders.capital/ff/v1';


// ============================================================================================
// ============================================================================================
// *****************************************
// *****************************************
// ************** Beta URL *****************
// *****************************************
// *****************************************

// Main :
export const serverbetaURL = 'https://api.financialfactory.capital/ff/v2';

// Test :
// export const serverbetaURL = 'https://api.capitalleaders.capital/ff/v2';

// ============================================================================================
// ============================================================================================
// ******************************************
// ******************************************
// *********** Profile Image URL ************
// ******************************************
// ******************************************

// Main :
export const profileImgURL = 'https://api.financialfactory.capital';

// Test :
// export const profileImgURL = 'https://api.capitalleaders.capital';

