import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateData';

const TableAward = ({ awardSummary }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [awardSummary]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

TableAward.propTypes = {
  awardSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { awardSummary: userInfo.user?.awardSummary };
}


export default connect(mapStateToProps)(TableAward);
