import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Button,
  ButtonToolbar,
  Card, CardBody, CardFooter, CardHeader, Modal, UncontrolledTooltip,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import {
  getBalance, getBuyInvestSummary, getOrders, getRefundSummary, setBalance, setOrders, setOrdersRecordWithdraw,
} from '../../../../redux/actions/userActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import useTimer from '../../../../utils/useTimer';
import { store } from '../../../App/store';
import { serverURL, serverbetaURL } from '../../../../utils/url';
import { clearLoadingWithdraw, setLoadingWithdraw } from '../../../../redux/actions/loaderAction';
import NoSecPass from '../../../Layout/NoSecPass';
import { showLogs } from '../../../App/Rules';
import axiosHeaders from '../../../../utils/axiosHelper';



const Withdraw = ({
  FFuser,
  withdrawWallet,
  havePlans,
  balanceWallet,
  haveSecPass,
  withdrawLoad,
  depositWallet,
  planWithdrawSetting,
  BuyInvestSummary,
}) => {
  // const availablePlans = [];

  const [availablePlans, setAvailablePlans] = useState([]);
  // const {
  //   FFuser, withdrawWallet, havePlans, balanceWallet, haveSecPass,
  // } = store.getState().userInfo.user;
  const { currencies, plancrypto } = store.getState().constants;

  const [disable, setDisable] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [noAmount, setNoAmount] = useState(false);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [agree, setAgree] = useState(false);

  const [secPass, setSecPass] = useState('');

  const [pay, setPay] = useState(30);
  const [recieve, setRecieve] = useState(3000);

  const secInput = useRef(null);

  const [plan, setPlan] = useState();
  const [planModal, setPlanModal] = useState();

  const [currency, setCurrencySelect] = useState();
  const [currencyModal, setCurrencySelectModal] = useState();

  const [wallet, setWallet] = useState();
  const [walletModal, setWalletModal] = useState();

  const [amount, setAmount] = useState();

  const [maxAmountCurrency, setMaxAmountCurrency] = useState(20000);
  const [minAmountErr, setMinAmountErr] = useState(false);

  const [minAmount, setMinAmount] = useState(1);
  const [maxAmount, setMaxAmount] = useState(10000);


  const [TronNeed, setTronNeed] = useState(50);
  const [ProfitStonk, setProfitStonk] = useState(10);

  const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  const [resendTime, setResendTime] = useTimer({
    startTime: 0,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    if (validator.current.fieldValid('agreement')
      && (planModal === 'Affiliate')
      && Number(currency) === 1
    ) {
      toggle();
      togglePass();
      setSecPass('');
      setTimeout(() => {
        secInput.current?.focus();
      }, 1000);
      validator.current.hideMessageFor('Security Password');
      forceUpdate(14);
    } else if (!validator.current.fieldValid('agreement')
      && (planModal === 'Affiliate')
      && Number(currency) === 1) {
      validator.current.showMessageFor('agreement');
      forceUpdate(15);
    } else if (!(planModal === 'Affiliate' && Number(currency) === 1)) {
      toggle();
      togglePass();
      setSecPass('');
      setTimeout(() => {
        secInput.current?.focus();
      }, 1000);
      validator.current.hideMessageFor('Security Password');
      forceUpdate(16);
    }
  };

  useEffect(() => {
    setPay(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 3 && f.planId === 2 && f.isPay === 1)?.amount || 30);
    setRecieve(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 1 && f.planId === 2 && f.isPay === 0)?.amount || 3000);
  }, [planWithdrawSetting]);

  useEffect(() => {
    if (!havePlans) {
      // setNotAllowed(true);
      // setNoAmount(true);
    }
    if (!currencies[0]) {
      setNotAllowed(true);
      setNoAmount(true);
    }
    // if (!withdrawWallet[0]) {
    //   setNotAllowed(true);
    //   setNoAmount(true);
    // }
    if (FFuser.isActive !== 1) {
      setNotAllowed(true);
      setNoAmount(true);
    }
    if (FFuser.isValidKYC !== 1) {
      setNotAllowed(true);
      setNoAmount(true);
    }
    if (FFuser.isAllowWithdraw !== 1) {
      setNotAllowed(true);
      setNoAmount(true);
    }
    if (havePlans) {
      if (availablePlans[0]) {
        setPlan(availablePlans[0].idPlan);
        setPlanModal(availablePlans[0].namePlan);
      }
    }
    if (depositWallet) {
      setWallet(depositWallet.address);
      setWalletModal('Deposit Wallet');
    }
    if (currencies[0]) {
      setCurrencySelect(currencies[0]?.id);
      setCurrencySelectModal(currencies[0]?.name);

      setMinAmount(plancrypto.find(cr => cr.ffplanId === 5 && cr.cryptocurrencyId
        === currencies[0].id)?.amount);
      setMaxAmountCurrency(plancrypto.find(cr => cr.ffplanId === 5 && cr.cryptocurrencyId
        === currencies[0].id)?.maxAmount);
      setTronNeed(currencies.find(f => f.id === 2)?.minStock || 50);

      setProfitStonk(plancrypto.find(p => p.cryptocurrencyId === currencies[0].id
        && p.ffplanId === 5)?.profitStonk || 10);

      forceUpdate(13);
      if (balanceWallet) {
        for (let i = 0; i < Object.keys(balanceWallet).length; i += 1) {
          if (Object.keys(balanceWallet)[i] === currencies[0].name) {
            for (let c = 0; c < Object.keys(balanceWallet[Object.keys(balanceWallet)[i]]).length; c += 1) {
              if (Object.keys(Object.values(balanceWallet)[i])[c] === availablePlans[0]?.namePlan?.replace(/ /g, '')) {
                if (Object.values(Object.values(balanceWallet)[i])[c]) {
                  setMaxAmount(Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency));
                  if ((Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency) < minAmount)) {
                    // setNotAllowed(true);
                  }
                  forceUpdate(15);
                } else {
                  // setNoAmount(true);
                  setMaxAmount(0);
                  setAmount('');
                  forceUpdate(16);
                }
              }
            }
            if (havePlans) {
              const tempPlans = [];
              for (let c = 0; c < Object.keys(balanceWallet[Object.keys(balanceWallet)[i]]).length; c += 1) {
                if ((Object.values(Object.values(balanceWallet)[i])[c] > 0)
                  && (Object.values(Object.values(balanceWallet)[i])[c] !== null)) {
                  for (let pl = 0; pl < Object.keys(havePlans)?.length; pl += 1) {
                    if (Object.values(havePlans)[pl]?.namePlan?.replace(/ /g, '')
                      === Object.keys(Object.values(balanceWallet)[i])[c]) {
                      if (Object.values(havePlans)[pl].isActiveUserPlan
                        && Object.values(havePlans)[pl].isActive) {
                        // temp hard code condition
                        // if (Object.keys(balanceWallet)[i] === 'NCC') {
                        if ((Object.values(havePlans)[pl].idPlan === 11 && Object.keys(balanceWallet)[i] === 'USDT')
                          || (
                            (Object.values(havePlans)[pl].idPlan !== 11 && Object.keys(balanceWallet)[i] !== 'USDT')
                          )) {
                          tempPlans.push(Object.values(havePlans)[pl]);
                        }
                        // } else {
                        //   tempPlans.push(Object.values(havePlans)[pl]);
                        // }
                      }
                    }
                    setAvailablePlans(tempPlans);
                    forceUpdate(24);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, []);


  const reset = () => {
    setAmount('');
    validator.current.hideMessageFor('Amount');
    forceUpdate(31);
  };

  useEffect(() => {
    // localStorage.setItem('lastWithdrawReq', Date.parse(new Date()));
    if (localStorage.getItem('lastWithdrawReq')) {
      setResendTime(180 - ((Date.parse(new Date()) - localStorage.getItem('lastWithdrawReq')) / 1000));
      //   dispatch(setLoadingBuy());
      // } else {
      //   dispatch(clearLoadingBuy());
    }
  }, []);

  const handleCheckWithdraw = (e) => {
    e.preventDefault();


    if (validator.current.fieldValid('Amount') && validator.current.fieldValid('plan')) {
      setAgree(false);
      validator.current.hideMessageFor('agreement');
      toggle();
    } else if (!validator.current.fieldValid('Amount')) {
      validator.current.showMessageFor('Amount');
      // setAmount('');
      setUnValid(true);
      forceUpdate(17);
    } else if (!validator.current.fieldValid('plan')) {
      // setAmount('');
      // setUnValid(true);
      validator.current.showMessageFor('plan');
      forceUpdate(14);
    }
  };

  const checkInputValue = async (e) => {
    if (!havePlans) {
      // setNotAllowed(true);
    }
    if (!withdrawWallet[0]) {
      // setNotAllowed(true);
    }
    await setAmount(e.target.value);
    if (validator.current.fieldValid('Amount') && !Number.isNaN(+e.target.value)) {
      validator.current.hideMessages();
      setUnValid(false);
      setValid('valid');
    } else if (Number.isNaN(+e.target.value)) {
      setAmount('');
      setValid('');
      setUnValid(true);
      forceUpdate(18);
    } else if (!validator.current.fieldValid('Amount')) {
      validator.current.showMessageFor('Amount');
      setUnValid(true);
      forceUpdate(19);
    }
  };


  const handleWithdraw = () => {
    if (validator.current.fieldValid('Security Password')) {
      if (plan === '91' || plan === '92' || plan === '93' || plan === '12' || plan === '13' || plan === '11') {
        const reqData = {
          cryptoCurrencyId: Number(currency),
          planId: (plan === '91' || plan === '92' || plan === '93') ? 9 : Number(plan),
          transactionType: plan.substring(1),
          amount: Number(amount),
          FFCode: FFuser.FFCode,
          walletAddress: wallet,
          token: localStorage.getItem('token'),
          secPass,
        };
        if (showLogs) {
          console.log('Withdraw reqData v2 :', reqData);
        }
        localStorage.setItem('lastWithdrawReq', new Date().getTime());
        dispatch(setLoadingWithdraw());
        setDisable(true);
        setResendTime(180);

        const current = new Date();
        const pendDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}T
            ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
        dispatch(setOrdersRecordWithdraw({
          kindId: 10,
          updated_at: pendDate,
          cryptocurrencyId: Number(currency),
          amount: Number(amount),
          ffplanId: Number(plan),
          walletId: withdrawWallet.find(wl => wl.address === wallet)
            ? withdrawWallet.find(wl => wl.address === wallet).id : depositWallet?.id,
          stateId: 299,
        }));
        reset();
        validator.current.hideMessages();
        window.scrollTo(0, document.body.scrollHeight);
        axios.post(`${serverbetaURL}/monetaries/manualWithdraw`, JSON.stringify(reqData), {
          headers: axiosHeaders(),
        })
          .then((data) => {
            if (showLogs) {
              console.log('manualWithdraw :', data);
            }
            dispatch(clearLoadingWithdraw());
            if (plan === '12') {
              dispatch(getRefundSummary());
            } else {
              dispatch(getBuyInvestSummary());
            }
            setDisable(false);
            setResendTime(0);
            if (data?.status === 200) {
              if (data?.data?.status === false) {
                toast.error(data?.data?.message, {
                  position: 'top-center',
                  autoClose: 15000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (data?.data?.status === true) {
                toast.success(data?.data?.message, {
                  position: 'top-center',
                  autoClose: 15000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          })
          .catch((err) => {
            dispatch(clearLoadingWithdraw());
            dispatch(getBuyInvestSummary());
            setDisable(false);
            setResendTime(0);
            console.log(err);
            if (err.response) {
              console.log(err.response);
            } else if (err.request) {
              console.log(err.request);
            }
          });
        togglePass();
      } else {
        localStorage.setItem('lastWithdrawReq', new Date().getTime());
        dispatch(setLoadingWithdraw());
        const withdrawData = {
          currencyId: Number(currency),
          planId: Number(plan),
          amount: Number(amount),
          FFCode: FFuser.FFCode,
          walletAddress: wallet,
          address: wallet,
          token: localStorage.getItem('token'),
          isAllowWithdraw: agree ? 1 : 0,
          secPass,
        };
        // console.log(withdrawData);
        setDisable(true);
        setResendTime(180);

        const current = new Date();
        const pendDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}T
            ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
        dispatch(setOrdersRecordWithdraw({
          kindId: 10,
          updated_at: pendDate,
          cryptocurrencyId: Number(currency),
          amount: Number(amount),
          ffplanId: Number(plan),
          walletId: withdrawWallet.find(wl => wl.address === wallet)
            ? withdrawWallet.find(wl => wl.address === wallet).id : depositWallet?.id,
          stateId: 299,
        }));
        reset();
        validator.current.hideMessages();
        window.scrollTo(0, document.body.scrollHeight);
        if (showLogs) {
          console.log('Withdraw data v1 :', withdrawData);
        }
        AuthRequest(`${serverURL}/holder/${Number(plan) === 2
          && Number(currency) === 1 ? 'withdrawAffiliete' : 'withdrawPlans'}`,
          withdrawData)
          .then((res) => {
            setDisable(false);
            return res.json();
          })
          .then((data) => {
            setResendTime(0);
            dispatch(clearLoadingWithdraw());
            if (showLogs) {
              console.log(Number(plan) === 2
                && Number(currency) === 1 ? 'withdrawAffiliete :' : 'withdrawPlans :', data);
            }
            if (data.numReq === 1 && data.sttReq === 'ok') {
              toast.success('Your Withdraw has been Done !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // dispatch(setOrders(data.orders));
              dispatch(setBalance(data.Balance));
            } else if (data.numReq === -4 && data.sttReq === 'No TRX for FEE') {
              toast.error('You do not have enough TRX for wage !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -3 && data.sttReq === 'no token in plan') {
              toast.error(`There is no ${currencyModal} currency in ${planModal} Plan !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -2 && data.sttReq === 'noCash') {
              toast.error(`You do not have enough ${currencyModal} in ${planModal} Plan !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -2 && data.sttReq === 'no have cash') {
              toast.error(`You do not have enough ${currencyModal} in ${planModal} Plan !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -2 && data.sttReq === 'no have min cash') {
              toast.error(`You requested amount of ${currencyModal} is less than allowed amount !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -5 && data.sttReq === 'no have plan') {
              toast.error(`You dont have ${planModal} Plan !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === 0 && data.sttReq === 'crypto not active or isWithdraw') {
              toast.error(`You can not withdraw from ${currencyModal} at the moment !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === -5 && data.sttReq === 'no have wallet') {
              toast.error(`You dont have ${walletModal} wallet !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === 0 && data.sttReq === 'have pending order') {
              toast.error('Another withdraw is under proccess, Try again later !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              dispatch(getOrders());
            } else if (data.numReq === 0 && data.sttReq === 'have problem') {
              toast.error('No response from server, Try again later !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === 0 && data.sttReq === 'is min or max') {
              toast.error('Your requested amount is not in available range !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === 0 && data.sttReq === 'stank no cash') {
              toast.error('Server response is not available right now !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (data.numReq === 0 && data.sttReq === 'wrong secPass') {
              toast.error('The entered security password is not true, try again !', {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            dispatch(getOrders());
          }).catch(() => {
            toast.error('Something went wrong , Try again later !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(clearLoadingWithdraw());
            dispatch(getOrders());
            setDisable(false);
            setResendTime(0);
          });
        togglePass();
      }
    } else {
      validator.current.showMessages();
      forceUpdate(30);
    }
  };

  const selectCurrency = async (e) => {
    await setCurrencySelect(e.target.value);
    if (currencies[0]) {
      setMinAmountErr(false);
      const selectedCurrency = currencies.find(entry => entry.id.toString() === e.target.value);
      setCurrencySelectModal(selectedCurrency?.name);

      setMinAmount(plancrypto.find(cr => cr.ffplanId === 5 && cr.cryptocurrencyId
        === selectedCurrency.id)?.amount);
      setMaxAmountCurrency(plancrypto.find(cr => cr.ffplanId === 5 && cr.cryptocurrencyId
        === selectedCurrency.id)?.maxAmount);


      if (balanceWallet) {
        for (let i = 0; i < Object.keys(balanceWallet).length; i += 1) {
          if (Object.keys(balanceWallet)[i] === selectedCurrency.name) {
            for (let c = 0; c < Object.keys(balanceWallet[Object.keys(balanceWallet)[i]]).length; c += 1) {
              if (Object.keys(Object.values(balanceWallet)[i])[c] === planModal?.replace(/ /g, '')) {
                if (Object.values(Object.values(balanceWallet)[i])[c]) {
                  setMaxAmount(Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency));
                  setAmount(Math.floor(Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency)));
                  forceUpdate(20);
                } else {
                  setMaxAmount(0);
                  setAmount('');
                  forceUpdate(21);
                }
              }
            }
            if (havePlans) {
              const tempPlans = [];
              for (let c = 0; c < Object.keys(balanceWallet[Object.keys(balanceWallet)[i]]).length; c += 1) {
                if ((Object.values(Object.values(balanceWallet)[i])[c] > 0)
                  && (Object.values(Object.values(balanceWallet)[i])[c] !== null)) {
                  for (let pl = 0; pl < Object.keys(havePlans).length; pl += 1) {
                    if (Object.values(havePlans)[pl].namePlan.replace(/ /g, '')
                      === Object.keys(Object.values(balanceWallet)[i])[c]) {
                      if (Object.values(havePlans)[pl].isActiveUserPlan
                        && Object.values(havePlans)[pl].isActive) {
                        // temp hard code condition

                        // if (Object.keys(balanceWallet)[i] === 'NCC') {
                        if ((Object.values(havePlans)[pl].idPlan === 11 && Object.keys(balanceWallet)[i] === 'USDT')
                          || (
                            (Object.values(havePlans)[pl].idPlan !== 11 && Object.keys(balanceWallet)[i] !== 'USDT')
                          )) {
                          tempPlans.push(Object.values(havePlans)[pl]);
                        }
                        // } else {
                        // tempPlans.push(Object.values(havePlans)[pl]);
                        // }

                        // end of temp hard code condition
                      }
                    }
                    setAvailablePlans(tempPlans);
                    forceUpdate(22);
                    setPlan('');
                    setAmount('');
                    // setNoAmount(true);
                    validator.current.hideMessageFor('Amount');
                    forceUpdate(23);
                  }
                }
              }
            }
          }
        }
      }
      setTronNeed(currencies.find(f => f.id === 2)?.minStock || 50);

      setProfitStonk(plancrypto.find(p => p.cryptocurrencyId === selectedCurrency.id
        && p.ffplanId === 5)?.profitStonk || 10);
      forceUpdate(13);
    }
  };


  const selectPlan = async (e) => {
    await setPlan(e.target.value);
    if (availablePlans[0]) {
      const selectedPlan = availablePlans.find(entry => entry.idPlan.toString() === e.target.value);
      setPlanModal(selectedPlan?.namePlan);
      if (currencies[0]) {
        const selectedCurrency = currencies.find(entry => entry.id.toString() === currency.toString());
        for (let i = 0; i < Object.keys(balanceWallet).length; i += 1) {
          if (Object.keys(balanceWallet)[i] === selectedCurrency.name) {
            for (let c = 0; c < Object.keys(balanceWallet[Object.keys(balanceWallet)[i]]).length; c += 1) {
              if (Object.keys(Object.values(balanceWallet)[i])[c] === selectedPlan?.namePlan.replace(/ /g, '')) {
                if (Object.values(Object.values(balanceWallet)[i])[c]) {
                  setMaxAmount(Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency));
                  setAmount(Math.floor(Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency)));
                  setNoAmount(false);
                  if ((Math.min(Object.values(Object.values(balanceWallet)[i])[c], maxAmountCurrency) < minAmount)) {
                    // setNotAllowed(true);
                    // setMinAmountErr(true);
                    // setNoAmount(true);
                  } else {
                    setNotAllowed(false);
                    setMinAmountErr(false);
                  }
                  forceUpdate(23);
                } else {
                  setMaxAmount(0);
                  // setNoAmount(true);
                  setAmount('');
                  forceUpdate(24);
                }
              }
            }
          }
        }
      }
    }
  };

  const selectWallet = async (e) => {
    await setWallet(e.target.value);
    if (e.target.value === depositWallet?.address) {
      setWalletModal('Deposit Wallet');
    } else {
      const selectedWallet = withdrawWallet?.find(entry => entry.address === e.target.value);
      setWalletModal(selectedWallet?.name);
    }
  };

  return (
    <>

      <UncontrolledTooltip placement="top" target="withHelp">
        You can transfer any of the currencies in your FF wallet to a wallet pre-registered on the TRC20 network (TRON).
        Please be careful in choosing and correcting your wallet address,
        because if you enter the wrong address,
        the transaction will not be refundable
        and Financial Factor has no obligation for you to enter the wrong address.
        To withdraw the profit of each of the income-generating plans,
        you must choose the type of plan and, if you have sufficient inventory knowledge,
        proceed to withdraw it.
        All transactions include network fees.
      </UncontrolledTooltip>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleWithdraw();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleWithdraw}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Withdraw</h4>
        </div>
        <div className="modal__body">
          {planModal === 'Affiliate' && Number(currency) === 1
            ? (
              <>
                {(amount % recieve === 0
                  ? (amount / recieve) * pay : (Math.floor(amount / recieve) + 1) * pay)
                  <= balanceWallet?.USDT?.Deposit
                  ? (
                    <>
                      <h5>Are you sure ? </h5>
                      <br />
                      <br />
                      <strong>{amount}</strong> {currencyModal} from
                      <strong> {planModal} </strong> plan to <strong>{walletModal}</strong> wallet ?
                      <br />
                      <br />
                    </>
                  ) : (
                    <>
                      <h5>No Enough Tether</h5>
                    </>
                  )}
              </>
            ) : (
              <>
                <h5>Are you sure ? </h5>
                <br />
                <br />
                <strong>{amount}</strong> {currencyModal} from
                <strong> {planModal} </strong> plan to <strong>{walletModal}</strong> wallet ?
                <br />
                <br />
              </>
            )}

          {planModal === 'Affiliate'
            && Number(currency) === 1
            && (amount % recieve === 0
              ? (amount / recieve) * pay : (Math.floor(amount / recieve) + 1) * pay) <= balanceWallet?.USDT?.Deposit

            ? (
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={agree}
                    id="agree"
                    defaultChecked={agree}
                    onChange={e => setAgree(!agree)}
                  />
                  <label className="form-check-label" htmlFor="agree">
                    I agree to pay {
                      amount % recieve === 0 ? (amount / recieve) * pay : (Math.floor(amount / recieve) + 1) * pay
                    } $
                    for withdraw {amount} NCC from {planModal} Plan
                  </label>
                </div>
                <div className="tefo_error">
                  {validator.current.message('agreement', agree, 'accepted')}
                </div>
              </div>
            ) : ''}
        </div>
        {planModal === 'Affiliate' && Number(currency) === 1
          ? (
            <>
              {(amount % recieve === 0
                ? (amount / recieve) * pay : (Math.floor(amount / recieve) + 1) * pay) <= balanceWallet?.USDT?.Deposit
                ? (
                  <div className="tefo_error text-center mt-3 tefo_warning_strong">
                    ** Be sure that your wallet is on the TRC20 network (TRON) **
                  </div>
                ) : (
                  <div className="tefo_error text-center mt-3 tefo_error_strong">
                    ** Sorry your tether deposit balance is NOT enough **
                  </div>
                )}
            </>
          ) : (
            <div className="tefo_error text-center mt-3 tefo_warning_strong">
              ** Be sure that your wallet is on the TRC20 network (TRON) **
            </div>
          )}

        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}

          {planModal === 'Affiliate' && Number(currency) === 1
            ? (
              <>
                {(amount % recieve === 0
                  ? (amount / recieve) * pay : (Math.floor(amount / recieve) + 1) * pay)
                  <= balanceWallet?.USDT?.Deposit
                  ? (
                    <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
                  ) : ''}
              </>
            ) : (
              <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
            )}

        </ButtonToolbar>
      </Modal>

      <Card>
        <CardHeader className="text-center">
          <h4 className="monetary_title">
            Withdraw <i id="withHelp" className="fas fa-info-circle monetary_title_info" />
          </h4>
        </CardHeader>
        <form onSubmit={e => e.preventDefault()}>
          <CardBody className="pb-0 monetary_CardBody">
            <div>
              <label className="form-label" htmlFor="currency">Currency</label>
              <select
                className="form-select text-center"
                id="currency"
                value={currency}
                onClick={e => selectCurrency(e)}
                onChange={e => selectCurrency(e)}
              >
                {currencies && currencies.filter(cw => cw.isWithdraw === 1)
                  .filter(cw => cw.isActive === 1).map((item, i) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className="form-label" htmlFor="plane">Plan</label>
              <select
                className="form-select text-center"
                id="plane"
                value={plan}
                onClick={e => selectPlan(e)}
                onChange={e => selectPlan(e)}
                disabled={balanceWallet?.TRX?.Deposit <= TronNeed ? 'true' : ''}
              >

                {availablePlans && availablePlans.map((item, i) => (
                  <option key={item.idPlan} value={item.idPlan}>
                    {item.namePlan}
                  </option>
                ))}

              </select>
              <div className="tefo_error tefo_error_strong">
                {validator.current.message('plan', plan, 'required')}
              </div>
            </div>
            <div className="">
              <label className="form-label mt-0" htmlFor="Amount">Amount</label>
              <input
                className={unValid ? 'unValid form-control text-center amount_input_space'
                  : `form-control text-center ${valid} amount_input_space`}
                type="text"
                placeholder="Amount"
                value={amount}
                autoComplete="off"
                onChange={e => checkInputValue(e)}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                }}
                onFocus={(e) => {
                  dispatch(getBalance());
                }}
                disabled={noAmount || !(FFuser?.isValidKYC === 1) ? 'true' : ''}
              />
              <div className="tefo_error tefo_error_strong">
                {validator.current.message('Amount', amount,
                  `required|min:${minAmount},num|max:${maxAmount},num|numeric`)}
              </div>
              <div className="tefo_error tefo_error_strong mb-2">
                {minAmountErr
                  && FFuser?.isValidKYC === 1
                  && FFuser?.isAllowWithdraw
                  && `Your ${currencyModal} balance should be at least ${minAmount} to withdraw.`}
              </div>
            </div>
            <div className="">
              <label className="form-label" htmlFor="wallet">Wallet</label>
              <select
                className="form-select text-center"
                id="wallet"
                value={wallet}
                onClick={e => selectWallet(e)}
                onChange={e => selectWallet(e)}
              >
                {depositWallet && (
                  <option key={depositWallet?.address} value={depositWallet?.address}>
                    {'Deposit Wallet'}
                    {' '}- {depositWallet?.address?.slice(0, 3)}
                    ***************
                    {depositWallet?.address?.slice(depositWallet?.address?.length - 4)}
                  </option>
                )}

                {withdrawWallet && withdrawWallet.map((item, i) => (
                  <option key={item.address} value={item.address}>
                    {item.name}
                    {' '}- {item.address?.slice(0, 3)}
                    ***************{item.address?.slice(item.address.length - 4)}
                  </option>
                ))}
              </select>
              {/* {validator.current.message('wallet', wallet, 'required')} */}
            </div>
          </CardBody>
        </form>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
        <CardFooter className="">
          <div className="form-group row">
            <div className="text-center mt-2">
              <button
                type="submit"
                className="btn btn-outline-theme tefo_buttons_lg w-100"
                disabled={disable
                  || notAllowed
                  || !(FFuser.isValidKYC === 1)
                  || !(FFuser.isValidInfo === 1)
                  || (balanceWallet?.TRX?.Deposit <= TronNeed)
                  || withdrawLoad}
                onClick={e => handleCheckWithdraw(e)}
              >
                Withdraw
                {/* {(resendTime >= 0 && disable && availablePlans[0] && withdrawWallet[0])
                  || withdrawLoad
                  ? (
                    <>
                      <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                    </>
                  ) : 'Withdraw'} */}
                {/* {resendTime !== 0 && disable && availablePlans[0] && withdrawWallet[0]
                  ? ` Wait ${resendTime} Second` : ''}
                {resendTime === 0 && !disable ? ' Withdraw' : ''} */}

              </button>
              <div className="mb-2 mt-2">
                <div className="tefo_error tefo_error_strong">
                  {FFuser.isActive
                    && FFuser.isValidKYC !== 0
                    && !FFuser.isAllowWithdraw
                    ? '*Your NOT Allowed to Withdraw right now !' : ''}
                </div>
                {FFuser.isActive
                  && FFuser.isValidKYC === 1
                  && balanceWallet?.TRX?.Deposit >= TronNeed
                  && FFuser.isAllowWithdraw === 1
                  && FFuser.isValidInfo === 3
                  ? (
                    <div className="tefo_error tefo_error_strong">
                      You can withdraw after personal info confirmation
                    </div>
                  ) : ''}
                <div className="tefo_error tefo_error_strong">
                  {resendTime > 0 && withdrawLoad
                    ? (
                      <div className="waiting_msg text-center">
                        {/* <i className="fas fa-exclamation-triangle" /> */}
                        {/* Your Buy Token is doing, */}
                        Please Wait {Math.floor(resendTime)} Seconds ...
                      </div>
                    )
                    : ''}
                  {balanceWallet?.TRX?.Deposit <= TronNeed
                    && FFuser.isActive
                    && TronNeed > 0
                    && FFuser.isValidKYC === 1
                    && FFuser.isValidInfo === 1
                    && FFuser.isAllowWithdraw === 1
                    ? `*Your TRX deposit balance must be at least ${TronNeed} to be able to make a Withdraw` : ''}

                  {/* {(balanceWallet?.TRX?.Deposit >= TronNeed) && (balanceWallet.Tron?.Deposit <= TronNeed )
                    && FFuser.isActive
                    && TronNeed > 0
                    && balanceWallet?.TRX?.Deposit >= 0
                    && FFuser.isValidKYC === 1
                    && FFuser.isValidInfo === 1
                    && FFuser.isAllowWithdraw
                    ? `*This transaction is impossible because 
                    after that Your TRX deposit balance will be less than ${TronNeed} ` : ''} */}
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

Withdraw.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  withdrawWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  withdrawLoad: PropTypes.bool.isRequired,
  planWithdrawSetting: PropTypes.string.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader, constants } = state;
  return {
    FFuser: userInfo.user?.FFuser,
    withdrawWallet: userInfo.user?.withdrawWallet,
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    withdrawLoad: loader.withdrawLoad,
    depositWallet: userInfo.user?.depositWallet,
    planWithdrawSetting: constants?.planWithdrawSetting,
    BuyInvestSummary: userInfo.user?.BuyInvestSummary,
  };
}

export default connect(mapStateToProps)(Withdraw);
