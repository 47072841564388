import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { ThemeProps } from '@/shared/prop-types/ReducerProps';
import ReactTableDnDBody from './ReactTableDnDBody';
import { serverURL } from '../../../../utils/url';
import { setMesseges } from '../../../../redux/actions/userActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { store } from '../../../../containers/App/store';
import { getMainColor } from '../../../helpers/themHelper';

const ReactTableDefaultBody = ({
  page, getTableBodyProps, prepareRow, actionOnRow, BuyInvestSummary, StackingSummary,
}) => (
  <tbody {...getTableBodyProps()}>
    {page.map((row, i) => {
      prepareRow(row);
      let active = false;
      if (
        ((row?.original?.buyInvestId === BuyInvestSummary?.active?.id) && BuyInvestSummary?.active)
        || ((row?.original?.stackingId === StackingSummary?.stage?.id) && StackingSummary?.stage)
      ) {
        active = true;
      }
      return (
        <tr onClick={() => actionOnRow(row.allCells)} {...row.getRowProps()}>
          <td
            style={{ color: active ? getMainColor() : '' }}
          >
            {i + 1}
          </td>
          {row.cells.map(cell => (
            <td
              style={{ color: active ? getMainColor() : '' }}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  actionOnRow: PropTypes.func.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, BuyInvestSummary, StackingSummary,
}) => {
  const dispatch = useDispatch();
  const [messageId, setMessageId] = useState();
  const [messageTitle, setMessageTitle] = useState();
  const [messageDescription, setMessageDescription] = useState();
  const [messageDate, setMessageDate] = useState();
  const [messagMmodal, setMessagMmodal] = useState(false);

  const { FFCode } = store.getState().userInfo.user?.FFuser;

  const openMessagMmodal = (id, title, description, date) => {
    setMessagMmodal(true);
    setMessageId(id);
    setMessageTitle(title);
    setMessageDescription(description);
    setMessageDate(date);
  };
  const closeMessagMmodal = () => {
    setMessagMmodal(false);
    AuthRequest(`${serverURL}/holder/readAnnouncements`, {
      FFCode, announcementId: messageId, token: localStorage.getItem('token'),
    }).then(res => res.json())
      .then((data) => {
        if (data.numReq === 1 && data.sttReq === 'ok') {
          dispatch(setMesseges(data.announcements));
        } else if (data.numReq === 0 && data.sttReq === 'Not exist') {
          toast.error('Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (data.numReq === 0) {
          toast.error('The Proccess have not done, Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }).catch(() => null);
  };
  const toggleMessagMmodal = () => {
    setMessagMmodal(prevState => !prevState);
  };

  const actionOnRow = async (row) => {
    if (row[0]?.row?.original?.msgdtls) {
      const msg = row[0]?.row?.original?.msgdtls;
      openMessagMmodal(
        msg.toString().split('&&')[1],
        msg.toString().split('&&')[2],
        msg.toString().split('&&')[3],
        msg.toString().split('&&')[4],
      );
    } else if (row[0]?.row?.original?.buyInvestId) {
      const active = BuyInvestSummary?.all?.find(bi => bi.id === row[0]?.row?.original?.buyInvestId);
      dispatch({
        type: 'SET_BUYINVEST_SUMMARY',
        active,
        all: BuyInvestSummary?.all,
      });
    } else if (row[0]?.row?.original?.stackingId) {
      if (StackingSummary) {
        const allStacking = [];
        if (StackingSummary?.actives) {
          for (let i = 0; i < StackingSummary?.actives?.length; i += 1) {
            allStacking?.push(StackingSummary?.actives[i]);
          }
        }
        if (StackingSummary?.cancels) {
          for (let i = 0; i < StackingSummary?.cancels?.length; i += 1) {
            allStacking?.push(StackingSummary?.cancels[i]);
          }
        }
        if (StackingSummary?.finishes) {
          for (let j = 0; j < StackingSummary?.finishes?.length; j += 1) {
            allStacking?.push(StackingSummary?.finishes[j]);
          }
        }
        const active = allStacking?.find(st => st.id === row[0]?.row?.original?.stackingId);
        dispatch({
          type: 'SET_STAKING_SUMMARY',
          stage: active,
          active: StackingSummary?.actives,
          profits: StackingSummary?.profits,
          finishes: StackingSummary?.finishes,
          cancels: StackingSummary?.cancels,
        });
      }
    }
  };

  return (
    <>

      <Modal
        isOpen={messagMmodal}
        toggle={toggleMessagMmodal}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{messageTitle}</h4>
          <p> {messageDate?.split('T')[0]}  {messageDate?.split('T')[1]?.split('.')[0]} </p>
        </div>
        <hr />
        <div className="modal__body mb-3 mt-3 text-left">
          <p>{messageDescription} </p>
          {/* <p>{messageId} </p> */}
          {/* <p>{messageDate} </p> */}
          {/* <p className="mt-5"> . </p> */}
          <hr />
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_ok" color="primary" onClick={closeMessagMmodal}>Dismiss</Button>
        </ButtonToolbar>
      </Modal>

      <Fragment>
        {withDragAndDrop
          ? (
            <ReactTableDnDBody
              page={page}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              updateDraggableData={updateDraggableData}
              theme={theme}
            />
          ) : (
            <ReactTableDefaultBody
              page={page}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              actionOnRow={actionOnRow}
              BuyInvestSummary={BuyInvestSummary}
              StackingSummary={StackingSummary}
            />
          )}
      </Fragment>
    </>
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
};

export default connect(state => ({
  theme: state.theme,
  BuyInvestSummary: state.userInfo.user?.BuyInvestSummary,
  StackingSummary: state.userInfo.user?.StackingSummary,
}))(ReactTableBody);
