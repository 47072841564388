import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import SimpleReactValidator from 'simple-react-validator';
import {
  Card, CardBody, CardLink,
} from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import getDeviceInfo from '../../../services/getDeviceInfo';
import request from '../../../services/fetchRequest';
import { logOut } from '../../../redux/actions/userActions';
import { serverURL } from '../../../utils/url';


const Register = ({ toLogin, sentCode }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordconfirm, setPasswordconfirm] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [, forceUpdate] = useState();
  const [quality, setQuality] = useState();
  const [requested, setRequested] = useState(false);

  localStorage.clear();


  const dispatch = useDispatch();

  const sendRequest = () => {
    setRequested(true);
    setInterval(() => {
      setRequested(false);
    }, 5000);
  };

  const { code } = useParams();

  const validatorConfigs = {
    messages: {
      required: 'That feild is required.',
      // default: 'Validation has failed!',
    },
    element: message => <div className="validationErr">{message}</div>,
  };

  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  const onSubmit = async (e) => {
    e.preventDefault();
    const deviceInfo = await getDeviceInfo();
    sendRequest();

    if (validator.current.allValid()) {
      if (quality >= 2 && password === passwordconfirm) {
        request(`${serverURL}/register`, {
          email, password, refCode: sentCode, deviceInfo,
        }).then(response => response.json())
          .then(async (data) => {
            // console.log(data);
            if (data.numReq === 1 && data.sttReq === 'ok') {
              // console.log(data);
              toast.success(`Your Regsitration done , 
              Your FF code and the Activation Link has been sent to 
              Your Email Address !`, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              toLogin();
            } else if (data.numReq === 0) {
              toast.error('This email has already been used !', {
                position: 'top-center',
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        validator.current.hideMessages();
      } else if (password !== passwordconfirm) {
        toast.error('Password and Confirm Password should be equal !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (quality < 2) {
        toast.error('Your password is not strong enough !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className="account__card">
      <Card className="loginCard">
        <CardBody>
          <form onSubmit={onSubmit} method="POST" name="login_form">
            <h2 className="text-center mb-3">Register</h2>
            {/* <h4 className="text-center mb-3">{code}</h4> */}
            <div className="mb-3">
              <div className="d-flex">
                <label htmlFor="email" className="form-label">Email Address</label>
              </div>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                name="email"
                placeholder="Enter your email address"
                aria-describedby="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {validator.current.message('email', email, 'required|email')}
            </div>
            <div className="">
              <div className="d-flex">
                <label className="form-label" htmlFor="password">Password</label>
              </div>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control form-control-password-login form-control-lg bg-white bg-opacity-5"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  aria-describedby="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="input-group-text input-group-text-login"
                  id="eye"
                  onClick={e => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOutlineIcon className="eye_icon" /> : <EyeOffOutlineIcon className="eye_icon" />}
                </button>
              </div>
              <PasswordStrengthBar
                password={password}
                scoreWords={['too short', 'weak', 'ok', 'good', 'strong']}
                onChangeScore={(score) => {
                  setQuality(score);
                }}
              />
              {validator.current.message('password', password, 'required|min:5')}
            </div>
            <div className="mb-2">
              <div className="d-flex">
                <label className="form-label" htmlFor="password">Confirm Password</label>
              </div>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control form-control-password-login form-control-lg bg-white bg-opacity-5"
                  type={showPasswordConfirm ? 'text' : 'password'}
                  placeholder="Enter your password"
                  aria-describedby="password"
                  value={passwordconfirm}
                  onChange={(e) => {
                    setPasswordconfirm(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="input-group-text input-group-text-login"
                  id="eye"
                  onClick={e => setShowPasswordConfirm(!showPasswordConfirm)}
                >
                  {showPasswordConfirm ? <EyeOutlineIcon className="eye_icon" />
                    : <EyeOffOutlineIcon className="eye_icon" />}
                </button>
              </div>
              {/* <PasswordStrengthBar
                        password={password}
                        scoreWords={['too short', 'weak', 'ok', 'good', 'strong']}
                        onChangeScore={(score) => {
                          setQuality(score);
                        }}
                      /> */}
              {validator.current.message('password confirm', passwordconfirm, 'required|min:5')}
            </div>
            <div className="mb-3">
              {/* <div className="landing_sublink_div">
                <CardLink onClick={toLogin} className="landing_sublink">I already have an account</CardLink>
              </div> */}
            </div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 submit_button"
              disabled={requested ? 'true' : ''}
            >
              Register
            </button>
            Have an account? <CardLink onClick={toLogin} className="landing_sublink">Log In</CardLink>
          </form>

        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </div>
  );
};

Register.propTypes = {
  toLogin: PropTypes.func.isRequired,
  sentCode: PropTypes.string.isRequired,
};


export default connect()(Register);
