import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateMessegeData';

const DataTable = ({ messages }) => {
  // const [reactTableData, setReactTableData] = useState();
  // const Data = CreateTableData();
  // // setReactTableData(Data);
  const [, forcestop] = useState();

  useEffect(() => {
    forcestop(13);
  }, [messages]);


  const reactTableData = CreateTableData();

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};



DataTable.propTypes = {
  messages: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { messages: userInfo.user?.messages };
}


export default connect(mapStateToProps)(DataTable);
