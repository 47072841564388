import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';


const AffiliateConditions = ({
  plancrypto,
}) => {
  const [tetherNeed, setTetherNeed] = useState();
  useEffect(() => {
    if (plancrypto[0]) {
      setTetherNeed(plancrypto.find(entry => entry.id === 5)?.amount);
      // setTetherNeed(100);
    }
  }, [plancrypto]);


  return (
    <>
      <div className="plan_conditions kyc_notes">
        <ul>
          <li>An affiliate plan is a plan to generate income based on the introduction of people.</li>
          <li>To join this plan, you need {tetherNeed} Tether.</li>
          <li>You can see how to calculate the reward of this plan in the table below.</li>
          <li>Each person can join the plan only once.</li>
          <li>
            A green icon means enough balance, gray icon means no currency in your wallet.
            The amount of shortage of each currency is written below it.
          </li>
        </ul>
      </div>
    </>
  );
};

AffiliateConditions.propTypes = {
  plancrypto: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { constants } = state;
  return {
    plancrypto: constants?.plancrypto,
  };
}

export default connect(mapStateToProps)(AffiliateConditions);

