import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import {
  getRefundSummary, getBalance,
} from '../../../../../redux/actions/userActions';
import { serverbetaURL } from '../../../../../utils/url';
import { clearLoadingRefund, setLoadingRefund } from '../../../../../redux/actions/loaderAction';
import NoSecPass from '../../../../Layout/NoSecPass';
import useTimer from '../../../../../utils/useTimer';
import { showLogs } from '../../../../App/Rules';
import { getCryptoImgUrl } from '../../../../../shared/helpers/themHelper';
import axiosHeaders from '../../../../../utils/axiosHelper';



const RefundJoin = ({
  havePlans,
  balanceWallet,
  depositWallet,
  withdrawWallet,
  refundSummary,
  isAllowPlan,
  isActive,
  FFCode,
  haveSecPass,
  plans,
  currencies,
  plancrypto,
  refundLoad,
}) => {
  const [, forceUpdate] = useState();

  const [haveRefund, setHaveRefund] = useState(false);

  const [refundTimeOk, setRefundTimeOk] = useState(true);


  const [agree, setAgree] = useState(false);

  const secInput = useRef(null);

  const [nccAmount, setNccAmount] = useState(0);
  const [teronAmount, setTeronAmount] = useState(0);

  const [secPass, setSecPass] = useState();

  const [nccNeed, setNccNeed] = useState(10000);

  const [TronStock, setTronStock] = useState(50);

  const [wallet, setWallet] = useState(depositWallet?.address);
  const [walletModal, setWalletModal] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    // const fundTimeStart = Date.parse(plans.find(entry => entry.id === 1).fromDate.replace(/-/g, '/'));
    // const fundTimeNow = new Date().getTime();
    // const fundTimeEnd = Date.parse(plans.find(entry => entry.id === 1).toDate.replace(/-/g, '/'));

    // if (fundTimeNow > fundTimeEnd || fundTimeNow < fundTimeStart) {
    //   setRefundTimeOk(false);
    // }

    if (!isActive) {
      setRefundTimeOk(false);
    }

    if (plans?.find(entry => entry.id === 1).isActive !== 1) {
      setRefundTimeOk(false);
    }

    if (balanceWallet) {
      setNccAmount(balanceWallet?.NCC?.Deposit || 0);
      setTeronAmount(balanceWallet?.TRX?.Deposit || 0);
    }

    if (refundSummary?.active?.id) {
      setHaveRefund(true);
    } else {
      setHaveRefund(false);
    }
  }, [havePlans, plancrypto, refundSummary]);

  const [resendTime, setResendTime] = useTimer({
    startTime: 0,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });



  const validator = useRef(new SimpleReactValidator());

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };
  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };


  const [modalCancel, setModalCancel] = useState(false);
  const toggleCancel = () => {
    setModalCancel(prevState => !prevState);
  };

  const handleRefund = () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastBuyReq', new Date().getTime());
      const refundData = {
        FFCode,
        secPass,
        planCryptoCurrencyId: 12,
        toCryptoCurrencyId: 0,
        refundstructuresId: 1,
        isWithdrawByService: 0,
        amount: nccNeed,
        token: localStorage.getItem('token'),
      };
      if (showLogs) {
        console.log(JSON.parse(JSON.stringify(refundData)));
      }
      setResendTime(180);
      dispatch(setLoadingRefund());
      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);
      axios.post(`${serverbetaURL}/refunds/set`, JSON.stringify(refundData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('setplan (Refund) :', data);
          }
          dispatch(getRefundSummary());
          dispatch(getBalance());
          dispatch(clearLoadingRefund());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(clearLoadingRefund());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });

      setResendTime(0);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleCheckRefund = () => {
    if ((nccAmount < nccNeed)) {
      toast.error(`Your NCC Amount is not Enough (${nccAmount}),
       Should be ${nccNeed} !`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if ((nccAmount >= nccNeed)) {
      toggle();
    }
  };

  const handleCancelRefund = () => {
    if (validator.current.fieldValid('Security Password')) {
      localStorage.setItem('lastReq', new Date().getTime());
      const reqData = {
        cryptoCurrencyId: 1,
        userPlanId: refundSummary?.active?.userPlanId,
        refundstructuresId: 1,
        FFCode,
        token: localStorage.getItem('token'),
        secPass,
        walletAddress: wallet,
      };
      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);
      if (showLogs) {
        console.log('request Data for cancel refund :', reqData);
      }
      setResendTime(180);
      dispatch(setLoadingRefund());
      axios.post(`${serverbetaURL}/refunds/planCancel`, JSON.stringify(reqData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('refunds planCancel :', data);
          }
          dispatch(getRefundSummary());
          dispatch(getBalance());
          dispatch(clearLoadingRefund());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getRefundSummary());
          dispatch(getBalance());
          dispatch(clearLoadingRefund());
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });
      setResendTime(0);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (
    <>
      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (
          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRefund();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button
                className="modal_ok"
                color="primary"
                onClick={
                  refundSummary?.active?.id
                    ? handleCancelRefund : handleRefund
                }
              >
                Confirm
              </Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modalCancel}
        toggle={toggleCancel}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Cancel Refund</h4>
        </div>
        <div className="modal__body">
          <>
            <h5>Are you sure ? </h5>
            <br />
            <br />
          </>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={agree}
                id="agree"
                defaultChecked={agree}
                onChange={e => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="agree">
                I agree to recieve my NCC with a 30% deduction ({Math.round(refundSummary?.active?.Price) * 0.7} NCC)
              </label>
            </div>
            <div className="pt-3">
              <Row>
                <Col md={2}>
                  <label className="form-label text-left d-block" htmlFor="wallet">Wallet :</label>
                </Col>
                <Col md={10}>
                  <select
                    className="form-select text-center"
                    id="wallet"
                    value={wallet}
                    onClick={e => setWallet(e.target.value)}
                    onChange={e => setWallet(e.target.value)}
                  >
                    {depositWallet && (
                      <option key={depositWallet?.address} value={depositWallet?.address}>
                        {'Deposit Wallet'}
                        {' '}- {depositWallet?.address?.slice(0, 3)}
                        ***************
                        {depositWallet?.address?.slice(depositWallet?.address?.length - 4)}
                      </option>
                    )}

                    {withdrawWallet && withdrawWallet.map((item, i) => (
                      <option key={item.address} value={item.address}>
                        {item.name}
                        {' '}- {item.address?.slice(0, 3)}
                        ***************{item.address?.slice(item.address.length - 4)}
                      </option>
                    ))}
                  </select>
                  <div className="tefo_error tefo_error_strong">
                    {validator.current.message('wallet', wallet, 'required')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="tefo_error">
              {validator.current.message('agreement', agree, 'accepted')}
            </div>
          </div>

        </div>

        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleCancel}>Cancel</Button>{' '}
          {agree ? (
            <Button
              className="modal_ok"
              color="primary"
              onClick={() => {
                toggleCancel();
                togglePass();
              }}
            >
              Confirm
            </Button>
          ) : ''}
        </ButtonToolbar>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Refund</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          Pay {nccNeed} NCC
          <br />
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <div>
        <ul>
          <li>You need to enable it in this section to enter and use the benefits of the plan fund.</li>
          <li>You can calculate the method of generating income from this plan in the table below.</li>
          <li>You can increase your income by inviting people through this plan.</li>
        </ul>
      </div>
      <div className="text-center">
        <div className="plan_img">
          <div className={(nccAmount < nccNeed) && !haveRefund ? 'plan_img_red' : 'plan_img_have'}>
            <img
              className={(nccAmount < nccNeed) && !haveRefund ? 'plan_image_gray' : 'plan_image'}
              alt=""
              src={getCryptoImgUrl().ncc}
            />
          </div>
          <div className="tefo_error_plan text-center">
            {(nccAmount < nccNeed) && !haveRefund
              && `${nccNeed - parseInt(nccAmount, 10)} NCC`}
            <br />
            {(nccAmount < nccNeed) && !haveRefund
              && 'required'}
          </div>
        </div>
      </div>

      {haveRefund && (
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_md w-50"
            onClick={toggleCancel}
            disabled={
              (refundSummary?.active?.allowCancel === 0 || refundSummary?.active?.allowCancel === null)
              || refundLoad
            }
          >
            {refundLoad ? (
              <>
                <div className="spinner-border text-success spinner-border-sm" /> Processing ...
              </>
            ) : 'Cancel Refund'}
          </button>
        </div>
      )}

      {!haveRefund
        && (
          <div className="my-3 text-center">
            {
              (teronAmount < TronStock)
                && (nccAmount >= nccNeed)
                ? (
                  <div className="tefo_error tefo_error_strong text-center mb-5">
                    *Your TRX deposit balance must be at least {TronStock} to Activating this plan.
                  </div>
                ) : ''
            }
            <button
              type="submit"
              className="btn btn-outline-theme tefo_buttons_md w-50"
              onClick={handleCheckRefund}
              disabled={haveRefund
                || !refundTimeOk
                || refundLoad
                || !isAllowPlan
                || (teronAmount < TronStock)
                || (nccAmount < nccNeed)
                ? true : ''}
            >
              {refundLoad ? (
                <>
                  <div className="spinner-border text-success spinner-border-sm" /> Processing ...
                </>
              ) : 'Join Now !'}
            </button>
          </div>
        )}
    </>
  );
};

RefundJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  refundSummary: PropTypes.objectOf.isRequired,
  withdrawWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  refundLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, constants, loader } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    refundSummary: userInfo.user?.refundSummary,
    balanceWallet: userInfo.user?.balanceWallet,
    withdrawWallet: userInfo.user?.withdrawWallet,
    depositWallet: userInfo.user?.depositWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    refundLoad: loader.refundLoad,
  };
}


export default connect(mapStateToProps)(RefundJoin);
