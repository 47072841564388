import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateBinaryData';

const DataTable = ({ binarySummary }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [binarySummary]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

DataTable.propTypes = {
  binarySummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { binarySummary: userInfo.user?.binarySummary };
}


export default connect(mapStateToProps)(DataTable);
