
const REFFERAL_CODE_VALID = 'REFFERAL_CODE_VALID';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const CODE_REQUEST = 'CODE_REQUEST';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
const LOGOUT = 'LOGOUT';
const SET_USER = 'SET_USER';
const ADMIN_SELECT = 'ADMIN_SELECT';
const SET_BALANCE = 'SET_BALANCE';
const SET_WITHDRAW_WALLETS = 'SET_WITHDRAW_WALLETS';
const SET_DEPOSIT_WALLETS = 'SET_DEPOSIT_WALLETS';
const SET_DEVICE = 'SET_DEVICE';
const SET_TOKEN = 'SET_TOKEN';
const SET_FFUSER = 'SET_FFUSER';
const SET_KYC = 'SET_KYC';
const SET_REFFERAL_TREE = 'SET_REFFERAL_TREE';
const SET_REFFERAL_CHART = 'SET_REFFERAL_CHART';
const SET_REFFERAL_LIST = 'SET_REFFERAL_LIST';
const GET_MESSAGES = 'GET_MESSAGES';
const SET_MESSAGES = 'SET_MESSAGES';
const SET_LEVELS = 'SET_LEVELS';
const SET_HAVE_PLAN = 'SET_HAVE_PLAN';
const SET_STAKING_SUMMARY = 'SET_STAKING_SUMMARY';
const SET_AFFILIATE_SUMMARY = 'SET_AFFILIATE_SUMMARY';
const SET_BUYINVEST_SUMMARY = 'SET_BUYINVEST_SUMMARY';
const SET_REFUND_SUMMARY = 'SET_REFUND_SUMMARY';
const SET_AWARD_SUMMARY = 'SET_AWARD_SUMMARY';
const SET_NFT_SUMMARY = 'SET_NFT_SUMMARY';
const SET_REPORT_PLANS = 'SET_REPORT_PLANS';
const SET_HISTORY_BUYINVEST = 'SET_HISTORY_BUYINVEST';
const SET_BINARY_SUMMARY = 'SET_BINARY_SUMMARY';
const SET_VOID_PLANS = 'SET_VOID_PLANS';
const SET_SEC_PASS = 'SET_SEC_PASS';
const SET_IS_CAPITAL = 'SET_IS_CAPITAL';
const SET_AUTO_WITH_AFF = 'SET_AUTO_WITH_AFF';
const SET_ORDERS = 'SET_ORDERS';
const SET_ORDERS_RECORD_BUY = 'SET_ORDERS_RECORD_BUY';
const SET_ORDERS_RECORD_WITHDRAW = 'SET_ORDERS_RECORD_WITHDRAW';
const SELECT_USER_REFFERAL = 'SELECT_USER_REFFERAL';
const CLEAR_USER_REFFERAL = 'CLEAR_USER_REFFERAL';


const initialState = {
  isLoggedIn: false,
  requestCode: false,
  user: null,
  deviceInfo: null,
  token: null,
  isReferalCodeValid: false,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        requestCode: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_DEVICE:
      return {
        ...state,
        deviceInfo: action.deviceInfo,
      };
    case LOGOUT:
      return {
        // ...state,
        isLoggedIn: false,
        requestCode: false,
        user: null,
        // deviceInfo: null,
        // token: null,
        isReferalCodeValid: false,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        requestCode: true,
        loginDate: new Date(),
      };
    case REFFERAL_CODE_VALID:
      return {
        isLoggedIn: false,
        requestCode: false,
        isReferalCodeValid: true,
      };
    case CODE_REQUEST:
      return {
        isLoggedIn: false,
        requestCode: true,
        user: null,
      };
    case SET_SEC_PASS:
      return {
        ...state,
        user: { ...state.user, haveSecPass: 1 },
      };
    case SET_IS_CAPITAL:
      return {
        ...state,
        user: { ...state.user, isCapital: action.isCapital },
      };
    case SET_AUTO_WITH_AFF:
      return {
        ...state,
        user: { ...state.user, autoWithAff: action.autoWithAff },
      };
    case ADMIN_SELECT:
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case SET_BALANCE:
      return {
        ...state,
        user: { ...state.user, balanceWallet: action.balance },
      };
    case SET_WITHDRAW_WALLETS:
      return {
        ...state,
        user: { ...state.user, withdrawWallet: action.withdrawWallet },
      };
    case SET_DEPOSIT_WALLETS:
      return {
        ...state,
        user: { ...state.user, depositWallet: action.DepositWallet },
      };
    case SET_FFUSER:
      return {
        ...state,
        user: { ...state.user, FFuser: action.FFuser },
      };
    case SET_KYC:
      return {
        ...state,
        user: { ...state.user, kyc: action.KYC },
      };
    case SET_REFFERAL_TREE:
      return {
        ...state,
        user: { ...state.user, Refferal: { ...state.user.Refferal, treeData: action.treeData } },
      };
    case SET_REFFERAL_CHART:
      return {
        ...state,
        user: { ...state.user, Refferal: { ...state.user.Refferal, chartData: action.chartData } },
      };
    case SET_REFFERAL_LIST:
      return {
        ...state,
        user: { ...state.user, Refferal: { ...state.user.Refferal, listData: action.listData } },
      };
    case GET_MESSAGES:
      return {
        ...state,
        user: { ...state.user, messages: action.messages },
      };
    case SET_LEVELS:
      return {
        ...state,
        user: { ...state.user, levels: action.levels },
      };
    case SET_MESSAGES:
      return {
        ...state,
        user: { ...state.user, messages: action.messages },
      };
    case SET_HAVE_PLAN:
      return {
        ...state,
        user: { ...state.user, havePlans: action.havePlans },
      };
    case SET_STAKING_SUMMARY:
      return {
        ...state,
        user: {
          ...state.user,
          StackingSummary: {
            ...state.user.StackingSummary,
            stage: action.stage,
            actives: action.active,
            finishes: action.finishes,
            cancels: action.cancels,
            profits: action.profits,
          },
        },
      };
    case SET_AFFILIATE_SUMMARY:
      return {
        ...state,
        user: { ...state.user, AffiliateSummary: action.AffiliateSummary },
      };
    case SET_REPORT_PLANS:
      return {
        ...state,
        user: { ...state.user, reportPlans: action.reportPlans },
      };
    case SET_HISTORY_BUYINVEST:
      return {
        ...state,
        user: { ...state.user, reportPlans: { ...state.user.reportPlans, historyBuyInvest: action.historyBuyInvest } },
      };
    case SET_BINARY_SUMMARY:
      return {
        ...state,
        user: { ...state.user, binarySummary: { members: action.members, profit: action.profit } },
      };
    case SET_REFUND_SUMMARY:
      return {
        ...state,
        user: {
          ...state.user,
          refundSummary: {
            ...state.user.refundSummary,
            active: action.active,
            finishes: action.finishes,
            cancels: action.cancels,
            profits: action.profits,
          },
        },
      };
    case SET_AWARD_SUMMARY:
      return {
        ...state,
        user: {
          ...state.user,
          awardSummary: action.awardSummary,
        },
      };
    case SET_NFT_SUMMARY:
      return {
        ...state,
        user: {
          ...state.user,
          nftSummary: action.nftSummary,
        },
      };
    case SET_BUYINVEST_SUMMARY:
      return {
        ...state,
        user: {
          ...state.user,
          BuyInvestSummary: {
            all: action.all, active: action.active,
          },
        },
      };
    case SET_VOID_PLANS:
      return {
        ...state,
        user: { ...state.user, voidPlans: action.voidPlans },
      };
    case SET_ORDERS:
      return {
        ...state,
        user: { ...state.user, orders: action.orders },
      };
    case SET_ORDERS_RECORD_BUY:
      return {
        ...state,
        user: { ...state.user, orders: [action.buyRecord, ...state.user.orders] },
      };
    case SET_ORDERS_RECORD_WITHDRAW:
      return {
        ...state,
        user: { ...state.user, orders: [action.withdrawRecord, ...state.user.orders] },
      };
    case SELECT_USER_REFFERAL:
      return {
        ...state,
        user: { ...state.user, selectedUser: action.selectedUser },
      };
    case CLEAR_USER_REFFERAL:
      return {
        ...state,
        user: { ...state.user, selectedUser: null },
      };
    default:
      return state;
  }
};

export default userReducer;
