import React from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import {
  Col, Input, Row,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';




const ReactTableFilter = ({
  // rows,
  setGlobalFilter,
  setFilterValue,
  placeholder,
  //  dataLength,
}) => {
  const location = useLocation();
  const onChange = useAsyncDebounce((item) => {
    const value = item.trim();
    setFilterValue(value);
    setGlobalFilter(value);
  }, 200);


  return (
    // <div className="table__search transaction_search">
    <Row className="text-center">


      <Col md={5} lg={6}>
        <Input
          className="table__search table__search-input text-center"
          onChange={e => onChange(e.target.value)}
          placeholder={`${placeholder}`}
        />
        {/* {dataLength !== rows.length && (
          <span>Found {rows.length} matches</span>
        )} */}
      </Col>

      <Col md={7} lg={6}>


        {/* {location.pathname !== '/Profile' && location.pathname !== '/Refferal' && location.pathname !== '/Reports'
          ? (
            <>
              <button type="button" className="btn btn-outline-theme table_filter_btn" onClick={() => onChange('')}>
                All
              </button>
            </>
          ) : ('')} */}
        {location.pathname === '/Monetary'
          ? (
            <>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('Buy')}
              >
                Buy
              </button>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('Withdraw')}
              >
                Withdraw
              </button>
            </>
          ) : ('')}

        {/* {location.pathname === '/Promotion'
          ? (
            <>
              <button type="button" className="btn btn-outline-theme table_filter_btn" onClick={() => onChange('Fund')}>
                Fund
              </button>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('Affiliate')}
              >
                Affiliate
              </button>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('Stacking')}
              >
                Staking
              </button>
            </>
          ) : ('')} */}
        {location.pathname === '/Messeges'
          ? (
            <>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('isAdminCreate0')}
              >
                System
              </button>
              <button
                type="button"
                className="btn btn-outline-theme table_filter_btn"
                onClick={() => onChange('isAdminCreate1')}
              >
                Anouncment
              </button>
            </>
          ) : ('')}

      </Col>
    </Row>
    // </div>
  );
};

const filterGreaterThan = (rows, id, filterValue) => rows.filter((row) => {
  const rowValue = row.values[id];
  return rowValue >= filterValue;
});

filterGreaterThan.autoRemove = val => typeof val !== 'number';

ReactTableFilter.propTypes = {
  // rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // dataLength: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
};

ReactTableFilter.defaultProps = {
  setFilterValue: () => { },
  placeholder: 'Search...',
};

export default ReactTableFilter;
