import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row, Container, Col,
} from 'reactstrap';
import { monetaryReload } from '../../../redux/actions/userActions';
import Buy from './components/Buy';
import Deposit from './components/Deposit';
import Transactions from './components/Transactions';
import Withdraw from './components/Withdraw';



const Monetary = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetaryReload());
  }, []);

  return (

    <Container className="dashboard">
      <Row>
        <Col md={4}>
          <Buy />
        </Col>
        <Col md={4}>
          <Withdraw />
        </Col>
        <Col md={4}>
          <Deposit />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Transactions />
        </Col>
      </Row>
    </Container>
  );
};

export default Monetary;
