import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button, Col, Popover, PopoverBody, PopoverHeader, Row,
} from 'reactstrap';
import { store } from '../../App/store';
import { setCurrency } from '../../../redux/actions/currencyActions';

const nccImgURL = `${process.env.PUBLIC_URL}/img/NCC.svg`;
const tronImgURL = `${process.env.PUBLIC_URL}/img/TRON.svg`;
const tetherImgURL = `${process.env.PUBLIC_URL}/img/TETHER.svg`;

const TopbarQuiceInfo = ({ dir }) => {
  const prices = store.getState().currency;

  const [IsOpenedNcc, setIsOpenedNcc] = useState(false);
  const [IsOpenedTether, setIsOpenedTether] = useState(false);
  const [IsOpenedtTron, setIsOpenedtTron] = useState(false);

  const dispatch = useDispatch();

  const handleOpenNcc = () => {
    setIsOpenedNcc(!IsOpenedNcc);
    setIsOpenedTether(false);
    setIsOpenedtTron(false);
    dispatch(setCurrency());
    setTimeout(() => {
      if (!IsOpenedNcc) {
        setIsOpenedNcc(false);
      }
    }, 6000);
  };

  const handleOpenTether = () => {
    setIsOpenedTether(!IsOpenedNcc);
    setIsOpenedNcc(false);
    setIsOpenedtTron(false);
    dispatch(setCurrency());
    setTimeout(() => {
      if (!IsOpenedTether) {
        setIsOpenedTether(false);
      }
    }, 3000);
  };

  const handleOpenTron = () => {
    setIsOpenedtTron(!IsOpenedtTron);
    setIsOpenedNcc(false);
    setIsOpenedTether(false);
    dispatch(setCurrency());
    setTimeout(() => {
      if (!IsOpenedtTron) {
        setIsOpenedtTron(false);
      }
    }, 3000);
  };

  return (
    <div className="quice">
      <div className="quice_info">
        <div className="quice_info_img">
          <Button
            id="nccQuiceInfo"
            onClick={handleOpenNcc}
            outline
            className="button-tooltip quiceInfo_btn"
          >
            <img className="quice_info_image" id="nccQuiceInfo" alt="" src={nccImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedNcc}
            target="nccQuiceInfo"
            toggle={handleOpenNcc}
            dir={dir}
          >
            <PopoverHeader>NCC details :</PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    {/* <li> Total :</li> */}
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.ncc} $</li>
                    {/* <li>4500</li> */}
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Buy Token :</li>
                    <li> Deposit :</li>
                    <li> ICO :</li>
                    <li> Stacking :</li>
                    <li> Fund :</li>
                    <li> Affiliate :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>234133</li>
                    <li>234133</li>
                    <li>234133</li>
                    <li>234133</li>
                    <li>234133</li>
                    <li>234133</li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.ncc} $</span>
          {/* <span className="quice_info_amount_price">Total : 4500</span> */}
        </div>
      </div>
      <div className="quice_info">
        <div className="quice_info_img">
          <Button
            id="tronQuiceInfo"
            onClick={handleOpenTether}
            outline
            className="button-tooltip quiceInfo_btn"
          >
            <img className="quice_info_image" id="tronQuiceInfo" alt="" src={tronImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedTether}
            target="tronQuiceInfo"
            toggle={handleOpenTether}
            dir={dir}
          >
            <PopoverHeader>Tron details :</PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    {/* <li> Total :</li> */}
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.tron} $</li>
                    {/* <li>4500</li> */}
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Deposit :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>  234133</li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.tron} $</span>
          {/* <span className="quice_info_amount_price">Total : 4500</span> */}
        </div>
      </div>
      <div className="quice_info">
        <div className="quice_info_img">
          <Button
            id="tetherQuiceInfo"
            onClick={handleOpenTron}
            outline
            className="button-tooltip quiceInfo_btn"
          >
            <img className="quice_info_image" id="tetherQuiceInfo" alt="" src={tetherImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedtTron}
            target="tetherQuiceInfo"
            toggle={handleOpenTron}
            dir={dir}
          >
            <PopoverHeader>Tether details :</PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    {/* <li> Total :</li> */}
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.tether} $</li>
                    {/* <li>4500</li> */}
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Deposit :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>  234133</li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.tether} $</span>
          {/* <span className="quice_info_amount_price">Total : 4500</span> */}
        </div>
      </div>
    </div>
  );
};

TopbarQuiceInfo.propTypes = {
  dir: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { currency } = state;
  return { currency };
}


export default connect(mapStateToProps)(TopbarQuiceInfo);

