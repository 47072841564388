import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, NavLink, Modal,
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import Highlighter from 'react-highlight-words';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { setMesseges, getWithdrawWallets } from '../../../../redux/actions/userActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { store } from '../../../../containers/App/store';
import { serverURL } from '../../../../utils/url';
import { showLogs } from '../../../../containers/App/Rules';
import { getStarImgUrl } from '../../../helpers/themHelper';


const ReactTableCell = ({ value: initialValue, state, column }) => {
  const [messageId, setMessageId] = useState();
  const [messageTitle, setMessageTitle] = useState();
  const [messageDescription, setMessageDescription] = useState();
  const [messageDate, setMessageDate] = useState();

  const [walletId, setWalletId] = useState();
  const [walletAdrs, setWalletAdrs] = useState();
  const [walletName, setWalletName] = useState();
  const [, forceUpdate] = useState();

  const { isHolder } = store.getState().userInfo.user.FFuser;
  const { FFCode } = store.getState().userInfo.user.FFuser;

  const dispatch = useDispatch();


  const validatorConfigs = {
    element: message => <div className="validationErr_profile">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  const [messagMmodal, setMessagMmodal] = useState(false);

  const openMessagMmodal = (id, title, description, date) => {
    setMessagMmodal(true);
    setMessageId(id);
    setMessageTitle(title);
    setMessageDescription(description);
    setMessageDate(date);
  };

  const closeMessagMmodal = () => {
    setMessagMmodal(false);
    AuthRequest(`${serverURL}/holder/readAnnouncements`, {
      FFCode, announcementId: messageId, token: localStorage.getItem('token'),
    }).then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log(data);
        }
        if (data.numReq === 1 && data.sttReq === 'ok') {
          dispatch(setMesseges(data.announcements));
        } else if (data.numReq === 0 && data.sttReq === 'Not exist') {
          toast.error('Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (data.numReq === 0) {
          toast.error('The Proccess have not done, Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }).catch(() => null);
  };


  const toggleMessagMmodal = () => {
    setMessagMmodal(prevState => !prevState);
  };

  const [editWalletMmodal, setEditWalletMmodal] = useState(false);
  const openEditWalletMmodal = (id, title, address) => {
    setEditWalletMmodal(true);
    setWalletId(id);
    setWalletName(title);
    setWalletAdrs(address);
  };
  const toggleEditWalletMmodal = () => {
    setEditWalletMmodal(prevState => !prevState);
  };

  const [removeWalletMmodal, setRemoveWalletMmodal] = useState(false);
  const openRemoveWalletMmodal = (id, title, address) => {
    setRemoveWalletMmodal(true);
    setWalletId(id);
    setWalletName(title);
    setWalletAdrs(address);
  };
  const toggleRemoveWalletMmodal = () => {
    setRemoveWalletMmodal(prevState => !prevState);
  };


  const handleRemoveWallet = (id, name, address) => {
    setRemoveWalletMmodal(false);
    AuthRequest(`${serverURL}/holder/deleteWithdrawWallet`, {
      FFCode, address, walletId: id,
    }).then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('deleteWithdrawWallet :', data);
        }
        if (data.numReq === 1 && data.sttReq === 'ok') {
          dispatch(getWithdrawWallets());
          toast.info(`"${name}" Wallet with "${address}" address Deleted !`, {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (data.numReq === 0 && data.sttReq === 'Not exist') {
          toast.error('Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (data.numReq === 0) {
          toast.error('The Proccess have not done, Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleEditWallet = (id, name, address) => {
    if (validator.current.allValid()) {
      setEditWalletMmodal(false);
      AuthRequest(`${serverURL}/holder/editWithdrawWallet`, {
        FFCode, address, walletId: id, name,
      }).then(res => res.json())
        .then((data) => {
          if (showLogs) {
            console.log('editWithdrawWallet :', data);
          }
          if (data.numReq === 1 && data.sttReq === 'ok') {
            dispatch(getWithdrawWallets());
            toast.info(`${name} wallet ${address} Edited !`, {
              position: 'top-center',
              hideProgressBar: true,
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else if (data.numReq === 0) {
            toast.error('The Proccess have not done, Try again later !', {
              position: 'top-center',
              hideProgressBar: true,
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (

    <Fragment>

      <Modal
        isOpen={removeWalletMmodal}
        toggle={toggleRemoveWalletMmodal}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Delete Wallet</h4>
        </div>
        <div className="modal__body mb-3 mt-3">
          <p>Are you want to delete this wallet ?</p>
          <br />
          <p>wallet Name : {walletName} </p>
          <p>wallet Address : {walletAdrs} </p>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleRemoveWalletMmodal}>Cancel</Button>{' '}
          <Button
            className="modal_ok"
            color="primary"
            onClick={() => handleRemoveWallet(walletId, walletName, walletAdrs)}
          >
            Delete
          </Button>
        </ButtonToolbar>
      </Modal>

      <Modal
        isOpen={messagMmodal}
        toggle={toggleMessagMmodal}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{messageTitle}</h4>
          <p> {messageDate?.split('T')[0]}  {messageDate?.split('T')[1]?.split('.')[0]} </p>
        </div>
        <hr />
        <div className="modal__body mb-3 mt-3 text-left">
          <p>{messageDescription} </p>
          {/* <p>{messageId} </p> */}
          {/* <p>{messageDate} </p> */}
          {/* <p className="mt-5"> . </p> */}
          <hr />
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_ok" color="primary" onClick={closeMessagMmodal}>Dismiss</Button>
        </ButtonToolbar>
      </Modal>

      <Modal
        isOpen={editWalletMmodal}
        toggle={toggleEditWalletMmodal}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Edit Wallet</h4>
        </div>
        <div className="modal__body mb-3 mt-3">
          <form className="text-left">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="walletAddress">Address</label>
              <input
                type="text"
                className="form-control text-center"
                id="walletAddress"
                placeholder="Your Wallet address"
                value={walletAdrs}
                onChange={e => setWalletAdrs(e.target.value)}
              />
              {validator.current.message('Wallet Address', walletAdrs, 'required')}
            </div>
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="WalletName">Name</label>
              <input
                type="text"
                className="form-control text-center"
                id="WalletName"
                placeholder="Your Wallet name"
                value={walletName}
                onChange={e => setWalletName(e.target.value)}
              />
              {validator.current.message('Wallet Name', walletName, 'required')}
            </div>
          </form>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleEditWalletMmodal}>Cancel</Button>{' '}
          <Button
            className="modal_ok"
            color="primary"
            onClick={() => handleEditWallet(walletId, walletName, walletAdrs)}
          >
            Apply
          </Button>
        </ButtonToolbar>
      </Modal>


      {column.disableGlobalFilter && !column.customRender
        ? (
          <Fragment>{initialValue}</Fragment>
        )
        : ''}

      {!column.disableGlobalFilter && !column.customRender
        ? (
          <Highlighter
            className="react-table__highlighter"
            searchWords={[state.globalFilter]}
            autoEscape
            textToHighlight={`${initialValue}`}
          />
        )
        : ''}

      {initialValue === 'DefaultWallet' && column.customRender
        ? (
          <span style={{ color: '#3cd2a5' }}><CheckCircleIcon /></span>
        )
        : ''}

      {initialValue === 'isAdminCreate0' && column.customRender
        ? (
          <Fragment>System</Fragment>
        )
        : ''}
      {initialValue === 'isAdminCreate1' && column.customRender
        ? (
          <Fragment>Anouncment</Fragment>
        )
        : ''}


      {initialValue?.toString().split('&&')[0] === 'message'
        && column.customRender && (isHolder === 1)
        ? (
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              openMessagMmodal(
                initialValue.toString().split('&&')[1],
                initialValue.toString().split('&&')[2],
                initialValue.toString().split('&&')[3],
                initialValue.toString().split('&&')[4],
              );
            }}
          >
            Read
          </button>
        )
        : ''}


      {initialValue?.toString().split('&&')[0] === 'WalletActivity'
        && column.customRender && (isHolder === 1)
        ? (
          <>
            <button
              type="button"
              className="btn table_btn no_padding"
              onClick={() => {
                openRemoveWalletMmodal(
                  initialValue.toString().split('&&')[1],
                  initialValue.toString().split('&&')[2],
                  initialValue.toString().split('&&')[3],
                );
              }}
            >
              <span className="table_icon lnr lnr-trash" />
            </button>
            <button
              type="button"
              className="btn table_btn no_padding"
              onClick={() => {
                openEditWalletMmodal(
                  initialValue.toString().split('&&')[1],
                  initialValue.toString().split('&&')[2],
                  initialValue.toString().split('&&')[3],
                );
              }}
            >
              <span className="table_icon lnr lnr-pencil" />
            </button>
          </>
        )
        : ''}


      {initialValue?.toString().split('&&')[0] === 'avatar'
        && column.customRender
        ? (
          <img
            className="binary_table_avatar"
            src={initialValue?.toString().split('&&')[1]}
            alt=""
          />
        )
        : ''}


      {initialValue?.toString().split('&&')[0] === 'starScore'
        && column.customRender
        ? (
          <>
            {[...Array(Math.floor(initialValue?.toString().split('&&')[1] / 1000))]?.slice(0, 5)
              ?.map(() => (
                <>
                  <img
                    className="binary_table_star"
                    src={getStarImgUrl()}
                    alt=""
                  />
                </>
              ))}
          </>
        )
        : ''}


      {initialValue?.toString().split('&&')[0] === 'attach'
        && initialValue?.toString().split('&&')[1] !== 'null'
        && column.customRender
        ? (
          <span className="attach_table">
            <NavLink target="_blank" className="no_padding" href={initialValue?.toString().split('&&')[1]}>
              <PaperclipIcon />
            </NavLink>
          </span>
        )
        : ''}

      {initialValue === 'refList1' && column.customRender
        ? (
          <span className="read_Message" />
        )
        : ''}

      {initialValue === 'refList0' && column.customRender
        ? (
          <span className="unread_Message" />
        )
        : ''}


      {initialValue === 'Done' && column.customRender
        ? (
          <span style={{ color: '#3cd2a5' }}>
            {initialValue}
          </span>
        )
        : ''}
      {initialValue === 'Pending' && column.customRender
        ? (
          <span style={{ color: 'rgb(251 218 53)' }}>
            {initialValue}
          </span>
        )
        : ''}
      {initialValue === 'Failed' && column.customRender
        ? (
          <span style={{ color: 'rgb(255 62 62)' }}>
            {initialValue}
          </span>
        )
        : ''}

      {initialValue === 'read1' && column.customRender
        ? (
          <span className="unread_Message" />
        )
        : ''}
      {initialValue === 'read0' && column.customRender
        ? (
          <span className="read_Message" />
        )
        : ''}


      {initialValue === 'activePlan1' && column.customRender
        ? (
          <span style={{ color: 'rgb(60 210 165)' }}>
            Active
          </span>
        )
        : ''}

      {initialValue === 'activePlan0' && column.customRender
        ? (
          <span style={{ color: 'rgb(255 62 62)' }}>
            Deactive
          </span>
        )
        : ''}

      {(initialValue === 'refundStatus338' || initialValue === 'refundStatus337') && column.customRender
        ? (
          <span style={{ color: '#3cd2a5' }}>
            Active
          </span>
        )
        : ''}
      {initialValue === 'refundStatus326' && column.customRender
        ? (
          <span style={{ color: '#F1F1F1' }}>
            Finished
          </span>
        )
        : ''}
      {initialValue === 'refundStatus327' && column.customRender
        ? (
          <span style={{ color: 'rgb(255 69 69)' }}>
            Canceled
          </span>
        )
        : ''}

      {(initialValue === 'planStatus1') && column.customRender
        ? (
          <span style={{ color: '#3cd2a5' }}>
            Active
          </span>
        )
        : ''}
      {initialValue === 'planStatus0' && column.customRender
        ? (
          <span style={{ color: 'rgb(255 69 69)' }}>
            Finished
          </span>
        )
        : ''}

    </Fragment>
  );
};

ReactTableCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  state: PropTypes.shape({
    globalFilter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  column: PropTypes.shape().isRequired,
};

export default connect()(ReactTableCell);
