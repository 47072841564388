import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { getHistoryBuyInvest } from '../../../../../../redux/actions/userActions';
import DataTable from './DataTable';




const HistoyBuyInvest = ({
  location, user, buyInvestReports, historyBuyInvest,
}) => {
  const [year, setYear] = useState((new Date()).getFullYear());
  const [yearList, setYearList] = useState([2022]);

  const monthList = [
    { label: 'All months', value: 0 },
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];

  const [month, setMonth] = useState(monthList.find(m => m.label === (new Date())
    .toLocaleString('default', { month: 'long' }))?.value);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const tempList = [];
  //   tempList.push((new Date()).getFullYear());
  //   for (let i = 1; i < 1; i += 1) {
  //     tempList.push((new Date()).getFullYear() + i);
  //     tempList.push((new Date()).getFullYear() - i);
  //   }
  //   setYearList(tempList);
  // }, []);


  const onChangeMonth = (e) => {
    setMonth(e.target.value);
    dispatch(getHistoryBuyInvest(e.target.value, year));
  };

  const onChangeYear = (e) => {
    setYear(e.target.value);
    // dispatch(getHistoryBuyInvest(month, e.target.value));
  };



  return (
    <>
      <div>
        <h1>
          Buy Invest History
        </h1>
      </div>
      <hr />
      <Row className="binary_invest_info_holder">
        <Col md={2} xs={6}>
          <label className="form-label" htmlFor="year">Year :</label>
          <select
            className="form-select text-center"
            id="year"
            value={year}
            onClick={e => onChangeYear(e)}
            onChange={e => onChangeYear(e)}
          >
            {yearList && yearList?.sort()?.map((item, i) => (
              <option
                key={item}
                value={item}
              >
                {item}
              </option>
            ))}
          </select>
        </Col>
        <Col md={2} xs={6}>
          <label className="form-label" htmlFor="month">Month :</label>
          <select
            className="form-select text-center"
            id="month"
            value={month}
            onClick={e => onChangeMonth(e)}
            onChange={e => onChangeMonth(e)}
          >
            {monthList && monthList?.map((item, i) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </Col>
        <Col md={1} xs={12} />
        <Col md={3} xs={6}>
          <div className="report_title">Sales volume</div>
          <div className="report_data_holder">
            <div className="report_data_item">
              <div className="report_data_label">
                USDT
              </div>
              <div className="report_data_value">
                {/* - */}
                {historyBuyInvest?.sell ? Number(historyBuyInvest?.sell)?.toFixed(2) : 0}
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} xs={6}>
          <div className="report_title">Total Profits</div>
          <div className="report_data_holder">
            <div className="report_data_item">
              <div className="report_data_label">
                USDT
              </div>
              <div className="report_data_value">
                {/* - */}
                {historyBuyInvest?.profit ? Number(historyBuyInvest?.profit)?.toFixed(2) : 0}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <div className="modal_history_table_holder">
        <DataTable />
      </div>

    </>
  );
};


HistoyBuyInvest.propTypes = {
  location: PropTypes.string.isRequired,
  user: PropTypes.objectOf.isRequired,
  buyInvestReports: PropTypes.objectOf.isRequired,
  historyBuyInvest: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    user: userInfo?.user,
    buyInvestReports: userInfo.user?.reportPlans?.buyInvest,
    historyBuyInvest: userInfo?.user?.reportPlans?.historyBuyInvest,
  };
}

export default withRouter(connect(mapStateToProps)(HistoyBuyInvest));
