import React from 'react';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import AwardJoin from './components/AwardJoinMenu';
import AwardReport from './components/AwardReportMenu';
import TableAward from './components/TableAwardMenu';

const AwardMenu = () => {
  const a = 1;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody className="plans_tabs">
              <Row className="mb-0">
                <div
                  // className={isMobile ? 'tabs mobile_tabs tabs--bordered-top' 
                  // : 'tabs tabs--justify tabs--bordered-top'}
                  className="tabs tabs--justify tabs--bordered-top"
                >
                  <Row className="mb-0">
                    <Col md={12} lg={12} xl={5}>
                      <AwardJoin />
                    </Col>
                    <Col md={12} lg={12} xl={7}>
                      <AwardReport />
                    </Col>
                  </Row>
                </div>
              </Row>
            </CardBody>
            <div className="card-arrow">
              <div className="card-arrow-top-left" />
              <div className="card-arrow-top-right" />
              <div className="card-arrow-bottom-left" />
              <div className="card-arrow-bottom-right" />
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="mt-3">
                <TableAward />
              </Row>
            </CardBody>
            <div className="card-arrow">
              <div className="card-arrow-top-left" />
              <div className="card-arrow-top-right" />
              <div className="card-arrow-bottom-left" />
              <div className="card-arrow-bottom-right" />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AwardMenu;
