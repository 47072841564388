import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Tree from 'react-d3-tree';
import {
  Col, Modal, Row, UncontrolledTooltip,
} from 'reactstrap';
import useCenteredTree from './treeHelper';
import SearchFF from './SearchFF';
import { userSelectRefferal } from '../../../../../../redux/actions/userActions';
import { profileImgURL } from '../../../../../../utils/url';
import { getLogoImgUrl } from '../../../../../../shared/helpers/themHelper';



const TreeChart = ({
  Refferal, selectUser, listData, FFuser, theme,
}) => {
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [datatree, setDatatree] = useState({});
  const [zoom, setZoom] = useState(0.75);

  const [selectedUser, setSelectedUser] = useState({
    name: '- - - - - - - -',
  });

  const [logo, setLogo] = useState();
  useEffect(() => {
    setLogo(getLogoImgUrl());
  }, [theme]);

  const [userFromSelect, setUserFromSelect] = useState(0);

  const dispatch = useDispatch();

  const [force, forceUpdate] = useState();

  const [depth, setDepth] = useState(1);

  const [showFullModal, setShowFullModal] = useState();
  const toggleFull = () => {
    setShowFullModal(pre => !pre);
  };

  const containerStyles = {
    width: '100%',
    height: '50vh',
  };

  const containerStylesModal = {
    width: '100%',
    height: '92vh',
  };

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <>
      <g>

        <text
          strokeWidth="0.1"
          x="-36"
          y="69"
          className={selectUser?.FFCode === nodeDatum.name ? 'node_active_code' : 'not_active_code'}
          onClick={() => {
            setSelectedUser(nodeDatum);
            if (nodeDatum.name !== FFuser.FFCode) {
              dispatch(userSelectRefferal(listData?.find(u => u.FFCode === nodeDatum.name)));
            }
          }}
        >
          {nodeDatum.name}
        </text>

        <rect
          id={`node${nodeDatum.name}`}
          width="75px"
          height="20px"
          x="-38"
          y="54"
          rx="3"
          fill="transparent"
        />
        {selectUser?.FFCode !== nodeDatum.name ? (
          <>
            <image
              alt=""
              // xlinkHref={nodeDatum?.attributes?.userImage
              //   ? `${profileImgURL}/${nodeDatum?.attributes?.userImage}` : logo}
              xlinkHref={logo}
              x="-38"
              y="-28"
              rx="50"
              ry="50"
              height="75px"
              width="75px"
              preserveAspectRatio="none"
              onClick={() => {
                setSelectedUser(nodeDatum);
                dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
                toggleNode();
                setZoom(1);
              }}
            />
            <rect
              width="75px"
              height="75px"
              x="-38"
              y="-28"
              rx="5"
              className="not_active_node"
              onClick={() => {
                toggleNode();
                setZoom(1);
                setSelectedUser(nodeDatum);
                dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
              }}
            />
          </>
        ) : (
          <>
            <image
              alt=""
              // xlinkHref={nodeDatum?.attributes?.userImage
              //   ? `${profileImgURL}/${nodeDatum?.attributes?.userImage}` : logo}
              xlinkHref={logo}
              x="-38"
              y="-28"
              rx="50"
              ry="50"
              height="75px"
              width="75px"
              preserveAspectRatio="none"
              onClick={() => {
                setSelectedUser(nodeDatum);
                dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
                toggleNode();
                setZoom(1);
              }}
            />
            <rect
              strokeWidth="3"
              width="75px"
              height="75px"
              x="-38"
              y="-28"
              rx="5"
              className="node_active"
              onClick={() => {
                setSelectedUser(nodeDatum);
                dispatch(userSelectRefferal(listData.find(u => u.FFCode === nodeDatum.name)));
                toggleNode();
                setZoom(1);
              }}
            />
          </>
        )}

        {/* <line x1="-38" y1="-47" x2="32" y2="-47" strokeWidth="0.9" /> */}

        {/* {nodeDatum.attributes?.BuyToken ? (
          <rect width="12" className="node_plan" height="12" x="21" y="52" fill="green" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="21" y="52" fill="transparent" />
        )}

        {nodeDatum.attributes?.Stacking ? (
          <rect width="12" className="node_plan" height="12" x="-30" y="52" fill="yellow" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="-30" y="52" fill="transparent" />
        )}

        {nodeDatum.attributes?.Affiliate ? (
          <rect width="12" className="node_plan" height="12" x="-13" y="52" fill="#9370DB" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="-13" y="52" fill="transparent" />
        )}

        {nodeDatum.attributes?.Fund ? (
          <rect width="12" className="node_plan" height="12" x="4" y="52" fill="#005fff" />
        ) : (
          <rect width="12" className="node_plan" height="12" x="4" y="52" fill="transparent" />
        )} */}


        <UncontrolledTooltip placement="top" target={`node${nodeDatum.name}`}>
          FF Code : {nodeDatum.name}
          <br />
          Nickname : {nodeDatum?.attributes?.nickname}
          <br />
          Buy Invest: {nodeDatum.attributes?.buyInvest === '1' ? ' ✔️' : '-'}
          <br />
          Fund : {nodeDatum.attributes?.Fund ? ' ✔️' : ' -'}
          <br />
          Affiliate : {nodeDatum.attributes?.Affiliate ? ' ✔️' : ' -'}
          <br />
          Staking : {nodeDatum.attributes?.Stacking ? ' ✔️' : ' -'}
          <br />
          Award : {nodeDatum.attributes?.Award ? ' ✔️' : ' -'}
        </UncontrolledTooltip>

      </g>
    </>
  );

  useEffect(() => {
    if (Refferal) {
      setDatatree(Refferal.treeData);
      const firstLevel = Refferal.treeData.children?.length;
      if (firstLevel >= 12) {
        setZoom(0.5);
      } else if (firstLevel < 12 && firstLevel >= 8) {
        setZoom(0.7);
      } else if (firstLevel < 8 && firstLevel > 4) {
        setZoom(0.85);
      } else if (firstLevel <= 4) {
        setZoom(1);
      }
    }
  }, []);

  useEffect(() => {
    setDatatree(Refferal.treeData);
  }, []);


  useEffect(() => {
    if (selectUser?.FFCode !== FFuser.FFCode && selectUser) {
      setDepth(Number(selectUser?.level) + 1);
      if (Number(selectUser?.level) + 1 < 4) {
        setZoom(0.8);
      } else {
        setZoom(0.3);
      }
      forceUpdate(!force);
    }
  }, [userFromSelect]);


  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      return 'link__to-leaf';
    }
    return 'link__to-branch';
  };


  return (
    <>
      {/* <Row> */}
      <SearchFF
        userImage={selectedUser?.name !== FFuser.FFCode ? selectedUser?.attributes?.userImage : ''}
        nickname={selectedUser?.name !== FFuser.FFCode ? selectedUser?.attributes?.nickname : ''}
        setUserFromSelect={setUserFromSelect}
      />
      <hr />
      <Col md={12}>

        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(100);
              setZoom(0.3);
              forceUpdate(!force);
            }}
          >
            Expand {' '}
            <i className="fas fa-angle-double-down" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              setDepth(0);
              setZoom(1);
              forceUpdate(!force);
            }}
          >
            Collapse {' '}
            <i className="fas fa-angle-double-up" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn btn_tree"
            onClick={() => {
              toggleFull();
            }}
          >
            Fullscreen {' '}
            <i className="fas fa-expand" />
          </button>
        </div>
      </Col>

      <Modal
        isOpen={showFullModal}
        toggle={toggleFull}
        modalClassName="ltr-support"
        // aria-describedby="modal-modal-full"
        className="modal-tree"
      >
        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              setDepth(100);
              setZoom(0.3);
              forceUpdate(!force);
            }}
          >
            {/* Expand {' '} */}
            <i className="fas fa-angle-double-down" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              setDepth(0);
              setZoom(1);
              forceUpdate(!force);
            }}
          >
            {/* Collapse {' '} */}
            <i className="fas fa-angle-double-up" />
          </button>
          <button
            type="button"
            className="btn btn-outline-theme table-btn"
            onClick={() => {
              toggleFull();
            }}
          >
            {/* Fullscreen {' '} */}
            <i className="fas fa-compress" />
          </button>
        </div>
        <div className="full_tree" style={containerStylesModal} ref={containerRef}>
          <Tree
            data={datatree}
            translate={translate}
            renderCustomNodeElement={renderRectSvgNode}
            orientation="vertical"
            enableLegacyTransitions="true"
            transitionDuration={500}
            zoom={zoom}
            siblings="1"
            nonSiblings="1"
            initialDepth={depth}
            nodeSet="1"
            centeringTransitionDuration={1000}
            id="treeNode"
            dimensions={dimensions}
            allowForeignObjects
            pathClassFunc={getDynamicPathClass}
            rootNodeClassName="node_root"
            branchNodeClassName="node_branch"
            leafNodeClassName="node_leaf"
            key={force}
          />
        </div>
      </Modal>


      <div style={containerStyles} ref={containerRef}>
        <Tree
          data={datatree}
          translate={translate}
          renderCustomNodeElement={renderRectSvgNode}
          orientation="vertical"
          enableLegacyTransitions="true"
          transitionDuration={500}
          zoom={zoom}
          siblings="1"
          nonSiblings="1"
          initialDepth={depth}
          nodeSet="1"
          centeringTransitionDuration={1000}
          id="treeNode"
          dimensions={dimensions}
          allowForeignObjects
          pathClassFunc={getDynamicPathClass}
          rootNodeClassName="node_root"
          branchNodeClassName="node_branch"
          leafNodeClassName="node_leaf"
          key={force}
        />
      </div>
      {/* <div className="text-center pt-2 plan_tree_info">
        <span className="tree_plan_fund tree_plan" />  Fund |{'  '}
        <span className="tree_plan_affiliate tree_plan" />  Affiliate |{'  '}
        <span className="tree_plan_stacking tree_plan" />  Staking |{'  '}
        <span className="tree_plan_buy tree_plan" />  Buy Invest
      </div> */}
    </>
  );
};

TreeChart.propTypes = {
  Refferal: PropTypes.objectOf.isRequired,
  selectUser: PropTypes.objectOf.isRequired,
  listData: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};


function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    Refferal: userInfo.user?.Refferal,
    listData: userInfo?.user?.Refferal?.listData,
    selectUser: userInfo.user?.selectedUser,
    FFuser: userInfo.user?.FFuser,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(TreeChart);
