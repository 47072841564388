import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, icon, notifCount, route, onClick, isBold,
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p
        className={isBold ? 'sidebar__link-title font-weight-bold' : 'sidebar__link-title'}
      >
        {title}
        {notifCount ? <Badge className="sidebar__link-badge"><span>{notifCount}</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isBold: PropTypes.bool,
  notifCount: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  isBold: false,
  notifCount: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;
