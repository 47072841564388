import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  Card, Row, Container, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import DataTable from './components/DataTable';
import TabMesseges from './components/TabMesseges';
import { getMesseges } from '../../../redux/actions/userActions';



const Messeges = () => {
  const [activeTab, setActiveTab] = useState('1');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMesseges());
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>

      <UncontrolledTooltip placement="right" target="messHelp">
        You can see all the messages you have received in this section and search for the message you are looking for.
      </UncontrolledTooltip>


      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="msg_cardBody">
                <Row>
                  <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem className="refferal_nav">
                          <NavLink
                            className={classnames({ active: activeTab === '1' }, 'tab_link')}
                            onClick={() => toggle('1')}
                          >
                            Messeges <i id="messHelp" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' }, 'tab_link')}
                            onClick={() => toggle('2')}
                            disabled
                          >
                            Ticket
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          {/* <TabMesseges /> */}
                          {' '}
                        </TabPane>
                        <TabPane tabId="2">
                          2
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Row>
                <Row className="mt-3">
                  {activeTab === '1' ? <DataTable /> : null}
                </Row>
              </CardBody>
              <div className="card-arrow">
                <div className="card-arrow-top-left" />
                <div className="card-arrow-top-right" />
                <div className="card-arrow-bottom-left" />
                <div className="card-arrow-bottom-right" />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Messeges;
