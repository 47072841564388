import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar, UncontrolledTooltip,
} from 'reactstrap';
import { Pie, PieChart } from 'recharts';
import ReactSpeedometer from 'react-d3-speedometer';

import SimpleReactValidator from 'simple-react-validator';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { setBalance, setHavePlan, getRefferal } from '../../../../../redux/actions/userActions';
import NoSecPass from '../../../../Layout/NoSecPass';
import { serverURL } from '../../../../../utils/url';
import { clearLoadingAffiliate, setLoadingAffiliate } from '../../../../../redux/actions/loaderAction';
import AffiliateConditions from './AffiliateConditions';
import AffiliateAutomatic from './AffiliateAutomatic';
import { showLogs } from '../../../../App/Rules';
import { getCryptoImgUrl } from '../../../../../shared/helpers/themHelper';




const AffiliateJoin = ({
  theme,
  havePlans,
  balanceWallet,
  isAllowPlan,
  isActive,
  FFCode,
  haveSecPass,
  plans,
  currencies,
  plancrypto,
  affiliateLoad,
}) => {
  const [tetherImgURL, setTetherImgURL] = useState();

  useEffect(() => {
    setTetherImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tether}`);
  }, [theme]);

  const [fillcolor, setFillcolor] = useState('#3cd2a5');

  const [valueChart, setValueChart] = useState(90);
  const level1 = [{ value: valueChart / 10, fill: fillcolor },
  { value: 100 - valueChart / 10, fill: '#f2f4f7' }];
  const level1More = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];

  const [, forceUpdate] = useState();

  const [haveAffiliate, setHaveAffiliate] = useState();

  const [affiliateTimeOk, setAffiliateTimeOk] = useState(true);

  const [inRequest, setInRequest] = useState(false);

  const secInput = useRef(null);

  const [TronStock, setTronStock] = useState(50);

  const [ProfitStonkTron, setProfitStonkTron] = useState(10);

  const [nccAmount, setNccAmount] = useState(0);
  const [teronAmount, setTeronAmount] = useState(0);
  const [tetherAmount, setTetherAmount] = useState(0);

  const [secPass, setSecPass] = useState();

  // const [nccNeed, setNccNeed] = useState();
  const [tetherNeed, setTetherNeed] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const affiliateTimeStart = Date.parse(plans.find(entry => entry.id === 2).fromDate.replace(/-/g, '/'));
    const affiliateTimeNow = new Date().getTime();
    const affiliateTimeEnd = Date.parse(plans.find(entry => entry.id === 2).toDate.replace(/-/g, '/'));


    if (affiliateTimeNow > affiliateTimeEnd || affiliateTimeNow < affiliateTimeStart) {
      setAffiliateTimeOk(false);
    }

    if (!isActive) {
      setAffiliateTimeOk(false);
    }

    if (plans.find(entry => entry.id === 2).isActive !== 1) {
      setAffiliateTimeOk(false);
    }

    if (balanceWallet) {
      setNccAmount(balanceWallet?.NCC?.Deposit || 0);
      setTeronAmount(balanceWallet?.TRX?.Deposit || 0);
      setTetherAmount(balanceWallet?.USDT?.Deposit || 0);
    }
    if (havePlans) {
      if (havePlans.Affiliate) {
        // if (havePlans.Affiliate.isActiveUserPlan === 1 && havePlans.Affiliate.isActive === 1) {
        setHaveAffiliate(true);
        // }
      } else {
        setHaveAffiliate(false);
      }
    }

    if (plancrypto) {
      setTetherNeed(plancrypto.find(entry => entry.ffplanId === 2 && entry.cryptocurrencyId === 3)?.amount);
      setProfitStonkTron(plancrypto.find(entry => entry.ffplanId === 2 && entry.cryptocurrencyId === 3)?.profitStonk);
    }

    if (currencies[1]) {
      setTronStock(currencies.find(i => i.id === 2)?.minStock);
    }
  }, [havePlans, plancrypto]);


  const handleAffiiate = () => {
    if (validator.current.fieldValid('Security Password')) {
      setInRequest(true);
      dispatch(setLoadingAffiliate());
      localStorage.setItem('lastReq', new Date().getTime());
      AuthRequest(`${serverURL}/holder/setPlans`,
        {
          FFCode, planId: '2', token: localStorage.getItem('token'), secPass,
        })
        .then(res => res.json())
        .then((data) => {
          setInRequest(false);
          dispatch(clearLoadingAffiliate());
          if (showLogs) {
            console.log('setPlans (Affiliate) :', data);
          }
          if (data.numReq === 1 && data.sttReq === 'ok') {
            toast.success('You successfully active the Affiliate plan !', {
              position: 'top-center',
              autoClose: 15000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setHavePlan(data.havePlans));
            dispatch(setBalance(data.Balance));
            dispatch(getRefferal());
          } else if (data.numReq === -2 && data.sttReq === 'you have this plan') {
            toast.error('You already have the Affiliate plan !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(setHavePlan(data.havePlans));
          } else if (data.numReq === -2 && data.sttReq === 'user have problem') {
            toast.error('Your process can not be done, Try again later', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'not enough cash') {
            toast.error('There is not enough cash in your balance !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'plan not active') {
            toast.error('The Affiliate plan is not active right now !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === -2 && data.sttReq === 'expire') {
            toast.error('The Affiliate plan is not available in this date !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'wrong secPass') {
            toast.error('The entered security is not true, try again !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'not enough TRX') {
            toast.error('There is not enough TRX in your balance !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (data.numReq === 0 && data.sttReq === 'have problem txid') {
            toast.error('Server response is not available right now (taxid) !', {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(clearLoadingAffiliate());
        });
      togglePass();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleCheckAffiiate = () => {
    if (tetherAmount < tetherNeed) {
      toast.error(`Your Tether Amount is not Enough(${tetherAmount}),
       Should be ${tetherNeed} !`, {
        position: 'top-center',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (tetherAmount >= tetherNeed) {
      toggle();
    }
  };



  return (
    <>
      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAffiiate();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleAffiiate}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Affiliate Plan</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          <div className="d-flex justify-content-around">
            <span className="font-weight-bold">
              <img src={tetherImgURL} className="crypto_moda_img" alt="" />
              {tetherNeed}
            </span>
          </div>
          <br />
          <br />
          You are going to pay {tetherNeed} Tether to join the Affiliate plan .Do you confirm ?
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>


      <div className="text-center">
        {tetherNeed !== 0 ? (
          <div className="plan_img">
            <div className={(tetherAmount < tetherNeed) && !haveAffiliate ? 'plan_img_red' : 'plan_img_have'}>
              <img
                className={(tetherAmount < tetherNeed) && !haveAffiliate ? 'plan_image_gray' : 'plan_image'}
                alt=""
                src={tetherImgURL}
              />
            </div>
            <div className="tefo_error_plan text-center">
              {(tetherAmount < tetherNeed) && !haveAffiliate
                && `${tetherNeed - parseInt(tetherAmount, 10)} Tether`}
              <br />
              {(tetherAmount < tetherNeed) && !haveAffiliate
                && 'required'}
            </div>
            {haveAffiliate
              && (
                <div className="tefo_error_plan text-center">
                  <span className="havePlan_msg">You already have the Affiliate plan !</span>
                </div>
              )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="mb-3 text-center">
        {haveAffiliate ? ''
          : (
            <>
              {
                (teronAmount < ProfitStonkTron * 3)
                  && (tetherAmount >= tetherNeed)
                  ? (
                    <div className="tefo_error tefo_error_strong text-center mb-5">
                      *Your TRX deposit balance must be at least {ProfitStonkTron * 3} to Activating this plan.
                    </div>
                  ) : ''
              }
              <button
                type="submit"
                className="btn btn-outline-theme tefo_buttons_md w-50"
                onClick={handleCheckAffiiate}
                disabled={haveAffiliate
                  || !affiliateTimeOk
                  || affiliateLoad
                  || true
                  || inRequest
                  || !isAllowPlan
                  || (teronAmount < ProfitStonkTron * 3)
                  || (tetherAmount < tetherNeed) ? true : ''}
              >
                {inRequest || affiliateLoad ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                  </>
                ) : 'Join Now !'}
              </button>
            </>
          )}

        <div className="aff_desk_automatic text-center">
          <AffiliateAutomatic />
        </div>
      </div>
    </>
  );
};

AffiliateJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  plans: PropTypes.string.isRequired,
  plancrypto: PropTypes.string.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  affiliateLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, constants, loader, theme,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    affiliateLoad: loader.affiliateLoad,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(AffiliateJoin);

