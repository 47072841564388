import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '@/shared/prop-types/ReducerProps';
import { logOut } from '../../../redux/actions/userActions';
import { clearCurrency } from '../../../redux/actions/currencyActions';
import { clearConstants } from '../../../redux/actions/constantActions';

const Sidebar = ({
  changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, FFuser,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  // const dispatch = useDispatch();
  // const history = useHistory();


  // setInterval(() => {
  //   if (!FFuser?.isActive) {
  //     history.push({
  //       pathname: '/',
  //     });
  //     console.log('from sidebar');
  //     dispatch(logOut());
  //     dispatch(clearCurrency());
  //     dispatch(clearConstants());
  //   }
  // }, 20000);

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        aria-label="sidebar visibility"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onClick={() => { }}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            sidebarCollapse={sidebar.collapse}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    FFuser: userInfo.user?.FFuser,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
