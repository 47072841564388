import React from 'react';

import {
  Col,
} from 'reactstrap';




const Detail = () => (

  <>
    <Col>
      1
    </Col>
  </>
);



export default Detail;
