import React, { useMemo } from 'react';
import { store } from '../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   width: '9x',
      // },
      {
        Header: 'Kind',
        accessor: 'kind',
      },
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Currency',
        accessor: 'Currency',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Amount',
        accessor: 'Amount',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Plan',
        accessor: 'Plan',
        disableGlobalFilter: true,
      },
      {
        Header: 'Wallet',
        accessor: 'Wallet',
        // disableGlobalFilter: true,
        // Footer: (info) => {
        //   const { rows, flatRows } = info;
        //   const totalAge = useMemo(
        //     () => rows.reduce((sum, row) => Number(row.values.age) + sum, 0),
        //     [rows],
        //   );
        //   const age = Math.round(totalAge / flatRows.length);
        //   return <span>{age}</span>;
        // },
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        forBadge: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const { orders, withdrawWallet, depositWallet } = store.getState().userInfo.user;
  const {
    constants, currencies, plans,
  } = store.getState().constants;


  const data = [];
  const rows = () => {
    orders?.map((order, i) => {
      if (order.walletId === depositWallet.id) {
        data.push({
          id: i + 1,
          kind: constants[1]?.constants?.find(k => k.id === order.kindId)?.name
            ? constants[1]?.constants?.find(k => k.id === order.kindId)?.name : '',
          date: order?.created_at?.toString()?.split('T')[0],
          time: order?.created_at?.toString()?.split('T')[1]?.split('.')[0],
          Currency: currencies.find(cr => cr.id === order.cryptocurrencyId)?.name,
          Amount: order.amount,
          Plan: plans.find(pl => pl.id === order.ffplanId)?.planTitle
            ? plans.find(pl => pl.id === order.ffplanId)?.planTitle : '',
          Wallet: 'Deposit Wallet',
          Status: constants[12]?.constants?.find(s => s.id === order.stateId)?.name
            ? constants[12]?.constants?.find(s => s.id === order.stateId)?.name : ' ',
        });
      } else {
        data.push({
          id: i + 1,
          kind: constants[1]?.constants?.find(k => k.id === order.kindId)?.name
            ? constants[1]?.constants?.find(k => k.id === order.kindId)?.name : '',
          date: order?.updated_at?.toString()?.split('T')[0],
          time: order?.updated_at?.toString()?.split('T')[1]?.split('.')[0],
          Currency: currencies.find(cr => cr.id === order.cryptocurrencyId)?.name,
          Amount: order?.amount,
          Plan: plans.find(pl => pl.id === order?.ffplanId)?.planTitle
            ? plans.find(pl => pl.id === order?.ffplanId)?.planTitle : '',
          Wallet: withdrawWallet.find(wl => wl.id === order?.walletId)?.name
            ? withdrawWallet.find(wl => wl.id === order?.walletId)?.name : '',
          Status: constants[12]?.constants?.find(s => s.id === order?.stateId)?.name
            ? constants[12]?.constants?.find(s => s.id === order?.stateId)?.name : ' ',
        });
      }
      return data;
    });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
