// 281221 import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import SimpleReactValidator from 'simple-react-validator';
import { Card, CardBody, CardLink } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import request from '../../../services/fetchRequest';

import { RefCodeVallid } from '../../../redux/actions/userActions';
import { serverURL } from '../../../utils/url';



const RefferalCode = ({ toRegister, history, toLogin }) => {
  const inputRef = useRef(null);
  const [refCode, setRefcode] = useState('');
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();

  const [requested, setRequested] = useState(false);


  const sendRequest = () => {
    setRequested(true);
    setInterval(() => {
      setRequested(false);
    }, 5000);
  };

  const validatorConfigs = {
    messages: {
      required: 'That feild is required.',
      // default: 'Validation has failed!',
    },
    element: message => <div className="validationErr">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  const onSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      sendRequest();
      await request(`${serverURL}/refferalcode`, { refCode })
        .then(response => response.json())
        .then((data) => {
          // console.log(data);
          if (data.numReq === 1 && data.sttReq === 'ok') {
            const toRgistr = true;
            toast.success(`Your Refferal Code is Valid , 
              Now enter Your Email Address and password to Register !`, {
              position: 'top-center',
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const sentCode = refCode.toString();
            dispatch(RefCodeVallid());
            history.push({
              pathname: '/',
              toRgistr,
              sentCode,
            });
            toRegister();
          } else if (data.numReq === 0 && data.sttReq === 'noRefCode') {
            toast.error('Your Refferal Code is Not Valid !', {
              position: 'top-center',
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setRefcode('');
            validator.current.hideMessages();
            forceUpdate(1);
          }
        })
        .catch(err => null);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  });


  return (
    <>
      {/* {refCode.length === 5 ? toRegister() : null} */}
      <div className="account__card">
        <Card className="loginCard">
          <CardBody>
            <form onSubmit={onSubmit} method="POST" name="login_form">
              <h4 className="text-center mb-3">Enter Your Refferal Code :</h4>

              <div className="mb-1">
                <input
                  className="form-control form-control-lg bg-white bg-opacity-5 verification mb-3 mt-4"
                  maxLength="8"
                  type="text"
                  ref={inputRef}
                  placeholder=""
                  aria-describedby="refCode"
                  value={refCode}
                  onChange={e => setRefcode(e.target.value)}
                />
                {validator.current.message('refferal code', refCode, 'required|min:8')}
              </div>

              <button
                type="submit"
                className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 submit_button"
                disabled={requested ? 'true' : ''}
              >
                Confirm
              </button>
              {/* <div className="landing_sublink_div"> */}
              Have an account? <CardLink onClick={toLogin} className="landing_sublink">Log In</CardLink>
              {/* </div> */}

            </form>

          </CardBody>
          <div className="card-arrow">
            <div className="card-arrow-top-left" />
            <div className="card-arrow-top-right" />
            <div className="card-arrow-bottom-left" />
            <div className="card-arrow-bottom-right" />
          </div>
        </Card>
      </div>
      {/* </div> */}
    </>

  );
};

// RefferalCode.propTypes = {
//   dispatch: PropTypes.func.isRequired,
// };

RefferalCode.propTypes = {
  toRegister: PropTypes.func.isRequired,
  toLogin: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
};



export default withRouter(connect(null)(RefferalCode));
