import React from 'react';
import {
  Row, Container, Col,
} from 'reactstrap';



const ChangeNode = () => (

  <Container className="dashboard">
    <Row>
      <Col md={6}>
        <div className="mb-5">
          <label className="form-label" htmlFor="search">Search</label>
          <input
            type="text"
            className="form-control text-center"
            id="search"
            placeholder="Search the FF codes ..."
          />
        </div>
        <div className="access_admin_divItem mb-4">
          <div className="change_node_title mb-3">
            Node Details
          </div>
          <div className="change_node_items">
            <Row>
              <Col col={6}>
                <ul className="change_node_ul">
                  <li>Subsets</li>
                  <li>Subsets Level</li>
                  <li>Active Subsets</li>
                  <li>Deactive Subsets</li>
                </ul>
              </Col>
              <Col col={6}>
                <ul className="change_node_ul change_node_ul_value">
                  <li>12452</li>
                  <li>5</li>
                  <li>24</li>
                  <li>53</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className="access_admin_divItem mb-4">
          <div className="change_node_title mb-3">
            Parent Details
          </div>
          <div className="change_node_items">
            <Row>
              <Col col={6}>
                <ul className="change_node_ul">
                  <li>FF Code</li>
                  <li>Date Of Join</li>
                  <li>Firstname</li>
                  <li>Lastname</li>
                </ul>
              </Col>
              <Col col={6}>
                <ul className="change_node_ul change_node_ul_value">
                  <li>30303030</li>
                  <li>12/28/2021</li>
                  <li>tofigh</li>
                  <li>nazari</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={1}>
        {' '}
      </Col>
      <Col md={5}>
        <div className="mb-3">
          <label className="form-label" htmlFor="action">Action</label>
          <select className="form-select" id="action">
            <option>Only this</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="mb-5">
          <label className="form-label" htmlFor="NewParent">New Parent FF Code</label>
          <select className="form-select" id="NewParent">
            <option>Only this</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="mb-5 text-center">
          <button type="button" className="btn btn-outline-theme">CHANGE</button>
        </div>
      </Col>
    </Row>
  </Container>
);

export default ChangeNode;
