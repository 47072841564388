import React, { useMemo } from 'react';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'NCC',
        accessor: 'amount',
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        // disableSortBy: true,
      },
      {
        Header: 'Cancel Time',
        accessor: 'Cancel',
      },
    ],
    [],
  );
  const { StackingSummary } = store.getState().userInfo.user;


  const data = [];

  function compare(a, b) {
    if (a.id < b.id) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 0;
  }

  const rows = () => {
    const allStacking = [];
    if (StackingSummary) {
      if (StackingSummary?.actives) {
        for (let i = 0; i < StackingSummary?.actives?.length; i += 1) {
          allStacking?.push(StackingSummary?.actives[i]);
        }
      }
      if (StackingSummary?.cancels) {
        for (let i = 0; i < StackingSummary?.cancels?.length; i += 1) {
          allStacking?.push(StackingSummary?.cancels[i]);
        }
      }
      if (StackingSummary?.finishes) {
        for (let j = 0; j < StackingSummary?.finishes?.length; j += 1) {
          allStacking?.push(StackingSummary?.finishes[j]);
        }
      }

      allStacking.sort(compare);

      for (let pl = 0; pl < allStacking?.length; pl += 1) {
        data.push({
          date: allStacking[pl]?.created_at?.split(' ')[0],
          time: allStacking[pl]?.created_at?.split(' ')[1],
          Status: `refundStatus${allStacking[pl]?.typeId}`,
          amount: Math.floor(allStacking[pl]?.Price) || '',
          Cancel: allStacking[pl]?.typeId === 327 ? allStacking[pl]?.updated_at?.split(' ')[0] : '-',
          stackingId: allStacking[pl]?.id,
        });
      }
    }
    return data;
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
