import React, { useMemo } from 'react';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Start',
        accessor: 'Start',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'USDT',
        accessor: 'Amount',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Price',
        accessor: 'Price',
        // disableGlobalFilter: true,
      },
      {
        Header: 'NCC',
        accessor: 'NCC',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        customRender: true,
        // disableGlobalFilter: true,
        // disableSortBy: true,
      },
      {
        Header: 'Finish',
        accessor: 'finish',
      },
    ],
    [],
  );
  const BuyInvestSummary = store.getState().userInfo.user?.BuyInvestSummary?.all;


  const data = [];

  // function compare(a, b) {
  //   if (a.updated_at < b.updated_at) {
  //     return 1;
  //   }
  //   if (a.updated_at > b.updated_at) {
  //     return -1;
  //   }
  //   return 0;
  // }

  const formatDate = (date) => {
    // const daaaaat = new Date(date);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();
    if (month?.toString().length < 2) {
      month = `0${month}`;
    }
    if (day?.toString().length < 2) {
      day = `0${day}`;
    }
    return [year, month, day].join('-');
  };

  const rows = () => {
    if (BuyInvestSummary) {
      for (let pl = 0; pl < BuyInvestSummary?.length; pl += 1) {
        const startDate = new Date(BuyInvestSummary[pl]?.created_at);
        const finishDate = new Date(startDate.setMonth(startDate.getMonth() + 10));
        let status;
        if ((new Date()) < finishDate) {
          status = 1;
        } else {
          status = 0;
        }
        data.push({
          Start: BuyInvestSummary[pl]?.created_at?.split(' ')[0],
          time: BuyInvestSummary[pl]?.created_at?.split(' ')[1],
          Amount: Number(BuyInvestSummary[pl]?.amount)?.toFixed(0),
          Price: (Number(BuyInvestSummary[pl]?.amount) / (Number(BuyInvestSummary[pl]?.toAmount)))?.toFixed(3),
          NCC: Number(BuyInvestSummary[pl]?.toAmount).toFixed(0),
          Status: `planStatus${status}`,
          finish: formatDate(finishDate) || ' - ',
          buyInvestId: BuyInvestSummary[pl]?.id,
        });
      }
    }
    return data;
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
