export const setLoadingBuy = () => dispatch => dispatch({ type: 'SET_LOADING_BUY' });
export const clearLoadingBuy = () => dispatch => dispatch({ type: 'CLEAR_LOADING_BUY' });

export const setLoadingWithdraw = () => dispatch => dispatch({ type: 'SET_LOADING_WITHDRAW' });
export const clearLoadingWithdraw = () => dispatch => dispatch({ type: 'CLEAR_LOADING_WITHDRAW' });

export const setLoadingAffiliate = () => dispatch => dispatch({ type: 'SET_LOADING_AFFILIATE' });
export const clearLoadingAffiliate = () => dispatch => dispatch({ type: 'CLEAR_LOADING_AFFILIATE' });

export const setLoadingFund = () => dispatch => dispatch({ type: 'SET_LOADING_FUND' });
export const clearLoadingFund = () => dispatch => dispatch({ type: 'CLEAR_LOADING_FUND' });

export const setLoadingKyc = () => dispatch => dispatch({ type: 'SET_LOADING_KYC' });
export const clearLoadingKyc = () => dispatch => dispatch({ type: 'CLEAR_LOADING_KYC' });

export const setLoadingStacking = () => dispatch => dispatch({ type: 'SET_LOADING_STAKING' });
export const clearLoadingStacking = () => dispatch => dispatch({ type: 'CLEAR_LOADING_STAKING' });

export const setLoadingBuyInvest = () => dispatch => dispatch({ type: 'SET_LOADING_BUYINVEST' });
export const clearLoadingBuyInvest = () => dispatch => dispatch({ type: 'CLEAR_LOADING_BUYINVEST' });

export const setLoadingRefund = () => dispatch => dispatch({ type: 'SET_LOADING_REFUND' });
export const clearLoadingRefund = () => dispatch => dispatch({ type: 'CLEAR_LOADING_REFUND' });

export const setLoadingAward = () => dispatch => dispatch({ type: 'SET_LOADING_AWARD' });
export const clearLoadingAward = () => dispatch => dispatch({ type: 'CLEAR_LOADING_AWARD' });

export const setLoadingMagic = () => dispatch => dispatch({ type: 'SET_LOADING_MAGIC' });
export const clearLoadingMagic = () => dispatch => dispatch({ type: 'CLEAR_LOADING_MAGIC' });

export const setLoadingNft = () => dispatch => dispatch({ type: 'SET_LOADING_NFT' });
export const clearLoadingNft = () => dispatch => dispatch({ type: 'CLEAR_LOADING_NFT' });
