import React, { useMemo } from 'react';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',
        // disableGlobalFilter: true,
        customRender: true,
        // disableSortBy: true,
      },
      {
        Header: 'Finished at',
        accessor: 'finish',
      },
    ],
    [],
  );
  const { awardSummary } = store.getState().userInfo.user;


  const data = [];

  // function compare(a, b) {
  //   if (a.updated_at < b.updated_at) {
  //     return 1;
  //   }
  //   if (a.updated_at > b.updated_at) {
  //     return -1;
  //   }
  //   return 0;
  // }

  const rows = () => {
    if (awardSummary) {
      for (let pl = 0; pl < awardSummary?.length; pl += 1) {
        data.push({
          date: awardSummary[pl]?.created_at?.split(' ')[0],
          time: awardSummary[pl]?.created_at?.split(' ')[1],
          Status: `refundStatus${awardSummary[pl]?.typeId}`,
          finish: awardSummary[pl]?.EndTime?.split(' ')[0] || ' - ',
        });
      }
    }
    return data;
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
