import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { store } from '../../../../App/store';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateWalletData';

const DataTable = ({ withdrawWallet }) => {
  const reactTableData = CreateTableData();

  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [withdrawWallet]);

  return (

    <DataReactTable reactTableData={reactTableData} />

  );
};

DataTable.propTypes = {
  withdrawWallet: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { withdrawWallet: userInfo.user?.withdrawWallet };
}


export default connect(mapStateToProps)(DataTable);
