import React, { useState } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody,
} from 'reactstrap';
import classnames from 'classnames';
import TreeChart from './components/Treechart/TreeChart';
import DataTable from './components/DataTable';
import ChangeNode from './components/ChangeNode';
import SearchFF from './components/SearchFF';
import DataTableTransactions from './components/DataTableTransactions';


const UserList = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (

    <Card>
      <CardBody>
        <div className="tabs tabs--justify tabs--bordered-top">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' }, 'tab_link')}
                  onClick={() => toggle('1')}
                >
                  Users Tree
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' }, 'tab_link')}
                  onClick={() => toggle('2')}
                >
                  Users List
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' }, 'tab_link')}
                  onClick={() => toggle('3')}
                >
                  Change Node
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' }, 'tab_link')}
                  onClick={() => toggle('4')}
                >
                  Transactions
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <SearchFF />
                <TreeChart selectedUser />
              </TabPane>
              <TabPane tabId="2">
                <DataTable />
              </TabPane>
              <TabPane tabId="3">
                <ChangeNode />
              </TabPane>
              <TabPane tabId="4">
                <DataTableTransactions />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </CardBody>
      <div className="card-arrow">
        <div className="card-arrow-top-left" />
        <div className="card-arrow-top-right" />
        <div className="card-arrow-bottom-left" />
        <div className="card-arrow-bottom-right" />
      </div>
    </Card>
  );
};
export default UserList;
