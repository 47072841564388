import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import DataReactTable from './components/DataReactTable';
import CreateTableData from './CreateRefferalData';

const DataTable = ({ listData }) => {
  const reactTableData = CreateTableData();
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(13);
  }, [listData]);

  return (
    <DataReactTable reactTableData={reactTableData} />
  );
};

DataTable.propTypes = {
  listData: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return { listData: userInfo.user?.Refferal?.listData };
}


export default connect(mapStateToProps)(DataTable);
