import React, { useMemo } from 'react';
import { getAvatarPlaceHolder } from '../../../../../../../shared/helpers/themHelper';
import { profileImgURL } from '../../../../../../../utils/url';
import { store } from '../../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   width: '9x',
      // },
      {
        Header: 'FF Code',
        accessor: 'FFCode',
      },
      {
        Header: 'Level',
        accessor: 'Level',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Time',
        accessor: 'Time',
        // disableGlobalFilter: true,
        Footer: 'Total :',
      },
      {
        Header: 'Sell',
        accessor: 'Sell',
        // disableGlobalFilter: true,
        Footer: (info) => {
          const { rows } = info;
          const totalAge = useMemo(
            () => rows.reduce((sum, row) => Number(row.values.Sell) + sum, 0),
            [rows],
          );
          return <span>{Math.floor(totalAge)}</span>;
        },
      },
      {
        Header: 'Profit',
        accessor: 'Profit',
        // disableGlobalFilter: true,
        Footer: (info) => {
          const { rows } = info;
          const totalAge = useMemo(
            () => rows.reduce((sum, row) => Math.floor(Number(row.values.Profit)) + sum, 0),
            [rows],
          );
          return <span>{totalAge}</span>;
        },
      },
    ],
    [],
  );

  const historyData = store.getState().userInfo?.user?.reportPlans?.historyBuyInvest?.history;


  const data = [];
  const rows = () => {
    if (historyData !== []) {
      historyData?.map((hist, i) => {
        data.push({
          id: i + 1,
          FFCode: hist?.FFCode,
          Level: hist?.lvl,
          date: hist?.DATE,
          Time: hist?.TIME,
          Sell: Math.floor(hist?.amount),
          Profit: Math.floor(hist?.profit || 0),
        });
        return data;
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
