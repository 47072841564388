import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  ButtonToolbar,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import { useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import PasswordStrengthBar from 'react-password-strength-bar';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { logOut } from '../../../../redux/actions/userActions';
import { store } from '../../../App/store';
import { clearCurrency } from '../../../../redux/actions/currencyActions';
import { clearConstants } from '../../../../redux/actions/constantActions';
import { serverbetaURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';
import axiosHeaders from '../../../../utils/axiosHelper';



const LoginPassword = () => {
  const [oldpass, setOldpass] = useState('');
  const [newpass, setNewpass] = useState();
  const [confirmpass, setConfirmpass] = useState();
  const [quality, setQuality] = useState();

  const [secPass, setSecPass] = useState();
  const [passModal, setPassModal] = useState(false);
  // const togglePass = () => {
  //   setPassModal(prevState => !prevState);
  // };

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [showErrorEqual, setShowErrorEqual] = useState(false);
  const [showErrorStrong, setShowErrorStrong] = useState(false);
  const [showErrorOld, setShowErrorOld] = useState(false);

  const [unValidOld, setUnValidOld] = useState(false);
  const [unValidnew, setUnValidnew] = useState(false);
  const [unValidconfirm, setUnValidconfirm] = useState(false);

  const [disable, setDisable] = useState(false);

  const { FFCode, email, isActive } = store.getState().userInfo.user.FFuser;
  const { haveSecPass } = store.getState().userInfo.user;
  // const [validOld, setValidOld] = useState(false);
  // const [validnew, setValidnew] = useState(false);
  // const [validconfirm, setValidconfirm] = useState(false);


  // useEffect(() => {
  //   if (!isActive) {
  //     setDisable(true);
  //   }
  // }, []);


  const [, forceUpdate] = useState();

  const validatorConfigs = {
    element: message => <div className="tefo_error">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  // const handleGoToPass = () => {
  //   toggle();
  //   togglePass();
  // };


  const reset = () => {
    setOldpass('');
    setNewpass('');
    setConfirmpass('');
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    history.push({
      pathname: '/',
      haveMsg: true,
      msg: 'changPass',
    });
    // localStorage.clear();
    dispatch(logOut());
    dispatch(clearCurrency());
    dispatch(clearConstants());
  };


  const checkEqualPass = (e) => {
    if (newpass !== confirmpass) {
      setShowErrorEqual(true);
      setUnValidconfirm(true);
    }
  };

  const checkChangePass = (e) => {
    e.preventDefault();
    if (quality >= 2 && (newpass === confirmpass) && oldpass) {
      toggle();
    } else if (quality < 2 && newpass) {
      setShowErrorStrong(true);
      setUnValidnew(true);
    }
    if ((newpass !== confirmpass) && confirmpass) {
      setShowErrorEqual(true);
      setUnValidconfirm(true);
    }
    if (oldpass === '') {
      setShowErrorOld(true);
      setUnValidOld(true);
      forceUpdate(13);
    }
  };

  const changePasssword = () => {
    if (validator.current.allValid()) {
      const passData = {
        user: {
          FFCode,
          oldpass,
          email,
        },
        newPass: newpass,
        secPass,
      };
      if (showLogs) {
        console.log(passData);
      }
      axios.post(`${serverbetaURL}/auth/changePassword`, JSON.stringify(passData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('changePassword :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              toggle();
            }
            if (data?.data?.status === true) {
              toggle();
              reset();
              handleLogout();
            }
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          toggle();
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <h6>Are you sure about changing your password ?</h6>
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={changePasssword}>Yes, I am sure</Button>
        </ButtonToolbar>
      </Modal>

      {/* <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={changePasssword}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <>
            <h5>You need Security Password to do this</h5>
            <br />
            <br />
            <h6>
              For setting up your security password
              <br />
              <br />
              go to Profile {'>'} password {'>'} Security Password
            </h6>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Dismiss</Button>{' '}
            </ButtonToolbar>
          </>
        )}
      </Modal> */}

      <div className="add_wallet">
        <h4>
          Login Password
        </h4>
      </div>
      <form onSubmit={checkChangePass}>
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="oldpass">Old Password</label>
          <div className="input-group flex-nowrap">
            <input
              type={showPasswordOld ? 'text' : 'password'}
              className={unValidOld
                ? `form-control form-control-password-login
                 form-control-lg bg-white bg-opacity-5 unValid amount_input_space`
                : `form-control form-control-password-login
                 form-control-lg bg-white bg-opacity-5 amount_input_space`}
              id="oldpass"
              placeholder=""
              value={oldpass}
              onChange={(e) => {
                setOldpass(e.target.value);
                setShowErrorOld(false);
                setUnValidOld(false);
              }}
            />
            <button
              type="button"
              className="input-group-text input-group-text-login"
              id="eye"
              onClick={e => setShowPasswordOld(!showPasswordOld)}
            >
              {showPasswordOld ? <EyeOutlineIcon className="eye_icon" />
                : <EyeOffOutlineIcon className="eye_icon" />}
            </button>
          </div>
          <div className="tefo_error">
            {showErrorOld && 'You have to enter your old password !'}
          </div>
        </div>
        <div className="form-group mb-0">
          <label className="form-label" htmlFor="newpass">New Password</label>
          <div className="input-group flex-nowrap">
            <input
              type={showPassword ? 'text' : 'password'}
              className={unValidnew
                ? `form-control form-control-password-login
                 form-control-lg bg-white bg-opacity-5 unValid amount_input_space`
                : `form-control form-control-password-login
                 form-control-lg bg-white bg-opacity-5 amount_input_space`}
              id="newpass"
              placeholder=""
              value={newpass}
              onChange={(e) => {
                setNewpass(e.target.value);
                setShowErrorStrong(false);
                setShowErrorEqual(false);
                setUnValidnew(false);
              }}
            />
            <button
              type="button"
              className="input-group-text input-group-text-login"
              id="eye"
              onClick={e => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOutlineIcon className="eye_icon" />
                : <EyeOffOutlineIcon className="eye_icon" />}
            </button>
          </div>
          <div className="tefo_error tefo_error_strong">
            {showErrorStrong && 'Your password is not strong enough !'}
          </div>
          <PasswordStrengthBar
            password={newpass}
            scoreWords={['too short', 'weak', 'ok', 'good', 'strong']}
            onChangeScore={(score) => {
              setQuality(score);
            }}
          />
        </div>
        <div className="form-group mb-2">
          <label className="form-label" htmlFor="confirmpass">Confirm Password</label>
          <div className="input-group flex-nowrap">
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              className={unValidconfirm
                ? `form-control form-control-password-login 
                form-control-lg bg-white bg-opacity-5 unValid amount_input_space`
                : `form-control form-control-password-login 
                form-control-lg bg-white bg-opacity-5 amount_input_space`}
              id="confirmpass"
              placeholder=""
              value={confirmpass}
              onChange={(e) => {
                setConfirmpass(e.target.value);
                setUnValidconfirm(false);
              }}
              onBlur={e => checkEqualPass(e)}
              onFocus={e => setShowErrorEqual(false)}
            />
            <button
              type="button"
              className="input-group-text input-group-text-login"
              id="eye"
              onClick={e => setShowPasswordConfirm(!showPasswordConfirm)}
            >
              {showPasswordConfirm ? <EyeOutlineIcon className="eye_icon" />
                : <EyeOffOutlineIcon className="eye_icon" />}
            </button>
          </div>
          <div className="tefo_error">
            {showErrorEqual && 'Password and Confirm Password should be Equal !'}
          </div>
          <div className="tefo_error" />
        </div>
        <div className="form-group mb-1 mt-3 text-center">
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_md"
            disabled={disable}
          >
            Change
          </button>
        </div>
      </form>
    </>
  );
};


export default withRouter(connect()(LoginPassword));
