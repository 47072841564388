import { useMemo } from 'react';
import { store } from '../../../../../App/store';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   // disableGlobalFilter: true,
      //   width: 500,
      // },
      {
        Header: 'FF Code',
        accessor: 'ffCode',
        // Footer: 'Middle age:',
        // disableGlobalFilter: true,
        width: 500,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
      },
      {
        Header: 'Activity',
        accessor: 'Activity',
        disableGlobalFilter: true,
        customRender: true,
      },
      {
        Header: 'Level',
        accessor: 'Level',
        disableGlobalFilter: true,
      },
    ],
    [],
  );

  const { listData } = store.getState().userInfo.user?.Refferal;


  const data = [];
  const rows = () => {
    listData?.map((list, i) => {
      data.push({
        id: i + 1,
        ffCode: list?.FFCode,
        date: list?.date,
        Activity: `refList${list?.active}`,
        Level: list?.level,
      });
      return data;
    });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
