import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card, Row, Container, CardBody, Col, Nav, NavItem, TabContent, TabPane, NavLink, UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';

import { connect, useDispatch } from 'react-redux';
import PersonalInfo from './components/PersonalInfo';
import KYC from './components/KYC';
import Wallet from './components/Wallet';
import LoginPassword from './components/LoginPassword';
import DataTable from './components/DataTable';
import TradingPassword from './components/TradingPassword';
import { profileReload } from '../../../redux/actions/userActions';


const Profile = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileReload());
  }, []);

  return (
    <>
      <UncontrolledTooltip placement="top" target="authHelp">
        In this section, you can authenticate and confirm it to use the features of the site.
        Conditions for sending documents:
        Black and white images are not allowed.
        Using a folded image is not allowed.
        Only the original image of the documents is required.
        The text of the letter of commitment must be in handwritten form with the signature and the date of the day.
        Images should not be mirrored or inverted.
        The Text: While reading and approving the rules of invoice financial services,
        documents have been sent for authentication.
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target="walletHelp">
        In this section, you can enter your wallets with the desired specifications and
        name Also search by the relevant fields in the table below.
        * Be careful in entering your wallet information.
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target="PassHelp">
        In this section, you can change the login password.
        You can also set and change your security password. Also, if you forgot your password,
        request a new security password.
        To change the password, you must enter the old one and then the new one.
        The password must contain uppercase and lowercase letters, numbers and symbols.
        It is also not allowed to use dots and dashes.
      </UncontrolledTooltip>


      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="">
                <Row className="mb-0">
                  <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem className="refferal_nav">
                          <NavLink
                            className={classnames({ active: activeTab === '1' }, 'tab_link')}
                            onClick={() => toggle('1')}
                          >
                            Authentication <i id="authHelp" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' }, 'tab_link')}
                            onClick={() => toggle('2')}
                          >
                            Wallet <i id="walletHelp" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '3' }, 'tab_link')}
                            onClick={() => toggle('3')}
                          >
                            Password <i id="PassHelp" className="fas fa-info-circle" />
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row className="mb-0">
                            <Col md={4} lg={4}>
                              <PersonalInfo />
                            </Col>
                            {/* <Col md={1} /> */}
                            <Col md={8}>
                              <KYC />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Wallet />
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col md={1} />
                            <Col md={4}>
                              <LoginPassword />
                            </Col>
                            <Col md={2} />
                            <Col md={4}>
                              <TradingPassword />
                            </Col>
                            <Col md={1} />
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Row>
                <Row className="mb-0">
                  {activeTab === '2' ? <DataTable /> : null}
                </Row>
              </CardBody>
              <div className="card-arrow">
                <div className="card-arrow-top-left" />
                <div className="card-arrow-top-right" />
                <div className="card-arrow-bottom-left" />
                <div className="card-arrow-bottom-right" />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};


export default connect()(Profile);
