import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Map from './components/Map';

const VectorMap = ({ theme }) => {
  const [mapMainColor, setMapMainColor] = useState('#a1a1a1');
  useEffect(() => {
    if (theme === 'theme-dark') {
      setMapMainColor('#3cd2a5');
    } else if (theme === 'theme-capital') {
      setMapMainColor('#ff8f1f');
    }
  }, [theme]);

  return (
    <Map mapMainColor={mapMainColor} />
  );
};


VectorMap.propTypes = {
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { theme } = state;
  return {
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(VectorMap);
