import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import ReactSpeedometer from 'react-d3-speedometer';
import AffiliateTable from './AffiliateTable';
import { getMainColor } from '../../../../../shared/helpers/themHelper';



const AffiliateReport = ({
  havePlans, AffiliateSummary, balanceWallet, planWithdrawSetting, theme,
}) => {
  const containerStylesModal = {
    width: '100%',
    height: '92vh',
  };
  const [showFullModal, setShowFullModal] = useState();
  const toggleFull = () => {
    setShowFullModal(pre => !pre);
  };

  const [YourProfit, setYourProfit] = useState(0);
  const [Repayment, setRepayment] = useState(0);
  const [nccTotal, setNccTotal] = useState(0);
  const [nccCredit, setNccCredit] = useState(0);
  const [usdtTotal, setUsdtTotal] = useState(0);

  const [themeColor, setThemeColor] = useState('');

  const [pay, setPay] = useState(30);
  const [recieve, setRecieve] = useState(3000);

  const [RemainDay, setRemainDay] = useState(0);

  useEffect(() => {
    setNccTotal(Number(AffiliateSummary?.totalNCC || 0)?.toFixed(0));
    setNccCredit(Number(AffiliateSummary?.totalCreditNCC || 0)?.toFixed(0));
    setUsdtTotal(Number(balanceWallet?.USDT?.Deposit || 0)?.toFixed(2));
    setYourProfit(Number(AffiliateSummary?.totalProfit || 0)?.toFixed(2));
    setRepayment(Number(AffiliateSummary?.totalRePayment || 0)?.toFixed(2));

    setPay(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 3 && f.planId === 2 && f.isPay === 1)?.amount || 30);
    setRecieve(planWithdrawSetting?.find(f => f.cryptocurrencyId
      === 1 && f.planId === 2 && f.isPay === 0)?.amount || 3000);

    // setNccTotal(8500);
    // setNccCredit(150);
    // setUsdtTotal(35);

    // get the remaining day until end of month 
    const dateToday = new Date();
    const lastDayOfMonth = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate();
    const daysUntilEndOfMonth = lastDayOfMonth - dateToday.getDate();
    setRemainDay(daysUntilEndOfMonth);
  }, [AffiliateSummary, planWithdrawSetting, balanceWallet]);

  useEffect(() => {
    // if (theme === 'theme-dark') {
    // setThemeColor('#3cd2a5');
    // } else if (theme === 'theme-capital') {
    //   setThemeColor('#ff8f1f');
    // }
    setThemeColor(getMainColor());
  }, [theme]);


  getMainColor();

  return (
    <>
      <Modal
        isOpen={showFullModal}
        toggle={toggleFull}
        modalClassName="ltr-support"
        className="modal-tree"
      >
        <div className="text-right">
          <button
            type="button"
            className="btn btn-outline-theme table-btn mb-1"
            onClick={() => {
              toggleFull();
            }}
          >
            {/* Fullscreen {' '} */}
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="full_tree" style={containerStylesModal}>
          <AffiliateTable />
        </div>
      </Modal>

      {/* ====================== */}
      {/*   Affi guage DESKTOP   */}
      {/* ====================== */}

      <div className="affi_guage_desk">

        <div className="text-center mt-5">

          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={4000}
              needleHeightRatio={0.4}
              ringWidth={10}
              currentValueText={`Total USDT: ${usdtTotal || 0} $`}
              minValue={0}
              value={usdtTotal > ((Math.ceil(nccTotal / recieve)) * pay)
                ? (Math.ceil((Number(nccTotal) || recieve) / recieve)) * pay : Number(usdtTotal)}
              // maxValue={nccTotal % recieve
              //   ? ((Math.floor(nccTotal / recieve) + 1) * pay) || pay
              //   : (Math.floor(nccTotal / recieve) * pay) || pay}
              maxValue={(Math.ceil(Number(nccTotal) / recieve)) * pay === pay
                ? pay : (Math.floor(Number(nccTotal) / recieve) + 1) * pay}
              valueTextFontSize="10px"
              labelFontSize="5px"
              // segments={10}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={150}
              height={100}
              forceRender
              // segmentColors={['red', 'grey', 'grey', 'grey', 'lightgreen']}
              customSegmentLabels={[
                {
                  text: 'E',
                  position: 'INSIDE',
                  color: '#ffffff80',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '19px',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: 'F',
                  position: 'INSIDE',
                  color: '#141414',
                },
              ]}
            />
          </div>

          {/* <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={4000}
              needleHeightRatio={0.4}
              ringWidth={10}
              currentValueText={`Power: ${havePlans?.Affiliate
                ? ((Math.floor(usdtTotal / pay) * recieve) + Number(nccCredit) || 0) : 0}`}
              minValue={0}
              value={(((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit)) >= nccTotal)
                && (havePlans?.Affiliate?.isActiveUserPlan === 1)
                ? ((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit))
                : 0}
              maxValue={((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit)) + recieve}
              valueTextFontSize="10px"
              labelFontSize="5px"
              // segments={10}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={150}
              height={100}
              forceRender
              // segmentColors={['red', 'grey', 'grey', 'grey', 'lightgreen']}
              customSegmentLabels={[
                {
                  text: 'E',
                  position: 'INSIDE',
                  color: '#ffffff80',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '19px',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: 'F',
                  position: 'INSIDE',
                  color: '#141414',
                },
              ]}
            />
          </div> */}

          <div className="powerAndCredit_holder">
            <div className="affi_credit">
              <span className="affi_credit_label">
                Credit :
              </span>
              <span className="affi_credit_value">
                <strong>
                  {Number(nccCredit)}
                </strong>
              </span>
            </div>
            <hr className="affi_guage_hr" />
            <div className="affi_power">
              <span className="affi_power_label">
                Power :
              </span>
              <span className="affi_power_value">
                <strong>
                  {(havePlans?.Affiliate?.isActiveUserPlan === 1)
                    ? ((Math.floor(usdtTotal / pay) * recieve) + Number(nccCredit))
                    : 0}
                </strong>
              </span>
            </div>
          </div>

          <div className="afiiliate_speed_holder afiiliate_speed_holder_ncc">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={2000}
              needleHeightRatio={0.4}
              ringWidth={10}
              currentValueText={`Total NCC: ${nccTotal || 0} `}
              minValue={0}
              value={nccTotal || 0}
              maxValue={(Math.floor(nccTotal / recieve) + 1) * recieve}
              // maxValue={3150}
              valueTextFontSize="10px"
              labelFontSize="5px"
              // segments={10}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={150}
              height={100}
              forceRender
            />
          </div>
        </div>


        <div className="text-center">

          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeElastic"
              needleTransitionDuration={4000}
              needleHeightRatio={0.7}
              ringWidth={20}
              currentValueText={`Your Profit: ${YourProfit || 0} $`}
              minValue={0}
              value={YourProfit || 0}
              maxValue={1000}
              valueTextFontSize="10px"
              labelFontSize="30px"
              segments={10}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              // width={window.innerWidth / 6}
              width={250}
              height={170}
              forceRender
            />
          </div>

          <div className="remain_day_affi_holder text-center">
            <div
              className={RemainDay < 10 ? 'remain_day_affi text-center remain_error'
                : 'remain_day_affi text-center'}
            >
              <span className="remain_day_affi_desk_value">
                {RemainDay}
              </span>
            </div>
            Remaning Days
          </div>

          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeElastic"
              needleTransitionDuration={4000}
              needleHeightRatio={0.7}
              ringWidth={20}
              currentValueText={`Repayment : ${Repayment || 0} %`}
              minValue={0}
              value={Repayment || 0}
              maxValue={100}
              valueTextFontSize="10px"
              labelFontSize="30px"
              segments={10}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              // width={window.innerWidth / 6}
              width={250}
              height={170}
              forceRender
            />
          </div>

        </div>
      </div>



      {/* ===================== */}
      {/*   Affi guage MOBILE   */}
      {/* ===================== */}

      <div className="affi_guage_mobile">

        <div className="text-center">

          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={4000}
              needleHeightRatio={0.4}
              ringWidth={8}
              currentValueText={`Total USDT: ${usdtTotal || 0} $`}
              minValue={0}
              value={usdtTotal > ((Math.ceil(nccTotal / recieve)) * pay)
                ? (Math.ceil((Number(nccTotal) || recieve) / recieve)) * pay : Number(usdtTotal)}
              // maxValue={nccTotal % recieve
              //   ? ((Math.floor(nccTotal / recieve) + 1) * pay) || pay
              //   : (Math.floor(nccTotal / recieve) * pay) || pay}
              maxValue={(Math.ceil(Number(nccTotal) / recieve)) * pay === pay
                ? pay : (Math.floor(Number(nccTotal) / recieve) + 1) * pay}
              valueTextFontSize="10px"
              labelFontSize="5px"
              // segments={1}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={110}
              height={80}
              forceRender
              customSegmentLabels={[
                {
                  text: 'E',
                  position: 'INSIDE',
                  color: '#ffffff80',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '19px',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: 'F',
                  position: 'INSIDE',
                  color: '#141414',
                },
              ]}
            />
          </div>

          {/* <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={4000}
              needleHeightRatio={0.4}
              ringWidth={8}
              currentValueText={`Power: ${havePlans?.Affiliate
                ? ((Math.floor(usdtTotal / pay) * recieve) + Number(nccCredit) || 0) : 0}`}
              minValue={0}
              value={(((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit)) >= nccTotal)
                && (havePlans?.Affiliate?.isActiveUserPlan === 1)
                ? ((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit))
                : 0}
              maxValue={((Math.ceil(usdtTotal / pay) * recieve) + Number(nccCredit)) + recieve}
              valueTextFontSize="10px"
              labelFontSize="5px"
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={110}
              height={80}
              forceRender
              customSegmentLabels={[
                {
                  text: 'E',
                  position: 'INSIDE',
                  color: '#ffffff80',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '19px',
                },
                {
                  text: ' ',
                  position: 'INSIDE',
                  color: '#555',
                },
                {
                  text: 'F',
                  position: 'INSIDE',
                  color: '#141414',
                },
              ]}
            />
          </div> */}

          <div className="powerAndCredit_holder">
            <div className="affi_credit">
              <span className="affi_credit_label">
                Credit :
              </span>
              <span className="affi_credit_value">
                <strong>
                  {Number(nccCredit)}
                </strong>
              </span>
            </div>
            <hr className="affi_guage_hr" />
            <div className="affi_power">
              <span className="affi_power_label">
                Power :
              </span>
              <span className="affi_power_value">
                <strong>
                  {(havePlans?.Affiliate?.isActiveUserPlan === 1)
                    ? ((Math.floor(usdtTotal / pay) * recieve) + Number(nccCredit))
                    : 0}
                </strong>
              </span>
            </div>
          </div>

          <div className="afiiliate_speed_holder  afiiliate_speed_holder_ncc">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeSinInOut"
              needleTransitionDuration={4000}
              needleHeightRatio={0.4}
              ringWidth={8}
              currentValueText={`Total NCC: ${nccTotal || 0} `}
              minValue={0}
              value={nccTotal || 0}
              maxValue={(Math.floor(nccTotal / recieve) + 1) * recieve}
              valueTextFontSize="10px"
              labelFontSize="5px"
              // segments={1}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              width={110}
              height={80}
              forceRender

            />
          </div>
        </div>

        <div className="text-center remain_day_affi_holder_div_mobile">
          <div
            className="remain_day_affi_holder_mobile text-center"
          >
            <div
              className={RemainDay < 10 ? 'remain_day_affi_mobile text-center remain_error'
                : 'remain_day_affi_mobile text-center'}
            >
              <span className="remain_day_affi_mobile_value">
                {RemainDay}
              </span>
            </div>
            {/* Remaning Days */}
          </div>
        </div>



        <div className="text-center">


          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeElastic"
              needleTransitionDuration={4000}
              needleHeightRatio={0.55}
              ringWidth={10}
              // currentValueText="0 $"
              currentValueText={`Your Profit: ${YourProfit || 0} $`}
              minValue={0}
              value={YourProfit || 0}
              maxValue={1000}
              valueTextFontSize="10px"
              labelFontSize="30px"
              segments={5}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              // width={window.innerWidth / 6}
              width={160}
              height={130}
              forceRender
            />
          </div>

          <div className="afiiliate_speed_holder">
            <ReactSpeedometer
              className="speedometer"
              needleTransition="easeElastic"
              needleTransitionDuration={4000}
              needleHeightRatio={0.55}
              ringWidth={10}
              currentValueText={`Repayment : ${Repayment || 0} %`}
              minValue={0}
              value={Repayment || 0}
              maxValue={100}
              valueTextFontSize="10px"
              labelFontSize="30px"
              segments={5}
              // customSegmentStops={[0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]}
              // customSegmentStops={[0, 250, 750, 1000]}
              // segmentColors={['#3cd2a5', '#ffffff10', '#ffffff10']}
              // customSegmentLabels={[
              //   {
              //     text: 'Very Bad',
              //     position: 'INSIDE',
              //     color: '#555',
              //   },
              //   {
              //     text: 'Bad',
              //     position: 'INSIDE',
              //     color: '#555',
              //   },
              //   {
              //     text: 'Ok',
              //     position: 'INSIDE',
              //     color: '#555',
              //     fontSize: '19px',
              //   },
              //   {
              //     text: 'Good',
              //     position: 'INSIDE',
              //     color: '#555',
              //   },
              //   {
              //     text: 'Very Good',
              //     position: 'INSIDE',
              //     color: '#555',
              //   },
              // ]}
              textColor="#9da3a6"
              startColor="#ffffff10"
              endColor={themeColor}
              needleColor={themeColor}
              // width={window.innerWidth / 6}
              width={160}
              height={130}
              forceRender
            />
          </div>


        </div>
      </div>



      <div className="text-center ">
        <button
          type="button"
          className="btn btn-outline-theme table-btn calc_table_affi_btn mb-4"
          onClick={() => {
            toggleFull();
          }}
        >
          {/* <i className="fas fa-calculator" />
        {' '} */}
          Calculator
        </button>
      </div>
    </>
  );
};


AffiliateReport.propTypes = {
  havePlans: PropTypes.objectOf.isRequired,
  AffiliateSummary: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  planWithdrawSetting: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme, constants } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    AffiliateSummary: userInfo?.user?.AffiliateSummary,
    balanceWallet: userInfo?.user?.balanceWallet,
    planWithdrawSetting: constants?.planWithdrawSetting,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(AffiliateReport);
