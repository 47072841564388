const axiosHeaders = () => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': true,
  Authorization: `Bearer ${localStorage.getItem('token')}`,
});

export default axiosHeaders;



