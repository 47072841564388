import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';




const RefundTable = ({ Fund, plancrypto }) => {
  const [lvl, setLvl] = useState(0);
  const [level, setLevel] = useState(0);

  const [fee1, setFee1] = useState(30);
  const [fee2, setFee2] = useState(10);
  const [fee3, setFee3] = useState(20);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState();

  const commafy = (num) => {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const validatorConfigs = {
    element: message => <div className="validationErr">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const handleLevel = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setUnValid(false);
      setValid('valid');
      setLevel(lvl);
    } else {
      setUnValid(true);
      setValid('');
      setLvl('');
      // validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <div className="mb-3 mt-3">
        In this table, by entering the number of people invited by you who have activated their refund plan,
        you can see the amount of your reward from the following levels.
      </div>
      <div className="mb-3 mt-3">
        <span className="table_title">
          Profits calculations :
        </span>
        <table
          className="table table-sm affiliate text-center calcTable react-table resizable-table"
          style={{ height: 'auto' }}
        >
          <thead>
            <tr>
              <th scope="col">Level</th>
              <th scope="col">Member</th>
              <th scope="col">Fee</th>
              <th scope="col">All Money</th>
              {/* <th scope="col">Received</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>{commafy(level)}</td>
              <td>{fee1} $</td>
              <td>{commafy((level) * fee1)}</td>
              {/* <td>{commafy((level) * fee1) * (Fund?.Level1?.profit / 100)}</td> */}
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{commafy(level ** 2)}</td>
              <td>{fee2} $</td>
              <td>{commafy(Math.floor(((level ** 2) * fee2)))}</td>
              {/* <td>{commafy(Math.floor(((level ** 2) * fee2)) * (Fund?.Level2?.profit / 100))}</td> */}
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{commafy(level ** 3)}</td>
              <td>{fee3} $</td>
              <td>{commafy(Math.floor(((level ** 3) * fee3)))}</td>
              {/* <td>{commafy(Math.floor(((level ** 3) * fee3)) * (Fund?.Level3?.profit / 100))}</td> */}
            </tr>
            <tr>
              <td />
              <td />
              {/* <td /> */}
              <th style={{ color: '#3cd2a5' }}>Total :</th>
              <th className="total_table_affiliate">{commafy((((level) * fee1))
                + (Math.floor(((level ** 2) * fee2)))
                + (Math.floor(((level ** 3) * fee3))))}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-left">
        <label className="form-label" htmlFor="level">Members :</label>
      </div>
      <Row>
        <Col md={3}>
          <form onSubmit={handleLevel}>
            <div className="input-group flex-nowrap">
              <input
                className={unValid ? 'unValid form-control text-center' : `form-control text-center ${valid}`}
                placeholder="Member at first Level (2-9)"
                id="level"
                autoComplete="false"
                value={lvl}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                }}
                onChange={async (e) => {
                  await setLvl(e.target.value);
                  if (validator.current.allValid()) {
                    setUnValid(false);
                    setValid('valid');
                  }
                  if (!validator.current.allValid()) {
                    setUnValid(true);
                    setValid('');
                    setLvl('');
                  }
                }}
                onClick={() => setLvl('')}
              />
              <button type="submit" className="input-group-text promotion_table_btn" onClick={handleLevel}>
                Calculate
              </button>
            </div>
          </form>
          {validator.current.message('Members', lvl, 'numeric|max:9,num|min:2,num')}
        </Col>
      </Row>
    </>
  );
};

RefundTable.propTypes = {
  Fund: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, constants } = state;
  return {
    plancrypto: constants?.plancrypto,
    Fund: userInfo?.user?.voidPlans?.Fund,
  };
}

export default connect(mapStateToProps)(RefundTable);
