import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { getMainColor } from '../../../../../../shared/helpers/themHelper';


const RefferalChart = ({ dir, chartData, theme }) => {
  const data = [
    { name: 'Jan', num: chartData?.moon?.find(c => c.name === 'Jan')?.num || 0 },
    { name: 'Feb', num: chartData?.moon?.find(c => c.name === 'Feb')?.num || 0 },
    { name: 'Mar', num: chartData?.moon?.find(c => c.name === 'Mar')?.num || 0 },
    { name: 'Apr', num: chartData?.moon?.find(c => c.name === 'Apr')?.num || 0 },
    { name: 'May', num: chartData?.moon?.find(c => c.name === 'May')?.num || 0 },
    { name: 'Jun', num: chartData?.moon?.find(c => c.name === 'Jun')?.num || 0 },
    { name: 'Jul', num: chartData?.moon?.find(c => c.name === 'Jul')?.num || 0 },
    { name: 'Aug', num: chartData?.moon?.find(c => c.name === 'Aug')?.num || 0 },
    { name: 'Sep', num: chartData?.moon?.find(c => c.name === 'Sep')?.num || 0 },
    { name: 'Oct', num: chartData?.moon?.find(c => c.name === 'Oct')?.num || 0 },
    { name: 'Nov', num: chartData?.moon?.find(c => c.name === 'Nov')?.num || 0 },
    { name: 'Dec', num: chartData?.moon?.find(c => c.name === 'Dec')?.num || 0 },
  ];

  const [themeColor, setThemeColor] = useState('');
  useEffect(() => {
    // if (theme === 'theme-dark') {
    setThemeColor(getMainColor());
    // } else if (theme === 'theme-capital') {
    //   setThemeColor('#ff8f1f');
    // }
  }, [theme]);

  return (
    <Col col={12}>
      {/* <Card>
        <CardBody> */}
      <div className="card__title">
        {/* <h5 className="bold-text">Your Refferal Progress</h5> */}
      </div>
      <div dir="ltr">
        <ResponsiveContainer height={300}>
          <LineChart
            data={data}
            width={400}
            height={400}
            margin={{
              top: 0, right: 0, left: -15, bottom: 0,
            }}
          >
            <XAxis dataKey="name" reversed={dir === 'rtl'} />
            <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
            <CartesianGrid strokeDasharray="2 2" />
            <Tooltip
              itemStyle={{ color: 'Black', backgroundColor: themeColor }}
              labelStyle={{ color: 'Black', backgroundColor: themeColor }}
              contentStyle={{ color: 'Black', backgroundColor: themeColor }}
            />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="num" className="refferal_chart_line" activeDot={{ r: 8 }} />

          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* </CardBody>
      </Card> */}
    </Col>
  );
};

RefferalChart.propTypes = {
  dir: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  chartData: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    chartData: userInfo.user?.Refferal?.chartData,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(RefferalChart);
