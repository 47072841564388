import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  UncontrolledTooltip, Button, Modal, ButtonToolbar,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReactSpeedometer from 'react-d3-speedometer';
import { setBalance, setHavePlan, getRefferal } from '../../../../../redux/actions/userActions';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { store } from '../../../../App/store';
import serverURL from '../../../../../utils/url';
import { clearLoadingStacking, setLoadingStacking } from '../../../../../redux/actions/loaderAction';



const DrawTable = ({
  balanceWallet,
  havePlans,
  stackingLoad,
  FFCode,
  isActive,
  isAllowPlan,
  haveSecPass,
  plans,
  plancrypto,
  currencies,
  tab,
  StackingSummary,
  setShowHistory,
}) => {
  const [asasas, setAsasas] = useState(false);


  return (
    <>

      <div className="pr-3 pl-3">
        <div className="text-right">
          <button
            type="button"
            className="btn magic_history_btn m-2"
            onClick={() => setShowHistory(false)}
          >
            <i className="fas fa-times" />
          </button>
          <h3 className="text-left magic_title_gold">
            Draw History
          </h3>
        </div>
        <table
          className="table table-sm affiliate text-center calcTable react-table resizable-table"
          style={{ height: 'auto' }}
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Draw Time</th>
              <th scope="col">Price</th>
              <th scope="col">Winner Code</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2022-06-5</td>
              <td>100 USDT</td>
              <td>68745898126</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>2022-01-05</td>
              <td>100 USDT</td>
              <td>98451621789</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>2022-01-05</td>
              <td>100 USDT</td>
              <td>98451621789</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>2022-01-05</td>
              <td>100 USDT</td>
              <td>98451621789</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>2022-01-05</td>
              <td>100 USDT</td>
              <td>98451621789</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>2022-01-05</td>
              <td>100 USDT</td>
              <td>98451621789</td>
            </tr>
          </tbody>
        </table>
      </div>


    </>
  );
};

DrawTable.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
  setShowHistory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader, constants } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    StackingSummary: userInfo.user?.StackingSummary,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
  };
}


export default connect(mapStateToProps)(DrawTable);
