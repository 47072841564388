import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import {
  Card, Row, Container, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import { useParams, withRouter, useHistory } from 'react-router-dom';

import DataTable from './DataTable';
import { getAvatarPlaceHolder, getLogoImgUrl, getStarImgUrl } from '../../../../../shared/helpers/themHelper';
import { profileImgURL } from '../../../../../utils/url';
import { getBinarySummaryProfit } from '../../../../../redux/actions/userActions';



const BinaryInvest = ({
  binarySummary, FFuser, theme, tab,
}) => {
  const [year, setYear] = useState((new Date()).getFullYear());
  const [yearList, setYearList] = useState([2022]);

  const monthList = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  const [month, setMonth] = useState(monthList.find(m => m.label === (new Date())
    .toLocaleString('default', { month: 'long' }))?.value);


  const dispatch = useDispatch();

  const onChangeMonth = (e) => {
    setMonth(e.target.value);
    dispatch(getBinarySummaryProfit(e.target.value, year));
  };

  const onChangeYear = (e) => {
    setYear(e.target.value);
    // dispatch(getBinarySummaryProfit(month, e.target.value));
  };

  // useEffect(() => {
  //   const tempList = [];
  //   tempList.push((new Date()).getFullYear());
  //   for (let i = 1; i < 5; i += 1) {
  //     tempList.push((new Date()).getFullYear() + i);
  //     tempList.push((new Date()).getFullYear() - i);
  //   }
  //   setYearList(tempList);
  // }, []);

  useEffect(() => {
    if (tab?.toString() === '3') {
      setMonth(monthList.find(m => m.label === (new Date())
        .toLocaleString('default', { month: 'long' }))?.value);
      dispatch(getBinarySummaryProfit());
    }
  }, [tab]);


  return (
    <>

      <Row className="binary_invest_info_holder">
        <Col md={2} xs={6}>
          <label className="form-label" htmlFor="year">Year :</label>
          <select
            className="form-select text-center"
            id="year"
            value={year}
            onClick={e => onChangeYear(e)}
            onChange={e => onChangeYear(e)}
          >
            {yearList && yearList?.sort()?.map((item, i) => (
              <option
                key={item}
                value={item}
              >
                {item}
              </option>
            ))}
          </select>
        </Col>
        <Col md={2} xs={6}>
          <label className="form-label" htmlFor="month">Month :</label>
          <select
            className="form-select text-center"
            id="month"
            value={month}
            onClick={e => onChangeMonth(e)}
            onChange={e => onChangeMonth(e)}
          >
            {monthList && monthList?.map((item, i) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </Col>
        <Col>
          <div className="binary_holder">
            <div>
              Your Binary Invest Profit :
              {' '}
              <span>
                {binarySummary?.profit || 0}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>

        <div className="binary_invest_table_holder">

          <DataTable />

        </div>


        <div className="binary_invest_card_holder">

          {binarySummary?.members?.slice(0, 5)?.map((item, i) => (
            <div className="binary_item">
              <div className="binary_item_pic">
                <img src={item?.userImage ? `${profileImgURL}/${item?.userImage}` : getAvatarPlaceHolder()} alt="" />
              </div>
              <div className="binary_item_col2">
                <div className="binary_item_ffcode">
                  {item?.FFCode}
                </div>
                <div className="binary_item_direct">
                  Direct: {item?.countUser}
                </div>
                <div className="binary_item_joindate">
                  Join: {item?.created_at.split('T')[0]}
                </div>
              </div>
              <div className="binary_item_col3">
                <div className="binary_item_nickname">
                  {item?.nickname}
                </div>
                <div className="binary_item_score">
                  {[...Array(Math.floor(item?.amount / 1000))]?.slice(0, 5)?.map((itemStar, ii) => (
                    <>
                      <img src={getStarImgUrl()} alt="" />
                    </>
                  ))}
                </div>
                <div className="binary_item_sell">
                  {Math.floor(item?.amount)} $
                </div>
              </div>
            </div>
          ))}

        </div>



      </Row>
    </>
  );
};


BinaryInvest.propTypes = {
  binarySummary: PropTypes.objectOf.isRequired,
  theme: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
};


function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    binarySummary: userInfo?.user?.binarySummary,
    theme: theme.className,
  };
}

export default withRouter(connect(mapStateToProps)(BinaryInvest));
