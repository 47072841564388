import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, Pie, PieChart,
} from 'recharts';
import { showLogs } from '../../../../App/Rules';
import getTooltipStyles from '@/shared/helpers';
import { getMainColor } from '../../../../../shared/helpers/themHelper';


const ReportsAffiliate = ({
  FFuser,
  haveSecPass,
  havePlans,
  balanceWallet,
  voidPlans,
  currencies,
  plans,
  plancrypto,
  depositWallet,
  ncc,
  tron,
  BuyInvestSummary,
  dir,
  theme,
}) => {
  const [fillcolor, setFillcolor] = useState('#3cd2a5');
  const [members, setMembers] = useState();

  const data = [
    { sell: 'Jan', amount: 0 },
    { sell: 'Feb', amount: 0 },
    { sell: 'Mar', amount: 0 },
    { sell: 'Apr', amount: 0 },
    { sell: 'May', amount: 0 },
    { sell: 'Jun', amount: 0 },
    { sell: 'Jul', amount: 0 },
    { sell: 'Aug', amount: 0 },
    { sell: 'Sep', amount: 0 },
    { sell: 'Oct', amount: 0 },
    { sell: 'Nov', amount: 0 },
    { sell: 'Dec', amount: 0 },
  ];

  const pieData = [
    { value: 0, fill: fillcolor },
    {
      value: 100, fill: '#f2f4f7',
    },
  ];
  const pieDataMore = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];

  useEffect(() => {
    // if (theme === 'theme-dark') {
    setFillcolor(getMainColor());
    // } else if (theme === 'theme-capital') {
    //   setFillcolor('#ff8f1f');
    // }
  }, [theme]);

  return (

    <>


      <Card>
        <CardHeader className="text-center">
          <h4>Affiliate</h4>
        </CardHeader>
        <form>
          <CardBody>
            <h6 className="text-center"> Summary {(new Date()).getFullYear()} : </h6>

            <ResponsiveContainer className="text-center m-auto report_chart" height={170} width="100%">
              <BarChart data={data} margin={{ top: 10, left: -20 }}>
                <Tooltip
                  itemStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  labelStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  contentStyle={{ color: 'Black', backgroundColor: fillcolor }}
                  {...getTooltipStyles('defaultItems')}
                />
                <XAxis dataKey="sell" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="amount" name="sell" className="desk_ref_chart_bar" barSize={8} />
              </BarChart>
            </ResponsiveContainer>

            <h6 className="mt-3 mb-0"> {(new Date()).toLocaleString('default', { month: 'long' })} : </h6>

            <div className="report_title">Sales volume</div>
            <div className="report_data_holder">
              <div className="report_data_item">
                <div className="report_data_label">
                  NCC
                </div>
                <div className="report_data_value">
                  -
                </div>
              </div>
              <div className="report_data_item">
                <div className="report_data_label">
                  USDT
                </div>
                <div className="report_data_value">
                  -
                </div>
              </div>
            </div>

            <div className="report_title">Total Profits</div>
            <div className="report_data_holder">
              <div className="report_data_item">
                <div className="report_data_label">
                  NCC
                </div>
                <div className="report_data_value">
                  -
                </div>
              </div>
              <div className="report_data_item">
                <div className="report_data_label">
                  USDT
                </div>
                <div className="report_data_value">
                  -
                </div>
              </div>
            </div>

            <div className="report_chart_holder">
              <PieChart className="m-auto report_piechart" height={250} width={300}>
                <Pie
                  data={members > 0 ? pieDataMore : pieData}
                  dataKey="value"
                  cx={130}
                  cy={140}
                  startAngle={270}
                  endAngle={-90}
                  innerRadius={72}
                  outerRadius={80}
                  paddingAngle={1.5}
                />
              </PieChart>
              <p className="report_chart_label">
                {Math.floor(0)} %
                <br />
                {/* <hr className="report_seperator" /> */}
                <span className="report_chart_member">
                  {Math.floor(0)} Member
                </span>
              </p>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn btn-outline-theme table-btn calc_table_refund_btn"
                disabled
              >
                History
              </button>
            </div>


          </CardBody>
        </form>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};

ReportsAffiliate.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  voidPlans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  plans: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tron: PropTypes.string.isRequired,
  ncc: PropTypes.string.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  dir: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo,
    constants,
    currency,
    theme,
  } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    havePlans: userInfo?.user?.havePlans,
    balanceWallet: userInfo?.user?.balanceWallet,
    depositWallet: userInfo?.user?.depositWallet,
    haveSecPass: userInfo?.user?.haveSecPass,
    voidPlans: userInfo?.user?.voidPlans,
    plancrypto: constants?.plancrypto,
    plans: constants?.plans,
    currencies: constants?.currencies,
    tron: currency.tron,
    ncc: currency.ncc,
    BuyInvestSummary: userInfo.user?.BuyInvestSummary,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(ReportsAffiliate);
