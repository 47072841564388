import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';


import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { Card, CardBody, CardLink } from 'reactstrap';
import { toast } from 'react-toastify';
import request from '../../../services/fetchRequest';
import { serverbetaURL } from '../../../utils/url';
import { showLogs } from '../../App/Rules';

const ForgetPass = ({ toLogin }) => {
  const [email, setEmail] = useState();
  const [, forceUpdate] = useState();
  const [kere, setKere] = useState(0);
  const [requested, setRequested] = useState(false);



  const dispatch = useDispatch();

  const sendRequest = () => {
    setRequested(true);
    setInterval(() => {
      setRequested(false);
    }, 5000);
  };

  const validatorConfigs = {
    messages: {
      required: 'That feild is required.',
      // default: 'Validation has failed!',
    },
    element: message => <div className="validationErr">{message}</div>,
  };
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const reset = () => {
    setEmail('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid() && kere < 5) {
      sendRequest();

      axios.post(`${serverbetaURL}/auth/forgetPassword`, JSON.stringify({ email }), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('forgetPassword :', data);
          }
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setKere(kere + 1);
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              toLogin();
            }
          }
        }).catch(() => {
          toast.error('Something went wrong , Try again later !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setKere(kere + 1);
        });
    } else if (kere >= 5) {
      toast.error('Sorry! Your request banned , Try out later ...', {
        position: 'top-center',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className="account__card">
      <Card className="loginCard">
        <CardBody>

          <form onSubmit={onSubmit} method="POST" name="login_form">
            <h2 className="text-center mb-3">Recover your password</h2>
            {/* <div className="text-white text-opacity-50 text-center mb-4">
              1
            </div> */}
            <div className="mb-3">
              <div className="d-flex">
                <label htmlFor="email" className="form-label">Email Address</label>
              </div>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                name="email"
                placeholder="Enter your email address"
                aria-describedby="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {validator.current.message('email', email, 'required|email')}
            </div>
            <div className="mb-3">
              <div className="landing_sublink_div">
                <CardLink onClick={toLogin} className="landing_sublink">Back to login</CardLink>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 submit_button"
              disabled={requested ? 'true' : ''}
            >
              Send Request
            </button>
            {/* <div className="text-center text-white text-opacity-50">
              Dont have an account yet? <a href="page_register.html">Sign up</a>.
            </div> */}
          </form>

        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </div>
  );
};

ForgetPass.propTypes = {
  toLogin: PropTypes.func.isRequired,
};

export default connect()(ForgetPass);
