import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  Row, Col,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { setCurrency } from '../../../../redux/actions/currencyActions';
import { getMainColor } from '../../../../shared/helpers/themHelper';



const AwardTable = ({
  theme,
}) => {
  const [lvl, setLvl] = useState(0);
  const [level, setLevel] = useState(0);
  const [fee, setFee] = useState(0);
  const [feeTether, setFeeTether] = useState(0);
  const [Ncc, setNcc] = useState(0);

  const dispatch = useDispatch();

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [themeColor, setThemeColor] = useState();

  useEffect(() => {
    setThemeColor(getMainColor());
  }, [theme]);




  const commafy = (num) => {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const validatorConfigs = {
    element: message => <div className="validationErr">{message}</div>,
  };

  const validator = useRef(new SimpleReactValidator(validatorConfigs));


  const handleLevel = async (e) => {
    e.preventDefault();
    setLevel(lvl);
  };

  return (
    <>

      <div className="mb-3 mt-3">
        <table className="table table-sm affiliate text-center calcTable react-table resizable-table">
          <thead>
            <tr>
              <th scope="col">Level</th>
              <th scope="col">Member</th>
              <th scope="col">Score</th>
              <th scope="col">Level Score</th>
              <th scope="col">Total Score</th>
              <th scope="col">Rounded</th>
              <th scope="col">Received($)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>{commafy(level ** 1)}</td>
              <td>20</td>
              <td>{commafy((level ** 1) * 20)}</td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{commafy(level ** 2)}</td>
              <td>10</td>
              <td>{commafy((level ** 2) * 10)}</td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{commafy(level ** 3)}</td>
              <td>10</td>
              <td>{commafy((level ** 3) * 10)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10)
                ) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10)
                ) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{commafy(level ** 4)}</td>
              <td>5</td>
              <td>{commafy((level ** 4) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10) + ((level ** 4) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10)
                  + ((level ** 4) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10)
                  + ((level ** 4) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <th className="total_table_affiliate_title" style={{ color: themeColor }}>Total :</th>
              <td className="total_table_affiliate" style={{ color: themeColor }}>
                {commafy(((Math.round((((level ** 1) * 20)
                  + ((level ** 2) * 10) + ((level ** 3) * 10)
                ) / 1000)) * 5000) + ((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 10)
                  + ((level ** 4) * 5)) / 1000)) * 5000))} $
              </td>
            </tr>
            {/* <tr>
              <th scope="row">5</th>
              <td>{commafy(level ** 5)}</td>
              <td>5</td>
              <td>{commafy((level ** 5) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>{commafy(level ** 6)}</td>
              <td>5</td>
              <td>{commafy((level ** 6) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>{commafy(level ** 7)}</td>
              <td>5</td>
              <td>{commafy((level ** 7) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>{commafy(level ** 8)}</td>
              <td>5</td>
              <td>{commafy((level ** 8) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>{commafy(level ** 9)}</td>
              <td>5</td>
              <td>{commafy((level ** 9) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5)) / 1000)) * 5000)} $
              </td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>{commafy(level ** 10)}</td>
              <td>5</td>
              <td>{commafy((level ** 10) * 5)}</td>
              <td>
                {commafy(((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5)
                  + ((level ** 10) * 5))}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5)
                  + ((level ** 10) * 5)) / 1000)) * 1000)}
              </td>
              <td>
                {commafy((Math.round((((level ** 1) * 20) + ((level ** 2) * 10) + ((level ** 3) * 5)
                  + ((level ** 4) * 5) + ((level ** 5) * 5) + ((level ** 6) * 5)
                  + ((level ** 7) * 5) + ((level ** 8) * 5) + ((level ** 9) * 5)
                  + ((level ** 10) * 5)) / 1000)) * 5000)} $
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <Row className="affiliate_under_table">
        <Col md={2}>
          <Row className="affiliate_under_table">
            <Col md={5} xs={5}>
              <label className="form-label quiceInfo_row" htmlFor="level">Members :</label>
            </Col>
          </Row>
        </Col>
        {/* <Col md={7} /> */}
      </Row>
      <Row className="affiliate_table_values_row">
        <Col md={2}>
          <form onSubmit={e => handleLevel(e)}>
            <div className="input-group flex-nowrap">
              <input
                className={unValid ? 'unValid form-control text-center' : `form-control text-center ${valid}`}
                placeholder="5-9"
                id="level"
                autoComplete="false"
                value={lvl}
                onBlur={() => {
                  setUnValid(false);
                  setValid('');
                }}
                onChange={async (e) => {
                  await setLvl(e.target.value);
                  // if (validator.current.allValid()) {
                  //   setUnValid(false);
                  //   setValid('valid');
                  // }
                  if (!validator.current.allValid()) {
                    // setUnValid(true);
                    setValid('');
                    setLvl('');
                  }
                }}
                onClick={() => setLvl('')}
                onFocus={e => dispatch(setCurrency())}
              />
              <button
                type="submit"
                className="input-group-text promotion_table_btn"
                style={{ color: themeColor }}
              // onClick={e => handleLevel(e)}
              >
                Calculate
              </button>
            </div>
          </form>
          {validator.current.message('Members', lvl, 'numeric|max:9,num|min:5,num')}
          {validator.current.message('price', Ncc, 'min:0,num')}
        </Col>
      </Row>
    </>
  );
};


AwardTable.propTypes = {
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { theme } = state;
  return {
    theme: theme?.className,
  };
}

export default connect(mapStateToProps)(AwardTable);
