import axios from 'axios';
import { showLogs } from '../../containers/App/Rules';
import { serverURL } from '../../utils/url';


export const SET_INITIAL_CURRENCY = 'SET_INITIAL_CURRENCY';
export const SET_CURRENCY = 'SET_CURRENCY';
export const CLEAR_CURRENCY = 'CLEAR_CURRENCY';



export const setInitialCurrency = price => dispatch => dispatch({ type: 'SET_INITIAL_CURRENCY', price });
export const clearCurrency = () => dispatch => dispatch({ type: 'CLEAR_CURRENCY' });

export const setCurrency = () => (dispatch) => {
  axios({
    method: 'get',
    url: `${serverURL}/holder/checkPrice`,
  })
    .then((response) => {
      if (showLogs) {
        console.log('checkPrice :', response);
      }
      const nccPrice = Number(response.data.NCC).toFixed(4);
      const tronPrice = Number(response.data.TRX).toFixed(4);
      const tetherPrice = Number(response.data.USDT).toFixed(4);
      dispatch({
        type: 'SET_CURRENCY',
        nccPrice,
        tronPrice,
        tetherPrice,
      });
    }).catch(e => null);
};
