

// ***************************************
// *************** Logs ******************
// ***************************************

// export const showLogs = true;

export const showLogs = false;




// ***************************************
// *********** custom FFCode *************
// ***************************************
// export const customFFCode = '30334455';
// export const customFFCode = '66666666';
// export const customFFCode = '30000001';

// export const customFFCode = '30378887';
// export const customFFCode = '30638552';
// export const customFFCode = '30795941';
// export const customFFCode = '30429344';
// export const customFFCode = '30309633';
// export const customFFCode = '30412509';
// export const customFFCode = '30395089';
// export const customFFCode = '30303030';
// export const customFFCode = '30796627';

export const customFFCode = false;


