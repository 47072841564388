import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';


import SelectSearch, { fuzzySearch } from 'react-select-search';
import { userSelectRefferal } from '../../../../../../redux/actions/userActions';
import { getLogoImgUrl, getPlanIcon } from '../../../../../../shared/helpers/themHelper';
import { profileImgURL } from '../../../../../../utils/url';



const SearchFF = ({
  listData,
  selectedUser,
  FFuser,
  setUserFromSelect,
  userImage,
  nickname,
  theme,
}) => {
  const [value, setValue] = useState();
  const [options, setOptions] = useState();

  const dispatch = useDispatch();

  const [logo, setLogo] = useState();
  useEffect(() => {
    setLogo(getLogoImgUrl());
  }, [theme]);

  const handleCodeSelect = (v) => {
    setValue(v);
    if (selectedUser?.FFCode !== FFuser.FFCode) {
      setUserFromSelect(selectedUser?.FFCode);
      dispatch(userSelectRefferal(listData.find(u => u.FFCode === v)));
    }
  };

  useEffect(() => {
    const tempOptions = [];
    listData?.forEach((user, i) => {
      tempOptions.push({ name: user.FFCode, value: user.FFCode });
    });
    setOptions(tempOptions);
  }, []);



  useEffect(() => {
    if (selectedUser) {
      if (selectedUser?.FFCode !== FFuser.FFCode) {
        setValue(selectedUser.FFCode);
      }
    }
  }, [selectedUser]);

  function getOptions(query) {
    return new Promise((resolve, reject) => {
      const filterUsers = listData?.filter(u => u?.FFCode?.includes(query));
      resolve(filterUsers?.map(({ FFCode, id }) => ({ value: FFCode, name: FFCode })))
        .catch(reject);
    });
  }



  return (
    <div className="reffral_info_holder">
      {/* <Row> */}
      <div className="refferal_plans_holder">
        <div className="refferal_planicon_holder">

          <div className="planicon_holder">
            <img
              className={selectedUser?.plan?.find(p => p.id === 1) ? '' : 'planicon_deactive'}
              src={getPlanIcon().fund}
              alt=""
            />
            <div className={selectedUser?.plan?.find(p => p.id === 1)
              ? 'refferal_plan_name refferal_plan_name_active' : 'refferal_plan_name'}
            >
              Fund
            </div>
          </div>

          <div className="planicon_holder">
            <img
              className={selectedUser?.plan?.find(p => p.id === 2) ? '' : 'planicon_deactive'}
              src={getPlanIcon().affiliate}
              alt=""
            />
            <div className={selectedUser?.plan?.find(p => p.id === 2)
              ? 'refferal_plan_name refferal_plan_name_active' : 'refferal_plan_name'}
            >
              Affiliate
            </div>
          </div>

          <div className="planicon_holder">
            <img
              className={selectedUser?.plan?.find(p => p.id === 3) ? '' : 'planicon_deactive'}
              src={getPlanIcon().stacking}
              alt=""
            />
            <div className={selectedUser?.plan?.find(p => p.id === 3)
              ? 'refferal_plan_name refferal_plan_name_active' : 'refferal_plan_name'}
            >
              Stacking
            </div>
          </div>

          <div className="planicon_holder">
            <img
              className={selectedUser?.buyInvest === '1' ? '' : 'planicon_deactive'}
              src={getPlanIcon().buyinvest}
              alt=""
            />
            <div className={selectedUser?.buyInvest === '1'
              ? 'refferal_plan_name refferal_plan_name_active' : 'refferal_plan_name'}
            >
              Buy Invest
            </div>
          </div>

        </div>
      </div>
      <div className="refferal_row_two">
        <div className="refferal_avatar">
          <img
            src={selectedUser?.userImage ? `${profileImgURL}/${selectedUser?.userImage}` : logo}
            alt=""
          />
        </div>
        <div className="refferal_nick_search_holder">
          <div className="refferal_nickname">
            <span className={selectedUser?.nickname ? '' : 'no_nick_name'}>
              {selectedUser?.nickname || 'Nickname'}
            </span>
          </div>
          <div>
            <SelectSearch
              options={options}
              value={value}
              onChange={v => handleCodeSelect(v)}
              search
              getOptions={getOptions}
              // filterOptions={fuzzySearch}
              placeholder={selectedUser ? selectedUser?.FFCode : 'Search the FF codes...'}
            />
          </div>
        </div>
      </div>


      {/* <Col md={5}>
        <div className="mb-1">
          <div className="refferal_info">
            <Row>
              <span>Fund: {selectedUser?.plan?.find(p => p.id === 1) ? ' ✅' : ' ❌'}</span>
              <span>Affiliate: {selectedUser?.plan?.find(p => p.id === 2) ? ' ✅' : ' ❌'}</span>
              <span>Staking: {selectedUser?.plan?.find(p => p.id === 3) ? ' ✅' : ' ❌'}</span>
              <span>Buy Invest: {selectedUser?.buyInvest ? ' ✅' : ' ❌'}</span>
            </Row>
          </div>
        </div>
      </Col> */}
      {/* </Row> */}



    </div>


  );
};


SearchFF.propTypes = {
  listData: PropTypes.objectOf.isRequired,
  selectedUser: PropTypes.objectOf.isRequired,
  FFuser: PropTypes.objectOf.isRequired,
  setUserFromSelect: PropTypes.func.isRequired,
  userImage: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    listData: userInfo?.user?.Refferal?.listData,
    selectedUser: userInfo?.user?.selectedUser,
    FFuser: userInfo?.user?.FFuser,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(SearchFF);

