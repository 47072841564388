import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, Modal, ButtonToolbar,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import {
  getBalance, getStackingSummary,
} from '../../../../../redux/actions/userActions';
import { serverbetaURL } from '../../../../../utils/url';
import { clearLoadingStacking, setLoadingStacking } from '../../../../../redux/actions/loaderAction';
import NoSecPass from '../../../../Layout/NoSecPass';
import { showLogs } from '../../../../App/Rules';
import { getCryptoImgUrl } from '../../../../../shared/helpers/themHelper';
import axiosHeaders from '../../../../../utils/axiosHelper';



const StakingJoin = ({
  balanceWallet,
  stackingLoad,
  FFCode,
  theme,
  isActive,
  isAllowPlan,
  haveSecPass,
  plancrypto,
  StackingSummary,
}) => {
  const [duration, setDuration] = useState(12);
  const [nccAmount, setNccAmount] = useState();
  const [secPass, setSecPass] = useState();
  const [nccNeed, setNccNeed] = useState(10000);
  const [nccMax, setNccMax] = useState();
  const [, forceUpdate] = useState();

  const [disable, setDisable] = useState(false);

  const secInput = useRef(null);

  const [nccImgURL, setNccImgURL] = useState();
  useEffect(() => {
    setNccImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().ncc}`);
  }, [theme]);

  const [inRequest, setInRequest] = useState(false);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [TronStock, setTronStock] = useState(50);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());


  const dispatch = useDispatch();

  useEffect(() => {
    if (balanceWallet?.NCC?.Deposit === 0) {
      setDisable(true);
    }


    if (!isActive) {
      setDisable(true);
    }

    setNccMax(Math.round(balanceWallet?.NCC?.Deposit));
  }, [StackingSummary, balanceWallet]);

  const reset = () => {
    setNccAmount('');
    validator.current.hideMessages();
    forceUpdate(13);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };
  const handleCheckAmount = async (e) => {
    await setNccAmount(e.target.value);
    if (validator.current.fieldValid('ncc amount') && !Number.isNaN(+e.target.value)) {
      setUnValid(false);
      setValid('valid');
    } else if (Number.isNaN(+e.target.value)) {
      validator.current.showMessages();
      setNccAmount('');
      setUnValid(true);
      setValid('');
      forceUpdate(1);
    } else if (!Number.isNaN(+e.target.value)) {
      validator.current.showMessages();
      setUnValid(true);
      setValid('');
      forceUpdate(1);
    }
  };

  const handlecheckStacking = (e) => {
    e.preventDefault();

    if (validator.current.fieldValid('ncc amount') && balanceWallet?.TRX?.Deposit > TronStock) {
      toggle();
    } else if (!validator.current.fieldValid('ncc amount')) {
      validator.current.showMessages();
      setUnValid(true);
      forceUpdate(1);
    } else if (balanceWallet?.TRX?.Deposit < TronStock) {
      toast.error(`Your TRX deposit balance must be at least ${TronStock} to Activating this plan.`, {
        position: 'top-center',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };



  //                 ?(
  //   `*Your TRX deposit balance must be at least ${TronStock} to Activating this plan.`
  // ) : ''

  const handleStacking = () => {
    if (validator.current.fieldValid('Security Password')) {
      const stackingData = {
        FFCode,
        secPass,
        planCryptoCurrencyId: '13',
        cryptoCurrencyId: 1,
        isWithdrawByService: false,
        toCryptoCurrencyId: false,
        token: localStorage.getItem('token'),
        amount: nccAmount,
      };
      if (showLogs) {
        console.log(stackingData);
      }
      setInRequest(true);
      setDisable(true);
      dispatch(setLoadingStacking());
      reset();
      togglePass();
      localStorage.setItem('lastReq', new Date().getTime());
      validator.current.hideMessages();

      axios.post(`${serverbetaURL}/stackings/set`, JSON.stringify(stackingData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('staking/set :', data);
          }
          setInRequest(false);
          setDisable(false);
          dispatch(getStackingSummary());
          dispatch(getBalance());
          dispatch(clearLoadingStacking());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(clearLoadingStacking());
          dispatch(getStackingSummary());
          setInRequest(false);
          setDisable(false);
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleStacking();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleStacking}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >

        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <div className="text-center">
            <img src={nccImgURL} className="crypto_moda_img m-auto mb-2" alt="" />
            <h6>{nccAmount}</h6>
          </div>
          <br />
          <p>Stacking <strong> {nccAmount} </strong>  NCC for {duration} months</p>
          {/* ? */}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <div className="plan_conditions kyc_notes pb-0">
        <ul>
          <li>In this plan, you can receive an annual reward based on the amount of NCC you provide to the network.</li>
          <li>
            By joining this plan,
            the amount of NCCs in your wallet will be blocked in the wallet until the end of the contract period.
          </li>
        </ul>
      </div>
      <form onSubmit={e => handlecheckStacking(e)}>
        <div className="form-group mb-1">
          <label className="form-label" htmlFor="duration">Duration</label>
          <select
            className="form-select text-center"
            value={duration}
            onClick={async (e) => {
              await setDuration(e.target.value);
            }}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            disabled
          >
            <option selected value={12}>
              12 months
            </option>
          </select>
        </div>
        <div className="form-group mb-2">
          <label className="form-label" htmlFor="amount">Amount</label>
          <input
            type="text"
            className={unValid ? 'unValid form-control text-center ' : `${valid} form-control text-center`}
            id="amount"
            placeholder="Your NCC amount"
            value={nccAmount}
            onChange={e => handleCheckAmount(e)}
            onBlur={() => {
              setUnValid(false);
              setValid('');
              setTimeout(() => {
                validator.current.hideMessages();
                forceUpdate(13);
              }, 10000);
            }}
          />
          <div className="tefo_error tefo_error_strong">
            {validator.current.message('ncc amount', nccAmount,
              `required|min:${nccNeed},num|max:${nccMax},num|numeric|integer`)}
          </div>
        </div>
        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_lg w-100"
            disabled={disable
              || !isAllowPlan
              || true
              || stackingLoad
              ? 'true' : ''}
          >
            {inRequest || stackingLoad ? (
              <>
                <div className="spinner-border text-success spinner-border-sm" /> Processing ...
              </>
            ) : 'Stacking'}
          </button>
          <div className="tefo_error tefo_error_strong text-left staking_tron_err">
            {/* {
              (balanceWallet?.TRX?.Deposit < TronStock)

                ? (
                  `*Your TRX deposit balance must be at least ${TronStock} to Activating this plan.`
                ) : ''
            } */}
          </div>
        </div>
      </form>
    </>
  );
};

StakingJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, loader, constants, theme,
  } = state;
  return {
    balanceWallet: userInfo.user?.balanceWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    plancrypto: constants?.plancrypto,
    StackingSummary: userInfo.user?.StackingSummary,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(StakingJoin);
