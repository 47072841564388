import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Button, Col, Popover, PopoverBody, PopoverHeader, Row,
} from 'reactstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import { store } from '../../App/store';
import Format2 from '../../../utils/Format2';
import { getCryptoImgUrl } from '../../../shared/helpers/themHelper';

const TopbarQuiceInfo = ({
  dir,
  theme,
  balance,
  IsOpenedNcc,
  IsOpenedTether,
  IsOpenedTron,
  setIsOpenedNcc,
  setIsOpenedTether,
  setIsOpenedTron,
  handleOpenNcc,
  handleOpenTether,
  handleOpenTron,
}) => {
  const prices = store.getState().currency;

  const [hiddenNcc, setHiddenNcc] = useState(true);
  const [hiddenTron, setHiddenTron] = useState(true);
  const [hiddenTether, setHiddenTether] = useState(true);

  const collapseAll = () => {
    setIsOpenedNcc(false);
    setIsOpenedTether(false);
    setIsOpenedTron(false);
  };


  const [nccImgURL, setNccImgURL] = useState();
  const [tronImgURL, setTronImgURL] = useState();
  const [tetherImgURL, setTetherImgURL] = useState();

  useEffect(() => {
    setNccImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().ncc}`);
    setTronImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tron}`);
    setTetherImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tether}`);
  }, [theme]);



  return (
    <div className="quice">
      <div className="quice_info">
        {(IsOpenedNcc || IsOpenedTether || IsOpenedTron) && (
          <button
            className="topbar__back"
            aria-label="topbar__back"
            type="button"
            onClick={collapseAll}
          />
        )}
        <div className="quice_info_img">
          <Button
            id="nccQuiceInfo"
            onClick={handleOpenNcc}
            outline
            className={IsOpenedNcc ? 'button-tooltip quiceInfo_btn open_quice_btn'
              : 'button-tooltip quiceInfo_btn close_quice_btn'}
          >
            {/* <span className="quice_info_image" id="nccQuiceInfo">.</span> */}
            <img className="quice_info_image" id="nccQuiceInfo" alt="" src={nccImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedNcc}
            target="nccQuiceInfo"
            toggle={handleOpenNcc}
            dir={dir}
            className="topmenu_popovers ncc_popover"
          >
            <PopoverHeader className="text-left">
              <p className="quice_info_title">
                NCC details
                <button
                  type="button"
                  className="secure_eye_btn_in"
                  id="eye"
                  onClick={e => setHiddenNcc(!hiddenNcc)}
                >
                  {hiddenNcc ? <EyeOffOutlineIcon className="eye_icon" />
                    : <EyeOutlineIcon className="eye_icon" />}
                </button>
              </p>
            </PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    <li> Total :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.ncc} $</li>
                    <li>{hiddenNcc ? '**********'
                      : (Number(balance?.NCC?.Deposit)
                        + Number(balance?.NCC?.BuyToken)
                        + Number(balance?.NCC?.Stacking)
                        + Number(balance?.NCC?.Fund)
                        + Number(balance?.NCC?.BuyInvest)
                        + Number(balance?.NCC?.Affiliate))
                        .toFixed(8).toString().substring(0, 7)}
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Deposit :</li>
                    <li> Buy Token :</li>
                    <li> Stacking :</li>
                    <li> Fund :</li>
                    <li> Affiliate :</li>
                    <li> Buy Invest :</li>
                  </ul>
                </Col>
                <Col col={1}>
                  <ul className="quiceInfo_ul_value">
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.Deposit || 0)?.toFixed(2)}
                      {balance?.NCC?.Deposit === null && !hiddenNcc && '0.00'}
                    </li>
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.BuyToken || 0)?.toFixed(2)}
                      {balance?.NCC?.BuyToken === null && !hiddenNcc && '0.00'}
                    </li>
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.Stacking || 0)?.toFixed(2)}
                      {balance?.NCC?.Stacking === null && !hiddenNcc && '0.00'}
                    </li>
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.Fund || 0)?.toFixed(2)}
                      {balance?.NCC?.Fund === null && !hiddenNcc && '0.00'}
                    </li>
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.Affiliate || 0)?.toFixed(2)}
                      {balance?.NCC?.Affiliate === null && !hiddenNcc && '0.00'}
                    </li>
                    <li>
                      {hiddenNcc ? '********' : (balance?.NCC?.BuyInvest || 0)?.toFixed(2)}
                      {balance?.NCC?.BuyInvest === null && !hiddenNcc && '0.00'}
                    </li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.ncc} $</span>
          <span className="quice_info_amount_price">
            Total : {hiddenNcc ? '**********'
              : (Number(balance?.NCC?.Deposit || 0)
                + Number(balance?.NCC?.BuyToken || 0)
                + Number(balance?.NCC?.Stacking || 0)
                + Number(balance?.NCC?.Fund || 0)
                + Number(balance?.NCC?.Affiliate || 0)
                + Number(balance?.NCC?.BuyInvest || 0))
                .toFixed(8).toString().substring(0, 7)}
            <button
              type="button"
              className="secure_eye_btn_out"
              id="eye"
              onClick={e => setHiddenNcc(!hiddenNcc)}
            >
              {hiddenNcc ? <EyeOffOutlineIcon className="eye_icon" />
                : <EyeOutlineIcon className="eye_icon" />}
            </button>
          </span>
        </div>
      </div>
      <div className="quice_info">
        <div className="quice_info_img">
          <Button
            id="tronQuiceInfo"
            onClick={handleOpenTron}
            outline
            className={IsOpenedTron ? 'button-tooltip quiceInfo_btn open_quice_btn'
              : 'button-tooltip quiceInfo_btn close_quice_btn'}
          >
            <img className="quice_info_image" id="tronQuiceInfo" alt="" src={tronImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedTron}
            target="tronQuiceInfo"
            toggle={handleOpenTron}
            dir={dir}
            className="topmenu_popovers tron_popover"
          >
            <PopoverHeader className="text-left">
              <p className="quice_info_title">
                Tron details
                <button
                  type="button"
                  className="secure_eye_btn_in"
                  id="eye"
                  onClick={e => setHiddenTron(!hiddenTron)}
                >
                  {hiddenTron ? <EyeOffOutlineIcon className="eye_icon" />
                    : <EyeOutlineIcon className="eye_icon" />}
                </button>
              </p>
            </PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    <li> Total :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.tron} $</li>
                    <li>{hiddenTron ? '**********'
                      : (Number(balance?.TRX?.Deposit || 0)
                        + Number(balance?.TRX?.Fund || 0))
                        .toFixed(8).toString().substring(0, 7)}
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Deposit :</li>
                    <li> Fund :</li>
                  </ul>
                </Col>
                <Col col={1}>
                  <ul className="quiceInfo_ul_value">
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>
                      {hiddenTron ? '********' : (balance?.TRX?.Deposit || 0)?.toFixed(2)}
                      {balance?.TRX?.Deposit === null && !hiddenTron && '0.00'}
                    </li>
                    <li>
                      {hiddenTron ? '********' : (balance?.TRX?.Fund || 0)?.toFixed(2)}
                      {balance?.TRX?.Fund === null && !hiddenTron && '0.00'}
                    </li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.tron} $</span>
          <span className="quice_info_amount_price">
            Total : {hiddenTron ? '**********'
              : (Number(balance?.TRX?.Deposit || 0)
                + Number(balance?.TRX?.Fund || 0))
                .toFixed(8).toString().substring(0, 7)}
            <button
              type="button"
              className="secure_eye_btn_out"
              id="eye"
              onClick={e => setHiddenTron(!hiddenTron)}
            >
              {hiddenTron ? <EyeOffOutlineIcon className="eye_icon" />
                : <EyeOutlineIcon className="eye_icon" />}
            </button>
          </span>
        </div>
      </div>
      <div className="quice_info">
        <div className="quice_info_img">
          <Button
            id="tetherQuiceInfo"
            onClick={handleOpenTether}
            outline
            className={IsOpenedTether ? 'button-tooltip quiceInfo_btn open_quice_btn'
              : 'button-tooltip quiceInfo_btn close_quice_btn'}
          >
            <img className="quice_info_image" id="tetherQuiceInfo" alt="" src={tetherImgURL} />
          </Button>
          <Popover
            placement="bottom"
            isOpen={IsOpenedTether}
            target="tetherQuiceInfo"
            toggle={handleOpenTether}
            dir={dir}
            className="topmenu_popovers tether_popover"
          >
            <PopoverHeader className="text-left">
              <p className="quice_info_title">
                Tether details
                <button
                  type="button"
                  className="secure_eye_btn_in"
                  id="eye"
                  onClick={e => setHiddenTether(!hiddenTether)}
                >
                  {hiddenTether ? <EyeOffOutlineIcon className="eye_icon" />
                    : <EyeOutlineIcon className="eye_icon" />}
                </button>
              </p>
            </PopoverHeader>
            <PopoverBody>
              <Row className="quiceInfo_row inner_quice_info">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name_head">
                    <li> Price :</li>
                    <li> Total :</li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value_head">
                    <li>{prices.tether} $</li>
                    <li>{hiddenTether ? '***********'
                      : (Number(balance?.USDT?.Deposit || 0)
                        + Number(balance?.USDT?.BuyToken || 0)
                        + Number(balance?.USDT?.Fund || 0)
                        + Number(balance?.USDT?.Affiliate || 0)
                        + Number(balance?.USDT?.BuyInvest || 0)
                        + Number(balance?.USDT?.BuyInvestProfit || 0)
                        + Number(balance?.USDT?.Refund || 0)
                        + Number(balance?.USDT?.Award || 0)
                        + Number(balance?.USDT?.BuyInvestBinaryPlus || 0))
                        .toFixed(8).toString().substring(0, 7)}
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr className="quiceInfo_hr" />
              <Row className="quiceInfo_row">
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_name">
                    <li> Buy Token :</li>
                    <li> Deposit :</li>
                    <li> Fund :</li>
                    <li> Affiliate :</li>
                    <li> Buy Invest :</li>
                    <li> BI Profit :</li>
                    <li> BinaryPlus :</li>
                    <li> Refund :</li>
                    <li> Award :</li>
                  </ul>
                </Col>
                <Col col={1}>
                  <ul className="quiceInfo_ul_value">
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                    <li>
                      .........
                    </li>
                  </ul>
                </Col>
                <Col className="quiceInfo_col">
                  <ul className="quiceInfo_ul_value">
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.BuyToken || 0)?.toFixed(2)}
                      {balance?.USDT?.BuyToken === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.Deposit || 0)?.toFixed(2)}
                      {balance?.USDT?.Deposit === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.Fund || 0)?.toFixed(2)}
                      {balance?.USDT?.Fund === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.Affiliate || 0)?.toFixed(2)}
                      {balance?.USDT?.Affiliate === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.BuyInvest || 0)?.toFixed(2)}
                      {balance?.USDT?.BuyInvest === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.BuyInvestProfit || 0)?.toFixed(2)}
                      {balance?.USDT?.BuyInvestProfit === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.BuyInvestBinaryPlus || 0)?.toFixed(2)}
                      {balance?.USDT?.BuyInvestBinaryPlus === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.Refund || 0)?.toFixed(2)}
                      {balance?.USDT?.Refund === null && !hiddenTether && '0.00'}
                    </li>
                    <li>
                      {hiddenTether ? '********' : (balance?.USDT?.Award || 0)?.toFixed(2)}
                      {balance?.USDT?.Award === null && !hiddenTether && '0.00'}
                    </li>
                  </ul>
                </Col>
              </Row>
            </PopoverBody>
          </Popover>
        </div>
        <div className="quice_info_amount">
          <span className="quice_info_amount_price">Price : {prices.tether} $</span>
          <span className="quice_info_amount_price">
            Total : {hiddenTether ? '***********'
              : (Number(balance?.USDT?.Deposit || 0)
                + Number(balance?.USDT?.BuyToken || 0)
                + Number(balance?.USDT?.Fund || 0)
                + Number(balance?.USDT?.Affiliate || 0)
                + Number(balance?.USDT?.BuyInvest || 0)
                + Number(balance?.USDT?.BuyInvestProfit || 0)
                + Number(balance?.USDT?.Refund || 0)
                + Number(balance?.USDT?.Award || 0)
                + Number(balance?.USDT?.BuyInvestBinaryPlus || 0))
                .toFixed(8).toString().substring(0, 7)}
            <button
              type="button"
              className="secure_eye_btn_out"
              id="eye"
              onClick={e => setHiddenTether(!hiddenTether)}
            >
              {hiddenTether ? <EyeOffOutlineIcon className="eye_icon" />
                : <EyeOutlineIcon className="eye_icon" />}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

TopbarQuiceInfo.propTypes = {
  dir: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  balance: PropTypes.objectOf.isRequired,
  IsOpenedNcc: PropTypes.bool.isRequired,
  IsOpenedTether: PropTypes.bool.isRequired,
  IsOpenedTron: PropTypes.bool.isRequired,
  setIsOpenedNcc: PropTypes.func.isRequired,
  setIsOpenedTether: PropTypes.func.isRequired,
  setIsOpenedTron: PropTypes.func.isRequired,
  handleOpenNcc: PropTypes.func.isRequired,
  handleOpenTether: PropTypes.func.isRequired,
  handleOpenTron: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
  const { currency, userInfo, theme } = state;
  return {
    currency,
    balance: userInfo.user?.balanceWallet,
    theme: theme.className,
  };
}


export default connect(mapStateToProps)(TopbarQuiceInfo);

