import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarQuiceInfo from './TopbarQuiceInfo';
import TopbarConvertor from './TopbarConvertor';


const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const a = 1;

  return (
    <div className="topbar topbar_custom">
      <div className="topbar__wrapper">
        <div className="topbar__left topbar__left_custome">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/Desktop" />
        </div>
        <div className="topbar__right topbar__right_custome">
          <div className="topbar__right-quice">
            <TopbarQuiceInfo />
          </div>
          <div className="topbar__right-over">
            <TopbarConvertor />
            <TopbarProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
