import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

import {
  Card, CardBody, CardHeader,
} from 'reactstrap';

import { useParams, withRouter, useHistory } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import VerificationCode from './components/VerificationCode';
import ForgetPass from './components/ForgetPass';
import RefferalCode from './components/RefferalCode';


const Landing = ({ location }) => {
  const backImg = `${process.env.PUBLIC_URL}/img/back.png`;
  const logoImg = `${process.env.PUBLIC_URL}/img/logo.png`;

  const { ffCode } = useParams();
  const history = useHistory();

  const styles = {
    backgroundImage: `url(${backImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderColor: '#333',
    backgroundPosition: 'center',
  };

  const [toLogin, setToLogin] = useState(location.tologin);
  const [toRegister, setToRegister] = useState(location.toRegister);
  const [toForgetpass, setToForgetpass] = useState(false);
  const [toCode, setToCode] = useState(false);
  const [toRefferal, setToRefferal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);


  const handleToLogin = () => {
    setToLogin(true);
    setToCode(false);
    setToRegister(false);
    setToForgetpass(false);
    setToRefferal(false);
  };

  const handleToRegister = () => {
    setToLogin(false);
    setToCode(false);
    setToRegister(true);
    setToForgetpass(false);
    setToRefferal(false);
  };

  const codeRequest = () => {
    setToCode(true);
    setToLogin(false);
    setToRefferal(false);
  };

  const passRequest = () => {
    setToLogin(false);
    setToCode(false);
    setToRegister(false);
    setToForgetpass(true);
    setToRefferal(false);
  };

  const goToLogin = () => {
    setToLogin(true);
    setToCode(false);
    setToRegister(false);
    setToForgetpass(false);
    setToRefferal(false);
  };

  const refferalRequest = () => {
    setToLogin(false);
    setToCode(false);
    setToRegister(false);
    setToForgetpass(false);
    setToRefferal(true);
  };

  useEffect(() => {
    // window.addEventListener('load', (e) => {
    setIsLoading(false);
    setTimeout(() => setIsLoaded(true), 3000);
    // });
    if (location.haveMsg) {
      if (location.msg === 'changPass') {
        toast.success('Your Password has been Changed, Now Login with Your New Password !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (location.msg === 'regFromLink') {
        toast.success(`Your Regsitration done , 
              Your FF code and the activation link has been sent to 
              Your Email Address, you should activate your account from
              your email !`, {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (location.msg === 'unValRefLink') {
        toast.error('Your Refferal Link is NOT Valid !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (location.msg === 'expire') {
        setTimeout(() => {
          history.go(0);
        }, 4000);
        toast.warn('Your session has ended for security reasons, Login again to continue !', {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (location.msg === 'mouseMove') {
        toast.warn('Your session has ended due to inactivity !', {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else if (ffCode) {
      const tologin = true;
      goToLogin();
      history.push({
        pathname: '/',
        tologin,
        ffCode,
        // haveMsg: true,
        // msg: 'regFromLink',
      });
    }
  }, []);



  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;


  return (

    <Fragment>
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          draggablePercent={30}
        />

        {!isLoaded && (
          <div className={`load${isLoading ? '' : ' loaded'}`}>
            <div className="load__icon-wrap">
              <img src={logo} alt="" />
            </div>
          </div>
        )}

        <div className="account backLanding" style={styles}>
          <Card className="landingHolder">
            <CardHeader className="landigHolderHeader text-center">
              <div className="landigHolderHeader_div">
                <img className="logoImage" alt="Financial Factory" src={logoImg} />
                <h2 className="text-center financial_brand">Financial Factory</h2>
                <h2 className="text-center financial_brand">{location.code}</h2>
                <div className="landingButtons">
                  {toLogin ? (
                    ''
                  ) : (
                    <button type="button" className="btn btn-outline-theme btn-lg landing_btn" onClick={handleToLogin}>
                      Login
                    </button>
                  )}
                  {/* <button
                    type="button"
                    className="btn btn-outline-theme btn-lg landing_btn"
                    onClick={refferalRequest}
                  >
                    Register
                  </button> */}
                </div>
              </div>
            </CardHeader>
            <CardBody className="landingCardBody">
              <div className="landingFormHolder text-center">
                {toLogin ? (
                  <Login
                    afterSubmit={codeRequest}
                    toRegister={refferalRequest}
                    forgetPass={passRequest}
                    ffcode={location.ffCode}
                  />
                ) : null}
                {toRefferal ? <RefferalCode toLogin={goToLogin} toRegister={handleToRegister} /> : null}
                {toCode ? <VerificationCode toLogin={goToLogin} /> : null}
                {toForgetpass ? <ForgetPass toLogin={goToLogin} /> : null}
                {toRegister ? <Register toLogin={goToLogin} sentCode={location.sentCode.toString()} /> : null}
              </div>
            </CardBody>
            <div className="text-center mb-3 copyright">
              ©2022 Financial Factory, All rights reserved. (Version 1.0)
            </div>
            <div className="card-arrow">
              <div className="card-arrow-top-left landingHolder-topleft" />
              <div className="card-arrow-top-right landingHolder-topright" />
              <div className="card-arrow-bottom-left landingHolder-bottomleft" />
              <div className="card-arrow-bottom-right landingHolder-bottomright" />
            </div>
          </Card>
        </div>
      </>
    </Fragment>
  );
};

Landing.propTypes = {
  location: PropTypes.string.isRequired,
};


export default withRouter(connect()(Landing));
