import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Col,
  Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import FundJoin from './fund/FundJoin';
import FundTable from './fund/FundTable';
import AffiliateJoin from './affiliate/AffiliateJoin';
import StakingJoin from './staking/StakingJoin';
import AffiliateReport from './affiliate/AffiliateReport';
import AffiliateAutomatic from './affiliate/AffiliateAutomatic';
import AffiliateConditions from './affiliate/AffiliateConditions';
import StakingReport from './staking/StakingReport';
import MagicConditions from './magicBuy/MagicConditions';
import MagicBuy from './magicBuy/MagicBuy';
import MagicReport from './magicBuy/MagicReport';
import BuyInvestConditions from './buyInvest/BuyInvestConditions';
import BuyInvest from './buyInvest/BuyInvest';
import BuyInvestUSDT from './buyInvest/BuyInvestUSDT';
import BuyInvestNCC from './buyInvest/BuyInvestNCC';
import {
  getAwardSummary, getBuyInvestSummary, getNftSummary, getRefundSummary, getStackingSummary,
} from '../../../../redux/actions/userActions';
import RefundJoin from './refund/RefundJoin';
import RefundReport from './refund/RefundReport';
import AwardJoin from './award/AwardJoin';
import AwardReport from './award/AwardReport';
import NftJoin from './nft/NftJoin';
import NftReport from './nft/NftReport';
import NccFinanceJoin from './ncc-finance/NccFinanceJoin';
import NccFinanceReport from './ncc-finance/NccFinanceReport';

const Tabs = ({ activeTab, toggle }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' }, 'tab_link')}
              onClick={() => toggle('1')}
            >
              Fund
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' }, 'tab_link')}
              onClick={() => toggle('2')}
            >
              Affiliate
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' }, 'tab_link')}
              onClick={() => {
                toggle('4');
                dispatch(getBuyInvestSummary());
              }}
            >
              Buy Invest
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' }, 'tab_link')}
              onClick={() => {
                toggle('5');
                dispatch(getAwardSummary());
              }}
            >
              Award
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' }, 'tab_link')}
              onClick={() => {
                toggle('6');
                dispatch(getRefundSummary());
              }}
            >
              Refund
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' }, 'tab_link')}
              onClick={() => {
                toggle('7');
                dispatch(getNftSummary());
              }}
            >
              NCC Finance
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' }, 'tab_link')}
              onClick={() => {
                toggle('8');
                dispatch(getNftSummary());
              }}
            >
              NCC Finance
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' }, 'tab_link')}
              onClick={() => {
                toggle('3');
                dispatch(getStackingSummary());
              }}
            >
              Stacking
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '9' }, 'tab_link')}
              onClick={() => toggle('9')}
            >
              Magic Buy
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="mb-0">
              <Col md={12} lg={5}>
                <FundJoin />
              </Col>
              <Col md={12} lg={7}>
                <FundTable />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={4}>
                <AffiliateConditions />
                <div className="aff_desk_join text-center">
                  <AffiliateJoin />
                </div>
              </Col>
              <Col md={12} lg={12} xl={8} className="">
                <AffiliateReport tab={activeTab} />
                <div className="aff_mobile_join text-center">
                  <AffiliateJoin />
                </div>
                <div className="aff_mobile_automatic text-center mb-4">
                  <AffiliateAutomatic />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <StakingJoin />
              </Col>
              <Col md={12} lg={12} xl={7}>
                <StakingReport tab={activeTab} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <BuyInvestConditions />
                <BuyInvest />
              </Col>
              <Col md={12} lg={12} xl={7}>
                <BuyInvestNCC />
                <BuyInvestUSDT />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <AwardJoin />
              </Col>
              <Col md={12} lg={12} xl={7}>
                <AwardReport />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <RefundJoin />
              </Col>
              <Col md={1} className="m-0" />
              <Col md={12} lg={12} xl={6} className="m-0">
                <RefundReport tab={activeTab} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="7">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <NftJoin />
              </Col>
              <Col md={1} className="m-0" />
              <Col md={12} lg={12} xl={6} className="m-0">
                <NftReport />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="8">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <NccFinanceJoin />
              </Col>
              <Col md={1} className="m-0" />
              <Col md={12} lg={12} xl={6} className="m-0">
                <NccFinanceReport />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="8">
            <Row className="mb-0">
              <Col md={12} lg={12} xl={5}>
                <MagicConditions />
                <MagicBuy />
              </Col>
              <Col md={12} lg={12} xl={7}>
                <MagicReport />
              </Col>
            </Row>
          </TabPane> */}
        </TabContent>
      </div>
    </>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};


export default Tabs;
