import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row, Container, Col,
} from 'reactstrap';
import { getRefferal } from '../../../redux/actions/userActions';
import RefferalGenerate from './components/RefferalGenerate';
import RefferalReport from './components/RefferalReport';



const Refferal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'CLEAR_USER_REFFERAL' });
    dispatch(getRefferal());
  }, []);

  return (

    <Container className="dashboard">
      <Row>
        <Col md={4}>
          <RefferalGenerate />
        </Col>
        <Col md={8}>
          <RefferalReport />
        </Col>
      </Row>
    </Container>
  );
};

export default Refferal;
