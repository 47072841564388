import canvasToImage from 'canvas-to-image';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { RWebShare } from 'react-web-share';


import { QRCode } from 'react-qrcode-logo';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Card, CardBody, CardHeader, Popover, PopoverHeader, UncontrolledTooltip,
} from 'reactstrap';
import { setDepositWallet } from '../../../../redux/actions/userActions';
import AuthRequest from '../../../../services/AuthFetchRequest';
import { serverURL } from '../../../../utils/url';
import { showLogs } from '../../../App/Rules';



const Deposit = ({
  FFCode, isActive, isAllowDeposit, depositWallet,
}) => {
  const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [IsOpenedCopied, setIsOpenedCopied] = useState(false);
  const handleOpenCopied = () => {
    setIsOpenedCopied(true);
  };

  const [walletAddrs, setWalletAdrs] = useState(depositWallet ? depositWallet.address : '');


  useEffect(() => {
    setWalletAdrs(depositWallet ? depositWallet.address : '');
  }, [depositWallet]);

  const [copyCode, setCopycode] = useState('fas fa-copy');


  const dispatch = useDispatch();

  const copyCodeToClipboard = () => {
    const element = document.querySelector('#refCode');
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopycode('fas fa-check');
    setTimeout(() => {
      setCopycode('fas fa-copy');
      setIsOpenedCopied(false);
    }, 2000);
  };


  useEffect(() => {
    if (!isActive) {
      setDisable(true);
    }
  }, [isActive]);

  const handleGenerateWallet = () => {
    setLoading(true);
    AuthRequest(`${serverURL}/holder/checkDepositWallet`,
      { FFCode, token: localStorage.getItem('token') })
      .then(res => res.json())
      .then((data) => {
        if (showLogs) {
          console.log('checkDepositWallet :', data);
        }
        setLoading(false);
        if (data.numReq === 1 && data.sttReq === 'ok') {
          setWalletAdrs(data.depositWallet.address);
          dispatch(setDepositWallet(data.depositWallet));
          toast.success('Your Deposit Wallet set up Successfully !', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      });
  };

  const downloadRefQR = () => {
    const refCodeQR = document.getElementById('react-qrcode-logo');
    canvasToImage(refCodeQR, {
      name: `${FFCode}-DepositQR`,
      type: 'jpg',
      quality: 1,
    });
  };


  return (
    <>

      <UncontrolledTooltip placement="top" target="depHelp">
        You can create your wallet on the site by pressing the generate button.
        Transfer and top up your wallet on the site using the QR code or written address.
      </UncontrolledTooltip>

      {walletAddrs ? (
        <Popover
          placement="top"
          isOpen={IsOpenedCopied}
          target="copied"
          toggle={handleOpenCopied}
          className="copied_popover"
        >
          <PopoverHeader>Copied !</PopoverHeader>
        </Popover>

      ) : ''}

      <Card>
        <CardHeader className="text-center">
          <h4 className="monetary_title">
            Deposit <i id="depHelp" className="fas fa-info-circle monetary_title_info" />
          </h4>
        </CardHeader>
        <CardBody className="monetary_CardBody">
          {walletAddrs ? (
            <>
              <div className="text-center deposit_monetary_qrCode">
                <QRCode
                  value={disable || !isAllowDeposit ? '' : walletAddrs}
                  logoWidth="220"
                  eyeRadius={[
                    [0, 80, 0, 80], // top-left eye
                    [80, 0, 80, 0], // top-right eye
                    [80, 0, 80, 0], // bottom-left
                  ]}
                  logoOpacity="0.4"
                  qrStyle="dots"
                  bgColor="#fff"
                  fgColor="#000"
                  ecLevel="Q"
                  size="180"
                  logoImage={logo}
                />
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-outline-theme table-btn mt-1"
                    onClick={e => downloadRefQR()}
                    disabled={disable || !isAllowDeposit ? 'true' : ''}
                  >
                    <i className="fas fa-arrow-down" />
                  </button>

                  <RWebShare
                    data={{
                      text: walletAddrs,
                      url: serverURL,
                      title: `${FFCode}-Deposit`,
                    }}
                  // onClick={() => console.log('shared successfully!')}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-theme table-btn mt-1"
                      disabled={disable || !isAllowDeposit ? 'true' : ''}
                    >
                      <i className="fas fa-share-alt" />
                    </button>
                  </RWebShare>

                </div>
              </div>
              <div className="text-center mt-3">
                <div className="input-group flex-nowrap deposit_monetary_adrs">
                  <input
                    className="form-control text-center"
                    type="text"
                    // placeholder={!isAllowDeposit ? '-     -     -     - ' : ''}
                    id="refCode"
                    value={disable || !isAllowDeposit ? '' : walletAddrs}
                    disabled={disable || !isAllowDeposit ? 'true' : ''}
                  />
                  <button
                    type="button"
                    id="copied"
                    className="input-group-text"
                    onClick={copyCodeToClipboard}
                    disabled={disable || !isAllowDeposit ? 'true' : ''}
                  >
                    <i className={copyCode} />
                  </button>
                </div>
                <div className="tefo_error text-center mt-1 tefo_error_strong">
                  {!isAllowDeposit ? '*You are NOT allowed to deposit right now !' : ''}
                </div>
              </div>
            </>
          ) : (
            <div className="form-group row">
              <div className="text-center generate_deposit">
                <button
                  type="button"
                  className="btn btn-outline-theme tefo_buttons_lg"
                  onClick={handleGenerateWallet}
                  disabled={disable || !isAllowDeposit || loading ? 'true' : ''}
                >
                  Generate Wallet
                </button>
                <div className="tefo_error text-center mt-3 mb-5 tefo_error_strong">
                  {!isAllowDeposit ? '*You are NOT allowed to deposit right now !' : ''}
                </div>
              </div>
            </div>
          )}
        </CardBody>
        <div className="card-arrow">
          <div className="card-arrow-top-left" />
          <div className="card-arrow-top-right" />
          <div className="card-arrow-bottom-left" />
          <div className="card-arrow-bottom-right" />
        </div>
      </Card>
    </>
  );
};

Deposit.propTypes = {
  depositWallet: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  isAllowDeposit: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { userInfo } = state;
  return {
    depositWallet: userInfo.user?.depositWallet,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isAllowDeposit: userInfo.user?.FFuser?.isAllowDeposit,
    isActive: userInfo.user?.FFuser?.isActive,
  };
}

export default connect(mapStateToProps)(Deposit);


