import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  UncontrolledTooltip, Button, Modal, ButtonToolbar, Card, CardHeader, CardBody, CardFooter, Col, Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import {
  getBalance, getBuyInvestSummary,
} from '../../../../../redux/actions/userActions';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { store } from '../../../../App/store';
import { serverbetaURL } from '../../../../../utils/url';
import { clearLoadingBuyInvest, setLoadingBuyInvest } from '../../../../../redux/actions/loaderAction';
import NoSecPass from '../../../../Layout/NoSecPass';
import useTimer from '../../../../../utils/useTimer';
import { setCurrency } from '../../../../../redux/actions/currencyActions';
import { showLogs } from '../../../../App/Rules';
import axiosHeaders from '../../../../../utils/axiosHelper';



const BuyInvest = ({
  FFuser,
  haveSecPass,
  havePlans,
  balanceWallet,
  voidPlans,
  currencies,
  plans,
  plancrypto,
  depositWallet,
  ncc,
  tron,
  buyInvestLoad,
  BuyInvestSummary,
}) => {
  const [currency, setBuyCurrency] = useState();
  const [Amount, setAmount] = useState();
  const [secPass, setSecPass] = useState('');
  const [AmountTether, setAmountTether] = useState();
  const [, forceUpdate] = useState();

  const secInput = useRef(null);

  const [currencyList, setCurrencyList] = useState([]);

  const [MinRemove, setMinRemove] = useState(50);
  const [MaxRemove, setMaxRemove] = useState(100000);
  const [Profit, setProfit] = useState(5);

  const [disable, setDisable] = useState(false);

  const [DeactiveUser, setDeactiveUser] = useState(false);

  const [unValid, setUnValid] = useState(false);
  const [valid, setValid] = useState(false);

  const [unValidTether, setUnValidTether] = useState(false);


  const [TronNeed, setTronNeed] = useState(50);
  const [ProfitStonk, setProfitStonk] = useState(10);


  const validator = useRef(new SimpleReactValidator());

  const dispatch = useDispatch();

  const [resendTime, setResendTime] = useTimer({
    startTime: 0,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };

  useEffect(() => {
    // localStorage.setItem('lastBuyReq', Date.parse(new Date()));
    if (localStorage.getItem('lastBuyReq')) {
      setResendTime(180 - ((Date.parse(new Date()) - localStorage.getItem('lastBuyReq')) / 1000));
      //   dispatch(setLoadingBuy());
      // } else {
      //   dispatch(clearLoadingBuy());
    }
  }, []);


  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const reset = () => {
    setAmount('');
    setAmountTether('');
    setSecPass('');
    validator.current.hideMessages();
    forceUpdate(13);
  };

  useEffect(async () => {
    const buyTimeNow = new Date().getTime();
    const buyTimeStart = Date.parse(plans.find(i => i.id === 4).fromDate.replace(/-/g, '/'));
    const buyTimeEnd = Date.parse(plans.find(i => i.id === 4).toDate.replace(/-/g, '/'));


    if (plans.find(i => i.id === 4).isActive === 0) {
      setDisable(true);
      setDeactiveUser(true);
    }
    if (!FFuser.isActive) {
      setDeactiveUser(true);
      setDisable(true);
    }
    if (FFuser.isAllowBuy !== 1) {
      setDeactiveUser(true);
      setDisable(true);
    }

    if (buyTimeNow > buyTimeEnd || buyTimeNow < buyTimeStart) {
      setDeactiveUser(true);
      setDisable(true);
    }

    if (plans.find(i => i.id === 4).isActive === 1
      && FFuser.isActive === 1
      && FFuser.isAllowBuy === 1
      && buyTimeNow > buyTimeEnd
      && buyTimeNow < buyTimeStart) {
      setDeactiveUser(false);
      setDisable(false);
    }

    if (plancrypto[0]) {
      const currencyArr = [];
      for (let cr = 0; cr < plancrypto.length; cr += 1) {
        if (plancrypto[cr].ffplanId === 4 && plancrypto[cr].isActive === 1) {
          const cryptoTimeNow = new Date().getTime();
          const cryptoTimeStart = Date.parse(plancrypto[cr].fromDate.replace(/-/g, '/'));
          const cryptoTimeEnd = Date.parse(plancrypto[cr].toDate.replace(/-/g, '/'));
          if ((cryptoTimeNow < cryptoTimeEnd) && (cryptoTimeNow > cryptoTimeStart)) {
            if (currencies.find(i => i.id === plancrypto[cr].cryptocurrencyId)?.isBuy
              && currencies.find(i => i.id === plancrypto[cr].cryptocurrencyId)?.isActive) {
              currencyArr.push(plancrypto[cr]);
            }
          }
        }
      }

      setBuyCurrency(currencyArr[0]?.cryptocurrencyId?.toString());
      setMaxRemove(currencyArr[0]?.maxAmount);

      if (currencyArr[0]?.cryptocurrencyId?.toString() === '2') {
        // setProfitStonk((currencyArr[0]?.profitStonk) * 2);
        setProfitStonk(0);
      } else {
        setProfitStonk(currencyArr[0]?.profitStonk * 2);
      }

      setTronNeed(50); // yani teron
      // setTronNeed(currencies.find(i => i.id === 2)?.minStock); // yani teron

      if (currencyArr[0]?.isUserPay) {
        if (currencyArr[0]?.cryptocurrencyId?.toString() === '2') {
          setProfit(0);
        } else {
          setProfit(voidPlans?.BuyToken?.Level1?.profit || 0
            + voidPlans?.BuyToken?.Level2?.profit || 0
            + voidPlans?.BuyToken?.Level3?.profit || 0
            + voidPlans?.BuyToken?.Level4?.profit || 0
            + voidPlans?.BuyToken?.Level5?.profit || 0
            + voidPlans?.BuyToken?.Level6?.profit || 0
            + voidPlans?.BuyToken?.Level7?.profit || 0
            + voidPlans?.BuyToken?.Level8?.profit || 0
            + voidPlans?.BuyToken?.Level9?.profit || 0
            + voidPlans?.BuyToken?.Level10?.profit || 0);
        }
      } else {
        setProfit(0);
      }


      // setMinRemove(Math.max(
      //   currencyArr[0]?.amount,
      //   (currencies.find(i => i.id === 2)?.minStock) - (balanceWallet?.TRX?.Deposit)
      //   + (currencyArr[0]?.cryptocurrencyId?.toString() === '2'
      //     ? (currencyArr[0]?.profitStonk) * 2 : currencyArr[0]?.profitStonk),
      //   currencyArr[0]?.cryptocurrencyId?.toString() === '2'
      //     ? (currencyArr[0]?.profitStonk) * 2 : currencyArr[0]?.profitStonk,
      // ).toFixed(2));

      setMinRemove(Math.max(
        currencyArr[0]?.amount,

        (currencyArr[0]?.cryptocurrencyId?.toString() === '2'
          ? 20 : (currencyArr[0]?.profitStonk) * 2),

        currencyArr[0]?.cryptocurrencyId?.toString() === '2'
          ? 20 : (currencyArr[0]?.profitStonk) * 2,
      ).toFixed(2));

      setCurrencyList(currencyArr);
      forceUpdate(13);

      if (!currencyArr[0]) {
        setDisable(true);
        setDeactiveUser(true);
      }
    }
  }, [FFuser, plancrypto]);


  const handleConvert = async (e) => {
    await setAmount(e.target.value);
    if (currency === '1' && ncc !== 0) {
      if (validator.current.fieldValid('Amount') && !Number.isNaN(+e.target.value)) {
        setUnValid(false);
        setValid('valid');
        setAmountTether((e.target.value * ncc).toFixed(4));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmountTether((e.target.value * ncc).toFixed(4));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else if (currency === '2' && tron !== 0) {
      if (validator.current.fieldValid('Amount') && !Number.isNaN(+e.target.value)) {
        setUnValid(false);
        setValid('valid');
        setAmountTether((e.target.value * tron).toFixed(4));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmountTether((e.target.value * tron).toFixed(4));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else {
      setAmount('');
      setAmountTether('');
    }
  };

  const handleConvertOnTether = async (e) => {
    await setAmountTether(e.target.value);
    if (currency === '1' && ncc !== 0) {
      if (validator.current.fieldValid('Amount Tether') && !Number.isNaN(+e.target.value)) {
        setUnValidTether(false);
        setUnValid(false);
        setValid('valid');
        setAmount((e.target.value / ncc).toFixed(0));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount((e.target.value / ncc).toFixed(0));
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (e.target.value === '') {
        setAmount('');
      }
    } else if (currency === '2' && tron !== 0) {
      if (validator.current.fieldValid('Amount Tether') && !Number.isNaN(+e.target.value)) {
        setUnValidTether(false);
        setUnValid(false);
        setValid('valid');
        setAmount((e.target.value / tron).toFixed(0));
      } else if (Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount('');
        setAmountTether('');
        setUnValid(true);
        setValid('');
        forceUpdate(1);
      } else if (!Number.isNaN(+e.target.value)) {
        validator.current.showMessages();
        setAmount((e.target.value / tron).toFixed(0));
        setUnValid(true);
        setValid('');
        forceUpdate(13);
      } else if (e.target.value === '') {
        setAmountTether('');
      }
    } else {
      setAmount('');
      setAmountTether('');
    }
  };

  const handlecheckBuy = (e) => {
    e.preventDefault();
    if (validator.current.fieldValid('Amount') && validator.current.fieldValid('Amount Tether')) {
      toggle();
    } else if (!validator.current.fieldValid('Amount')) {
      setUnValid(true);
      validator.current.showMessageFor('Amount');
      forceUpdate(1);
    } else if (!validator.current.fieldValid('Amount Tether')) {
      setUnValidTether(true);
      validator.current.showMessageFor('Amount Tether');
      forceUpdate(1);
    }
  };

  const handleBuyInvest = async () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastBuyReq', new Date().getTime());
      const buyInvestData = {
        planCryptoCurrencyId: '9',
        isWithdrawByService: false,
        toCryptoCurrencyId: '1',
        amount: Number(AmountTether),
        FFCode: FFuser?.FFCode,
        // token: localStorage.getItem('token'),
        secPass,
      };
      // console.log(JSON.parse(JSON.stringify(buyInvestData)));
      setDisable(true);
      setResendTime(180);
      togglePass();
      reset();
      validator.current.hideMessages();
      forceUpdate(13);
      dispatch(setLoadingBuyInvest());
      axios.post(`${serverbetaURL}/users/setplan`, JSON.stringify(buyInvestData), {
        headers: axiosHeaders(),
      })
        .then((data) => {
          if (showLogs) {
            console.log('setPlan (Buy Invest) :', data);
          }
          dispatch(getBuyInvestSummary());
          dispatch(clearLoadingBuyInvest());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(getBuyInvestSummary());
          dispatch(clearLoadingBuyInvest());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });

      setResendTime(0);
      setDisable(false);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (

    <>

      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              ref={secInput}
              value={secPass}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleBuyInvest();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleBuyInvest}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">
            Buy Invest
          </h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          Pay {AmountTether} Tether for Buy Investing
          {' '}
          {!Number.isNaN(parseFloat(Amount)) && Number(currency) === 1
            ? Number(Amount)?.toFixed(2) : ''}
          {/* {!Number.isNaN(parseFloat(Amount)) && Number(currency) === 1
            ? (Amount * ((100 - Profit) / 100)).toFixed(2) : ''}
          {!Number.isNaN(parseFloat(Amount)) && Number(currency) === 2
            ? (Amount - 20).toFixed(2) : ''} */}
          {' '}
          {currencyList.find(c => c.cryptocurrencyId === Number(currency))?.planCryptoCurrencyDtlTitle?.split('-')[1]}
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>

      <form>
        {/* <Row>
          <Col md={1} />
          <Col> */}
        <div>
          <label className="form-label" htmlFor="AmountTether">Amount Tether :</label>
          <input
            className={unValidTether ? 'unValid form-control text-center amount_input_space'
              : 'form-control text-center amount_input_space'}
            type="text"
            placeholder="Amount Tether"
            value={AmountTether}
            disabled={disable
              || (balanceWallet?.TRX?.Deposit < TronNeed)
              || !balanceWallet?.USDT?.Deposit}
            onChange={async (e) => {
              await handleConvertOnTether(e);
              if (!validator.current.fieldValid('Amount')) {
                setUnValid(true);
                forceUpdate(13);
              } else {
                setUnValid(false);
                forceUpdate(13);
              }
            }}
            onFocus={(e) => {
              dispatch(getBalance());
              dispatch(setCurrency());
              setAmount('');
              setAmountTether('');
              setUnValidTether(false);
              validator.current.hideMessages();
              forceUpdate(13);
            }}
            onBlur={() => {
              setUnValid(false);
              setValid('');
              setTimeout(() => {
                validator.current.hideMessages();
                forceUpdate(13);
              }, 10000);
            }}
          />
          <div className="tefo_error tefo_error_strong">
            {validator.current.message(
              'Amount Tether', AmountTether, `max:${balanceWallet?.USDT?.Deposit},num|min:${1},num`,
            )}
          </div>
        </div>
        {/* </Col>
          <Col> */}
        <div className="">
          <label className="form-label" htmlFor="Amount">
            Amount {currencies.find(i => i.id === Number(currency))?.name} :
          </label>
          <input
            id="Amount"
            className={unValid ? 'unValid form-control text-center amount_input_space'
              : `${valid} form-control text-center amount_input_space`}
            type="text"
            autoComplete="off"
            placeholder={`Amount ${currencies.find(i => i.id === Number(currency))?.name}`}
            value={Amount}
            disabled={disable
              || (balanceWallet?.TRX?.Deposit < TronNeed)
              || !balanceWallet?.USDT?.Deposit}
            onChange={async (e) => {
              await handleConvert(e);
              setAmount(e.target.value);
              if (!validator.current.fieldValid('Amount Tether')) {
                setUnValidTether(true);
                forceUpdate(13);
              } else {
                setUnValidTether(false);
                forceUpdate(13);
              }
            }}
            onFocus={(e) => {
              dispatch(getBalance());
              dispatch(setCurrency());
              setAmount('');
              setAmountTether('');
              setUnValidTether(false);
              validator.current.hideMessages();
              forceUpdate(13);
            }}
            onBlur={() => {
              setUnValid(false);
              setValid('');
              setTimeout(() => {
                validator.current.hideMessages();
                forceUpdate(13);
              }, 10000);
            }}
          />
          <div className="tefo_error tefo_error_strong">
            {validator.current.message('Amount', Amount, `required|min:${0},num`)}
          </div>
        </div>
        {/* </Col>
          <Col md={1} />
        </Row> */}
      </form>

      <div className="form-group row">
        <div className="text-center mt-2">
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_lg w-100"
            onClick={e => handlecheckBuy(e)}
            disabled={
              disable
              // || Number(BuyInvestSummary?.nccTotal) > 0
              || (balanceWallet?.TRX?.Deposit < TronNeed)
              // || (balanceWallet?.TRX?.Deposit < TronNeed)
              || (Number(AmountTether) === 0.0000)
              || !balanceWallet?.USDT?.Deposit
              || buyInvestLoad
              || true
            }
          >

            {(resendTime >= 0 && disable && currencyList[0] && !DeactiveUser)
              || buyInvestLoad
              ? (
                <>
                  <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                </>
              ) : 'Buy Invest'}

          </button>


          <div className="mb-2 mt-2">
            {/* <div className="tefo_error tefo_error_strong">
              {FFuser.isActive
                && !FFuser.isAllowBuy
                ? '*You are NOT Allowed to make a Buy Invest right now !' : ''}
            </div> */}
            <div className="tefo_error tefo_error_strong">
              {FFuser?.isActive
                // && FFuser.isAllowBuy
                // && TronNeed > 0
                // && Amount < (ProfitStonk * 2)
                // && (balanceWallet?.TRX?.Deposit >= TronNeed)
                && !BuyInvestSummary?.nccTotal
                && (balanceWallet?.TRX?.Deposit < TronNeed)
                && balanceWallet?.TRX?.Deposit >= 0
                ? `*Your TRX deposit balance must be at least ${TronNeed}
                     to be able to Buy Invest ` : ''}
              {/* after that Your TRX deposit balance will be less than ${TronNeed} ` : ''} */}
            </div>
            {/* {BuyInvestSummary?.nccTotal
              && (
                <div className="activeTime_plan">
                  Activation Date :
                  {' '}
                  <span>
                    {BuyInvestSummary?.created_at.split(' ')[0]}
                    {' '}
                    At
                    {' '}
                    {BuyInvestSummary?.created_at.split(' ')[1]}
                  </span>
                </div>
              )} */}

          </div>
        </div>
      </div>
    </>
  );
};

BuyInvest.propTypes = {
  FFuser: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  balanceWallet: PropTypes.objectOf.isRequired,
  depositWallet: PropTypes.objectOf.isRequired,
  BuyInvestSummary: PropTypes.objectOf.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  voidPlans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  plans: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tron: PropTypes.string.isRequired,
  ncc: PropTypes.string.isRequired,
  buyInvestLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo,
    constants,
    currency,
    loader,
  } = state;
  return {
    FFuser: userInfo?.user?.FFuser,
    havePlans: userInfo?.user?.havePlans,
    balanceWallet: userInfo?.user?.balanceWallet,
    depositWallet: userInfo?.user?.depositWallet,
    BuyInvestSummary: userInfo.user?.BuyInvestSummary?.active,
    haveSecPass: userInfo?.user?.haveSecPass,
    voidPlans: userInfo?.user?.voidPlans,
    plancrypto: constants?.plancrypto,
    plans: constants?.plans,
    currencies: constants?.currencies,
    tron: currency.tron,
    ncc: currency.ncc,
    buyInvestLoad: loader.buyInvestLoad,
  };
}


export default connect(mapStateToProps)(BuyInvest);
