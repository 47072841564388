const SET_CONSTANTS = 'SET_CONSTANTS';
const SET_PLAN_CRYPTO = 'SET_PLAN_CRYPTO';
const CLEAR_CONSTANTS = 'CLEAR_CONSTANTS';
const SET_PLAN_CONSTANTS = 'SET_PLAN_CONSTANTS';
const SET_PLAN_PROFIT_SETTING = 'SET_PLAN_PROFIT_SETTING';
const SET_PLAN_WITHDRAW_SETTING = 'SET_PLAN_WITHDRAW_SETTING';
const SET_STRUCTURES = 'SET_STRUCTURES';
const SET_BANNERS = 'SET_BANNERS';

const initialState = {
  constants: null,
};

const constantReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_CONSTANTS:
      return {
        ...state,
        constants: action.constants,
        plans: action.plans,
        currencies: action.cryptocurrencies,
        plancrypto: action.plancryptocurrencydtls,
        deskMap: action.deskMap,
        planProfitSetting: action.planProfitSetting,
        planWithdrawSetting: action.planWithdrawSetting,
      };
    case SET_PLAN_CRYPTO:
      return {
        ...state,
        plancrypto: action.plancrypto,
      };
    case SET_PLAN_CONSTANTS:
      return {
        ...state,
        plans: action.plans,
      };
    case SET_PLAN_PROFIT_SETTING:
      return {
        ...state,
        planProfitSetting: action.planProfitSetting,
      };
    case SET_PLAN_WITHDRAW_SETTING:
      return {
        ...state,
        planWithdrawSetting: action.planWithdrawSetting,
      };
    case SET_STRUCTURES:
      return {
        ...state,
        structures: action.structures,
      };
    case SET_BANNERS:
      return {
        ...state,
        banners: { desktop: action.deskBanners, mobile: action.mobBanners },
      };
    case CLEAR_CONSTANTS:
      return null;
    default:
      return state;
  }
};

export default constantReducer;

