const SET_LOADING_BUY = 'SET_LOADING_BUY';
const CLEAR_LOADING_BUY = 'CLEAR_LOADING_BUY';

const SET_LOADING_WITHDRAW = 'SET_LOADING_WITHDRAW';
const CLEAR_LOADING_WITHDRAW = 'CLEAR_LOADING_WITHDRAW';

const SET_LOADING_AFFILIATE = 'SET_LOADING_AFFILIATE';
const CLEAR_LOADING_AFFILIATE = 'CLEAR_LOADING_AFFILIATE';

const SET_LOADING_FUND = 'SET_LOADING_FUND';
const CLEAR_LOADING_FUND = 'CLEAR_LOADING_FUND';

const SET_LOADING_KYC = 'SET_LOADING_KYC';
const CLEAR_LOADING_KYC = 'CLEAR_LOADING_KYC';

const SET_LOADING_STAKING = 'SET_LOADING_STAKING';
const CLEAR_LOADING_STAKING = 'CLEAR_LOADING_STAKING';

const SET_LOADING_BUYINVEST = 'SET_LOADING_BUYINVEST';
const CLEAR_LOADING_BUYINVEST = 'CLEAR_LOADING_BUYINVEST';

const SET_LOADING_REFUND = 'SET_LOADING_REFUND';
const CLEAR_LOADING_REFUND = 'CLEAR_LOADING_REFUND';

const SET_LOADING_AWARD = 'SET_LOADING_AWARD';
const CLEAR_LOADING_AWARD = 'CLEAR_LOADING_AWARD';

const SET_LOADING_MAGIC = 'SET_LOADING_MAGIC';
const CLEAR_LOADING_MAGIC = 'CLEAR_LOADING_MAGIC';

const SET_LOADING_NFT = 'SET_LOADING_NFT';
const CLEAR_LOADING_NFT = 'CLEAR_LOADING_NFT';



const initialState = {
  buyLoad: false,
  withdrawLoad: false,
  affiliateLoad: false,
  fundLoad: false,
  kycLoad: false,
  stackingLoad: false,
};

const loaderReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_LOADING_BUY:
      return {
        ...state,
        buyLoad: true,
      };
    case CLEAR_LOADING_BUY:
      return {
        ...state,
        buyLoad: false,
      };
    case SET_LOADING_WITHDRAW:
      return {
        ...state,
        withdrawLoad: true,
      };
    case CLEAR_LOADING_WITHDRAW:
      return {
        ...state,
        withdrawLoad: false,
      };
    case SET_LOADING_AFFILIATE:
      return {
        ...state,
        affiliateLoad: true,
      };
    case CLEAR_LOADING_AFFILIATE:
      return {
        ...state,
        affiliateLoad: false,
      };
    case SET_LOADING_FUND:
      return {
        ...state,
        fundLoad: true,
      };
    case CLEAR_LOADING_FUND:
      return {
        ...state,
        fundLoad: false,
      };
    case SET_LOADING_KYC:
      return {
        ...state,
        kycLoad: true,
      };
    case CLEAR_LOADING_KYC:
      return {
        ...state,
        kycLoad: false,
      };
    case SET_LOADING_STAKING:
      return {
        ...state,
        stackingLoad: true,
      };
    case CLEAR_LOADING_STAKING:
      return {
        ...state,
        stackingLoad: false,
      };
    case SET_LOADING_BUYINVEST:
      return {
        ...state,
        buyInvestLoad: true,
      };
    case CLEAR_LOADING_BUYINVEST:
      return {
        ...state,
        buyInvestLoad: false,
      };
    case SET_LOADING_REFUND:
      return {
        ...state,
        refundLoad: true,
      };
    case CLEAR_LOADING_REFUND:
      return {
        ...state,
        refundLoad: false,
      };
    case SET_LOADING_AWARD:
      return {
        ...state,
        awardLoad: true,
      };
    case CLEAR_LOADING_AWARD:
      return {
        ...state,
        awardLoad: false,
      };
    case SET_LOADING_MAGIC:
      return {
        ...state,
        magicLoad: true,
      };
    case CLEAR_LOADING_MAGIC:
      return {
        ...state,
        magicLoad: false,
      };
    case SET_LOADING_NFT:
      return {
        ...state,
        nftLoad: true,
      };
    case CLEAR_LOADING_NFT:
      return {
        ...state,
        nftLoad: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
