import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { getNftSummary } from '../../../../../redux/actions/userActions';
import NoSecPass from '../../../../Layout/NoSecPass';
import { serverbetaURL } from '../../../../../utils/url';
import { clearLoadingNft, setLoadingNft } from '../../../../../redux/actions/loaderAction';
import { showLogs } from '../../../../App/Rules';
import { getNccFinanceImgUrl } from '../../../../../shared/helpers/themHelper';




const NftJoin = ({
  theme,
  havePlans,
  balanceWallet,
  isAllowPlan,
  isActive,
  FFCode,
  haveSecPass,
  plans,
  currencies,
  plancrypto,
  nftLoad,
}) => {
  const [nftImgURL, setNftImgURL] = useState();

  useEffect(() => {
    setNftImgURL(`${process.env.PUBLIC_URL}${getNccFinanceImgUrl()}`);
  }, [theme]);

  const [fillcolor, setFillcolor] = useState('#3cd2a5');

  const [valueChart, setValueChart] = useState(90);
  const level1 = [{ value: valueChart / 10, fill: fillcolor },
  { value: 100 - valueChart / 10, fill: '#f2f4f7' }];
  const level1More = [{ value: 100, fill: fillcolor },
  { value: 0, fill: '#f2f4f7' }];

  const [, forceUpdate] = useState();

  const [haveNft, setHaveNft] = useState();

  const secInput = useRef(null);

  const [teronAmount, setTeronAmount] = useState(0);
  const [tetherAmount, setTetherAmount] = useState(0);

  const [secPass, setSecPass] = useState();

  const [TronStock, setTronStock] = useState(50);
  const [tetherNeed, setTetherNeed] = useState(110);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const validator = useRef(new SimpleReactValidator());

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };
  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (balanceWallet) {
      setTetherAmount(balanceWallet?.USDT?.Deposit || 0);
    }
  }, [havePlans, plancrypto]);


  const handleJoinNft = async () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastReq', new Date().getTime());

      const nftDataSend = {
        FFCode,
        secPass,
        planCryptoCurrencyId: '14',
        toCryptoCurrencyId: '0',
        awardstructuresId: '1',
        isWithdrawByService: 0,
        amount: Number(tetherNeed),
        token: localStorage.getItem('token'),
      };

      if (showLogs) {
        console.log('nftDataSend :', nftDataSend);
      }

      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);

      dispatch(setLoadingNft());
      axios.post(`${serverbetaURL}/nfts/set`, JSON.stringify(nftDataSend), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('/nft/set :', data);
          }
          dispatch(getNftSummary());
          dispatch(clearLoadingNft());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(getNftSummary());
          dispatch(clearLoadingNft());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleJoinNft();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleJoinNft}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">NFT Plan</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <br />
          <br />
          <div className="d-flex justify-content-around">
            <span className="font-weight-bold">
              <img src={nftImgURL} className="crypto_moda_img" alt="" />
              {tetherNeed} $
            </span>
          </div>
          <br />
          <br />
          You are going to pay {tetherNeed} Tether to join the NFT plan .Do you confirm ?
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>


      <div className="text-center mb-3">
        <img src={nftImgURL} alt="" className="nft_img w-50" />
      </div>
      <div className="mb-3 text-center">

        <>
          <button
            type="submit"
            className="btn btn-outline-theme tefo_buttons_md w-50"
            // onClick={toggle}
            disabled={haveNft
              || !isAllowPlan
              || (teronAmount < TronStock)
              || (tetherAmount < tetherNeed)
              || nftLoad
              || true}
          >
            {nftLoad ? (
              <>
                <div className="spinner-border text-success spinner-border-sm" />  Processing ...
              </>
            ) : 'Join Now !'}
          </button>
        </>

      </div>
    </>
  );
};

NftJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  plans: PropTypes.string.isRequired,
  plancrypto: PropTypes.string.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  nftLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, constants, loader, theme,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    affiliateLoad: loader.nftLoad,
    theme: theme.className,
  };
}

export default connect(mapStateToProps)(NftJoin);

