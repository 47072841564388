import axios from 'axios';
import {
  browserName, browserVersion, isMobile, isAndroid, osName, mobileModel,
} from 'react-device-detect';


const getDeviceInfo = async () => {
  const devinfo = {};
 await axios.get('https://geolocation-db.com/json/')
  .then((ipRes) => { 
    devinfo.country_code = ipRes.data.country_code;
    devinfo.country_name = ipRes.data.country_name;
    devinfo.city = ipRes.data.city;
    devinfo.IPv4 = ipRes.data.IPv4;
    devinfo.browserName = browserName;
    devinfo.browserVersion = browserVersion;
    devinfo.isMobile = isMobile;
    devinfo.isAndroid = isAndroid;
    devinfo.osName = osName;
    devinfo.mobileModel = mobileModel;
  }) 
  .catch((err) => {
    console.log(err);
    devinfo.country_code = null;
    devinfo.country_name = null;
    devinfo.city = null;
    devinfo.IPv4 = null;
    devinfo.browserName = null;
    devinfo.browserVersion = null;
    devinfo.isMobile = null;
    devinfo.isAndroid = null;
    devinfo.osName = null;
    devinfo.mobileModel = null;
    });
    return devinfo;
};

export default getDeviceInfo;

