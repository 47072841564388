import { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   accessor: 'id',
      //   // Footer: 'Middle age:',
      //   disableGlobalFilter: true,
      //   // width: 1000,
      // },
      {
        Header: 'Status',
        accessor: 'Status',
        customRender: true,
        disableGlobalFilter: true,
      },
      {
        Header: 'Date Of Join',
        accessor: 'date',
        disableGlobalFilter: true,
      },
      {
        Header: 'FF Code',
        accessor: 'FFCode',
        // disableGlobalFilter: true,
      },
      {
        Header: 'Parent FF Code',
        accessor: 'ParentFFCode',
        // disableGlobalFilter: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 100; i += 1) {
      data.push({
        id: i,
        date: getRandomDate(new Date(2022, 3, 1), new Date(2015, 3, 1)),
        Status: ['active', 'NonActive'][Math.floor((Math.random() * 2))],
        FFCode: Math.round(Math.random() * 3365659516) + 2024541918,
        ParentFFCode: Math.round(Math.random() * 3365659516) + 2024541918,
        Activity: ['activateWallet checked', 'activateWallet'][Math.floor((Math.random() * 2))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
