import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  UncontrolledTooltip, Button, Modal, ButtonToolbar, Col, Row, Badge,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReactSpeedometer from 'react-d3-speedometer';
import { setBalance, setHavePlan, getRefferal } from '../../../../../redux/actions/userActions';
import AuthRequest from '../../../../../services/AuthFetchRequest';
import { store } from '../../../../App/store';
import serverURL from '../../../../../utils/url';
import { clearLoadingStacking, setLoadingStacking } from '../../../../../redux/actions/loaderAction';
import DrawTable from './DrawTable';
import CodeDropdown from '../../../../../shared/components/CodeDropdown';



const MagicReport = ({
  balanceWallet,
  havePlans,
  stackingLoad,
  FFCode,
  isActive,
  isAllowPlan,
  haveSecPass,
  plans,
  plancrypto,
  currencies,
  tab,
  StackingSummary,
}) => {
  const [showHistory, setShowHistory] = useState(false);
  // const [isDraw, setIsDraw] = useState(true);
  const [isDraw, setIsDraw] = useState(true);

  const [day, setDay] = useState(1);
  const [hour, setHour] = useState(2);
  const [minute, setMinute] = useState(3);
  const [second, setSecond] = useState(4);

  const items = [
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
    '3216546551',
  ];
  const items2 = [
    '5498465165',
    '5498465165',
    '5498465165',
    '5498465165',
  ];

  const secondsToTime = async (secs) => {
    if (secs > 0) {
      const days = Math.floor(secs / (60 * 60 * 24));
      await setDay(days);

      const divisorForHours = secs % (60 * 60 * 24);
      const hours = Math.floor(divisorForHours / (60 * 60));
      await setHour(hours < 10 ? `0${hours}` : hours);

      const divisorForMinutes = secs % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);
      await setMinute(minutes < 10 ? `0${minutes}` : minutes);

      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.ceil(divisorForSeconds === 0 ? 59 : divisorForSeconds);
      await setSecond(seconds < 10 ? `0${seconds - 1}` : seconds - 1);
    } else {
      await setDay(0);
      await setHour(0);
      await setMinute(0);
      await setSecond(0);
    }
  };


  useEffect(() => {
    setInterval(() => {
      // secondsToTime(1000000);
      secondsToTime(1658983211 - ((new Date().getTime()) / 1000));
      // console.log(new Date().getTime());
    }, 1000);
  }, []);



  return (
    <>
      <div className="text-center mt-3">
        {showHistory ? (
          <DrawTable setShowHistory={setShowHistory} />
        ) : (
          <>
            {isDraw ? (
              <>
                <div>
                  <h1
                    className="magic_title_gold"
                  // onClick={() => setIsDraw(!isDraw)}
                  // onKeyDown={() => setIsDraw(!isDraw)}
                  >
                    Draw Done !
                  </h1>
                  <button type="button" className="btn_aghaie" onClick={() => setIsDraw(!isDraw)}>.</button>
                </div>
                <div className="magic_result_holder">
                  <div className="magic_result_value">
                    22
                  </div>
                  <div className="magic_result_value">
                    01
                  </div>
                  <div className="magic_result_value">
                    57
                  </div>
                  <div className="magic_result_value">
                    89
                  </div>
                  <div className="magic_result_value">
                    10
                  </div>
                </div>
                <div>
                  <h4 className="magic_title_gold">
                    Next Draw Starts at :
                  </h4>
                  <h4 className="">
                    30 Jun 2022
                  </h4>
                  <h5 className="magic_title_gold">
                    Are You Ready ?
                  </h5>
                </div>
                <div className="magic_amount w-50 m-auto">
                  <p className="text-left">
                    Your amount :
                    {' '}
                    <span className="magic_usdt_amount">
                      0
                    </span>
                    {' '}
                    USDT
                  </p>
                  <div className="meter red">
                    <span style={{ width: '5%' }} />
                  </div>
                </div>
                <div className="magic_codes_holder">
                  <Row>
                    <Col md={2} />
                    <Col md={4} xs={6}>
                      <CodeDropdown
                        items={items}
                        title="+ Chance Codes"
                      />
                      <div className="topbar__btn-new-label">
                        <Badge className="codes_tefo__link-badge">
                          <span>
                            +2
                          </span>
                        </Badge>
                      </div>
                    </Col>
                    <Col md={4} xs={6}>
                      <CodeDropdown
                        items={items2}
                        title="+ Chance Codes"
                      />
                    </Col>
                    <div className="topbar__btn-new-label">
                      <Badge className="codes_tefo__link-badge">
                        <span>
                          +3
                        </span>
                      </Badge>
                    </div>
                    <Col md={2} />
                  </Row>
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn magic_history_btn"
                    onClick={() => setShowHistory(true)}
                  >
                    Draw History
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className="magic_draw_time">
                    Draw Time:
                    {' '}
                    <span>
                      30 june 2022
                    </span>
                  </p>
                  <button type="button" className="btn_aghaie" onClick={() => setIsDraw(!isDraw)}>.</button>
                  <div className="magic_timer">
                    <span className="magic_timer_value">
                      {day}
                    </span>
                    {' '}
                    :
                    {' '}
                    <span className="magic_timer_value">
                      {hour}
                    </span>
                    {' '}
                    :
                    {' '}
                    <span className="magic_timer_value">
                      {minute}
                    </span>
                    {' '}
                    :
                    {' '}
                    <span className="magic_timer_value">
                      {second}
                    </span>
                  </div>
                  <div className="magic_label">
                    <span className="magic_timer_label">
                      Day
                    </span>
                    {' '}

                    {' '}
                    <span className="magic_timer_label">
                      Hour
                    </span>
                    {' '}

                    {' '}
                    <span className="magic_timer_label">
                      Minute
                    </span>
                    {' '}

                    {' '}
                    <span className="magic_timer_label">
                      Second
                    </span>
                  </div>
                </div>

                <div className="magic_amount mt-3">
                  <p className="text-left">
                    Your amount :
                    {' '}
                    <span className="magic_usdt_amount">
                      53
                    </span>
                    {' '}
                    USDT
                  </p>
                  <div className="meter red">
                    <span style={{ width: '42%' }} />
                  </div>
                </div>

                <div className="magic_codes_holder">
                  <Row>
                    <Col md={2} />
                    <Col md={4} xs={6}>
                      <CodeDropdown
                        items={items}
                        title="+ Chance Codes"
                      />
                      <div className="topbar__btn-new-label">
                        <Badge className="codes_tefo__link-badge">
                          <span>
                            +3
                          </span>
                        </Badge>
                      </div>
                    </Col>
                    <Col md={4} xs={6}>
                      <CodeDropdown
                        items={items2}
                        title="+ Chance Codes"
                      />
                      <div className="topbar__btn-new-label">
                        <Badge className="codes_tefo__link-badge">
                          <span>
                            +3
                          </span>
                        </Badge>
                      </div>
                    </Col>
                    <Col md={2} />
                  </Row>
                </div>

                <div className="text-right">
                  <button
                    type="button"
                    className="btn magic_history_btn"
                    onClick={() => setShowHistory(true)}
                  >
                    Draw History
                  </button>
                </div>
              </>
            )}
          </>
        )}


      </div>

    </>
  );
};

MagicReport.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  stackingLoad: PropTypes.bool.isRequired,
  FFCode: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  plans: PropTypes.objectOf.isRequired,
  plancrypto: PropTypes.objectOf.isRequired,
  StackingSummary: PropTypes.objectOf.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  tab: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, loader, constants } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    stackingLoad: loader.stackingLoad,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    haveSecPass: userInfo.user?.haveSecPass,
    StackingSummary: userInfo.user?.StackingSummary,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
  };
}


export default connect(mapStateToProps)(MagicReport);
