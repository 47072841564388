import React, { useEffect, useState } from 'react';
import {
  Col,
  Progress, Row,
} from 'reactstrap';
import { store } from '../../../../App/store';


const TimeLine = () => {
  const [value, setValue] = useState(91);
  const [period, setPeriod] = useState(0);


  useEffect(() => {
    // console.log(Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)));
    // console.log(store.getState().userInfo.user?.FFuser?.created_at);
    // alert(TimeNow);
    // alert(TimeStart);

    setTimeout(() => {
      const TimeNow = new Date().getTime();
      const TimeStart = Date.parse(store.getState().userInfo.user?.FFuser?.created_at);
      if (Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)) < 1) {
        setValue(1);
        setPeriod(1);
      } else {
        setValue(Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)));
        setPeriod(Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)));
      }
      // setValue(Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)));
      // setPeriod(Math.round((TimeNow - TimeStart) / (1000 * 60 * 60 * 24)));
      // console.log(first);
    }, 1000);
  }, []);


  return (
    <>
      {/* <div className="card__title Progress_title" /> */}
      {/* <h5 className="subhead text-center" >

        </h5>
      </div> */}
      <Row className="timeLine_row">
        <Col md={2} xs={3}>
          <div
            className="progress-wrap progress-wrap--big progress_period"
          >
            <Progress
              value={100}
            >
              <span
                className={Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1) >= 70
                  ? 'days_red' : ''}
              >
                Period {Math.floor((period - 1) / 90) + 1 > 1 ? Math.floor((period - 1) / 90) : ''}
              </span>
            </Progress>
          </div>
        </Col>
        <Col>
          <div
            className={Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1) >= 70
              ? 'progress-wrap progress-wrap--big back_red_p' : 'progress-wrap progress-wrap--big'}
          >
            <Progress
              value={(Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1) / 90) * 100}
            >
              <span
                className={Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1) >= 70
                  ? 'days_red' : ''}
              >
                {Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1)}

                {Math.round((((value - 1) / 90) - Math.floor((value - 1) / 90)) * 90 + 1) < 20 ? 'd' : ' Days'}
              </span>
            </Progress>
          </div>
        </Col>
      </Row>

      {/* <div
        className={value >= 70 ? 'progress-wrap progress-wrap--big back_red_p' : 'progress-wrap progress-wrap--big'}
      >
        <Progress
          value={(value / 90) * 100}
        >
          <span
            className={value >= 70 ? 'days_red' : ''}
          >
            {value} {value < 20 ? 'd' : 'Day'}
          </span>
        </Progress>
      </div> */}
    </>
  );
};

export default TimeLine;

