import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Modal, Button, ButtonToolbar,
} from 'reactstrap';

import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { getAwardSummary } from '../../../../redux/actions/userActions';
import NoSecPass from '../../../Layout/NoSecPass';
import { serverbetaURL, serverURL } from '../../../../utils/url';
import { clearLoadingAward, setLoadingAward } from '../../../../redux/actions/loaderAction';
import { showLogs } from '../../../App/Rules';
import {
  getAwardBoardImgUrl, getAwardResultImg, getCryptoImgUrl, getLegendImgUrl,
} from '../../../../shared/helpers/themHelper';




const AwardJoin = ({
  theme,
  havePlans,
  balanceWallet,
  isAllowPlan,
  isActive,
  FFCode,
  haveSecPass,
  plans,
  currencies,
  plancrypto,
  awardSummary,
  awardLoad,
  nccPrice,
  structure,
}) => {
  const [tetherImgURL, setTetherImgURL] = useState();
  const [legendImgURL, setLegendImgURL] = useState();
  const [boardImgURL, setBoardImgURL] = useState();
  const [resultImgURL, setResultImgURL] = useState();

  const [activeStructure, setActiveStructure] = useState({});
  const [revivalStructure, setRevivalStructure] = useState({});

  useEffect(() => {
    setTetherImgURL(`${process.env.PUBLIC_URL}${getCryptoImgUrl().tether}`);
    setLegendImgURL(`${process.env.PUBLIC_URL}${getLegendImgUrl()}`);
    setBoardImgURL(`${process.env.PUBLIC_URL}${getAwardBoardImgUrl()}`);
    setResultImgURL(`${process.env.PUBLIC_URL}${getAwardResultImg()}`);
  }, [theme]);

  const [, forceUpdate] = useState();

  const [disable, setDisable] = useState(false);

  const secInput = useRef(null);

  const dispatch = useDispatch();

  const validator = useRef(new SimpleReactValidator());

  const [TronStock, setTronStock] = useState(50);

  const [teronAmount, setTeronAmount] = useState(0);
  const [tetherAmount, setTetherAmount] = useState(0);
  const [tetherNeed, setTetherNeed] = useState(100);

  const [secPass, setSecPass] = useState();

  const [isRevival, setIsRevival] = useState(false);



  const [modalRevival, setModalRevival] = useState(false);
  const toggleRevival = () => {
    setModalRevival(prevState => !prevState);
  };

  const [passModalRevival, setPassModalRevival] = useState(false);
  const togglePassRevival = () => {
    setPassModalRevival(prevState => !prevState);
  };

  const handleGoToPassRevival = () => {
    toggleRevival();
    togglePassRevival();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };

  const [modalClose, setModalClose] = useState(false);
  const toggleClose = () => {
    setModalClose(prevState => !prevState);
  };

  const [passModalClose, setPassModalClose] = useState(false);
  const togglePassClose = () => {
    setPassModalClose(prevState => !prevState);
  };


  const handleGoToPassClose = () => {
    toggleClose();
    togglePassClose();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };


  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [passModal, setPassModal] = useState(false);
  const togglePass = () => {
    setPassModal(prevState => !prevState);
  };

  const handleGoToPass = () => {
    toggle();
    togglePass();
    setSecPass('');
    setTimeout(() => {
      secInput.current?.focus();
    }, 1000);
    validator.current.hideMessageFor('Security Password');
    forceUpdate(13);
  };


  useEffect(() => {
    // if (structure[0]) {
    //   if (awardSummary.find(a => a.HaveTime > 0)) {
    //     setActiveStructure(structure?.find(s => s.id === awardSummary.find(a => a.HaveTime > 0)?.awardstructuresId));
    //   } else {
    setActiveStructure(structure[0]);
    //   }
    // }
  }, []);


  useEffect(() => {
    if (balanceWallet) {
      setTeronAmount(balanceWallet?.TRX?.Deposit || 0);
      setTetherAmount(balanceWallet?.USDT?.Deposit || 0);
    }
    if (awardSummary.find(a => a.HaveTime > 0)) {
      setDisable(true);
      setIsRevival(true);
    } else {
      setDisable(false);
      setIsRevival(false);
    }
    // const curruntStructuresId = awardSummary.find(a => a.HaveTime > 0)?.awardstructuresId;
    // if (curruntStructuresId <= 3) {
    //   setRevivalStructure(structure[curruntStructuresId]);
    // }
    setRevivalStructure(structure[0]);
  }, [havePlans, plancrypto, awardSummary]);


  const handleAward = async () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastReq', new Date().getTime());

      const awardDataSend = {
        FFCode,
        secPass,
        planCryptoCurrencyId: '11',
        toCryptoCurrencyId: '1',
        awardstructuresId: activeStructure?.id,
        isWithdrawByService: 0,
        amount: Number(tetherNeed),
        token: localStorage.getItem('token'),
      };

      if (showLogs) {
        console.log('awardDataSend :', awardDataSend);
      }

      setDisable(true);
      togglePass();
      validator.current.hideMessages();
      forceUpdate(13);

      dispatch(setLoadingAward());
      axios.post(`${serverbetaURL}/awards/set`, JSON.stringify(awardDataSend), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('/awards/set :', data);
          }
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });

      setDisable(false);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleAwardRevival = () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastReq', new Date().getTime());

      const awardRevivalDataSend = {
        FFCode,
        secPass,
        planCryptoCurrencyId: '11',
        cryptoCurrencyId: '3',
        userPlanId: awardSummary.find(a => a.HaveTime > 0)?.userPlanId,
        // toCryptoCurrencyId: '1',
        awardstructuresId: awardSummary.find(a => a.HaveTime > 0)?.awardstructuresId,
        amount: Number(tetherNeed),
        token: localStorage.getItem('token'),
      };

      if (showLogs) {
        console.log('award Revival DataSend :', awardRevivalDataSend);
      }

      setDisable(true);
      togglePassRevival();
      validator.current.hideMessages();
      forceUpdate(13);

      dispatch(setLoadingAward());
      axios.post(`${serverbetaURL}/awards/continuation`, JSON.stringify(awardRevivalDataSend), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('/awards/continuation :', data);
          }
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });

      setDisable(false);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleAwardClose = () => {
    if (validator.current.fieldValid('Security Password')) {
      console.clear();
      localStorage.setItem('lastReq', new Date().getTime());

      const awardCloseDataSend = {
        FFCode,
        secPass,
        planCryptoCurrencyId: '11',
        cryptoCurrencyId: '3',
        userPlanId: awardSummary.find(a => a.HaveTime > 0)?.userPlanId,
        // toCryptoCurrencyId: '1',
        awardstructuresId: awardSummary.find(a => a.HaveTime > 0)?.awardstructuresId,
        token: localStorage.getItem('token'),
      };

      if (showLogs) {
        console.log('award Close DataSend :', awardCloseDataSend);
      }

      setDisable(true);
      togglePassClose();
      validator.current.hideMessages();
      forceUpdate(13);

      dispatch(setLoadingAward());
      axios.post(`${serverbetaURL}/awards/fastClose`, JSON.stringify(awardCloseDataSend), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Credentials': true,
        },
      })
        .then((data) => {
          if (showLogs) {
            console.log('/awards/fastClose :', data);
          }
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          if (data?.status === 200) {
            if (data?.data?.status === false) {
              toast.error(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data?.data?.status === true) {
              toast.success(data?.data?.message, {
                position: 'top-center',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err) => {
          dispatch(getAwardSummary());
          dispatch(clearLoadingAward());
          console.log(err);
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          }
        });

      setDisable(false);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (
    <>
      <Modal
        isOpen={passModal}
        toggle={togglePass}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggle();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePass}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleAward}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={passModalClose}
        toggle={togglePassClose}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggle();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePassClose}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleAwardClose}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={passModalRevival}
        toggle={togglePassRevival}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{' '}</h4>
        </div>
        {haveSecPass ? (

          <div className="modal__body">
            <h5>Enter your Security Password : </h5>
            <br />
            <input
              id="secPass"
              className="form-control text-center secPass_input"
              type="password"
              placeholder=""
              maxLength="6"
              value={secPass}
              ref={secInput}
              onChange={e => setSecPass(e.target.value)}
              onFocus={e => setSecPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggle();
                }
              }}
            />
            <div className="tefo_error tefo_error_strong">
              {validator.current.message('Security Password', secPass, 'required|size:6|numeric')}
            </div>
            <ButtonToolbar className="modal__footer tefo_modal__footer">
              <Button className="modal_cancel" onClick={togglePassRevival}>Cancel</Button>{' '}
              <Button className="modal_ok" color="primary" onClick={handleAwardRevival}>Confirm</Button>
            </ButtonToolbar>
          </div>
        ) : (
          <NoSecPass togglePass={togglePass} />
        )}
      </Modal>


      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Award Plan</h4>
        </div>
        <div className="modal__body">
          <h5>Are you sure ? </h5>
          <div className="current_legend_holder">
            <div className="current_legend_structure">
              <span className="current_legend_prize">
                {activeStructure?.amount}
                <span>
                  USDT
                </span>
              </span>
              <img src={boardImgURL} className="current_board" alt="" />
            </div>
            <div className="current_legend_info">
              <div className="current_legend_info_duration text-left">
                <span>Duration :</span>
                {' '}
                <span>{activeStructure?.duration} Month</span>
              </div>
              <div className="current_legend_info_score text-left">
                <span>Score :</span>
                {' '}
                <span>1000 </span>
              </div>
            </div>
          </div>
          <div className="my-4">
            You have to earn 1000 score in {activeStructure?.duration} month to win {activeStructure?.amount} $
          </div>
          <div className="d-flex justify-content-around">
            <span className="font-weight-bold">
              <img src={tetherImgURL} className="crypto_moda_img" alt="" />
              {tetherNeed}
            </span>
          </div>
          You are going to
          pay {tetherNeed} Tether and recieve {Math.floor(tetherNeed / nccPrice)} NCC and join the Award plan .
          Do you confirm ?
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPass}>Confirm</Button>
        </ButtonToolbar>
      </Modal>


      <Modal
        isOpen={modalRevival}
        toggle={toggleRevival}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Revival Award</h4>
        </div>
        <div className="modal__body">
          <h5>Revival Award Plan with pay ${tetherNeed} $ </h5>
          <div className="current_legend_holder">
            <div className="current_legend_structure">
              <span className="current_legend_prize">
                {revivalStructure?.amount}
                <span>
                  USDT
                </span>
              </span>
              <img src={boardImgURL} className="current_board" alt="" />
            </div>
            <div className="current_legend_info">
              <div className="current_legend_info_duration text-left">
                <span>Duration :</span>
                {' '}
                <span>{revivalStructure?.duration} Month</span>
              </div>
              <div className="current_legend_info_score text-left">
                <span>Score :</span>
                {' '}
                <span>1000 </span>
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-around">
            <span className="font-weight-bold">
              <img src={tetherImgURL} className="crypto_moda_img" alt="" />
              {tetherNeed}
            </span>
          </div>

        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleRevival}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPassRevival}>Confirm</Button>
        </ButtonToolbar>
      </Modal>


      <Modal
        isOpen={modalClose}
        toggle={toggleClose}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">Close Award</h4>
        </div>
        <div className="modal__body">

          <div className="close_board_award position-relative">
            <img src={resultImgURL} alt="" className="w-75" />
            <div className="close_board_award_score">
              <span className="scrore_value_close">
                {awardSummary.find(a => a.HaveTime > 0)?.sumscore || 0}
              </span>
              <span>
                / 1000
              </span>
            </div>
            <div className="close_board_award_contract">
              <span>Score Price :</span>
              {' '}
              <span>{Number(awardSummary.find(a => a.HaveTime > 0)?.closePrice)?.toFixed(1)} $ </span>
            </div>
            <div className="close_board_award_prize">
              <span className="scrore_value_close">
                {(Number(awardSummary.find(a => a.HaveTime > 0)?.closePrice)?.toFixed(1))
                  * (awardSummary.find(a => a.HaveTime > 0)?.sumscore)}
              </span>
              $
            </div>
          </div>


        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_cancel" onClick={toggleClose}>Cancel</Button>{' '}
          <Button className="modal_ok" color="primary" onClick={handleGoToPassClose}>Confirm</Button>
        </ButtonToolbar>
      </Modal>





      <div className="text-center award_join_holder">


        <div className="current_legend_holder mb-1">
          <div className="currunt_legend_closePrice">
            <span>Score Price :</span>
            {' '}
            <span>{Number(activeStructure?.closePrice || '')?.toFixed(0)} $ </span>
          </div>
          <div className="current_legend_structure">
            <span className="current_legend_prize">
              {activeStructure?.amount || ''}
              <span>
                USDT
              </span>
            </span>
            <img src={boardImgURL} className="current_board" alt="" />
          </div>
          <div className="current_legend_info">
            <div className="current_legend_info_duration text-left">
              <span>Duration :</span>
              {' '}
              <span>{activeStructure?.duration || ''} Month</span>
            </div>
            <div className="current_legend_info_score text-left">
              <span>Score :</span>
              {' '}
              <span>1000 </span>
            </div>
          </div>
        </div>

        {/* <div className="list_legend_holder mb-3">
          {structure?.map((item, i) => (
            <button
              onClick={() => setActiveStructure(structure?.find(s => s?.id === i + 1))}
              type="button"
              key={item?.id}
            >
              <img
                src={legendImgURL}
                alt=""
                className={((activeStructure?.id !== item?.id) && (!isRevival))
                  || (((awardSummary.find(a => a.HaveTime > 0)?.awardstructuresId) === item?.id) && (isRevival))
                  ? 'active_prize_legend' : ''}
              />
              <span className="list_legend_value">
                {item?.amount} $
              </span>
            </button>
          ))}

        </div> */}

        <div className="mt-5 award_range_holder text-center">

          <div className="award_range_input m-auto">

            <span>{tetherNeed} $</span>

          </div>


        </div>

        {isRevival ? (
          <div className="mt-4 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-outline-theme tefo_buttons_md w-25"
              onClick={toggleRevival}
              disabled={(awardSummary?.find(a => a.HaveTime > 0)?.awardstructuresId === 4)
                || (awardSummary.find(a => a.HaveTime > 0)?.HaveTime > 10)}
            >
              {awardLoad ? (
                <>
                  <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                </>
              ) : 'Revival'}
            </button>
            <button
              type="button"
              className="btn btn-outline-theme tefo_buttons_md w-25"
              onClick={toggleClose}
              disabled={(awardSummary?.find(a => a.HaveTime > 0)?.sumscore === '0')}
            >
              {awardLoad ? (
                <>
                  <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                </>
              ) : 'Close'}
            </button>
          </div>
        ) : (
          <>
            <div className="mt-4">
              <button
                type="submit"
                className="btn btn-outline-theme tefo_buttons_md w-50"
                onClick={toggle}
                disabled={
                  disable
                    || !isAllowPlan
                    || (teronAmount < TronStock)
                    || awardLoad
                    || (tetherAmount < tetherNeed) ? true : ''
                }
              >
                {awardLoad ? (
                  <>
                    <div className="spinner-border text-success spinner-border-sm" />  Processing ...
                  </>
                ) : 'Join Now !'}
              </button>
            </div>
            <div className="mb-2 mt-2">
              <div className="tefo_error tefo_error_strong">
                {isActive
                  && (balanceWallet?.TRX?.Deposit < TronStock)
                  && balanceWallet?.TRX?.Deposit >= 0
                  ? `*Your TRX deposit balance must be at least ${TronStock}
                     to be able to Buy Invest ` : ''}
              </div>
            </div>
          </>
        )}

      </div>


    </>
  );
};

AwardJoin.propTypes = {
  balanceWallet: PropTypes.objectOf.isRequired,
  havePlans: PropTypes.objectOf.isRequired,
  FFCode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  haveSecPass: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  isAllowPlan: PropTypes.string.isRequired,
  plans: PropTypes.string.isRequired,
  plancrypto: PropTypes.string.isRequired,
  nccPrice: PropTypes.string.isRequired,
  currencies: PropTypes.objectOf.isRequired,
  awardSummary: PropTypes.objectOf.isRequired,
  structure: PropTypes.objectOf.isRequired,
  awardLoad: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    userInfo, constants, loader, theme, currency,
  } = state;
  return {
    havePlans: userInfo.user?.havePlans,
    balanceWallet: userInfo.user?.balanceWallet,
    haveSecPass: userInfo.user?.haveSecPass,
    FFCode: userInfo.user?.FFuser?.FFCode,
    isActive: userInfo.user?.FFuser?.isActive,
    isAllowPlan: userInfo.user?.FFuser?.isAllowPlan,
    plans: constants?.plans,
    plancrypto: constants?.plancrypto,
    currencies: constants?.currencies,
    affiliateLoad: loader.affiliateLoad,
    theme: theme.className,
    awardLoad: loader.awardLoad,
    nccPrice: currency?.ncc,
    awardSummary: userInfo.user?.awardSummary,
    structure: constants?.structures?.awards || [{}, {}, {}, {}],
  };
}

export default connect(mapStateToProps)(AwardJoin);

