import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import {
  Badge,
  Button,
  ButtonToolbar,
  Col,
  Modal,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serverURL } from '../../../utils/url';
import AuthRequest from '../../../services/AuthFetchRequest';
import { setMesseges } from '../../../redux/actions/userActions';
import { getMainColor } from '../../../shared/helpers/themHelper';


const notificationIcon = `${process.env.PUBLIC_URL}/img/notification.svg`;

const TopbarNotification = ({
  collapseNotification, isCollapsed, dir, messages, FFCode, theme,
}) => {
  const [messagMmodal, setMessagMmodal] = useState(false);
  const [notifModal, setNotifModal] = useState();

  const [unreadColor, setUnreadColor] = useState();

  useEffect(() => {
    setUnreadColor(getMainColor());
  }, [theme]);

  const notifications = [];
  const periods = {
    year: 365 * 30 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  const dispatch = useDispatch();

  const toggleMessagMmodal = () => {
    setMessagMmodal(prevState => !prevState);
  };

  const closeMessagMmodal = () => {
    setMessagMmodal(false);
    collapseNotification();
    AuthRequest(`${serverURL}/holder/readAnnouncements`, {
      FFCode, announcementId: notifModal.id, token: localStorage.getItem('token'),
    }).then(res => res.json())
      .then((data) => {
        // console.log(data);
        if (data.numReq === 1 && data.sttReq === 'ok') {
          dispatch(setMesseges(data.announcements));
        } else if (data.numReq === 0 && data.sttReq === 'Not exist') {
          toast.error('Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (data.numReq === 0) {
          toast.error('The Proccess have not done, Try again later !', {
            position: 'top-center',
            hideProgressBar: true,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }).catch(() => {
        toast.error('Something went wrong , Try again later !', {
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


  const formatTime = (agoTime) => {
    if (agoTime > periods.year) {
      return `${Math.floor(agoTime / periods.year)} year ago`;
    }
    if (agoTime > periods.month) {
      return `${Math.floor(agoTime / periods.month)} month ago`;
    }
    if (agoTime > periods.week) {
      return `${Math.floor(agoTime / periods.week)} week ago`;
    }
    if (agoTime > periods.day) {
      return `${Math.floor(agoTime / periods.day)} day ago`;
    }
    if (agoTime > periods.hour) {
      return `${Math.floor(agoTime / periods.hour)} hour ago`;
    }
    if (agoTime > periods.minute) {
      return `${Math.floor(agoTime / periods.minute)} min ago`;
    }
    return 'Just now';
  };

  const rows = () => {
    messages?.slice(0, 5).map((message, i) => {
      // if (!message.pivot.isRead) {
      notifications.push({
        id: message?.pivot?.id,
        Status: message?.pivot?.isRead,
        date: formatTime(Date.now() - Date.parse(message?.pivot?.created_at)),
        dateModal: message.created_at.toString().split('T')[0],
        time: message.created_at.toString().split('T')[1].split('.')[0],
        Kind: message?.isAdminCreate,
        Subject: message?.announcementTitle,
        Attach: `attach&&${message.attachFileAddressLink}`,
        Description: message?.announcementDesc,
      });
      // }
      return notifications;
    });
  };

  rows();

  return (
    <>
      <Modal
        isOpen={messagMmodal}
        toggle={toggleMessagMmodal}
        modalClassName="ltr-support"
        className="modal-dialog--primary"
      >
        <div className="modal__header mb-1">
          <h4 className="text-modal modal__title">{notifModal?.Subject}</h4>
          <p> {notifModal?.dateModal} {notifModal?.time}</p>
        </div>
        <hr />
        <div className="modal__body mb-3 mt-3 text-left">
          <p>{notifModal?.Description} </p>
          <hr />
        </div>
        <ButtonToolbar className="modal__footer tefo_modal__footer">
          <Button className="modal_ok" color="primary" onClick={e => closeMessagMmodal(e)}>Dismiss</Button>
        </ButtonToolbar>
      </Modal>


      <div className="quice_info quice_info_notification">
        {isCollapsed && (
          <button
            className="topbar__back"
            aria-label="topbar__back"
            type="button"
            onClick={collapseNotification}
          />
        )}
        <div className="quice_info_img">
          <Button
            id="notification"
            onClick={collapseNotification}
            outline
            className={collapseNotification ? 'button-tooltip quiceInfo_btn'
              : 'button-tooltip quiceInfo_btn btn_quice_hover'}
          >
            {messages?.filter(m => m.pivot.isRead === 0).length > 0 && (
              <div className="topbar__btn-new-label">
                <Badge className="topbar_tefo__link-badge">
                  <span>
                    {messages?.filter(m => m.pivot.isRead === 0).length}
                  </span>
                </Badge>
              </div>
            )}
            <img
              className="quice_info_image quice_info_image_right "
              id="notification"
              alt=""
              src={notificationIcon}
            />
          </Button>

          <Popover
            placement="bottom"
            isOpen={isCollapsed}
            target="notification"
            toggle={collapseNotification}
            className="notification_popover topmenu_popovers"
            dir={dir}
          >

            <button type="button" className="btn_convertor" onClick={collapseNotification}>

              <span className="lnr lnr-cross" />
            </button>

            <PopoverHeader className="text-left">
              <p className="quice_info_title">
                Notifications
              </p>
            </PopoverHeader>
            <hr className="convertor_hr" />
            <PopoverBody className="notification_popover_body">

              {notifications.map(notification => (
                <button
                  type="button"
                  className="notif_topbar_btn"
                  onClick={(e) => {
                    setNotifModal(notification);
                    setMessagMmodal(true);
                    collapseNotification();
                  }}
                >
                  <div className="" key={notification.id}>
                    <p className="">
                      <Row>
                        <Col col={6} className="text-left">
                          <span className="message_sign">
                            {notification.Status ? (
                              <span className="unread_Message" />
                            ) : (
                              <span className="read_Message" style={{ backgroundColor: unreadColor }} />
                            )}
                          </span>
                          <span className="notification_title">
                            {notification.Subject}
                          </span>
                        </Col>
                        <Col col={6}>
                          <p className="notification_date">{notification.date}</p>
                        </Col>
                      </Row>
                    </p>
                  </div>
                </button>
              ))}

              <Link className="topbar__collapse-link" to="/Messeges" onClick={collapseNotification}>
                See all notifications
              </Link>

            </PopoverBody>

          </Popover>
        </div>
      </div>
    </>
  );
};

TopbarNotification.propTypes = {
  FFCode: PropTypes.string.isRequired,
  dir: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  collapseNotification: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  messages: PropTypes.objectOf.isRequired,
};

function mapStateToProps(state) {
  const { userInfo, theme } = state;
  return {
    messages: userInfo.user?.messages,
    FFCode: userInfo.user?.FFuser?.FFCode,
    theme: theme?.className,
  };
}


export default connect(mapStateToProps)(TopbarNotification);
